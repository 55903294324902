import { createSlice } from '@reduxjs/toolkit';

import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import Response from 'app/config/Response';

const initialState = {
  index: {
    data: [],
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc',
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
  create: {
    showProcessingModal: false,
    isCreating: false,
    isCreated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },
  edit: {
    isLoading: false,
    isError: false,
    errorCode: null,
    data: null,
    errorMessage: null,
  },
  update: {
    showProcessingModal: false,
    isUpdating: false,
    isUpdated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },
  show: {
    showBriefDetailModal: false,
    fundingSourceId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
};

export const fundingSourceSlice = createSlice({
  name: 'fundingSources',
  initialState,
  reducers: {
    getFundingSourcesInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getFundingSourcesSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getFundingSourcesFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetFundingSourcesFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    addFundingSourceInit: (state) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: true,
        isCreated: false,
        isFailed: false,
        errors: null,
        data: null,
      };
    },

    addFundingSourceSuccess: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },

    addFundingSourceFailure: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: false,
        isFailed: true,
        errors: null,
        data: null,
        errorMessage: payload?.response?.data?.message || '',
      };
    },

    retryToCreate: (state) => {
      state.create.retries += 1;
    },

    setShowCreateProcessingModal: (state, { payload }) => {
      state.create.showProcessingModal = payload;
    },

    editFundingSourceInit: (state) => {
      state.edit = {
        ...state.edit,
        isLoading: true,
        isError: false,
        errorCode: null,
        data: null,
        errorMessage: null,
      };

      state.update = {
        ...state.update,
        showProcessingModal: false,
      };
    },

    editFundingSourceSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.edit = {
          ...state.edit,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          data: null,
          errorMessage: 'FundingSource not found',
        };
      } else {
        state.edit = {
          ...state.edit,
          isLoading: false,
          isError: false,
          errorCode: null,
          data: payload.data,
        };
      }
    },

    editFundingSourceFailure: (state, { payload }) => {
      state.edit = {
        ...state.edit,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        data: payload,
      };
    },

    updateFundingSourceInit: (state) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: true,
        isUpdated: false,
        isFailed: false,
        errors: null,
        data: null,
      };
    },

    updateFundingSourceSuccess: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: false,
        isUpdated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },

    updateFundingSourceFailure: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: false,
        isUpdated: false,
        isFailed: true,
        errors: payload,
        data: null,
        errorMessage: payload?.response?.data?.message || '',
      };
    },

    retryToUpdate: (state) => {
      state.update.retries += 1;
    },

    setShowEditProcessingModal: (state, { payload }) => {
      state.update.showProcessingModal = payload;
    },

    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParam: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    getFundingSourceInit: (state) => {
      state.show = {
        ...state.show,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getFundingSourceSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          errorMessage: 'FundingSource not found',
        };
      } else {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: false,
          errorCode: null,
          errorMessage: null,
          data: payload.data,
        };
      }
    },

    getFundingSourceFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: null,
      };
    },

    resetGetFundingSourceFailure: (state) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getFundingSourceBriefDetailsInit: (state, { payload }) => {
      state.show = {
        ...state.show,
        showBriefDetailModal: payload.showBriefDetailModal,
        fundingSourceId: payload.fundingSourceId,
      };
    },

    hideFundingSourceBriefDetails: (state) => {
      state.show = {
        showBriefDetailModal: false,
        fundingSourceId: null,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    deleteFundingSourceInit: (state, { payload: fundingSourceId }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == fundingSourceId) {
          return {
            ...item,
            isDeleting: true,
          };
        }
        return item;
      });
    },

    deleteFundingSourceSuccess: (state, { payload: fundingSourceId }) => {
      const { page_number: currentPage, page_size: pageSize } = state.index.params;

      let startSN = (currentPage - 1) * pageSize;

      let data = state.index.data.filter((item) => item.id !== fundingSourceId);

      state.index.data = data.map((item) => ({ ...item, sn: (startSN += 1) }));
    },

    deleteFundingSourceFailure: (state, { payload: fundingSourceId }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == fundingSourceId) {
          return {
            ...item,
            isDeleting: false,
          };
        }
        return item;
      });
    },

    updateIsActiveStatusInit: (state, { payload: { fundingSourceId, isActive } }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == fundingSourceId) {
          return {
            ...item,
            is_active: isActive,
            isTogglingIsActiveStatus: true,
          };
        }
        return item;
      });
    },

    updateIsActiveStatusSuccess: (state, { payload: { fundingSourceId, isActive } }) => {},

    updateIsActiveStatusFailure: (state, { payload: { fundingSourceId, isActive } }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == fundingSourceId) {
          return {
            ...item,
            is_active: !isActive,
            isTogglingIsActiveStatus: false,
          };
        }
        return item;
      });
    },
  },
});

export const {
  getFundingSourcesInit,
  getFundingSourcesSuccess,
  getFundingSourcesFailure,
  resetGetFundingSourcesFailure,
  addFundingSourceInit,
  addFundingSourceSuccess,
  addFundingSourceFailure,
  retryToCreate,
  updateFundingSourceInit,
  updateFundingSourceSuccess,
  updateFundingSourceFailure,
  retryToUpdate,
  setParam,
  resetParam,
  deleteFilterParams,
  setShowCreateProcessingModal,
  setShowEditProcessingModal,
  getFundingSourceInit,
  getFundingSourceSuccess,
  getFundingSourceFailure,
  resetGetFundingSourceFailure,
  editFundingSourceInit,
  editFundingSourceSuccess,
  editFundingSourceFailure,
  getFundingSourceBriefDetailsInit,
  hideFundingSourceBriefDetails,
  deleteFundingSourceInit,
  deleteFundingSourceSuccess,
  deleteFundingSourceFailure,
  updateIsActiveStatusInit,
  updateIsActiveStatusFailure,
  updateIsActiveStatusSuccess
} = fundingSourceSlice.actions;

export default fundingSourceSlice.reducer;
