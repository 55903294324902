import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import Button from 'core/buttons/Button';
import TextButton from 'core/buttons/TextButton';
import AuthFormWrapper from 'core/forms/AuthFormWrapper';
import FormTextField from 'hook-form-controls/FormTextField';
import PasswordResetLinkExpired from './PasswordResetLinkExpired';

import app from 'app/config/app';
import Notify from 'utils/Notify';
import { buildRoute } from 'routes';
import YupPassword from 'utils/YupPassword';
import http from 'app/services/httpService';
import apiEndpoints from 'routes/apiEndpoints';
import PasswordResetSuccess from './PasswordResetSuccess';

YupPassword(Yup);

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must contain 8 or more characters')
    .minLowercase(1, 'Password must contain at least 1 lower case letter')
    .minUppercase(1, 'Password must contain at least 1 upper case letter')
    .minNumbers(1, 'Password must contain at least 1 number')
    .minSymbols(1, 'Password must contain at least 1 special character'),
  confirm_password: Yup.string()
    .required('Please retype your password.')
    .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
});

function ResetPasswordForm({ userId, code }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [isResetSuccess, setIsResetSuccess] = useState(false);

  const theme = useTheme();

  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      user_id: userId,
      code,
      ...(app.isDevelopmentMode()
        ? {
            password: 'Kathmandu@123',
            confirm_password: 'Kathmandu@123',
          }
        : {}),
    },
    resolver: yupResolver(resetPasswordSchema),
    mode: 'onChange',
  });

  const {
    formState: { errors },
    handleSubmit,
  } = methods;

  const onSubmit = async (data) => {
    setIsProcessing(true);
    try {
      let endpoint = app.apiBaseUrl + apiEndpoints.resetPassword;
      await http.post(endpoint, data);
      setIsResetSuccess(true);
    } catch (err) {
      if (err?.response?.status === 400) {
        setIsLinkExpired(true);
      } else {
        Notify.error('Failed to reset password');
      }
    }
    setIsProcessing(false);
  };

  const handleBackToLogin = (e) => {
    if (confirm("You're just a click away to reset password. \nYou want to leave this page ?"))
      navigate(buildRoute('login'));
  };

  const handleCancel = () => setIsLinkExpired(false);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AuthFormWrapper title="Reset Password" subtitle="">
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <FormTextField
                  name="password"
                  label="Password"
                  type="password"
                  error={!!errors?.password}
                  helperText={errors?.password?.message ?? ''}
                  size="large"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name="confirm_password"
                  label="Renter Password"
                  type="password"
                  error={!!errors?.confirm_password}
                  helperText={errors?.confirm_password?.message ?? ''}
                  size="large"
                  variant="standard"
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  type="submit"
                  size="large"
                  disabled={isProcessing}
                  sx={{ textTransform: 'none' }}
                  fullWidth
                >
                  {isProcessing ? 'Processing' : 'Reset Password'}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <a
                  onClick={handleBackToLogin}
                  style={{
                    color: theme.palette.primary.main,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  <Typography variant="body1">Goto Login</Typography>
                </a>
              </Grid>
            </Grid>
          </AuthFormWrapper>
        </form>
      </FormProvider>
      {isLinkExpired && <PasswordResetLinkExpired onCancel={handleCancel} />}
      {isResetSuccess && <PasswordResetSuccess />}
    </>
  );
}

export default ResetPasswordForm;

ResetPasswordForm.propTypes = {
  userId: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};
