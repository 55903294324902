import styled from '@mui/material/styles/styled';

import DrawerLogo from 'assets/logo/logo_white_horizontal.svg';

const DrawerWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.background.paper,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2, 2),
  columnGap: 25,
  alignItems: 'center',
  '& ."MuiTypography-root': {
    fontFamily: "'Public Sans', sans-serif",
    fontWeight: 600,
    fontSize: '2rem',
  },
}));

function DrawerContent() {
  return (
    <DrawerWrapper>
      <img src={DrawerLogo} height="40px" />
    </DrawerWrapper>
  );
}

export default DrawerContent;
