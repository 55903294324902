import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import KycStatus from 'core/badges/KycStatus';
import SpringModal from 'core/modal/SpringModal';
import TextButton from 'core/buttons/TextButton';
import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import GroupedTableView from 'core/table/GroupedTableView';
import FormSectionTitle from 'core/forms/FormSectionTitle';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import CustomerClickAwayDetails from 'components/customers/CustomerClickAwayDetails';

import Auth from 'utils/Auth';
import acl from 'app/config/acl';
import Notify from 'utils/Notify';
import range from 'helpers/range';
import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import { useConfirm } from 'core/mui-confirm';
import { kycStatusOptions } from 'app/config/KYC';
import { changeKycStatus, getCustomerKyc } from 'app/features/customer-kycs/customerKycSaga';
import { getCustomerKycDocument } from 'app/features/customer-kyc-documents/customerKycDocumentsSaga';
import CustomerKycDocument from './CustomerKycDocument';
import CustomerKycDocumentPagination from './CustomerKycDocumentPagination';

export default function CustomerKyc({ customerKycId }) {
  const dispatch = useDispatch();
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    imageUrl: '',
  });

  const [status, setStatus] = useState('');
  const [isEditStatus, setIsEditStatus] = useState(false);

  const {
    isError,
    errorCode,
    isLoading,
    data: customerKyc,
    isStatusProcessing,
  } = useSelector((state) => state.customerKycs.show);

  const confirm = useConfirm();

  const handleSubmit = () => {
    if (isEmpty(status)) {
      Notify.error('Select a status');
      return;
    }

    confirm({
      description: `You want to change status to ${status}`,
      confirmationText: 'Yes, Change it',
      cancellationText: 'Cancel',
    })
      .then(() => dispatch(changeKycStatus({ id: customerKyc.id, kyc_status: status })))
      .catch((err) => setIsEditStatus(false));
  };

  const viewCustomerKycData = useMemo(
    () => [
      {
        groupName: '',
        groupData: [
          {
            label: 'Customer Name',
            data: 'user_name',
          },
          {
            label: 'Customer ID',
            data: 'user_id',
            get: ({ user_id }) =>
              user_id ? <CustomerClickAwayDetails customerId={user_id} /> : '-',
          },
          {
            label: 'KYC Status',
            data: 'kyc_status',
            get: ({ kyc_status }) => {
              if (isEditStatus && [acl.SUPERADMIN, acl.Admin].includes(Auth.role())) {
                return (
                  <Stack flexDirection="column" gap={2}>
                    <FormControl size="small" fullWidth>
                      <InputLabel id="select-status-label">Status</InputLabel>
                      <Select
                        size="small"
                        labelId="select-status-label"
                        value={status}
                        label="Status"
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        {kycStatusOptions.map((kycStatus, k) => (
                          <MenuItem key={k} value={kycStatus.value}>
                            {kycStatus.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      <Button
                        onClick={handleSubmit}
                        size="small"
                        sx={{ textDecoration: 'none' }}
                        disabled={isStatusProcessing}
                      >
                        {isStatusProcessing ? 'processing' : 'save'}
                      </Button>
                      <TextButton
                        onClick={() => setIsEditStatus(false)}
                        color="error"
                        disabled={isStatusProcessing}
                      >
                        cancel
                      </TextButton>
                    </Box>
                  </Stack>
                );
              } else {
                return (
                  <Box sx={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                    <KycStatus status={kyc_status} />
                    {[acl.SUPERADMIN, acl.Admin].includes(Auth.role()) && (
                      <TextButton startIcon={<EditIcon />} onClick={() => setIsEditStatus(true)}>
                        edit
                      </TextButton>
                    )}
                  </Box>
                );
              }
            },
          },
          {
            label: 'KYC Method',
            data: 'kyc_method',
          },

          {
            label: 'KYC Type',
            data: 'kyc_type',
          },
          ...(Auth.isSuperAdmin()
            ? [
                {
                  label: 'Partner ',
                  data: 'partner_id',
                  get: ({ partner_id, partner_name }) => (
                    <PartnerClickAwayDetails label={partner_name} partnerId={partner_id} />
                  ),
                },
              ]
            : []),
          {
            label: 'Is Verified ?',
            data: 'is_verified',
            get: ({ is_verified }) => <VerifiedBadge isVerified={!!is_verified} />,
          },
          {
            label: 'Is Active ?',
            data: 'is_active',
            get: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
          },
          {
            label: 'Source',
            data: 'source_id',
          },
          {
            label: 'Created At',
            data: 'created_ts',
            get: ({ created_ts }) =>
              !isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '-',
          },
          {
            label: 'Created By',
            data: 'created_by',
          },
          {
            label: 'Updated At',
            data: 'updated_ts',
            get: ({ updated_ts }) =>
              !isEmpty(updated_ts) ? DateUtils.isoToString(updated_ts) : '-',
          },
          {
            label: 'Updated By',
            data: 'updated_by',
          },
        ],
      },
    ],
    [isEditStatus, status, isStatusProcessing, customerKyc?.kyc_status],
  );

  useEffect(() => {
    setIsEditStatus(false);
    if (customerKyc?.kyc_status) {
      setStatus(customerKyc.kyc_status);
    }
  }, [JSON.stringify(customerKyc)]);

  useEffect(() => {
    dispatch(getCustomerKyc(customerKycId));
  }, [customerKycId]);

  useEffect(() => {
    if (customerKyc?.user_id) {
      dispatch(getCustomerKycDocument(customerKyc?.user_id));
    }
  }, [customerKyc?.user_id, dispatch]);

  if (isError) return <Error status={errorCode} />;

  return (
    <>
      <Grid container spacing={2}>
        {viewCustomerKycData.map((group, i) => (
          <GroupedTableView
            key={i}
            title={group.groupName}
            data={group.groupData}
            item={customerKyc}
            isLoading={false}
          />
        ))}

        <Grid item xs={12}>
          <FormSectionTitle>Documents</FormSectionTitle>
          <Box sx={{ mt: 2 }}>
            <CustomerKycDocument customerId={customerKyc?.user_id} />
            <CustomerKycDocumentPagination />
          </Box>
        </Grid>
      </Grid>
      <SpringModal
        isOpen={imageModal.isOpen}
        header="Kyc Document"
        enableDefaultPadding
        onClose={() => {
          setImageModal({ isOpen: false, imageUrl: undefined });
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            height: '80vh',
            flexDirection: 'column',
            gap: '2rem',
          }}
        >
          <img
            src={imageModal.imageUrl}
            alt="Kyc document"
            style={{
              objectFit: 'contain',
              // backgroundPosition: 'center',
              width: '100%',
              height: 'calc(100% - 60px)',
            }}
          />
          {/* <Button
            as="a"
            href={imageModal?.imageUrl}
            target="_blank"
            rel="noreferrer"
            title="Open in new tab"
            style={{ textDecoration: 'none', color: '#fff' }}
          >
            Open in new Tab
          </Button> */}
        </Box>
      </SpringModal>
    </>
  );
}

CustomerKyc.propTypes = {
  customerKycId: PropTypes.string,
};
