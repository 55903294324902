import { utils, writeFileXLSX, writeFile } from 'xlsx';
import Notify from './Notify';
import { format } from 'date-fns';

class Sheet {
  /**
   * References: https://docs.sheetjs.com/docs/demos/react
   *
   * @param {Array} data
   * @param {String} fileName
   * @return {void}
   */
  static downloadJsonToExcel(data, fileName = 'data') {
    if (typeof data !== 'object' && !Array.isArray(data))
      throw new Error(
        'Sheet.downloadJsonToExcel() expects first parameter to be array. given ' + typeof data,
      );

    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, `${fileName + format(new Date(), 'yyyy-MM-dd')}.xlsx`);
  }

  /**
   * References
   * 1. https://docs.sheetjs.com/docs/demos/ml#csv-data-interchange
   * 2. https://stackoverflow.com/a/19328891/10525009
   *
   *
   * @param {Array} data
   * @param {String} fileName
   * @return {void}
   */

  static downloadJsonToCsv(data, fileName = 'data') {
    if (typeof data !== 'object' && !Array.isArray(data))
      throw new Error(
        'Sheet.downloadJsonToExcel() expects first parameter to be array. given ' + typeof data,
      );

    const ws = utils.json_to_sheet(data);
    /* generate CSV */
    const csv = utils.sheet_to_csv(ws);
    /* CSV -> Uint8Array -> Blob */
    const u8 = new TextEncoder().encode(csv);
    const blob = new Blob([u8], { type: 'text/csv' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      // other browsers
      let file = new File([blob], fileName, { type: 'octet/stream' });
      let exportUrl = URL.createObjectURL(file);
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = exportUrl;
      a.download = `${fileName + Number(+new Date())}.csv`;
      a.click();
      URL.revokeObjectURL(exportUrl);
    }
  }

  /**
   * export a Tanstack ReactTable selected rows original value to csv
   * References : https://tanstack.com/table/v8/docs/api/features/row-selection#getselectedrowmodel
   *
   * @param {Object} table
   * @param {String} name
   * @return {void}
   */
  static exportReactTableSelectedRowsToCsv(table, fileName) {
    if (typeof table !== 'object')
      throw new Error(
        `Sheet.exportReactTableSelectedRowsToCsv() expects parameter 1 to be object but ${typeof table} given`,
      );

    if (typeof fileName !== 'string')
      throw new Error(
        `Sheet.exportReactTableSelectedRowsToCsv() expects parameter 1 to be string but ${typeof table} given`,
      );

    let data = table.getSelectedRowModel().rows.map((r) => r.original);
    if (data.length <= 0) {
      Notify.error('Select atleast 1(s) rows');
      return;
    }
    this.downloadJsonToCsv(data, fileName);
  }

  /**
   * export a Tanstack ReactTable selected rows original value to excel
   * References : https://tanstack.com/table/v8/docs/api/features/row-selection#getselectedrowmodel
   *
   * @param {Object} table
   * @param {String} name
   * @return {void}
   */
  static exportReactTableSelectedRowsToExcel(table, fileName) {
    if (typeof table !== 'object')
      throw new Error(
        `Sheet.exportReactTableSelectedRowsToCsv() expects parameter 1 to be object but ${typeof table} given`,
      );

    if (typeof fileName !== 'string')
      throw new Error(
        `Sheet.exportReactTableSelectedRowsToCsv() expects parameter 1 to be string but ${typeof table} given`,
      );

    let data = table.getSelectedRowModel().rows.map((r) => r.original);
    if (data.length <= 0) {
      Notify.error('Select atleast 1(s) rows');
      return;
    }
    Sheet.downloadJsonToExcel(data, fileName);
  }
  static downloadTableToExcel(data = [], definition, fileName = 'data') {
    const table = document.createElement('table');
    const tableHead = document.createElement('thead');

    const tableHeadContent = Object.values(definition)
      .map(
        (
          key,
        ) => `<th style="border: 1px solid #000; padding: 5px; background-color: #000; color: #fff;"
      >${key}</th>`,
      )
      .join('');

    tableHead.innerHTML = `<tr>${tableHeadContent}</tr>`;

    const tableBody = document.createElement('tbody');

    let tableBodyContent = '';
    if (data?.length > 0) {
      tableBodyContent = data
        .map((row) => {
          return `<tr>${Object.keys(definition)
            .map((dataKey) => {
              if (typeof row[dataKey] === 'boolean' && row[dataKey] === true) {
                return `<td style="border: 1px solid #000; padding: 5px;">Yes</td>`;
              }
              if (typeof row[dataKey] === 'boolean' && row[dataKey] === false) {
                return `<td style="border: 1px solid #000; padding: 5px;">No</td>`;
              }
              if (
                dataKey === 'date_Money_Received_From_Customer' ||
                dataKey === 'date_Money_Made_Available_Beneficiary_customer' ||
                dataKey === 'doB_Individual' ||
                dataKey === 'doB_Individual_1'
              ) {
                return `<td style="border: 1px solid #000; padding: 5px;"> ${
                  row[dataKey] !== '' ? format(new Date(row[dataKey]), 'yyyy-MM-dd') : row[dataKey]
                }</td>`;
              }

              return `<td style="border: 1px solid #000; padding: 5px;">${row[dataKey] ?? ''}</td>`;
            })
            .join('')}</tr>`;
        })
        .join('');
    } else {
      tableBodyContent = `<tr><td colspan="${
        Object.keys(definition).length
      }">No data found</td></tr>`;
    }

    tableBody.innerHTML = tableBodyContent;

    table.appendChild(tableHead);
    table.appendChild(tableBody);

    const wb = utils.table_to_book(table, {
      dateNF: 'yyyy-mm-dd',
      cellDates: false,
    });

    writeFile(wb, `${fileName}.xlsx`);
  }
}

export default Sheet;
