import { useEffect, useContext } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import Transactions from 'components/transactions/Transactions';
import TransactionBriefDetailsModal from 'components/transactions/TransactionBriefDetailsModal';

import acl from 'app/config/acl';
import { currentRouteName } from 'routes';
import PendingTransactionsContext from 'app/context/PendingTransactionsContext';
import { resetGetTransactionsFailure } from 'app/features/transactions/transactionSlice';

function ListTransaction({ isNetworkError }) {
  const transactionState = useSelector((state) => state.transactions.index);

  const { isError, errorCode } = transactionState;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetTransactionsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetTransactionsFailure())}
      />
    );

  return (
    <PendingTransactionsContext.Provider
      value={currentRouteName() === 'pending-bank-transactions.index'}
    >
      <Content />
    </PendingTransactionsContext.Provider>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ListTransaction);

function Content() {
  const isPendingTransactionsContext = useContext(PendingTransactionsContext);

  return (
    <PageContent
      title={isPendingTransactionsContext ? 'Pending Bank Transactions' : 'Transactions'}
    >
      <Transactions />
      <TransactionBriefDetailsModal />
    </PageContent>
  );
}
