import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import Webhooks from 'components/webhooks/Webhooks';
import WebhookFilterValues from 'components/webhooks/WebhookFilterValues';
import { resetGetWebhooksFailure } from 'app/features/webhooks/webhookSlice';
import WebhookBriefDetailsModal from 'components/webhooks/WebhookBriefDetailsModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';

function ListWebhook({ isNetworkError }) {
  const webhookState = useSelector((state) => state.webhooks.index);

  const { isError, errorCode } = webhookState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetWebhooksFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetWebhooksFailure())}
      />
    );

  return (
    <PageContent
      title="Webhooks"
      rightTopEndContent={
        <Button
          onClick={() => navigate(buildRoute('webhooks.create'))}
          color="primary"
          startIcon={<AddIcon />}
        >
          Add Webhook
        </Button>
      }
    >
      <WebhookFilterValues />
      <Webhooks />
      <WebhookBriefDetailsModal />
    </PageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ListWebhook);
