import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import { connect } from 'react-redux';
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import Stepper from '@mui/material/Stepper';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { useState, useEffect, useMemo } from 'react';

import Spacer from 'core/spacer/Spacer';
import Button from 'core/buttons/Button';
import CircularProgress from 'core/progress/CircularProgress';

import { buildRoute } from 'routes';
import ucfirst from 'helpers/ucfirst';

import {
  retryToCreate,
  retryToUpdate,
  setShowEditProcessingModal,
  setShowCreateProcessingModal,
} from 'app/features/funding-sources-info/fundingSourceInfoSlice';

const style = {
  position: 'absolute',
  top: '64px',
  right: '0',
  width: 384,
  height: 'calc(100vh - 64px)',
  bgcolor: 'background.paper',
  border: 0,
  boxShadow: 24,
  p: 4,
};

function getStep1Label(isAddMode) {
  return isAddMode
    ? 'Creating New Partner Funding Source Info'
    : 'Updating Partner Funding Source Information Details';
}

function getStep2Label(isAddMode, isProcessed, isFailed) {
  if (isAddMode)
    return isProcessed
      ? 'Partner Funding Source Info created successfully'
      : isFailed
      ? 'Failed to create partner funding source Info'
      : '';
  return isProcessed
    ? 'Partner Funding Source Info updated successfully'
    : isFailed
    ? 'Failed to update partner funding source Info'
    : '';
}

function FundingSourceInfoProgressIndicatorModal(props) {
  const params = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isAddMode, open, isProcessing, isProcessed, isFailed, fundingSourcesInfo, errorMessage } =
    props;

  const steps = useMemo(() => {
    return [
      {
        label: getStep1Label(isAddMode),
        showProgress: true,
        color: 'primary',
      },
      {
        label: getStep2Label(isAddMode, isProcessed, isFailed),
        showProgress: false,
        color: isProcessed ? 'primary' : 'error',
      },
    ];
  }, [isAddMode, isProcessed, isFailed]);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const hideProgressModal = () => {
    dispatch(isAddMode ? setShowCreateProcessingModal(false) : setShowEditProcessingModal(false));
  };

  useEffect(() => {
    if (isProcessing) handleNext();
  }, [isProcessing]);

  useEffect(() => {
    if (isProcessed) handleNext();
  }, [isProcessed]);

  return (
    <div>
      <Modal open={open} onClose={() => {}}>
        <Box sx={style}>
          <Box sx={{ maxWidth: 400 }}>
            {/* Step process */}

            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step) => (
                <Step key={step.label}>
                  <StepLabel color={step.color}>
                    <Box display="flex" gap={2} alignItems="center">
                      <Typography color={step.color}>{step.label}</Typography>
                      {isProcessing && step.showProgress && (
                        <CircularProgress size={30} thickness={6} />
                      )}
                    </Box>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Spacer />

            {/* If Form Process Failed */}

            {isFailed && (
              <Box>
                <Typography variant="body2" color="error">
                  {ucfirst(errorMessage)}
                </Typography>
                <Spacer />
                <Button
                  color="primary"
                  onClick={() => dispatch(isAddMode ? retryToCreate() : retryToUpdate())}
                  fullWidth
                >
                  Retry
                </Button>
                <Spacer />
                <Button
                  color="error"
                  onClick={() => hideProgressModal()}
                  variant="outlined"
                  fullWidth
                >
                  Cancel
                </Button>
              </Box>
            )}

            {/* Is Form Process succeed */}
            {fundingSourcesInfo && (
              <Box display="flex" flexDirection="column" gap={1}>
                <Box>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => (
                      hideProgressModal(),
                      navigate(buildRoute('funding-sources-info.show', fundingSourcesInfo.id))
                    )}
                    fullWidth
                  >
                    View Details
                  </Button>
                </Box>
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => (
                      hideProgressModal(), navigate(buildRoute('funding-sources-info.index'))
                    )}
                    fullWidth
                  >
                    Partner Funding Source List
                  </Button>
                </Box>
                <Box>
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    onClick={() => hideProgressModal()}
                    fullWidth
                  >
                    Add New Partner Funding Source
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default compose(connect(mapStateToProps))(FundingSourceInfoProgressIndicatorModal);

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    open: ownProps.isAddMode
      ? state.fundingSourcesInfo.create.showProcessingModal
      : state.fundingSourcesInfo.update.showProcessingModal,
    isProcessing: ownProps.isAddMode
      ? state.fundingSourcesInfo.create.isCreating
      : state.fundingSourcesInfo.update.isUpdating,
    isProcessed: ownProps.isAddMode
      ? state.fundingSourcesInfo.create.isCreated
      : state.fundingSourcesInfo.update.isUpdated,
    isFailed: ownProps.isAddMode
      ? state.fundingSourcesInfo.create.isFailed
      : state.fundingSourcesInfo.update.isFailed,
    fundingSourcesInfo: ownProps.isAddMode
      ? state.fundingSourcesInfo.create.data
      : state.fundingSourcesInfo.update.data,
    errorMessage: ownProps.isAddMode
      ? state.fundingSourcesInfo.create.errorMessage
      : state.fundingSourcesInfo.update.errorMessage,
  };
}
FundingSourceInfoProgressIndicatorModal.propTypes = {
  isAddMode: PropTypes.bool.isRequired,
  open: PropTypes.bool,
  isProcessing: PropTypes.bool,
  isProcessed: PropTypes.bool,
  fundingSourcesInfo: PropTypes.object,
  isFailed: PropTypes.bool,
  errorMessage: PropTypes.string,
};
