import { createSlice } from '@reduxjs/toolkit';

import app from 'app/config/app';
import DateUtils from 'utils/DateUtils';

const initialState = {
  index: {
    data: [],
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc',
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
  create: {
    isLoading: false,
    isError: false,
    data: null,
  },
};

export const partnerCredentialSlice = createSlice({
  name: 'partnerCredentials',
  initialState,
  reducers: {
    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParam: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    getPartnerCredentialsInit: (state) => {
      state.index = {
        ...state.index,
        data: [],
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getPartnerCredentialsSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({
        sn: (startSN += 1),
        ...item,
        is_recently_created: DateUtils.isRecent(item.created_ts),
      }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getPartnerCredentialsFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetPartnerCredentialsFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    createPartnerCredentialInit: (state) => {
      state.create = {
        ...state.create,
        isLoading: true,
        isError: false,
        data: null,
      };
    },

    createPartnerCredentialSuccess: (state, { payload }) => {
      const { page_number: currentPage, page_size: pageSize } = state.index.params;

      let startSN = (currentPage - 1) * pageSize;

      let data = [payload.data, ...state.index.data].map((item) => ({
        ...item,
        sn: (startSN += 1),
        is_recently_created: DateUtils.isRecent(item.created_ts),
      }));

      state.index = {
        ...state.index,
        data,
      };

      state.create = {
        ...state.create,
        isLoading: false,
        isError: false,
        data: payload.data,
      };
    },

    createPartnerCredentialFailure: (state, { payload }) => {
      state.create = {
        ...state.create,
        isLoading: false,
        isError: true,
        data: null,
      };
    },

    deletePartnerCredentialInit: (state, { payload: partnerCredentialId }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == partnerCredentialId) {
          return {
            ...item,
            isDeleting: true,
          };
        }
        return item;
      });
    },

    deletePartnerCredentialSuccess: (state, { payload: partnerCredentialId }) => {
      const { page_number: currentPage, page_size: pageSize } = state.index.params;

      let startSN = (currentPage - 1) * pageSize;

      let data = state.index.data.filter((item) => item.id !== partnerCredentialId);

      state.index.data = data.map((item) => ({ ...item, sn: (startSN += 1) }));
    },

    deletePartnerCredentialFailure: (state, { payload: partnerCredentialId }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == partnerCredentialId) {
          return {
            ...item,
            isDeleting: false,
          };
        }
        return item;
      });
    },

    getPartnerCredentialInit: (state) => {},

    getPartnerCredentialSuccess: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        data: payload.data,
      };
    },

    getPartnerCredentialFailure: (state) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
      };
    },

    clearRecentlyCreatedCredentials: (state) => {
      state.create.data = null;
    },
  },
});

export const {
  setParam,
  resetParam,
  deleteFilterParams,
  getPartnerCredentialsInit,
  getPartnerCredentialsSuccess,
  getPartnerCredentialsFailure,
  createPartnerCredentialInit,
  createPartnerCredentialSuccess,
  createPartnerCredentialFailure,
  deletePartnerCredentialInit,
  deletePartnerCredentialSuccess,
  deletePartnerCredentialFailure,
  getPartnerCredentialInit,
  getPartnerCredentialSuccess,
  getPartnerCredentialFailure,
  clearRecentlyCreatedCredentials,
  resetGetPartnerCredentialsFailure,
} = partnerCredentialSlice.actions;

export default partnerCredentialSlice.reducer;
