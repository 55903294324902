import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import cleanObject from 'helpers/cleanObject';

function FilterValues({ value, onRemoveFilter, ignoreKeys }) {
  const [filter, setFilter] = useState({});

  useEffect(() => {
    let filterWithIgnoreKeys = cleanObject({ ...value });
    ignoreKeys.forEach((element) => delete filterWithIgnoreKeys[element]);
    setFilter(filterWithIgnoreKeys);
  }, [JSON.stringify(value)]);

  if (Object.entries(filter).length > 0)
    return (
      <Stack direction="row" spacing={1} sx={{ mb: 3 }}>
        {Object.keys(filter).map((filterKey, i) => (
          <Fragment key={i}>
            {ignoreKeys.includes(filterKey) ? (
              <></>
            ) : (
              <Chip
                key={filterKey}
                label={filter[filterKey]}
                size="medium"
                onDelete={() => onRemoveFilter(filterKey)}
              />
            )}
          </Fragment>
        ))}
      </Stack>
    );

  return <></>;
}

export default FilterValues;

FilterValues.propTypes = {
  value: PropTypes.object,
  onRemoveFilter: PropTypes.func,
  ignoreKeys: PropTypes.array,
};

FilterValues.defaultValues = {
  value: {},
  onRemoveFilter: () => {},
  ignoreKeys: [],
};
