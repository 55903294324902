import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import { connect } from 'react-redux';
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import Stepper from '@mui/material/Stepper';
import { useNavigate } from 'react-router-dom';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

import Button from 'core/buttons/Button';
import CircularProgress from 'core/progress/CircularProgress';

import { buildRoute } from 'routes';

import {
  retryToCreate,
  setShowCreateProcessingModal,
} from 'app/features/licensing-partners-map/licensingPartnersMapSlice';

const style = {
  position: 'absolute',
  top: '64px',
  right: '0',
  width: 384,
  height: 'calc(100vh - 64px)',
  bgcolor: 'background.paper',
  border: 0,
  boxShadow: 24,
  p: 4,
};

function LicensePartnerMapperProgressIndicatorModal(props) {
  const { open, isProcessing, isProcessed, isFailed, licensingPartnersMap, errorMessage } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: 'Creating New Licensing Partner Mapper',
      showProgress: true,
      color: 'primary',
    },
  ];

  const handleNext = () => setActiveStep((prev) => prev + 1);

  const hideProgressModal = () => {
    dispatch(setShowCreateProcessingModal(false));
  };

  useEffect(() => {
    if (isProcessing) handleNext();
  }, [isProcessing]);

  useEffect(() => {
    if (isProcessed) handleNext();
  }, [isProcessed]);

  return (
    <Modal open={open} onClose={hideProgressModal}>
      <Box sx={style}>
        <Box sx={{ maxWidth: 400 }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel>
                  <Typography color={step.color}>{step.label}</Typography>
                  {isProcessing && step.showProgress && (
                    <CircularProgress size={30} thickness={6} />
                  )}
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          {isFailed && (
            <Box>
              <Typography variant="body2" color="error">
                {errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1)}
              </Typography>
              <Button color="primary" onClick={() => dispatch(retryToCreate())} fullWidth>
                Retry
              </Button>
              <Button color="error" onClick={hideProgressModal} variant="outlined" fullWidth>
                Cancel
              </Button>
            </Box>
          )}

          {licensingPartnersMap && (
            <Box display="flex" flexDirection="column" gap={1}>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  hideProgressModal();
                  navigate(buildRoute('partners.show', licensingPartnersMap.partner_id));
                }}
                fullWidth
              >
                View Details
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default compose(connect(mapStateToProps))(LicensePartnerMapperProgressIndicatorModal);

function mapStateToProps(state) {
  return {
    open: state.licensingPartnersMap.create.showProcessingModal,
    isProcessing: state.licensingPartnersMap.create.isCreating,
    isProcessed: state.licensingPartnersMap.create.isCreated,
    isFailed: state.licensingPartnersMap.create.isFailed,
    licensingPartnersMap: state.licensingPartnersMap.create.data,
    errorMessage: state.licensingPartnersMap.create.errorMessage,
  };
}

LicensePartnerMapperProgressIndicatorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired,
  isProcessed: PropTypes.bool.isRequired,
  licensingPartners: PropTypes.object,
  isFailed: PropTypes.bool,
  errorMessage: PropTypes.string,
};
