import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';
import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';
import {
  getTransactionDocumentInit,
  getTransactionDocumentSuccess,
  getTransactionDocumentFailure,
} from './transactionDocumentSlice';

const getTransactionReceipt = createAction('transactionDocument/getReceipt');

function* getTransactionReceiptSaga({ payload }) {
  yield put(getTransactionDocumentInit());
  try {
    let endpoint = `${apiEndpoints.getTransactionReceipt}?${qs.stringify(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));

    yield put(getTransactionDocumentSuccess(response));
  } catch (err) {
    yield put(getTransactionDocumentFailure(err));
  }
}

function* transactionDocumentSaga() {
  yield takeLatest(getTransactionReceipt, getTransactionReceiptSaga);
}

export default transactionDocumentSaga;

export { getTransactionReceipt };
