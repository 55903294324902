import { useState } from 'react';
import Box from '@mui/material/Box';
import { useCallback } from 'react';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import TableGridFilter from 'core/table/TableGridFilter';
import ColumnVisibility from 'core/table/ColumnVisibility';
import TableActionsContainer from 'core/table/TableActionsContainer';

import { debounce } from 'redux-saga/effects';
import FilterType from 'app/config/FilterType';
import useQueryParams from 'hooks/useQueryParams';
import { getFundingSourcesInfo } from 'app/features/funding-sources-info/fundingSourceInfoSaga';
import fundingSourceInfoIgnoreFilterKeys from 'pages/funding-source-info/data/fundingSourceInfoIgnoreFilterKeys';

import {
  deleteFilterParams,
  setParam,
} from 'app/features/funding-sources-info/fundingSourceInfoSlice';

import SearchBox from 'core/forms/SearchBox';
import ReloadButton from 'core/buttons/ReloadButton';

export default function FundingSourcesInfoActions({ table }) {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const fundingSourceInfoState = useSelector((state) => state.fundingSourcesInfo.index);

  const [queryParams, updateQueryParams] = useQueryParams(fundingSourceInfoIgnoreFilterKeys);

  let availableFilters = [
    {
      label: 'Partner Name',
      value: 'partner_name',
      type: FilterType.input,
    },
    {
      label: 'Payment Name',
      value: 'payment_name',
      type: FilterType.input,
    },
    {
      label: 'Payment Value',
      value: 'payment_value',
      type: FilterType.input,
    },
    {
      label: 'Funding Key',
      value: 'funding_key',
      type: FilterType.input,
    },
    {
      label: 'Funding Key Value',
      value: 'funding_key_value',
      type: FilterType.input,
    },
  ];

  const { params: appliedFilters, success } = fundingSourceInfoState;

  const [search, setSearch] = useState(appliedFilters?.search ?? '');

  const handleOnFilter = useCallback((data) => {
    dispatch(setParam({ ...data, page_number: 1 }));
  }, []);

  const handleOnReset = useCallback((e) => {
    dispatch(deleteFilterParams([...availableFilters.map((filter) => filter.value)]));
  });

  return (
    <>
      <Box>
        <TableGridFilter
          showFilter={showFilter}
          appliedFilters={appliedFilters}
          availableFilters={availableFilters}
          onFilter={handleOnFilter}
          onReset={handleOnReset}
        />
      </Box>

      <TableActionsContainer>
        <Box></Box>

        <Box display="flex" gap={1}>
          <Button
            variant="outlined"
            endIcon={<FilterAltIcon />}
            onClick={() => setShowFilter(!showFilter)}
            uppercase
          >
            Filter
          </Button>

          {table && <ColumnVisibility table={table} />}

          <ReloadButton
            onClick={() => {
              dispatch(
                getFundingSourcesInfo({
                  ...appliedFilters,
                  page_number: 1,
                }),
              );
            }}
          />
        </Box>
      </TableActionsContainer>
    </>
  );
}
