const apiEndpoints = {
  // commons
  getCountries: '/api/common/countrylist',

  // account
  login: '/api/account/login',
  confirmEmail: '/api/account/confirmemail',
  confirmIP: '/api/account/confirmip',
  forgotPassword: '/api/account/forgotpassword',
  resetPasswordLink: '/api/account/resetpasswordlink',
  resetPassword: '/api/account/resetpassword',
  changePassword: '/api/account/changePassword',
  getLoggedInUser: '/api/account/details',
  refreshToken: '/api/account/refreshtoken',
  logout: '/api/account/logout',

  // partners
  getPartners: '/api/partner',
  createPartner: '/api/partner',
  updatePartner: '/api/partner/:partnerId',
  getPartner: '/api/partner/:partnerId',
  downloadPartnerReport: '/api/partner/export',

  // Licensing Partners

  createLicensingPartner: '/api/licensepartner',
  updateLicensingPartner: '/api/licensepartner/:licensingPartnerId',
  getLicensingPartners: '/api/licensepartner/getall',
  getLicensingPartner: '/api/licensepartner/: partnerId',
  deleteLicensingPartner: '/api/licensepartner/:licensingPartnerId',

  // Licensing Partners Mapper
  createLicensingPartnerMapper: '/api/partner/licensemap',
  getLicensingPartnerMap: '/api/partner/licensemap/:id',

  // customers
  getCustomers: '/api/customer',
  getCustomer: '/api/customer/:customerId',
  downloadCustomerReport: '/api/ExportCustomers',

  // customer kycs
  getCustomerKycs: '/api/customerkyc',
  getCustomerKyc: '/api/customerkyc/:kycId',
  getCustomerKycDocument: '/api/customer/:customerId/document',
  updateCustomerKycStatus: '/api/customerkyc/:kycId',
  downloadCustomerKycsReport: '/api/customerkyc/export',

  // customer Kycs Logs

  getCustomerKycLogs: '/api/customerkyc/logs',

  // partner credentials
  getPartnerCredentials: '/api/partner/credential',
  createPartnerCredential: '/api/partner/credential/:partnerId',
  deletePartnerCredential: '/api/partner/credential/:partnerCredentialId',
  getPartnerCredential: '/api/partner/credential/:partnerCredentialId',

  // accounts
  getAccounts: '/api/account/getall',
  createAccount: '/api/account/register',
  updateAccount: '/api/account/:accountId',
  getAccount: '/api/account/details/:accountId',
  updateIsActiveStatus: '/api/account/:accountId',
  deleteAccount: '/api/account/:accountId',

  // transaction
  getTransactions: '/api/transaction',
  getTransaction: '/api/transaction/:transactionId',
  getTransactionReceipt: '/api/GetReceipt',
  updateTransaction: '/api/transaction/:transactionId',
  exportTransactions: 'api/ExportTransaction',
  getZaiTransactions: '/api/zai/transactions',
  getZaiWebhookLogs: '/api/zai/webhook-log',
  getZaiRefundLogs: '/api/zai/refund-log',
  zaiCheckBalance: '/api/zai/:customerId/check-balance',
  zaiMakePayment: '/api/zai/make-payment',
  zaiRefundPayment: '/api/zai/refund-payment',
  zaiTransactionEnableDisable: '/api/zai/enable-disable',

  // transaction log
  getTransactionLogs: '/api/TransactionLogs/:transactionId',

  // apiConfig
  getApiConfigs: '/api/apiconfig',
  createApiConfig: '/api/apiconfig',
  updateApiConfig: '/api/apiconfig/:apiConfigId',
  getApiConfig: '/api/apiconfig/:apiConfigId',

  // funding source
  getFundingSources: '/api/fundingsource',
  getFundingSource: '/api/fundingsource/:fundingSourceId',
  createFundingSource: '/api/fundingsource',
  deleteFundingSource: '/api/fundingsource/:fundingSourceId',
  updateFundingSourceIsActiveStatus: '/api/fundingsource/:fundingSourceId',

  // funding source info

  getFundingSourcesInfo: '/api/fundingsourceinfo',
  getFundingSourceInfo: '/api/fundingsourceinfo/:id',
  createFundingSourceInfo: '/api/fundingsourceinfo',
  updateFundingSourceInfo: '/api/fundingsourceinfo/:id',
  deleteFundingSourceInfo: '/api/fundingsourceinfo/:id',

  // webhooks
  getWebhooks: '/api/webhook',
  createWebhook: '/api/webhook',
  updateWebhook: '/api/webhook/:webhookId',
  getWebhook: '/api/webhook/:webhookId',
  deleteWebhook: '/api/webhook/:webhookId',

  // webhook headers
  getWebhookHeaders: '/api/webhook/header',
  createWebhookHeader: '/api/webhook/header',
  updateWebhookHeader: '/api/webhook/header/:webhookHeaderId',
  getWebhookHeader: '/api/webhook/header/:webhookHeaderId',
  deleteWebhookHeader: '/api/webhook/header/:webhookHeaderId',

  // beneficiaries
  getBeneficiaries: '/api/beneficiary',
  getBeneficiary: '/api/beneficiary/:beneficiaryId',
  getCustomerBeneficiaries: '/api/:customerId/beneficiary',
  downloadBeneficiaryReport: '/api/beneficiary/export',

  // finmo payins
  getFinmoPayins: '/api/finmo/payin',
  getFinmoPayin: '/api/finmo/payin/:finmoPayinId',

  // finmo payouts
  getFinmoPayouts: '/api/finmo/payout',
  getFinmoPayout: '/api/finmo/payout/:finmoPayoutId',

  // partner funding sources
  getPartnerFundingSources: '/api/partner/fundingsource',
  getPartnerFundingSource: '/api/partner/fundingsource/:partnerFundingSourceId',
  createPartnerFundingSource: '/api/partner/fundingsource',
  deletePartnerFundingSource: '/api/partner/fundingsource/:partnerFundingSourceId',
  updatePartnerFundingSourceIsActiveStatus: '/api/partner/fundingsource/:partnerFundingSourceId',

  // partner countries
  getPartnerCountries: '/api/partner/country',
  getPartnerCountry: '/api/partner/country/:partnerCountryId',
  createPartnerCountry: '/api/partner/country',
  deletePartnerCountry: '/api/partner/country/:partnerCountryId',
  updatePartnerCountryIsActiveStatus: '/api/partner/country/:partnerCountryId',

  //aus trck reports
  getAusTrackReports: '/api/TrackReport',
};

export default apiEndpoints;

export const buildApiRoute = (apiRoute, ...params) => {
  if (Array.isArray(params)) {
    let arrayContainsObject = params.some(
      (e) => Object.prototype.toString.call(e) === '[object Object]',
    );

    if (arrayContainsObject && params.length > 0)
      throw new Error(
        'Expected only single parameter while route parameter has an object as a parameter',
      );

    if (arrayContainsObject) {
      Object.keys(params[0]).forEach(
        (key) => (apiRoute = apiRoute.replace(`:${key}`, params[0][key])),
      );
    } else {
      params.forEach((_, index) => (apiRoute = apiRoute.replace(/:([^/]+)/, params[index])));
    }

    return apiRoute;
  }

  return apiRoute.replace(/:([^/]+)/, (_, key) => params[key]);
};
