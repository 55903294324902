import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import http from 'app/services/httpService';
import apiEndpoints from 'routes/apiEndpoints';
import { callWrapperSaga } from 'app/services/sagaService';

import {
  getCustomerKycLogsInit,
  getCustomerKycLogsFailure,
  getCustomerKycLogsSuccess,
} from './customerKycLogsSlice';

const getCustomerKycLogs = createAction('customerKycLogs/list');

function* getCustomerKycLogsSaga({ payload }) {
  yield put(getCustomerKycLogsInit());
  try {
    let endpoint = `${apiEndpoints.getCustomerKycLogs}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getCustomerKycLogsSuccess(response.data));
  } catch (error) {
    yield put(getCustomerKycLogsFailure(error));
  }
}

function* customerKycLogsSaga() {
  yield takeLatest(getCustomerKycLogs, getCustomerKycLogsSaga);
}

export default customerKycLogsSaga;

export { getCustomerKycLogs };
