import { useEffect, useMemo, useState, memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import Error from 'core/errors/Error';
import Clipboard from 'core/clipboard/Clipboard';
import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import TableContainer from 'core/table/TableContainer';
import TanstackReactTable from 'core/table/TanstackReactTable';
import PartnerCredentialsActions from 'components/partner-credentials/PartnerCredentialsActions';
import PartnerCredentialPagination from 'components/partner-credentials/PartnerCredentialPagination';

import { useConfirm } from 'core/mui-confirm';
import {
  getPartnerCredentials,
  deletePartnerCredential,
} from 'app/features/partner-credentials/partnerCredentialSaga';

function PartnerCredentials({ partnerId }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const confirm = useConfirm();

  const [columnVisibility, setColumnVisibility] = useState({});

  const partnerCredentialState = useSelector((state) => state.partnerCredentials.index);

  const { data, params, isLoading, isError, errorCode } = partnerCredentialState;

  const handleDelete = (partnerCredentialId) => {
    confirm({
      icon: 'warning',
      confirmationText: 'Yes, Delete it.',
      description: 'This will permanently delete this credential.',
    })
      .then(() => dispatch(deletePartnerCredential(partnerCredentialId)))
      .catch((err) => {});
  };

  const columns = useMemo(
    () => [
      {
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        header: 'Client ID',
        accessorFn: (row) => row.client_id,
        cell: ({ row }) => (
          <Box display="flex" gap={1} alignItems="center">
            <Clipboard content={row.original.client_id} />
            {row.original.is_recently_created ? (
              <Chip size="small" color="warning" label="New" />
            ) : (
              ''
            )}
          </Box>
        ),
      },
      {
        header: 'Source ID',
        accessorFn: (row) => row.source_id ?? '-',
      },
      {
        header: 'Is Verified ?',
        accessorFn: (row) => row.is_verified,
        cell: (info) => {
          return <VerifiedBadge isVerified={!!info.getValue()} />;
        },
      },
      {
        header: 'Is Active ?',
        accessorFn: (row) => row.is_active,
        cell: (info) => <ActiveBadge isActive={!!info.getValue()} />,
      },
      {
        header: 'Action',
        accessorFn: (row) => row.id,
        cell: ({ row }) => (
          <Box display="flex" gap={1}>
            <IconButton
              color="error"
              onClick={() => handleDelete(row.original.id)}
              disabled={row.original?.isDeleting || false}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Box>
        ),
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(getPartnerCredentials({ ...params, partner_id: partnerId }));
  }, [JSON.stringify(params), partnerId]);

  if (isError) return <Error status={errorCode} />;

  return (
    <TableContainer>
      <PartnerCredentialsActions partnerId={partnerId} />
      <TanstackReactTable
        table={table}
        isLoading={isLoading}
        data={data}
        columnsCount={columns.length}
      />
      <PartnerCredentialPagination />
    </TableContainer>
  );
}

export default memo(PartnerCredentials);

PartnerCredentials.propTypes = {
  partnerId: PropTypes.string,
};
