import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import PageContent from 'layouts/PageContent';
import WebhookForm from 'components/webhooks/WebhookForm';
import WebhookFormSkeleton from 'components/webhooks/WebhookFormSkeleton';
import WebhookProgressIndicatorModal from 'components/webhooks/WebhookProgressIndicatorModal';

import acl from 'app/config/acl';
import { editWebhook } from 'app/features/webhooks/webhookSaga';

function EditWebhook() {
  const params = useParams();

  const dispatch = useDispatch();

  const {
    data: webhook,
    isError,
    errorMessage,
    isLoading,
    errorCode,
  } = useSelector((state) => state.webhooks.edit);

  useEffect(() => {
    dispatch(editWebhook(params.webhookId));
  }, [params.webhookId]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(editWebhook(params.webhookId))}
      />
    );

  return (
    <PageContent title="Edit Webhook">
      {isLoading ? <WebhookFormSkeleton /> : <WebhookForm isAddMode={false} webhook={webhook} />}
      <WebhookProgressIndicatorModal isAddMode={false} />
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(EditWebhook);
