import { forwardRef } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const SubmitButton = forwardRef(({ isLoading, ...props }, ref) => {
  const { children, sx, ...rest } = props;
  return (
    <Button
      type="submit"
      variant="contained"
      size="large"
      sx={{
        ...sx,
      }}
      disabled={isLoading}
      {...rest}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <div>{isLoading ? 'Processing' : children}</div>{' '}
        {isLoading ? <CircularProgress color="inherit" size={10} /> : ''}
      </Box>
    </Button>
  );
});

export default SubmitButton;

SubmitButton.defaultProps = {
  isLoading: false,
};
