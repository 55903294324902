import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import styled from '@mui/material/styles/styled';
import CircleIcon from '@mui/icons-material/Circle';
import ListSubheader from '@mui/material/ListSubheader';

import DropdownList from 'core/list/DropdownList';
import ListItemLink from 'core/list/ListItemLink';
import ListItemContexts from 'core/list/ListItemContexts';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';

const openedMixin = (theme) => ({
  width: app.drawerWith,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: app.drawerWith,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& div:first-of-type': {
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.background.paper,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.grey[500],
      borderRadius: '6px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.grey[600],
      cursor: 'pointer',
    },
    // ...(!open && {
    //   [theme.breakpoints.up('sm')]: {
    //     width: 'calc(56px + 1px)',
    //   },
    // }),
  },
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      backgroundColor: theme.palette.primary.main,
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      backgroundColor: theme.palette.primary.main,
    },
  }),
}));

function Sidebar({ open, sidebarItems, appBar }) {
  const [activeDropdownKey, setActiveDropdownKey] = useState(null);

  const handleSetActiveDropdownKey = (key) => {
    setActiveDropdownKey(activeDropdownKey === key ? null : key);
  };

  const items = sidebarItems.map((item, key) => {
    switch (item.type) {
      case 'link':
        if (!isEmpty(item?.roles) && !item?.roles?.includes(Auth.role()))
          return <Fragment key={key}></Fragment>;
        return (
          <ListItemLink
            isDrawerOpen={open}
            key={key}
            button
            icon={item.icon}
            primary={item.name}
            to={item.path}
            isActive={item.isActive ?? false}
          />
        );
      case 'dropdown': {
        let roles = item.children.map((i) => i.roles || []).reduce((a, i) => a.concat(i));
        roles = [...new Set(roles)];

        if (!isEmpty(roles) && !roles.includes(Auth.role())) return <Fragment key={key}></Fragment>;

        return (
          <DropdownList
            isDrawerOpen={open}
            key={key}
            label={item.name}
            icon={item.icon}
            open={activeDropdownKey === key}
            onClick={() => handleSetActiveDropdownKey(key)}
            isActive={item.isActive ?? false}
            items={item.children}
          />
        );
      }
      case 'divider':
        return <Divider key={key} />;
      case 'sub_header':
        return (
          <ListSubheader component="div" key={key}>
            {item?.name}
          </ListSubheader>
        );
      default:
        return <Fragment key={key}></Fragment>;
    }
  });

  return (
    <Drawer id="sidebar" variant="permanent" open={open}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ flex: 1 }}>{appBar}</Box>
      </Box>
      <List>{items}</List>
      <Divider />
    </Drawer>
  );
}

export default Sidebar;

Sidebar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  open: PropTypes.bool,
  onDrawerClose: PropTypes.func,
  sidebarItems: PropTypes.arrayOf(PropTypes.object),
  appBar: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
};

Sidebar.defaultProps = {
  open: false,
  onDrawerClose: () => {},
  drawerWidth: app.drawerWith,
  sidebarItems: [],
};
