import { createContext } from 'react';
// import { PROTECTED_ROUTES, currentRouteName } from 'routes';

// const PendingTransactionsContext = createContext(
//   window.location.pathname === PROTECTED_ROUTES.ListPendingBankTransaction.path,
// );

// const PendingTransactionsContext = createContext(
//   currentRouteName('pending-bank-transactions.index'),
// );

const PendingTransactionsContext = createContext(false);

export default PendingTransactionsContext;
