import { useSelector, useDispatch } from 'react-redux';

import FilterValues from 'core/filter/FilterValues';
import { deleteFilterParams } from 'app/features/api-configs/apiConfigSlice';

import App from 'app/config/app'

export default function PartnerFilterValues() {
  const dispatch = useDispatch();

  const { params } = useSelector((state) => state.apiConfigs.index);

  const handleOnRemoveFilter = (key) => {
    dispatch(deleteFilterParams(key));
  };

  return (
    <FilterValues
      value={params}
      onRemoveFilter={handleOnRemoveFilter}
      ignoreKeys={App.ignoreFilterKeys}
    />
  );
}
