import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

const ErrorContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'withInDashboard'
})(({ withInDashboard, theme }) => {
  return {
    height: withInDashboard ? '100%' : '100vh',
    width: withInDashboard ? '100%' : '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.background.paper
  };
});

export default ErrorContainer;
