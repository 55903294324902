/***
 * READ BLOG : https://medium.com/swlh/service-error-handling-with-redux-saga-b173f347251d
 */

import { call, put } from 'redux-saga/effects';

import { setIsNetworkError } from 'app/features/app/appSlice';

export function* callWrapperSaga(fn, ...args) {
  try {
    return yield call(fn, ...args);
  } catch (err) {
    if (err?.message === 'Network Error' || err?.code === 'ERR_NETWORK') {
      yield put(setIsNetworkError(true));
    } else {
      yield put(setIsNetworkError(false));
    }
    throw err;
  }
}
