import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import app from 'app/config/app';
import Button from 'core/buttons/Button';
import TextButton from 'core/buttons/TextButton';
import useQueryParams from 'hooks/useQueryParams';
import FormContainer from 'core/forms/FormContainer';
import FormSelect from 'hook-form-controls/FormSelect';
import AuthUserIsSuperAdmin from 'components/auth/AuthUserIsSuperAdmin';
import { getPartnerCountries } from 'app/features/partner-countries/partnerCountrySaga';
import { createLicensingPartnerMap } from 'app/features/licensing-partners-map/licensingPartnersMapSaga';

import { getPartner } from 'app/features/partners/partnerSaga';
import { getLicensingPartners } from 'app/features/licensing-partners/licensingPartnerSaga';
import { getPartnerFundingSources } from 'app/features/partner-funding-sources/partnerFundingSourceSaga';

const licensePartnerMapper = Yup.object().shape({
  partner_id: Yup.string().required('Partner ID is required'),
  licensing_partner_id: Yup.string().required('Licensing Partner ID is required'),
});

function LicensePartnerMapperForm({
  preFilterByPartner,
  LicensePartnerMapper,
  licensingPartnerId,
  retries,
  isCreated,
}) {
  const submitButtonRef = useRef(null);

  const [queryParams] = useQueryParams(app.ignoreFilterKeys);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [countryFilter, setCountryFilter] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  const [licensingPartnersData, setLicensingPartnersData] = useState([]);
  const [partnerFundingSourceOptions, setPartnerFundingSourceOptions] = useState([]);

  const licensingPartnersState = useSelector((state) => state.licensingPartners.index);

  const partnerFundingSourcesState = useSelector((state) => state.partnerFundingSources.index);

  const { params, data: partnerFundingSourceData, islaoding: isPartnerFundingSourceLoading } = partnerFundingSourcesState;
  const { data: licensePartnersResponse, isLoading: isLicensingPartnerLoading } =
    licensingPartnersState;

  const partnerCountriesState = useSelector((state) => state.partnerCountries.index);

  const { data: partnerCountry, isLoading: isCountryLoading } = partnerCountriesState;

  const {
    success: isSuccess,
    isLoading,
    data: partner,
  } = useSelector((state) => state.partners.show);

  const partnerOptions = partner
    ? [
        {
          label: partner.name,
          value: partner.id,
        },
      ]
    : [];

  const methods = useForm({
    defaultValues: {
      partner_id: licensingPartnerId,
      ...LicensePartnerMapper,
    },
    resolver: yupResolver(licensePartnerMapper),
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = methods;

  const onSubmit = async (data) => {
    const finalData = {
      partner_id: data?.partner_id,
      licensing_partner_id: data?.licensing_partner_id,
      payment_method_id: data?.payment_method_id,
    };

    dispatch(createLicensingPartnerMap(finalData));
  };

  useEffect(() => {
    if (retries > 0) submitButtonRef.current.click();
  }, [retries]);

  useEffect(() => {
    if (isCreated) reset({ partner_id: '' });
  }, [isCreated, reset]);

  useEffect(() => {
    if (partnerOptions[0]?.value && isSuccess) {
      setValue('partner_id', partnerOptions[0].value);
    }
  }, [partnerOptions, isSuccess]);

  const partnerId = partner?.id;


  useEffect(() => {
    if (partnerFundingSourceData) {
      const options = partnerFundingSourceData?.map((item) => ({
        label: item?.funding_source_name,
        value: item?.id,
      }));
      setPartnerFundingSourceOptions(options);
    }
  }, [partnerFundingSourceData]);

  useEffect(() => {
    dispatch(
      getPartnerFundingSources({
        ...queryParams,
        ...params,
        ...(preFilterByPartner ? { partner_id: partnerId } : {}),
      }),
    );
  }, [JSON.stringify(params)]);


  useEffect(() => {
    dispatch(getPartnerCountries({
      ...queryParams,
      is_active: true,
      partner_id: partnerId,
    }));
  }, [partnerId, dispatch]);

  useEffect(() => {
    setValue('payment_method_id', partnerFundingSourceOptions);
  }, [setValue, licensingPartnerId]);

  useEffect(() => {
    dispatch(getLicensingPartners());
  }, [dispatch]);

  useEffect(() => {
    if (licensingPartnerId) {
      dispatch(getPartner(licensingPartnerId));
    }
  }, [licensingPartnerId]);

  useEffect(() => {
    const partnerCountriesOptions = partnerCountry?.map((country) => country?.iso3);

    const countries = Array.from(
      new Set(
        licensePartnersResponse.map((item) => item.country).filter((country) => country !== null),
      ),
    );

    if (JSON.stringify(partnerCountriesOptions) !== JSON.stringify(countryOptions)) {
      setCountryOptions(
        partnerCountriesOptions.map((country) => ({ label: country, value: country })),
      );
    }
  }, [partnerCountry, licensePartnersResponse]);

  useEffect(() => {
    const filteredPartners = licensePartnersResponse
      ?.filter((item) => item.country === countryFilter)
      .map((item) => ({
        label: item.name,
        value: item.id,
      }));

    setLicensingPartnersData(filteredPartners || []);
  }, [countryFilter, licensePartnersResponse]);

  if (isPartnerFundingSourceLoading) return <div>Loading Payment Methods.......</div>;

  return (
    <FormContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item sm={12} md={6}>
              <AuthUserIsSuperAdmin>
                <FormSelect
                  name="partner_id"
                  label="Partner ID"
                  options={partnerOptions}
                  error={!!errors?.partner_id}
                  disabled
                  helperText={errors?.partner_id?.message ?? ''}
                />
              </AuthUserIsSuperAdmin>
            </Grid>

            <Grid item sm={12} md={6}>
              <FormSelect
                id="select-country"
                name="country"
                label="Country"
                value={countryFilter}
                fullWidth={true}
                options={countryOptions}
                error={!!errors.country}
                helperText={errors.country?.message ?? ''}
                onChange={(e) => {
                  setCountryFilter(e.target.value);
                }}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormSelect
                id="select-partner"
                name="licensing_partner_id"
                label="Licensing Partner ID"
                fullWidth={true}
                options={licensingPartnersData}
                error={!!errors?.licensing_partner_id}
                helperText={errors?.licensing_partner_id?.message ?? ''}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormSelect
                id="select-payment-id"
                name="payment_method_id"
                label="Payment method ID"
                options={partnerFundingSourceOptions}
                fullWidth={true}
                error={!!errors.payment_method_id}
                helperText={errors.payment_method_id?.message ?? ''}
              />
            </Grid>

            <Grid item sm={12} md={12}>
              <Box display="flex" justifyContent="space-between">
                <TextButton type="button" color="error" onClick={() => navigate(-1)}>
                  Cancel
                </TextButton>
                <Button
                  ref={submitButtonRef}
                  type="submit"
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                >
                  Create License Partner Mapper
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </FormContainer>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    retries: state.licensingPartnersMap.create.retries,
    isCreated: state.licensingPartnersMap.create.isCreated,
  };
}

export default compose(connect(mapStateToProps))(LicensePartnerMapperForm);

LicensePartnerMapperForm.propTypes = {
  LicensePartnerMapper: PropTypes.object,
  retries: PropTypes.number,
  isCreated: PropTypes.bool,
  licensingPartnerId: PropTypes.string,
};
