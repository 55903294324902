import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import ApiConfigForm from 'components/api-configs/ApiConfigForm';
import PartnerFormSkeleton from 'components/partners/PartnerFormSkeleton';
import ApiConfigProgressIndicatorModal from 'components/api-configs/ApiConfigProgressIndicatorModal';

import acl from 'app/config/acl';
import withRole from 'hoc/withRole';
import { editApiConfig } from 'app/features/api-configs/apiConfigSaga';

function EditApiConfig({ isNetworkError }) {
  const params = useParams();

  const dispatch = useDispatch();

  const {
    data: apiConfig,
    isError,
    errorMessage,
    isLoading,
    errorCode,
  } = useSelector((state) => state.apiConfigs.edit);

  useEffect(() => {
    dispatch(editApiConfig(params.apiConfigId));
  }, [params.apiConfigId, isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(editApiConfig(params.apiConfigId))}
      />
    );

  return (
    <PageContent title="Edit ApiConfig">
      {isLoading ? <PartnerFormSkeleton /> : <ApiConfigForm isAddMode={false} apiConfig={apiConfig} />}
      <ApiConfigProgressIndicatorModal isAddMode={false} />
    </PageContent>
  );
}

export default compose(withAppState, withRole(acl.SUPERADMIN))(EditApiConfig);
