const constants = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  REFRESH_TOKEN_VALID_TO: 'REFRESH_TOKEN_VALID_TO',
  USER: 'USER',
  VERIFY_IP: 'VERIFY_IP',
  REQUEST_TIMEOUT_ERROR: 'REQUEST_TIMEOUT_ERROR',
  INTENDED_PATH: 'INTENDED_PATH',
  TABLE_CHECKBOX_SELECT_CHECKBOX_ID: 'APP_SELECT_CHECKBOX',
  TABLE_SERIAL_NUMBER_COLUMN_ID: 'TABLE_SERIAL_NUMBER_COLUMN_ID',
  TABLE_ACTION_COLUMN_ID: 'TABLE_ACTION_COLUMN_ID',
  IS_DRAWER_OPEN: 'IS_DRAWER_OPEN',
};

export default constants;
