import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import { resetGetPartnerCredentialsFailure } from 'app/features/partner-credentials/partnerCredentialSlice';

import Auth from 'utils/Auth';
import acl from 'app/config/acl';
import withRole from 'hoc/withRole';
import PartnerCredentials from 'components/partner-credentials/PartnerCredentials';
import PartnerCredentialRecentlyCreated from 'components/partner-credentials/PartnerCredentialRecentlyCreated';

function ListPartnerCredentials({ isNetworkError }) {
  const credentialsState = useSelector((state) => state.partnerCredentials.index);

  const { isError, errorCode } = credentialsState;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetPartnerCredentialsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetPartnerCredentialsFailure())}
      />
    );

  return (
    <PageContent title="Credentials">
      <PartnerCredentialRecentlyCreated />
      <PartnerCredentials partnerId={Auth.getPartnerId()} />
    </PageContent>
  );
}

export default compose(withAppState, withRole(acl.ADMIN))(ListPartnerCredentials);
