import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import WebhookHeaders from 'components/webhook-headers/WebhookHeaders';
import WebhookHeaderFilterValues from 'components/webhook-headers/WebhookHeaderFilterValues';
import { resetGetWebhookHeadersFailure } from 'app/features/webhook-headers/webhookHeaderSlice';
import WebhookHeaderBriefDetailsModal from 'components/webhook-headers/WebhookHeaderBriefDetailsModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';

function ListWebhookHeader({ isNetworkError }) {
  const webhookHeaderState = useSelector((state) => state.webhookHeaders.index);

  const { isError, errorCode } = webhookHeaderState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetWebhookHeadersFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetWebhookHeadersFailure())}
      />
    );

  return (
    <PageContent
      title="Webhook Headers"
      rightTopEndContent={
        <Button
          onClick={() => navigate(buildRoute('webhook-headers.create'))}
          color="primary"
          startIcon={<AddIcon />}
        >
          Add Webhook Header
        </Button>
      }
    >
      <WebhookHeaderFilterValues />
      <WebhookHeaders />
      <WebhookHeaderBriefDetailsModal />
    </PageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ListWebhookHeader);
