import PropTypes from 'prop-types';
import { poppins } from 'app/themes/defaultTheme';

export default function Description({ children }) {
  return (
    <span
      style={{
        fontFamily: poppins,
        fontSize: '1.2rem',
        fontWeight: '400',
        color: '#637381'
      }}
    >
      {children}
    </span>
  );
}

Description.propTypes = {
  children: PropTypes.string.isRequired
};
