import { useSelector, useDispatch } from 'react-redux';

import app from 'app/config/app';
import FilterValues from 'core/filter/FilterValues';
import { deleteFilterParams } from 'app/features/transactions/transactionSlice';

export default function TransactionFilterValues() {
  const dispatch = useDispatch();

  const { params } = useSelector((state) => state.transactions.index);

  const handleOnRemoveFilter = (key) => {
    dispatch(deleteFilterParams(key));
  };

  return (
    <FilterValues
      value={params}
      onRemoveFilter={handleOnRemoveFilter}
      ignoreKeys={[...app.ignoreFilterKeys, 'partner_id', 'user_id']}
    />
  );
}
