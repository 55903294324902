import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getAusTrackReportsFailure,
  getAusTrackReportsSuccess,
  getAusTrackReportsInit,
  setShowCreateProcessingModal,
} from './ausTrackReportsSlice.js';

const getAusTrackReports = createAction('ausTrackReports/list');

/**
 * get a Aus track Reports list
 *
 */
function* getAusReportsSaga({ payload }) {
  yield put(getAusTrackReportsInit());
  try {
    let endpoint = `${apiEndpoints.getAusTrackReports}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getAusTrackReportsSuccess(Array.isArray(response?.data) ? response?.data : []));
    yield put(setShowCreateProcessingModal(false));
  } catch (err) {
    yield put(getAusTrackReportsFailure(err));
  }
}

function* ausTrackReportsSaga() {
  yield takeLatest(getAusTrackReports, getAusReportsSaga);
}

export default ausTrackReportsSaga;
export { getAusTrackReports };
