import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { compose } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import CallIcon from '@mui/icons-material/Call';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import Button from 'core/buttons/Button';
import Avatar from '@mui/material/Avatar';
import Skeleton from 'core/skeleton/Skeleton';

import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import useCountries from 'hooks/useCountries';

const AccountCardContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[200]}`,
}));

const AccountLogo = styled(Avatar)(({ theme }) => ({
  height: '80px',
  width: '80px',
  background: theme.palette.primary.main,
}));

const AccountDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'center',
}));

const AccountActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginRight: 'auto',
  alignSelf: 'baseline',
  justifyContent: 'end',
}));

function AccountCard({ isLoading, account }) {
  const { getCountryNameByIso3Code } = useCountries();

  const navigate = useNavigate();

  const params = useParams();

  if (isLoading)
    return (
      <AccountCardContainer elevation={0}>
        <Skeleton sx={{ width: '80px', height: '80px', borderRadius: '4px' }} />
        <AccountDetails sx={{ gap: 1 }}>
          <Skeleton sx={{ width: '250px', height: '32px', borderRadius: '4px' }} />
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={1}
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            <Skeleton sx={{ width: '200px', height: '16px', borderRadius: '4px' }} />
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={1}
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            <Skeleton sx={{ width: '180px', height: '12px', borderRadius: '10px' }} />
          </Stack>
        </AccountDetails>
        <AccountActions>
          <Skeleton sx={{ width: '130px', height: '37px', borderRadius: '4px' }} />
        </AccountActions>
      </AccountCardContainer>
    );

  return (
    <AccountCardContainer elevation={0}>
      <AccountLogo
        src={`https://ui-avatars.com/api/?background=232D6C&color=fff&name=${account.name}`}
        variant="rounded"
      />
      <AccountDetails sx={{ gap: 0.4 }}>
        <Typography variant="h5" fontWeight={800} letterSpacing={1.5} color="primary.main">
          {account.name}
        </Typography>
        <Grid container>
          {[
            {
              icon: <EmailIcon sx={{ fontSize: 'inherit' }} />,
              value: account.email,
            },
            {
              icon: <CallIcon sx={{ fontSize: 'inherit' }} />,
              value: account.phone_number,
            },
            {
              icon: <AdminPanelSettingsIcon sx={{ fontSize: 'inherit' }} />,
              value: account.user_type,
            },
          ].map((item, i) => (
            <Grid item xs={12} md={6} key={i}>
              <Stack
                flexDirection="row"
                alignItems="center"
                gap={1}
                sx={{ color: (theme) => theme.palette.primary.main }}
              >
                {item.icon}
                <Typography>{item.value}</Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </AccountDetails>
      <AccountActions>
        {isLoading ? (
          <Skeleton sx={{ width: '130px', height: '37px', borderRadius: '4px' }} />
        ) : (
          <Button onClick={() => navigate(buildRoute('accounts.edit', params.accountId))}>
            Edit Account
          </Button>
        )}
      </AccountActions>
    </AccountCardContainer>
  );
}

export default compose(connect(mapStateToProps))(AccountCard);

AccountCard.propTypes = {
  account: PropTypes.object,
  isLoading: PropTypes.bool,
};

AccountCard.defaultProps = {
  account: {},
  isLoading: false,
};

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    ...(!Object.prototype.hasOwnProperty.call(ownProps, 'account')
      ? {
          account: state.accounts.show.data || {},
        }
      : {}),
    ...(!Object.prototype.hasOwnProperty.call(ownProps, 'isLoading')
      ? {
          isLoading: state.accounts.show.isLoading || isEmpty(state.accounts.show.data),
        }
      : {}),
  };
}
