import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import WebhookHeader from 'components/webhook-headers/WebhookHeader';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import withRole from 'hoc/withRole';
import { resetGetWebhookHeaderFailure } from 'app/features/webhook-headers/webhookHeaderSlice';

function ViewWebhookHeader({ isNetworkError }) {
  const params = useParams();

  const { isError, errorCode } = useSelector((state) => state.webhookHeaders.show);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetWebhookHeaderFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error status={errorCode || 0} onRetry={() => dispatch(resetGetWebhookHeaderFailure())} />
    );

  return (
    <PageContent
      title="Webhook Header Details"
      rightTopEndContent={
        <Button
          onClick={() => navigate(buildRoute('webhook-headers.edit', params.webhookHeaderId))}
          color="primary"
          startIcon={<EditIcon />}
        >
          Edit Webhook Header
        </Button>
      }
    >
      <WebhookHeader webhookHeaderId={params.webhookHeaderId} />
    </PageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ViewWebhookHeader);
