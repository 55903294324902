const Route = {
  /**
   * check if route pathname is equal to path
   *
   * @param {string} path
   * @returns {boolean}
   */
  is: (path) => {
    if (typeof path !== 'string') throw new Error('Route.is() expects a string as argument');
    if (path.endsWith('*')) {
      path = path.slice(0, -1);
      return (
        window.location.pathname.startsWith(path) || window.location.pathname.startsWith('/' + path)
      );
    }

    return window.location.pathname.slice(1) === path;
  }
};

export default Route;
