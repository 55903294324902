import { useMemo, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';

import GroupedTableView from 'core/table/GroupedTableView';

import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import { getLoggedInUser } from 'app/features/auth/authSaga';

export default function MyProfile() {
  const dispatch = useDispatch();

  const account = useSelector((state) => state.auth.user);

  console.log(account);

  const myProfileData = useMemo(
    () => [
      {
        groupName: '',
        groupData: [
          {
            label: 'First Name',
            data: 'first_name'
          },
          {
            label: 'Last Name',
            data: 'last_name'
          },
          {
            label: 'Email',
            data: 'email'
          },
          {
            label: 'Phone Number',
            data: 'phone_number'
          },
          {
            label: 'Last Login at',
            data: 'last_login_ts',
            get: ({ last_login_ts }) =>
              !isEmpty(last_login_ts) ? DateUtils.isoToString(last_login_ts) : '-'
          }
        ]
      }
    ],
    []
  );

  useEffect(() => {
    if (isEmpty(account)) dispatch(getLoggedInUser());
  }, []);

  return (
    <Grid container spacing={2}>
      {myProfileData.map((group, i) => (
        <GroupedTableView
          key={i}
          title={group.groupName}
          data={group.groupData}
          item={account}
          isLoading={isEmpty(account)}
        />
      ))}
    </Grid>
  );
}
