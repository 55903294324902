import ZaiRefundLogs from 'components/zai-logs/ZaiRefundLogs';
import PageContent from 'layouts/PageContent';

function ListZaiRefundLogs() {
  return (
    <PageContent title="Zai Refund Logs">
      <ZaiRefundLogs />
    </PageContent>
  );
}

export default ListZaiRefundLogs;
