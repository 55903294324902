import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

import Spacer from 'core/spacer/Spacer';
import Divider from '@mui/material/Divider';
import Skeleton from 'core/skeleton/Skeleton';
import TextButton from 'core/buttons/TextButton';

import range from 'helpers/range';
import isEmpty from 'helpers/isEmpty';

const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '0',
  width: 450,
  height: '100vh',
  backgroundColor: theme.palette.background.paper,
  border: 0,
  boxShadow: 24,
}));

const ModalHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 3, 1.5, 7),
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '63.98px',
}));

const ModalBody = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  height: 'calc(100vh  - 48.5px)',
  padding: theme.spacing(1.5, 3, 3, 7),
  // '&::-webkit-scrollbar': {
  //   width: '10px',
  // },
  // '&::-webkit-scrollbar-track': {
  //   backgroundColor: theme.palette.grey[400],
  // },
  // '&::-webkit-scrollbar-thumb': {
  //   background: theme.palette.primary.main,
  //   borderRadius: '6px',
  // },
  // '&::-webkit-scrollbar-thumb:hover': {
  //   background: theme.palette.grey[600],
  //   cursor: 'pointer',
  // },
}));

const CloseButton = styled(Box)(() => ({
  position: 'absolute',
  top: 9,
  left: 6,
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 600,
  margin: 0,
  padding: 0,
  color: theme.palette.grey[800],
}));

const Value = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  margin: 0,
  padding: 0,
  color: theme.palette.grey[600],
  marginTop: '-0.2rem',
  wordBreak: 'break-word',
}));

function SidebarBriefModal({
  open,
  onClose,
  title,
  onClickView,
  viewButtonLabel,
  children,
  isLoading,
  viewData,
  item,
}) {
  return (
    <div>
      <Modal open={open} onClose={onClose}>
        <ModalContainer>
          <CloseButton>
            <IconButton color="error" onClick={onClose}>
              <CancelIcon />
            </IconButton>
          </CloseButton>
          <ModalHeader>
            <Typography color="background.paper" fontSize="1rem">
              {title}
            </Typography>
            {Object.prototype.toString.call(onClickView) === '[object Function]' && (
              <TextButton sx={{ color: 'white', fontSize: '0.8rem' }} onClick={onClickView}>
                {viewButtonLabel}
              </TextButton>
            )}
          </ModalHeader>
          <ModalBody>
            <Grid container rowSpacing={isLoading ? 3 : 2}>
              {isLoading || isEmpty(item)
                ? range(0, viewData.length).map((i) => (
                    <Grid item xs={12} key={i}>
                      <Skeleton
                        sx={{ borderRadius: '16px', height: '16px', width: '200px', mb: 1 }}
                      />
                      <Skeleton sx={{ borderRadius: '16px', height: '12px', width: '150px' }} />
                    </Grid>
                  ))
                : viewData.map((viewDataItem, i) => (
                    <Grid item xs={12} key={i}>
                      <Box>
                        <Label>{viewDataItem.label}</Label>
                        {Object.prototype.toString.call(viewDataItem?.get) ===
                        '[object Function]' ? (
                          <Box display="flex" justifyContent="start">
                            {viewDataItem.get(item)}
                          </Box>
                        ) : (
                          <Value>
                            {!isEmpty(item?.[viewDataItem.data]) ? item?.[viewDataItem.data] : '-'}
                          </Value>
                        )}
                      </Box>
                    </Grid>
                  ))}
            </Grid>
            {children}
          </ModalBody>
        </ModalContainer>
      </Modal>
    </div>
  );
}

export default SidebarBriefModal;

SidebarBriefModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  title: PropTypes.string.isRequired,
  onClickView: PropTypes.func,
  viewButtonLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  viewData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      data: PropTypes.any,
      get: PropTypes.func
    }),
  ),
  item: PropTypes.object,
};

SidebarBriefModal.defaultProps = {
  viewButtonLabel: ' View full details',
};
