import ZaiWebhookLogs from 'components/zai-logs/ZaiWebhookLogs';
import PageContent from 'layouts/PageContent';

function ListZaiWebhookLogs() {
  return (
    <PageContent title="Zai Webhook logs">
      <ZaiWebhookLogs />
    </PageContent>
  );
}

export default ListZaiWebhookLogs;
