import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import isEmpty from 'helpers/isEmpty';

export default function ViewTransactionDocument({ transactionDocument, isLoading }) {
  const transctionDocumentUrl = transactionDocument?.url;

  return (
    <Box>
      <Typography fontWeight={600} fontSize={18} marginBottom={2}>
        Document
      </Typography>

      <Box
        sx={{
          display: 'flex',
          gap: {
            md: 5,
          },
          flexDirection: {
            xs: 'row',
            md: 'row',
            lg: 'column',
          },
        }}
      >
        {isLoading ? (
          <Box>Loading...</Box>
        ) : (
          <Box>
            {isEmpty(transctionDocumentUrl) ? (
              <Typography>No Document Available</Typography>
            ) : (
              <Box>
                    <img src={transctionDocumentUrl} alt="transaction-document" width="250px" height="auto" />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
