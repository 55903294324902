import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getTransactionsInit,
  getTransactionsSuccess,
  getTransactionsFailure,
  getTransactionInit,
  getTransactionFailure,
  getTransactionSuccess,
  approveTransactionInit,
  approveTransactionSuccess,
  approveTransactionFailure,
  rejectTransactionInit,
  rejectTransactionSuccess,
  rejectTransactionFailure,
  exportTransactionInit,
  exportTransactionSuccess,
  exportTransactionFailure,
} from './transactionSlice';
import Notify from 'utils/Notify';
import downloadBlobResponseAsFile from 'helpers/downloadBlobResponseAsFile';
import dayjs from 'dayjs';

const getTransaction = createAction('transactions/get');
const getTransactions = createAction('transactions/list');
const approveTransaction = createAction('transactions/approve');
const rejectTransaction = createAction('transactions/reject');
const exportTransactions = createAction('transactions/export');

/**
 * get a single transaction
 */
function* getTransactionSaga({ payload: transactionId }) {
  try {
    yield put(getTransactionInit());
    let endpoint = buildApiRoute(apiEndpoints.getTransaction, transactionId);
    let response = yield callWrapperSaga(() => http.get(endpoint, transactionId));
    yield put(getTransactionSuccess(response.data));
  } catch (err) {
    yield put(getTransactionFailure(err));
  }
}

/**
 * get a transactions list
 *
 */
function* getTransactionsSaga({ payload }) {
  yield put(getTransactionsInit());
  try {
    let endpoint = `${apiEndpoints.getTransactions}?${qs.stringify(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getTransactionsSuccess(response.data));
  } catch (err) {
    yield put(getTransactionsFailure(err));
  }
}

function* approveTransactionSaga({ payload: { transactionId, ...data } }) {
  yield put(approveTransactionInit());
  try {
    let endpoint = buildApiRoute(apiEndpoints.updateTransaction, transactionId);
    let response = yield callWrapperSaga(() => http.post(endpoint, data));
    Notify.success('Transaction approved successfully.');
    yield put(approveTransactionSuccess(response.data));
  } catch (err) {
    yield put(approveTransactionFailure(err));
  }
}

function* rejectTransactionSaga({ payload: { transactionId, ...data } }) {
  yield put(rejectTransactionInit());
  try {
    let endpoint = buildApiRoute(apiEndpoints.updateTransaction, transactionId);
    let response = yield callWrapperSaga(() => http.post(endpoint, data));
    Notify.success('Transaction rejected successfully.');
    yield put(rejectTransactionSuccess(response.data));
  } catch (err) {
    yield put(rejectTransactionFailure(err));
  }
}

function* exportTransactionsSaga({ payload }) {
  yield put(exportTransactionInit());
  try {
    let endpoint = `${apiEndpoints.exportTransactions}?${qs.stringify(payload)}`;
    let response = yield callWrapperSaga(() =>
      http.get(endpoint, {
        responseType: 'blob',
      }),
    );
    downloadBlobResponseAsFile(response.data, {
      fileName: `transactions-${dayjs().format('YYYY-MM-DD')}`,
      blobType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      fileExtension: 'xlsx',
    });
    yield put(exportTransactionSuccess(response.data));
  } catch (err) {
    yield put(exportTransactionFailure(err));
  }
}

function* transactionSaga() {
  yield takeLatest(getTransaction, getTransactionSaga);
  yield takeLatest(getTransactions, getTransactionsSaga);
  yield takeLatest(approveTransaction, approveTransactionSaga);
  yield takeLatest(rejectTransaction, rejectTransactionSaga);
  yield takeLatest(exportTransactions, exportTransactionsSaga);
}

export default transactionSaga;
export {
  getTransaction,
  getTransactions,
  approveTransaction,
  rejectTransaction,
  exportTransactions,
};
