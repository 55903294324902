import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import Auth from 'utils/Auth';
import { buildRoute } from 'routes';
import { getCustomer } from 'app/features/customers/customerSaga';
import { hideCustomerBriefDetails } from 'app/features/customers/customerSlice';

function CustomerBriefDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const viewData = useMemo(
    () => [
      ...(Auth.isSuperAdmin()
        ? [
            {
              label: 'Partner Id',
              data: 'partner_id',
              get: ({ partner_id }) => <PartnerClickAwayDetails partnerId={partner_id} />
            }
          ]
        : []),
      {
        label: 'First Name',
        data: 'first_name'
      },
      {
        label: 'Last Name',
        data: 'last_name'
      },
      {
        label: 'Email',
        data: 'email'
      },
      {
        label: 'Mobile Number',
        data: 'mobile_number'
      },
      {
        label: 'State',
        data: 'state'
      },
      {
        label: 'Country',
        data: 'country'
      }
    ],
    []
  );

  const state = useSelector((state) => state.customers.show);

  const { showBriefDetailModal, customerId, isLoading, data } = state;

  useEffect(() => {
    if (customerId) dispatch(getCustomer(customerId));
  }, [customerId]);

  if (!showBriefDetailModal && !customerId) return <></>;

  return (
    <SidebarBriefModal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideCustomerBriefDetails())}
      onClickView={() => (
        dispatch(hideCustomerBriefDetails()), navigate(buildRoute('customers.show', customerId))
      )}
      title="Customer Details"
      viewData={viewData}
      item={data}
      isLoading={isLoading}
    />
  );
}

export default CustomerBriefDetailsModal;
