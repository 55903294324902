import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import Customer from 'components/customers/Customer';
import CustomerCard from 'components/customers/CustomerCard';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import withRole from 'hoc/withRole';
import { resetGetCustomerFailure } from 'app/features/customers/customerSlice';

function ViewCustomer({ isNetworkError }) {
  const params = useParams();

  const { isError, errorCode } = useSelector((state) => state.customers.show);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetCustomerFailure());
  }, [isNetworkError]);

  if (isError)
    return <Error status={errorCode || 0} onRetry={() => dispatch(resetGetCustomerFailure())} />;

  return (
    <PageContent
      title="Customer Details"
      rightTopEndContent={
        <Button onClick={() => navigate(buildRoute('customers.index'))} color="primary">
          List Customer
        </Button>
      }
    >
      <CustomerCard />
      <Customer customerId={params.customerId} />
    </PageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]))(ViewCustomer);
