import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import Partner from 'components/partners/Partner';
import PartnerCard from 'components/partners/PartnerCard';

import acl from 'app/config/acl';
import withRole from 'hoc/withRole';
import { resetGetPartnerFailure } from 'app/features/partners/partnerSlice';

function ViewPartner({ isNetworkError }) {
  const params = useParams();

  const { isError, errorCode, errorMessage } = useSelector((state) => state.partners.show);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetPartnerFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode || 0}
        message={errorMessage}
        onRetry={() => dispatch(resetGetPartnerFailure())}
      />
    );

  return (
    <PageContent title="Partner Details">
      <PartnerCard />
      <Partner partnerId={params.partnerId} />
    </PageContent>
  );
}

export default compose(withAppState, withRole(acl.SUPERADMIN))(ViewPartner);
