import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import app from 'app/config/app';

function ValidationErrorMessages({ errors }) {
  if (!app.showGroupError) return <></>;

  let hasErrors = Object.keys(errors).length > 0;

  return (
    <Box sx={{ mb: hasErrors ? 3 : 0 }} display="flex" gap={0.5} flexDirection="column">
      {Object.keys(errors).map((key) => {
        return (
          <Typography
            sx={{ fontSize: '0.75rem', fontWeight: 400 }}
            color="error"
            variant="caption"
            key={key}
          >
            {errors[key].message}
          </Typography>
        );
      })}
    </Box>
  );
}

export default ValidationErrorMessages;

ValidationErrorMessages.propTypes = {
  errors: PropTypes.object
};

ValidationErrorMessages.defaultProps = {
  errors: {}
};
