import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import Error from 'core/errors/Error';
import PartnersActions from './PartnersActions';
import ActiveBadge from 'core/badges/ActiveBadge';
import FormCheckbox from 'core/forms/FormCheckbox';
import PartnerPagination from './PartnerPagination';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import TableContainer from 'core/table/TableContainer';
import TanstackReactTable from 'core/table/TanstackReactTable';

import { buildRoute } from 'routes';
import withAppState from 'hoc/withAppState';
import constants from 'app/config/constants';
import useCountries from 'hooks/useCountries';
import Dimensions from 'app/config/Dimensions';
import { getPartners } from 'app/features/partners/partnerSaga';
import { getPartnerBriefDetailsInit } from 'app/features/partners/partnerSlice';

function Partners() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const partnerState = useSelector((state) => state.partners.index);

  const { data, params, isLoading, isError, errorCode } = partnerState;

  const { countries, getCountryNameByIso3Code } = useCountries();

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name,
      },
      {
        id: 'post_code',
        header: 'PostCode',
        accessorFn: (row) => row.post_code,
        isVisible: false,
      },
      {
        id: 'address',
        header: 'Address',
        accessorFn: (row) => row.address,
        isVisible: false,
      },
      {
        id: 'city',
        header: 'City',
        accessorFn: (row) => row.city,
      },
      {
        id: 'state',
        header: 'State',
        accessorFn: (row) => row.state,
      },
      {
        id: 'country',
        header: 'Country',
        accessorFn: (row) => row.country,
        cell: (info) => getCountryNameByIso3Code(info.getValue()),
      },
      {
        id: 'currency',
        header: 'Currency',
        accessorFn: (row) => row.currency,
      },
      {
        id: 'is_verified',
        header: 'Is Verified ?',
        accessorFn: (row) => row.is_verified,
        cell: (info) => {
          return <VerifiedBadge isVerified={!!info.getValue()} />;
        },
      },
      {
        id: 'is_active',
        header: 'Is Active ?',
        accessorFn: (row) => row.is_active,
        cell: (info) => <ActiveBadge isActive={!!info.getValue()} />,
      },
      {
        id: 'is_zai_enabled',
        header: 'Is Zai Enabled ?',
        accessorFn: (row) => row.is_zai_enabled,
        cell: (info) => <VerifiedBadge isVerified={!!info.getValue()} />,
      },

      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,
        cell: (info) => {
          const row = info.row.original;

          return (
            <Box display="flex" gap={1}>
              <IconButton
                size={Dimensions.table.iconButton.size}
                onClick={() => {
                  dispatch(
                    getPartnerBriefDetailsInit({
                      showBriefDetailModal: true,
                      partnerId: info.getValue(),
                    }),
                  );
                }}
              >
                <AspectRatioIcon fontSize={Dimensions.table.iconButton.iconSize} />
              </IconButton>
              <IconButton
                size={Dimensions.table.iconButton.size}
                onClick={() => navigate(buildRoute('partners.show', info.getValue()))}
              >
                <FullscreenIcon fontSize={Dimensions.table.iconButton.iconSize} />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [countries],
  );

  useEffect(() => {
    dispatch(getPartners({ ...params }));
  }, [JSON.stringify(params)]);

  const [columnVisibility, setColumnVisibility] = useState(
    columns
      .filter((c) => c?.isVisible === false)
      .map((c) => c.id)
      .reduce((a, k) => ({ ...a, [k]: false }), {}),
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(getPartners({ ...params }));
  }, [JSON.stringify(params)]);

  if (isError) return <Error status={errorCode} />;

  return (
    <TableContainer>
      <PartnersActions table={table} />
      <TanstackReactTable
        table={table}
        isLoading={isLoading}
        data={data}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <PartnerPagination />
    </TableContainer>
  );
}

export default withAppState(Partners);
