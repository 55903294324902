import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import FundingSources from 'components/funding-sources/FundingSources';
import FundingSourceFilterValues from 'components/funding-sources/FundingSourceFilterValues';
import { resetGetFundingSourcesFailure } from 'app/features/funding-sources/fundingSourceSlice';
import FundingSourceBriefDetailsModal from 'components/funding-sources/FundingSourceBriefDetailsModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';

function ListFundingSource({ isNetworkError }) {
  const fundingSourceState = useSelector((state) => state.fundingSources.index);

  const { isError, errorCode } = fundingSourceState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetFundingSourcesFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetFundingSourcesFailure())}
      />
    );

  return (
    <PageContent
      title="Funding Sources"
      rightTopEndContent={
        <Button
          onClick={() => navigate(buildRoute('funding-sources.create'))}
          color="primary"
          startIcon={<AddIcon />}
        >
          Add Funding Source
        </Button>
      }
    >
      <FundingSourceFilterValues />
      <FundingSources />
      <FundingSourceBriefDetailsModal />
    </PageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ListFundingSource);
