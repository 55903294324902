import { createSlice } from '@reduxjs/toolkit';

import constants from 'app/config/constants';
import { localStorageGet, localStorageHas } from 'helpers/localStorage';

const initialState = {
  isNetworkError: false,
  isCheckingInternetConnection: false,
  isDrawerOpen: localStorageHas(constants.IS_DRAWER_OPEN)
    ? localStorageGet(constants.IS_DRAWER_OPEN) === 'true'
    : true,
};

export const appSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsNetworkError: (state, { payload }) => {
      state.isNetworkError = payload;
    },
    setIsCheckingInternetConnection: (state, { payload }) => {
      state.isCheckingInternetConnection = payload;
    },
    setIsDrawerOpen: (state, { payload }) => {
      state.isDrawerOpen = payload;
    },
  },
});

export const { setIsNetworkError, setIsCheckingInternetConnection, setIsDrawerOpen } =
  appSlice.actions;

export default appSlice.reducer;
