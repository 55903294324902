import { createSlice } from '@reduxjs/toolkit';

import app from 'app/config/app';

const initialState = {
  index: {
    data: [],
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc',
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
};

export const customerKycLogSlice = createSlice({
  name: 'customerKycLogs',
  initialState,
  reducers: {
    getCustomerKycLogsInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },
    getCustomerKycLogsSuccess: (state, { payload }) => {
      let { data, pagination } = payload;
      const { totalCount, totalPage, currentPage, pageSize } = pagination;
      let startSN = (currentPage - 1) * pageSize;
      data = data?.map((item) => ({
        sn: (startSN += 1),
        ...item,
      }));
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },
    getCustomerKycLogsFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },
    resetGetCustomerKycLogsFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },
    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },
    resetParam: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },
    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },
  },
});

export const {
  getCustomerKycLogsInit,
  getCustomerKycLogsSuccess,
  getCustomerKycLogsFailure,
  resetGetCustomerKycLogsFailure,
  setParam,
  resetParam,
  deleteFilterParams,
} = customerKycLogSlice.actions;

export default customerKycLogSlice.reducer;
