import { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ListItem from './ListItem';
import ListItemContexts from './ListItemContexts';

const ListItemLink = function ListItemLink({
  isDrawerOpen,
  icon,
  primary,
  to,
  isActive,
  context,
  onClick,
  ...rest
}) {
  const CustomLink = useMemo(
    () => forwardRef((linkProps, ref) => <Link ref={ref} to={to} {...linkProps} />),
    [to],
  );

  if (!isDrawerOpen)
    return (
      <Tooltip title={primary} placement="right">
        <ListItem
          button
          component={CustomLink}
          onClick={onClick}
          context={context}
          isActive={isActive}
          {...rest}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={primary} />
        </ListItem>
      </Tooltip>
    );

  return (
    <ListItem
      button
      component={CustomLink}
      onClick={onClick}
      context={context}
      isActive={isActive}
      {...rest}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  );
};

export default ListItemLink;

ListItemLink.propTypes = {
  isDrawerOpen: PropTypes.bool,
  icon: PropTypes.node,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  context: PropTypes.oneOf([ListItemContexts.item, ListItemContexts.dropdownItem]),
};

ListItemLink.defaultProps = {
  isDrawerOpen: false,
  context: ListItemContexts.item,
};
