import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';

import { buildRoute } from 'routes';
import { getApiConfig } from 'app/features/api-configs/apiConfigSaga';
import { hideApiConfigBriefDetails } from 'app/features/api-configs/apiConfigSlice';

function ApiConfigBriefDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const viewData = useMemo(
    () => [
      {
        label: 'API Partner Code',
        data: 'api_partner_code',
      },
      {
        label: 'API Url',
        data: 'api_url',
      },
      {
        label: 'API User ID',
        data: 'api_user_id',
      },
      {
        label: 'API User Secret',
        data: 'api_user_secret',
      },
      {
        label: 'Ref1',
        data: 'ref1',
      },
      {
        label: 'Ref2',
        data: 'ref2',
      },
      {
        label: 'Ref3',
        data: 'ref3',
      },
      {
        label: 'Remarks',
        data: 'remarks',
      },
    ],
    [],
  );

  const state = useSelector((state) => state.apiConfigs.show);

  const { showBriefDetailModal, apiConfigId, isLoading, data, isError } = state;

  useEffect(() => {
    if (apiConfigId) dispatch(getApiConfig(apiConfigId));
  }, [apiConfigId]);

  if (!showBriefDetailModal && !apiConfigId) return <></>;

  return (
    <SidebarBriefModal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideApiConfigBriefDetails())}
      viewData={viewData}
      item={data}
      isLoading={isLoading}
      title="ApiConfig Details"
      onClickView={() => (
        dispatch(hideApiConfigBriefDetails()), navigate(buildRoute('api-configs.show', apiConfigId))
      )}
    ></SidebarBriefModal>
  );
}

export default ApiConfigBriefDetailsModal;
