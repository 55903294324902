class FinmoUtils {
  /**
   * this function helps to get a beneficiary name for a finmo payout
   *
   * @param {Object} payout_beneficiary_param
   * @return {String}
   */
  static getPayoutBeneficiaryName(payout_beneficiary_param) {
    if (payout_beneficiary_param?.type === 'individual') {
      return (
        payout_beneficiary_param?.individual?.first_name +
        ' ' +
        payout_beneficiary_param?.individual?.last_name
      );
    } else if (payout_beneficiary_param?.type === 'company') {
      return payout_beneficiary_param?.company?.name;
    } else {
      return undefined;
    }
  }
}

export default FinmoUtils;
