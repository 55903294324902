import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';

import { buildRoute } from 'routes';
import useAuthUser from 'hooks/useAuthUser';
import { getAccount } from 'app/features/accounts/accountSaga';
import { hideAccountBriefDetails } from 'app/features/accounts/accountSlice';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

function AccountBriefDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useAuthUser();

  const viewData = useMemo(
    () => [
      {
        label: 'Name',
        data: 'name'
      },
      {
        label: 'Email',
        data: 'email'
      },
      {
        label: 'Phone Number',
        data: 'phone_number'
      },
      {
        label: 'Role',
        data: 'user_type'
      },
      ...(user?.isSuperAdmin()
        ? [
            {
              label: 'Partner Id',
              data: 'partner_id',
              get: ({ partner_id, partner_name }) => (
                <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
              )
            }
          ]
        : [])
    ],
    []
  );

  const state = useSelector((state) => state.accounts.show);

  const { showBriefDetailModal, accountId, isLoading, data } = state;

  useEffect(() => {
    if (accountId) dispatch(getAccount(accountId));
  }, [accountId]);

  if (!showBriefDetailModal && !accountId) return <></>;

  return (
    <SidebarBriefModal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideAccountBriefDetails())}
      title="Account Details"
      onClickView={() => (
        dispatch(hideAccountBriefDetails()), navigate(buildRoute('accounts.show', accountId))
      )}
      viewData={viewData}
      item={data}
      isLoading={isLoading}
    />
  );
}

export default AccountBriefDetailsModal;
