import { useRef, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { compose } from '@reduxjs/toolkit';
import { useSearchParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Button from 'core/buttons/Button';
import FormContainer from 'core/forms/FormContainer';
import FormSelect from 'hook-form-controls/FormSelect';
import FormCheckbox from 'hook-form-controls/FormCheckbox';
import FormTextField from 'hook-form-controls/FormTextField';
import FormSelectPartner from 'hook-form-controls/FormSelectPartner';
import ValidationErrorMessages from 'core/errors/ValidationErrorMessages';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import acl from 'app/config/acl';
import ucwords from 'helpers/ucwords';
import YupPassword from 'utils/YupPassword';
import useQueryParams from 'hooks/useQueryParams';
import { createAccount, updateAccount } from 'app/features/accounts/accountSaga';

YupPassword(Yup);

const createAccountSchema = Yup.object().shape({
  partner_id: Yup.string()
    .nullable()
    .when('user_type', {
      is: (value) => [acl.ADMIN, acl.USER].includes(value),
      then: Yup.string().required('Partner is required'),
    }),
  first_name: Yup.string().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  user_type: Yup.string().required('Role is required'),
  phone_number: Yup.string().required('Phone number is required'),
  email: Yup.string().email('Enter a valid email address').required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must contain 8 or more characters')
    .minLowercase(1, 'Password must contain at least 1 lower case letter')
    .minUppercase(1, 'Password must contain at least 1 upper case letter')
    .minNumbers(1, 'Password must contain at least 1 number')
    .minSymbols(1, 'Password must contain at least 1 special character'),
  confirm_password: Yup.string()
    .required('Please retype your password.')
    .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
  is_active: Yup.boolean(),
});

const editAccountSchema = Yup.object().shape({
  first_name: Yup.string().nullable().required('First Name is required'),
  last_name: Yup.string().required('Last Name is required'),
  phone_number: Yup.string().required('Phone number is required'),
});

function AccountForm({ isAddMode, account, retries, isCreated }) {
  const submitButtonRef = useRef(null);

  const dispatch = useDispatch();

  const [params] = useQueryParams();

  const methods = useForm({
    defaultValues: {
      is_active: true,
      ...(app.isDevelopmentMode() && isAddMode ? {} : {}),
      ...(!Auth.isSuperAdmin()
        ? {
            partner_id: Auth.getPartnerId(),
          }
        : {}),
      ...(account ? account : {}),
    },
    resolver: yupResolver(isAddMode ? createAccountSchema : editAccountSchema),
    mode: 'onChange',
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
  } = methods;

  const userType = watch('user_type');

  const onSubmit = async (data) => {
    if (data?.user_type === acl.SUPERADMIN) {
      data.partner_id = null;
    }
    dispatch(isAddMode ? createAccount(data) : updateAccount({ ...data, id: account.id }));
  };

  useEffect(() => {
    if (userType === acl.SUPERADMIN) {
      setValue('partner_id', '');
    }
  }, [userType]);

  useEffect(() => {
    if (retries > 0) submitButtonRef.current.click();
  }, [retries]);

  useEffect(() => {
    if (isCreated) reset({ partner_id: getValues('partner_id') });
  }, [isCreated]);

  return (
    <FormContainer>
      {app.isDevelopmentMode() && <ValidationErrorMessages errors={errors} />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={3} columnSpacing={4}>
            {Auth.isSuperAdmin() && userType !== acl.SUPERADMIN && (
              <Grid item sm={12}>
                <FormSelectPartner
                  id="select-partner"
                  name="partner_id"
                  label="Select Partner"
                  error={!!errors?.partner_id}
                  helperText={errors?.partner_id?.message ?? ''}
                  disabled={isAddMode}
                />
              </Grid>
            )}
            <Grid item sm={12} md={6}>
              <FormTextField
                name="first_name"
                label="First Name"
                error={!!errors?.first_name}
                helperText={errors?.first_name?.message ?? ''}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormTextField
                name="last_name"
                label="Last Name"
                error={!!errors?.last_name}
                helperText={errors?.last_name?.message ?? ''}
              />
            </Grid>
            {isAddMode && (
              <Grid item sm={12} md={6}>
                <FormSelect
                  name="user_type"
                  label="Role"
                  error={!!errors?.user_type}
                  helperText={errors?.user_type?.message ?? ''}
                  options={[
                    ...(Auth.isSuperAdmin()
                      ? [
                          {
                            label: ucwords(acl.SUPERADMIN),
                            value: acl.SUPERADMIN,
                          },
                          {
                            label: ucwords(acl.ADMIN),
                            value: acl.ADMIN,
                          },
                        ]
                      : []),
                    {
                      label: ucwords(acl.USER),
                      value: acl.USER,
                    },
                  ]}
                />
              </Grid>
            )}
            <Grid item sm={12} md={6}>
              <FormTextField
                name="phone_number"
                label="Phone Number"
                error={!!errors?.phone_number}
                helperText={errors?.phone_number?.message ?? ''}
              />
            </Grid>
            {isAddMode && (
              <>
                <Grid item sm={12} md={6}>
                  <FormTextField
                    name="email"
                    label="Email"
                    error={!!errors?.email}
                    helperText={errors?.email?.message ?? ''}
                  />
                </Grid>
                <Grid item sm={12} md={6}></Grid>
                <Grid item sm={12} md={6}>
                  <FormTextField
                    type="password"
                    name="password"
                    label="Password"
                    error={!!errors?.password}
                    helperText={errors?.password?.message ?? ''}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <FormTextField
                    type="password"
                    name="confirm_password"
                    label="Confirm Password"
                    error={!!errors?.confirm_password}
                    helperText={errors?.confirm_password?.message ?? ''}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <FormCheckbox
                    name="is_active"
                    label="Is Active ?"
                    error={!!errors?.is_active}
                    helperText={errors?.is_active?.message ?? ''}
                  />
                </Grid>
              </>
            )}

            <Grid item sm={12} md={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  ref={submitButtonRef}
                  type="submit"
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                >
                  {isAddMode ? 'Create New Account' : 'Update Account'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </FormContainer>
  );
}

export default compose(connect(mapStateToProps))(AccountForm);

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    retries: ownProps.isAddMode ? state.accounts.create.retries : state.accounts.update.retries,
    isCreated: state.accounts.create.isCreated,
  };
}

AccountForm.propTypes = {
  isAddMode: PropTypes.bool.isRequired,
  account: PropTypes.object,
  retries: PropTypes.number,
  isCreated: PropTypes.bool,
};
