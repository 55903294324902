import { useSelector } from 'react-redux';

import acl from 'app/config/acl';

const useAuthUser = () => {
  const user = useSelector((state) => state.auth.user);

  const isSuperAdmin = () => {
    return user?.user_type === acl.SUPERADMIN;
  };

  const isAdmin = () => {
    return user?.user_type === acl.ADMIN;
  };

  const isUser = () => {
    return user?.user_type === acl.USER;
  };

  if (user)
    return {
      name: user.name,
      role: user.user_type,
      email: user.email,
      partnerId: user.partner_id,
      first_name: user.first_name,
      last_name: user.last_name,
      isSuperAdmin,
      isAdmin,
      isUser
    };

  return {
    isSuperAdmin,
    isAdmin,
    isUser
  };
};

export default useAuthUser;
