import { useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import Error from 'core/errors/Error';
import FormCheckbox from 'core/forms/FormCheckbox';
import TableContainer from 'core/table/TableContainer';
import TanstackReactTable from 'core/table/TanstackReactTable';

import DateUtils from 'utils/DateUtils';
import constants from 'app/config/constants';

import WebhookLogPagination from 'components/zai-transactions/WebhookLogPagination';
import { getZaiRefundLogs } from 'app/features/transactions/zaiTransactionSaga';
import ZaiRefundLogsAction from './ZaiRefundLogsAction';

export default function ZaiRefundLogs() {
  const dispatch = useDispatch();

  const [columnVisibility, setColumnVisibility] = useState({});

  const transactionState = useSelector((state) => state.zaiTransactions.refundLogs);

  const { data, params, isLoading, isError, errorCode } = transactionState;

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        header: 'Webhook ID',
        accessorFn: (row) => row.webhook_id,
      },

      {
        header: 'Refund Amount',
        accessorFn: (row) => row.refund_amount,
      },
      {
        header: 'refund Status',
        accessorFn: (row) => row.refund_status,
      },
      {
        header: 'Remarks',
        accessorFn: (row) => row.remarks,
      },
      {
        header: 'Currency',
        accessorFn: (row) => row.transaction_currency,
      },
      {
        header: 'Payment Amount',
        accessorFn: (row) => row.payment_amount,
        cell: ({ getValue }) => <Typography align="right">{getValue()}</Typography>,
      },
      {
        header: 'Remarks',
        accessorFn: (row) => row.remarks,
      },
      {
        header: 'Created Date',
        accessorFn: (row) => DateUtils.isoToString(row.created_ts),
      },
      {
        header: 'Updated Date',
        accessorFn: (row) => (row.updated_ts ? DateUtils.isoToString(row.updated_ts) : '-'),
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  if (isError) return <Error status={errorCode} />;

  useEffect(() => {
    dispatch(
      getZaiRefundLogs({
        ...params,
      }),
    );
  }, [JSON.stringify(params)]);

  return (
    <TableContainer>
      <ZaiRefundLogsAction table={table} />

      <TanstackReactTable
        table={table}
        isLoading={isLoading}
        data={data}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <WebhookLogPagination />
    </TableContainer>
  );
}
