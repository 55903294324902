import { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'core/buttons/Button';
import TransactionLogs from './TransactionLogs';
import SpringModal from 'core/modal/SpringModal';
import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import GroupedTableView from 'core/table/GroupedTableView';
import RejectTransactionForm from './RejectTransactionForm';
import ApproveTransactionForm from './ApproveTransactionForm';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import CustomerClickAwayDetails from 'components/customers/CustomerClickAwayDetails';

import Auth from 'utils/Auth';
import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import useCountries from 'hooks/useCountries';
import { fundingSources } from 'app/config/FundingSource';
import { transactionStatuses } from 'app/config/TransactionStatus';
import { getTransactionsLog } from 'app/features/transactions/transactionLogSaga';
import {
  approveTransaction,
  getTransaction,
  rejectTransaction,
} from 'app/features/transactions/transactionSaga';
import Clipboard from 'core/clipboard/Clipboard';

export default function Transaction({ transactionId }) {
  const dispatch = useDispatch();

  const [openApproveTransactionModal, setOpenApproveTransactionModal] = useState(false);
  const [openRejectTransactionModal, setOpenRejectTransactionModal] = useState(false);

  const hideApproveTransactionModal = useCallback(() => setOpenApproveTransactionModal(false), []);
  const hideRejectTransactionModal = useCallback(() => setOpenRejectTransactionModal(false), []);

  const { countries, getCountryNameByIso3Code } = useCountries();

  const viewTransactionData = useMemo(
    () => [
      {
        groupName: 'Brief Details',
        groupData: [
          {
            label: 'Transaction Id',
            data: 'id',
          },
          {
            label: 'Order Id',
            data: 'order_id',
          },

          ...(Auth.isSuperAdmin()
            ? [
                {
                  label: 'Partner',
                  data: 'partner_id',
                  get: ({ partner_id, partner_name }) => (
                    <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                  ),
                },
              ]
            : []),
          {
            label: 'Funding Id',
            data: 'funding_id',
          },
          {
            label: 'Funding Type',
            data: 'funding_type',
          },
          {
            label: 'Sending Country',
            get: ({ sending_country }) => getCountryNameByIso3Code(sending_country),
          },
          {
            label: 'Sending Amount',
            data: 'sending_amount',
          },
          {
            label: 'Sending Currency',
            data: 'sending_currency',
          },
          {
            label: 'Payout Country',
            get: ({ payout_country }) => getCountryNameByIso3Code(payout_country),
          },
          {
            label: 'Payout Amount',
            data: 'payout_amount',
          },
          {
            label: 'Payout Currency',
            data: 'payout_currency',
          },
          {
            label: 'Purpose',
            data: 'txn_purpose',
          },
          {
            label: 'Status',
            data: 'status',
          },
          {
            label: 'Transaction/Reference ID',
            data: 'transaction_reference_number',
            get: ({ transaction_reference_number }) =>
              transaction_reference_number ? (
                <Clipboard content={transaction_reference_number} />
              ) : (
                'N/A'
              ),
          },
          {
            label: 'Remarks',
            data: 'remarks',
          },
          {
            label: 'Is Active ?',
            data: 'is_active',
            get: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
          },
          {
            label: 'Is Verified ?',
            data: 'is_verified',
            get: ({ is_verified }) => <VerifiedBadge isVerified={!!is_verified} />,
          },

          {
            label: 'Created At',
            data: 'created_ts',
            get: ({ created_ts }) =>
              !isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '-',
          },
          {
            label: 'Created By',
            data: 'created_by',
          },
          {
            label: 'Updated At',
            data: 'updated_ts',
            get: ({ updated_ts }) =>
              !isEmpty(updated_ts) ? DateUtils.isoToString(updated_ts) : '-',
          },
          {
            label: 'Updated By',
            data: 'updated_by',
          },
        ],
      },
      {
        groupName: 'Customer Details',
        groupData: [
          {
            label: 'Customer',
            data: 'user_name',
          },
          {
            label: 'Customer ID',
            data: 'user_id',
            get: ({ user_id }) => <CustomerClickAwayDetails customerId={user_id} />,
          },
          {
            label: 'ID Type',
            data: 'id_type',
          },
          {
            label: 'Email',
            data: 'email',
          },
          {
            label: 'Date of Birth',
            data: 'dob',
          },
          {
            label: 'Mobile Number',
            data: 'mobile_number',
          },
          {
            label: 'Suburb',
            data: 'suburb',
          },
          {
            label: 'Street Type',
            data: 'street',
          },
          {
            label: 'Street Number',
            data: 'street_number',
          },
        ],
      },
      {
        groupName: 'Beneficiary Details',
        groupData: [
          {
            label: 'ID',
            data: 'beneficiary_id',
          },
          {
            label: 'Name',
            data: 'beneficiay_name',
          },
          {
            label: 'Type',
            data: 'beneficiary_type',
          },
          {
            label: 'Relation',
            data: 'beneficiary_relation',
          },
          {
            label: 'Address',
            data: 'beneficiary_address',
          },
          {
            label: 'Country',
            get: ({ beneficiary_country }) => getCountryNameByIso3Code(beneficiary_country),
          },
          {
            label: 'Currency',
            data: 'beneficiary_currency',
          },
          {
            label: 'Mobile Number',
            data: 'beneficiary_mobile_number',
          },
          {
            label: 'Payment Type',
            data: 'beneficiary_payment_type',
          },
          {
            label: 'Account Number',
            data: 'beneficiary_account_number',
          },
          {
            label: 'Branch',
            data: 'beneficiary_branch',
          },
        ],
      },
    ],
    [countries],
  );

  const handleApproveTransaction = (data) => {
    dispatch(
      approveTransaction({
        ...data,
        transactionId,
      }),
    );
  };

  const handleRejectTransaction = (data) => {
    dispatch(
      rejectTransaction({
        ...data,
        transactionId,
      }),
    );
  };

  const { isLoading, data: transaction } = useSelector((state) => state.transactions.show);

  const { isLoading: isApproving } = useSelector((state) => state.transactions.approve);
  const { isLoading: isRejecting } = useSelector((state) => state.transactions.reject);
  const { isLoading: isTransactionLoading, data: transactionLogData } = useSelector(
    (state) => state.transactionLogs,
  );

  /**
   * Note: For following funding sources we don't need to approve the transaction
   */
  const rejectFundingTypes = [
    // fundingSources.payNowQR.toUpperCase(),
    fundingSources.poli.toUpperCase(),
  ];

  useEffect(() => {
    if (isApproving || isRejecting) return;
    dispatch(getTransaction(transactionId));
    dispatch(getTransactionsLog(transactionId));
  }, [transactionId, isApproving, isRejecting]);

  return (
    <>
      <Grid container spacing={2}>
        {viewTransactionData.map((group, i) => (
          <GroupedTableView
            key={i}
            title={group.groupName}
            data={group.groupData}
            item={transaction}
            isLoading={isLoading}
          />
        ))}
      </Grid>
      {!isLoading &&
        transaction &&
        transaction.status.toUpperCase() === transactionStatuses.created.toUpperCase() &&
        Auth.isSuperAdmin() && (
          <Box display="flex" gap={3}>
            {transaction && !rejectFundingTypes.includes(transaction.funding_type.toUpperCase()) && (
              <>
                <Button color="success" onClick={() => setOpenApproveTransactionModal(true)}>
                  Approve
                </Button>

                <SpringModal
                  header="Approve Transaction"
                  isOpen={openApproveTransactionModal}
                  onClose={() => setOpenApproveTransactionModal(true)}
                  enableMaximizeButton={false}
                  enableDefaultPadding
                  sx={{
                    maxWidth: '600px',
                  }}
                >
                  <ApproveTransactionForm
                    onSubmit={handleApproveTransaction}
                    onCancel={hideApproveTransactionModal}
                  />
                </SpringModal>
              </>
            )}

            <Button color="error" onClick={() => setOpenRejectTransactionModal(true)}>
              Reject
            </Button>
            <SpringModal
              header="Reject Transaction"
              isOpen={openRejectTransactionModal}
              onClose={() => setOpenRejectTransactionModal(true)}
              enableMaximizeButton={false}
              enableDefaultPadding
              sx={{
                maxWidth: '600px',
              }}
            >
              <RejectTransactionForm
                onSubmit={handleRejectTransaction}
                onCancel={hideRejectTransactionModal}
              />
            </SpringModal>
          </Box>
        )}
    </>
  );
}

Transaction.propTypes = {
  transactionId: PropTypes.string,
};
