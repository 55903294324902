import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';

import { buildRoute } from 'routes';
import { getFinmoPayin } from 'app/features/finmo-payins/finmoPayinSaga';
import { hideFinmoPayinBriefDetails } from 'app/features/finmo-payins/finmoPayinSlice';
import ucwords from 'helpers/ucwords';
import Clipboard from 'core/clipboard/Clipboard';

function FinmoPayinBriefDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const viewData = useMemo(
    () => [
      {
        label: 'Payin ID',
        get: ({ payin_id }) => <Clipboard content={payin_id} />,
      },
      {
        label: 'Payin Method Name',
        data: 'payin_method_name',
      },
      {
        label: 'Payin Method Description',
        data: 'payin_method_description',
      },
      {
        label: 'Payin Method Category',
        get: ({ payin_method_category }) => ucwords(payin_method_category),
      },
      {
        label: 'PayID Reference',
        get: ({ payin_method_param }) => payin_method_param?.payid_reference,
      },
      {
        label: 'Amount',
        data: 'amount',
      },
      {
        label: 'Currency',
        data: 'currency',
      },
      {
        label: 'Status',
        data: 'status',
      },
      {
        label: 'Paycode',
        get: ({ pay_code }) => <Clipboard content={pay_code?.text} />,
      },
      {
        label: 'Invoice ID',
        get: ({ metadata }) => <Clipboard content={metadata?.invoice_id} />,
      },
    ],
    [],
  );

  const state = useSelector((state) => state.finmoPayins.show);

  const { showBriefDetailModal, finmoPayinId, isLoading, data } = state;

  useEffect(() => {
    if (finmoPayinId) dispatch(getFinmoPayin(finmoPayinId));
  }, [finmoPayinId]);

  if (!showBriefDetailModal && !finmoPayinId) return <></>;

  return (
    <SidebarBriefModal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideFinmoPayinBriefDetails())}
      title="Finmo Payin Details"
      onClickView={() => (
        dispatch(hideFinmoPayinBriefDetails()),
        navigate(buildRoute('finmo-payins.show', finmoPayinId))
      )}
      viewData={viewData}
      item={data}
      isLoading={isLoading}
    />
  );
}

export default FinmoPayinBriefDetailsModal;
