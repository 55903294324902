import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import Clipboard from 'core/clipboard/Clipboard';
import GroupedTableView from 'core/table/GroupedTableView';

import isEmpty from 'helpers/isEmpty';
import ucwords from 'helpers/ucwords';
import DateUtils from 'utils/DateUtils';
import { getFinmoPayin } from 'app/features/finmo-payins/finmoPayinSaga';
import Skeleton from 'core/skeleton/Skeleton';

const PrettyPrintContainer = styled('div')(({ theme }) => ({
  background: theme.palette.grey[200],
  borderRadius: '8px',
  padding: theme.spacing(3),
}));

export default function FinmoPayin({ finmoPayinId }) {
  const dispatch = useDispatch();

  const viewFinmoPayinData = useMemo(
    () => [
      {
        groupName: '',
        groupData: [
          {
            label: 'Payin ID',
            get: ({ payin_id }) => <Clipboard content={payin_id} />,
          },
          {
            label: 'Payin Method Name',
            data: 'payin_method_name',
          },
          {
            label: 'Payin Method Description',
            data: 'payin_method_description',
          },
          {
            label: 'Payin Method Category',
            get: ({ payin_method_category }) => ucwords(payin_method_category),
          },
          {
            label: 'PayID Reference',
            get: ({ payin_method_param }) => payin_method_param?.payid_reference,
          },
          {
            label: 'Amount',
            data: 'amount',
          },
          {
            label: 'Currency',
            data: 'currency',
          },

          {
            label: 'Status',
            get: ({ status }) => ucwords(status),
          },
          {
            label: 'Paycode',
            get: ({ pay_code }) => <Clipboard content={pay_code?.text} />,
          },
          {
            label: 'Invoice ID',
            get: ({ metadata }) => <Clipboard content={metadata?.invoice_id} />,
          },
          {
            label: 'Created At',
            data: 'created_at',
            get: ({ created_at }) =>
              !isEmpty(created_at) ? DateUtils.isoToString(created_at) : '-',
          },
          {
            label: 'Updated At',
            data: 'updated_at',
            get: ({ updated_at }) =>
              !isEmpty(updated_at) ? DateUtils.isoToString(updated_at) : '-',
          },
          {
            label: 'Expire At',
            data: 'expire_at',
            get: ({ expire_at }) => (!isEmpty(expire_at) ? DateUtils.isoToString(expire_at) : '-'),
          },
          {
            label: 'Expired At',
            data: 'expired_at',
            get: ({ expired_at }) =>
              !isEmpty(expired_at) ? DateUtils.isoToString(expired_at) : '-',
          },
          {
            label: 'Cancelled At',
            data: 'cancelled_at',
            get: ({ cancelled_at }) =>
              !isEmpty(cancelled_at) ? DateUtils.isoToString(cancelled_at) : '-',
          },
        ],
      },
    ],
    [],
  );

  const {
    isError,
    errorCode,
    isLoading,
    data: finmoPayin,
  } = useSelector((state) => state.finmoPayins.show);

  useEffect(() => {
    dispatch(getFinmoPayin(finmoPayinId));
  }, [finmoPayinId]);

  return (
    <>
      <Grid container spacing={2}>
        {viewFinmoPayinData.map((group, i) => (
          <GroupedTableView
            key={i}
            title={group.groupName}
            data={group.groupData}
            item={finmoPayin}
            isLoading={isLoading}
          />
        ))}
      </Grid>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box sx={{ width: '100%' }} display="flex" justifyContent="space-between">
            <Typography>More Details</Typography>
            <Clipboard label="" content={JSON.stringify(finmoPayin)} />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {isLoading ? (
            <Skeleton />
          ) : (
            <PrettyPrintContainer>
              <pre>
                <code>{JSON.stringify(finmoPayin, undefined, 2)}</code>
              </pre>
            </PrettyPrintContainer>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

FinmoPayin.propTypes = {
  finmoPayinId: PropTypes.string,
};
