import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import Button from 'core/buttons/Button';
import SearchBox from 'core/forms/SearchBox';
import ReloadButton from 'core/buttons/ReloadButton';
import ColumnVisibility from 'core/table/ColumnVisibility';
import TableActionsContainer from 'core/table/TableActionsContainer';

import debounce from 'helpers/debounce';
import FilterType from 'app/config/FilterType';
import useQueryParams from 'hooks/useQueryParams';
import ExportDocuments from 'core/lab/ExportDocuments';
import TableGridFilter from 'core/table/TableGridFilter';
import partnerIgnoreFilterKeys from './data/partnerIgnoreFilterKeys';
import { getPartners, getPartnersReportDownload } from 'app/features/partners/partnerSaga';
import {
  deleteFilterParams,
  resetPartnersReportDownload,
  setParam,
} from 'app/features/partners/partnerSlice';

export default function PartnerActions({ table }) {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const partnerState = useSelector((state) => state.partners.index);

  const [queryParams, updateQueryParams] = useQueryParams(partnerIgnoreFilterKeys);

  let availableFilters = [
    {
      label: '',
      value: 'search',
      type: FilterType.search,
    },
    {
      label: 'Name',
      value: 'name',
      type: FilterType.input,
    },
    {
      label: 'Country',
      value: 'country',
      type: FilterType.selectCountry,
    },
    {
      label: 'Contact Email',
      value: 'cnt_email',
      type: FilterType.input,
    },
    {
      label: 'Contact Mobile No.',
      value: 'cnt_mobile_number',
      type: FilterType.input,
    },
  ];

  const { params: appliedFilters, success } = partnerState;

  const [search, setSearch] = useState(appliedFilters?.search ?? '');

  const handleOnFilter = useCallback((data) => {
    dispatch(setParam({ ...data, page_number: 1 }));
  }, []);

  const handleOnSearch = useCallback((e) => {
    setSearch(e.target.value);
    debounce(() => {
      dispatch(setParam({ search: e.target.value, page_number: 1 }));
    }, 500);
  }, []);

  const handleOnClearSearch = useCallback(() => {
    setSearch('');
    dispatch(deleteFilterParams('search'));
  }, []);

  const handleOnReset = useCallback((e) => {
    dispatch(deleteFilterParams([...availableFilters.map((filter) => filter.value)]));
  });

  const handlePdfDownloadReport = () => {
    dispatch(
      getPartnersReportDownload({
        format: 'pdf',
      }),
    );
  };

  const handleExcelDownloadReport = () => {
    dispatch(
      getPartnersReportDownload({
        format: 'excel',
      }),
    );
  };

  useEffect(() => {
    if (success) {
      dispatch(resetPartnersReportDownload());
    }
  }, [success, dispatch]);

  useEffect(() => {
    updateQueryParams(appliedFilters);
    setSearch(appliedFilters?.search ?? '');
  }, [JSON.stringify(appliedFilters)]);

  useEffect(() => {
    dispatch(setParam(queryParams));
  }, [JSON.stringify(queryParams)]);

  return (
    <>
      <Box>
        <TableGridFilter
          showFilter={showFilter}
          appliedFilters={appliedFilters}
          availableFilters={availableFilters}
          onFilter={handleOnFilter}
          onReset={handleOnReset}
        />
      </Box>
      <TableActionsContainer>
        <Box>
          <SearchBox
            onChange={handleOnSearch}
            value={search || ''}
            onClickClearSearch={handleOnClearSearch}
          />
        </Box>
        <Box display="flex" gap={1}>
          <Button
            variant="outlined"
            endIcon={<FilterAltIcon />}
            onClick={() => setShowFilter(!showFilter)}
            uppercase
          >
            Filter
          </Button>

          {table && <ColumnVisibility table={table} />}
          {/* <DownloadButton onClick={() => {}} /> */}
          <ReloadButton
            onClick={() => {
              dispatch(getPartners({ ...appliedFilters, page_number: 1 }));
            }}
          />

          <ExportDocuments
            onExcelDownload={handleExcelDownloadReport}
            onPdfDownload={handlePdfDownloadReport}
          />
        </Box>
      </TableActionsContainer>
    </>
  );
}

PartnerActions.propTypes = {
  table: PropTypes.object,
};
