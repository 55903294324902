import { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

import { FormProvider, useForm } from 'react-hook-form';

import Button from 'core/buttons/Button';

import isEmpty from 'helpers/isEmpty';
import Spacer from 'core/spacer/Spacer';
import cleanObject from 'helpers/cleanObject';
import BuildFilterInputValue from 'core/filter/BuildFilterInputValue';
import { yupResolver } from '@hookform/resolvers/yup';

function TableGridFilter({
  showFilter,
  availableFilters,
  appliedFilters,
  onReset,
  onFilter,
  formResolver,
}) {
  const methods = useForm({
    ...(formResolver
      ? {
          resolver: yupResolver(formResolver),
        }
      : undefined),
  });

  const { handleSubmit, setValue } = methods;

  const handleReset = () => {
    // reset({});
    availableFilters.forEach((filter) => {
      setValue(filter.value, '');
    });

    if (Object.prototype.toString.call(onReset) === '[object Function]') onReset();
  };

  const onSubmit = async (data) => {
    if (Object.prototype.toString.call(onFilter) === '[object Function]')
      onFilter(cleanObject(data));
  };

  useEffect(() => {
    let availableFilterKeys = availableFilters.map((filter) => filter.value);
    for (let [key, value] of Object.entries(appliedFilters)) {
      if (availableFilterKeys.includes(key) && !isEmpty(value)) {
        setValue(key, value);
      }
    }
  }, [JSON.stringify(appliedFilters)]);

  return (
    <Collapse
      in={showFilter}
      style={{ transformOrigin: '0 0 0' }}
      {...(showFilter ? { timeout: 300 } : {})}
    >
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            p: 2,
            border: (theme) => `1px solid ${theme.palette.grey[400]}`,
            borderRadius: '0.5rem',
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography color="primary" fontWeight={600} letterSpacing={1.2}>
                    Filter
                  </Typography>
                </Grid>
                {availableFilters.map((filter, i) => {
                  return (
                    <Grid key={i} item xs={12} mg={6} lg={3}>
                      <BuildFilterInputValue filter={filter} filterByKey={filter.value} />
                    </Grid>
                  );
                })}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end" gap={2}>
                    <Button type="button" onClick={handleReset} size="large" variant="outlined">
                      Reset
                    </Button>
                    <Button type="submit" size="large" variant="contained" sx={{ px: 12 }}>
                      Filter
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Box>
      <Divider />
      <Spacer />
    </Collapse>
  );
}

export default memo(TableGridFilter);

TableGridFilter.propTypes = {
  id: PropTypes.string,
  showFilter: PropTypes.bool,
  availableFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
  appliedFilters: PropTypes.object,
  onReset: PropTypes.func,
  onFilter: PropTypes.func,
};

TableGridFilter.defaultValues = {
  showFilter: true,
  availableFilters: [],
  appliedFilters: {},
};
