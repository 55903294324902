import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import PageContent from 'layouts/PageContent';
import WebhookHeaderForm from 'components/webhook-headers/WebhookHeaderForm';
import WebhookHeaderFormSkeleton from 'components/webhook-headers/WebhookHeaderFormSkeleton';
import WebhookHeaderProgressIndicatorModal from 'components/webhook-headers/WebhookHeaderProgressIndicatorModal';

import acl from 'app/config/acl';
import { editWebhookHeader } from 'app/features/webhook-headers/webhookHeaderSaga';

function EditWebhookHeader() {
  const params = useParams();

  const dispatch = useDispatch();

  const {
    data: webhookHeader,
    isError,
    errorMessage,
    isLoading,
    errorCode,
  } = useSelector((state) => state.webhookHeaders.edit);

  useEffect(() => {
    dispatch(editWebhookHeader(params.webhookHeaderId));
  }, [params.webhookHeaderId]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(editWebhookHeader(params.webhookHeaderId))}
      />
    );

  return (
    <PageContent title="Edit Webhook Header">
      {isLoading ? (
        <WebhookHeaderFormSkeleton />
      ) : (
        <WebhookHeaderForm isAddMode={false} webhookHeader={webhookHeader} />
      )}
      <WebhookHeaderProgressIndicatorModal isAddMode={false} />
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(EditWebhookHeader);
