const FilterType = {
  search: 'search',
  input: 'input',
  select: 'select',
  multiSelect: 'multiselect',
  date: 'date',
  time: 'time',
  datepicker: 'datepicker',
  epochTimestampPicker: 'epochTimestampPicker',
  selectCountry: 'selectCountry',
};

export default FilterType;
