import React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import Spacer from 'core/spacer/Spacer';
import Button from 'core/buttons/Button';
import ServerOkSvg from 'assets/svg/server-ok.svg';

import { buildRoute } from 'routes';

const Wrapper = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  zIndex: 999,
  background: theme.palette.grey[100],
  display: 'grid',
  placeItems: 'center'
}));

const Content = styled(Box)(({ theme }) => ({
  display: 'grid',
  placeItems: 'center',
  gap: theme.spacing(1)
}));

export default function LoginVerified({ title, message }) {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Content>
        <img src={ServerOkSvg} />
        <Spacer />
        {title && (
          <Typography variant="h5" color="primary.light" fontWeight={600}>
            {title}
          </Typography>
        )}
        {message && <Typography color="grey.600">{message}</Typography>}
        <Spacer />
        <Button
          size="medium"
          onClick={() => navigate(buildRoute('login'))}
          sx={{ textTransform: 'none' }}
        >
          Goto Login
        </Button>
      </Content>
    </Wrapper>
  );
}

LoginVerified.defaultProps = {
  title: 'Login Verified!',
  message: 'Congratulations your IP has successfully been verified'
};
