const WebhookObjectTypes = [
  {
    label: 'Transaction',
    value: 'TRANSACTION',
  },
  {
    label: 'User',
    value: 'USER',
  },
  {
    label: 'KYC (Know Your Customer)',
    value: 'KYC',
  },
  {
    label: 'Live KYC (Know Your Customer)',
    value: 'LIVE_KYC',
  },
];

export default WebhookObjectTypes;
