import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import { buildRoute } from 'routes';
import useAuthUser from 'hooks/useAuthUser';
import useCountries from 'hooks/useCountries';
import { getPartnerCountry } from 'app/features/partner-countries/partnerCountrySaga';
import { hidePartnerCountryBriefDetails } from 'app/features/partner-countries/partnerCountrySlice';

function PartnerCountryBriefDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useAuthUser();

  const { getCountryNameByIso3Code } = useCountries();

  const viewData = useMemo(
    () => [
      ...(user?.isSuperAdmin()
        ? [
            {
              label: 'Partner Id',
              data: 'partner_id',
              get: ({ partner_id, partner_name }) => (
                <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
              ),
            },
          ]
        : []),
      {
        label: 'Country',
        get: ({ iso3 }) => getCountryNameByIso3Code(iso3),
      },
      {
        label: 'Currency',
        data: 'currency',
      },
      {
        label: 'Is Active ?',
        get: ({ is_active }) => (is_active ? 'Yes' : 'No'),
      },
    ],
    [],
  );

  const state = useSelector((state) => state.partnerCountries.show);

  const { showBriefDetailModal, partnerCountryId, isLoading, data } = state;

  useEffect(() => {
    if (partnerCountryId) dispatch(getPartnerCountry(partnerCountryId));
  }, [partnerCountryId]);

  if (!showBriefDetailModal && !partnerCountryId) return <></>;

  return (
    <SidebarBriefModal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hidePartnerCountryBriefDetails())}
      title="Partner Country Details"
      onClickView={() => (
        dispatch(hidePartnerCountryBriefDetails()),
        navigate(buildRoute('partner-countries.show', partnerCountryId))
      )}
      viewData={viewData}
      item={data}
      isLoading={isLoading}
    />
  );
}

export default PartnerCountryBriefDetailsModal;
