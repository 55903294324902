import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

function SectionTitle({ disableDivider, children }) {
  return (
    <>
      <Typography variant="subtitle1" fontWeight={600}>
        {children}
      </Typography>
      {disableDivider ? null : <Divider sx={{ py: 1 }} />}
    </>
  );
}

export default SectionTitle;

SectionTitle.propTypes = {
  disableDivider: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};
