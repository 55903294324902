import React from 'react';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

import Row from 'core/row/Row';
import Tabs from 'core/tabs/Tabs';
import RefundLogs from './RefundLogs';
import WebhookLogs from './WebhookLogs';
import Column from 'core/column/Column';
import Clipboard from 'core/clipboard/Clipboard';
import SpringModal from 'core/modal/SpringModal';

import { ZaiWebhookLogStatus } from 'app/config/ZaiWebHookLogConfig';
import { closeRefundPaymentModal } from 'app/features/transactions/zaiTransactionSlice';

export default function RefundPaymentModal() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.zaiTransactions.refundPayment);

  const { isModalOpen, isLoading, customerName, customerId } = state;

  const handleClose = () => {
    dispatch(closeRefundPaymentModal());
  };

  return (
    <SpringModal
      header="Refund Payment"
      isOpen={isModalOpen}
      onClose={isLoading ? undefined : handleClose}
      enableMaximizeButton={false}
      enableDefaultPadding
      sx={{
        minWidth: '800px',
      }}
    >
      <Column>
        <Row>
          <Row>
            <Typography fontWeight={600}>Customer Name : </Typography> &nbsp;
            <Typography>{customerName}</Typography>
          </Row>
          <Row alignItems="center">
            <Typography fontWeight={600}>Customer ID : </Typography> &nbsp;
            <Clipboard content={customerId} />
          </Row>
        </Row>
        <Tabs
          tabs={[
            {
              tabName: 'Webhook Logs',
              tabContent: (
                <WebhookLogs customerId={customerId} status={ZaiWebhookLogStatus.REFUNDABLE} />
              ),
            },
            {
              tabName: 'Refund Logs',
              tabContent: <RefundLogs customerId={customerId} />,
            },
          ]}
        />
      </Column>
    </SpringModal>
  );
}
