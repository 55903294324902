import { all } from 'redux-saga/effects';
import appSaga from './features/app/appSaga';
import authSaga from './features/auth/authSaga';
import partnerSaga from './features/partners/partnerSaga';
import accountSaga from './features/accounts/accountSaga';
import webhookSaga from './features/webhooks/webhookSaga';
import customerSaga from './features/customers/customerSaga';
import customerKycDocumentSaga from './features/customer-kyc-documents/customerKycDocumentsSaga';
import transactionDocumentSaga from './features/transactions/transactionDocumentSaga';
import constantSaga from './features/constants/constantSaga';
import apiConfigSaga from './features/api-configs/apiConfigSaga';
import finmoPayinSaga from './features/finmo-payins/finmoPayinSaga';
import transactionSaga from './features/transactions/transactionSaga';
import customerKycSaga from './features/customer-kycs/customerKycSaga';
import finmoPayoutSaga from './features/finmo-payouts/finmoPayoutSaga';
import beneficiarySaga from './features/beneficiaries/beneficiarySaga';
import transactionLogSaga from './features/transactions/transactionLogSaga';
import zaiTransactionSaga from './features/transactions/zaiTransactionSaga';
import webhookHeaderSaga from './features/webhook-headers/webhookHeaderSaga';
import fundingSourceSaga from './features/funding-sources/fundingSourceSaga';
import fundingSourceInfoSaga from './features/funding-sources-info/fundingSourceInfoSaga';
import partnerCountrySaga from './features/partner-countries/partnerCountrySaga';
import ausTrackReportsSaga from './features/ausTrackReports/ausTrackReportsSaga';
import partnerCredentialSaga from './features/partner-credentials/partnerCredentialSaga';
import partnerFundingSourceSaga from './features/partner-funding-sources/partnerFundingSourceSaga';
import customerKycLogsSaga from './features/customer-kyc-logs/customerKycLogsSaga';
import licensingPartnerSaga from './features/licensing-partners/licensingPartnerSaga';
import licensingPartnerMapSaga from './features/licensing-partners-map/licensingPartnersMapSaga';

export default function* rootSaga() {
  yield all([
    appSaga(),
    authSaga(),
    accountSaga(),
    partnerSaga(),
    licensingPartnerSaga(),
    licensingPartnerMapSaga(),
    webhookSaga(),
    customerSaga(),
    customerKycDocumentSaga(),
    transactionDocumentSaga(),
    constantSaga(),
    apiConfigSaga(),
    finmoPayinSaga(),
    finmoPayoutSaga(),
    customerKycSaga(),
    beneficiarySaga(),
    transactionSaga(),
    transactionLogSaga(),
    webhookHeaderSaga(),
    fundingSourceSaga(),
    fundingSourceInfoSaga(),
    partnerCountrySaga(),
    partnerCredentialSaga(),
    partnerFundingSourceSaga(),
    ausTrackReportsSaga(),
    zaiTransactionSaga(),
    customerKycLogsSaga(),
  ]);
}
