import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import Transaction from 'components/transactions/Transaction';

import { buildRoute } from 'routes';
import Button from 'core/buttons/Button';
import { resetGetTransactionFailure } from 'app/features/transactions/transactionSlice';
import withRole from 'hoc/withRole';
import acl from 'app/config/acl';
import { Box, Grid } from '@mui/material';
import ViewTransactionDocument from './ViewTransactionDocument';
import ViewTransactionDetailDocument from './ViewTransactionDetailDocument';

function ViewTransaction({ isNetworkError }) {
  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isError, errorCode, errorMessage } = useSelector((state) => state.transactions.show);

  useEffect(() => {
    if (isError) dispatch(resetGetTransactionFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(resetGetTransactionFailure())}
      />
    );

  return (
    <PageContent
      title="Transaction Details"
      rightTopEndContent={
        <Button onClick={() => navigate(buildRoute('transactions.index'))}>All Transaction</Button>
      }
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          md={10}
          lg={9}
          sx={{
            overflowY: {
              xs: 'visible',
              md: 'auto',
            },
            maxHeight: '100vh',
          }}
        >
          <Transaction transactionId={params.transactionId} />
        </Grid>
        <Box
          item
          xs={12}
          md={3}
          marginLeft={3}
          marginTop={3}
          sx={{
            position: 'sticky',
            top: 0,
          }}
        >
          <ViewTransactionDetailDocument transactionId={params.transactionId} />
        </Box>
      </Grid>
    </PageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ViewTransaction);
