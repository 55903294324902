import { createSlice } from '@reduxjs/toolkit';
import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import Response from 'app/config/Response';

const initialState = {
  index: {
    data: [],
    initialParams: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc',
    },
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc',
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    success: false,
    errorMessage: null,
  },

  show: {
    showBriefDetailModal: false,
    customerId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
};

export const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    getCustomersInit: (state) => {
      state.index.isLoading = true;
      state.index.isError = false;
      state.index.errorCode = null;
      state.index.errorMessage = null;
    },

    getCustomersSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;
      let startSN = (currentPage - 1) * pageSize;
      data = data.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        success: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getCustomersFailure: (state, { payload }) => {
      state.index.isLoading = false;
      state.index.isError = true;
      state.index.errorCode = payload?.response?.status ?? 0;
      state.index.errorMessage = payload?.response?.message;
    },

    resetGetCustomersFailure: (state) => {
      state.index.isLoading = false;
      state.index.isError = false;
      state.index.errorCode = null;
      state.index.errorMessage = null;
    },

    getCustomerReportDownloadInit: (state) => {
      state.index.isLoading = false;
      state.index.isError = false;
      state.index.errorCode = null;
      state.index.errorMessage = null;
      state.index.success = false;
    },

    getCustomerReportDownloadSuccess: (state, { payload }) => {
      let { data } = payload;

      state.index = {
        ...state.index,
        isLoading: false,
        success: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
      };
    },

    getCustomerReportDownloadFailure: (state, { payload }) => {
      state.index.isLoading = false;
      state.index.isError = true;
      state.index.errorCode = payload?.response?.status ?? 0;
      state.index.errorMessage = payload?.response?.message;
    },

    resetCustomerReportDownload: (state) => {
      state.index.isLoading = false;
      state.index.isError = false;
      state.index.errorCode = null;
      state.index.errorMessage = null;
      state.index.success = false;
    },

    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParam: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    getCustomerInit: (state) => {
      state.show.isLoading = true;
      state.show.isError = false;
      state.show.errorCode = null;
      state.show.errorMessage = null;
      state.show.data = null;
    },

    getCustomerSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.show.isLoading = false;
        state.show.isError = true;
        state.show.errorCode = Response.HTTP_NOT_FOUND;
        state.show.errorMessage = 'Customer not found';
      } else {
        state.show.isLoading = false;
        state.show.isError = false;
        state.show.errorCode = null;
        state.show.errorMessage = null;
        state.show.data = payload.data;
      }
    },

    getCustomerFailure: (state, { payload }) => {
      state.show.isLoading = false;
      state.show.isError = true;
      state.show.errorCode = payload?.response?.status ?? 0;
      state.show.errorMessage = payload?.response?.message;
      state.show.data = null;
    },

    resetGetCustomerFailure: (state) => {
      state.show.isLoading = false;
      state.show.isError = false;
      state.show.errorCode = null;
      state.show.errorMessage = null;
      state.show.data = null;
    },

    getCustomerBriefDetailsInit: (state, { payload }) => {
      state.show.showBriefDetailModal = payload.showBriefDetailModal;
      state.show.customerId = payload.customerId;
    },

    hideCustomerBriefDetails: (state) => {
      state.show.showBriefDetailModal = false;
      state.show.customerId = null;
      state.show.isLoading = false;
      state.show.isError = false;
      state.show.errorCode = null;
      state.show.errorMessage = null;
      state.show.data = null;
    },

    setPartnerId: (state, { payload }) => {
      state.index.partnerId = payload;
      state.index.params.page_number = 1;
    },
  },
});

export const {
  getCustomersInit,
  getCustomersSuccess,
  getCustomersFailure,
  resetGetCustomersFailure,
  setParam,
  resetParam,
  deleteFilterParams,
  getCustomerInit,
  getCustomerSuccess,
  getCustomerFailure,
  resetGetCustomerFailure,
  getCustomerBriefDetailsInit,
  hideCustomerBriefDetails,
  setPartnerId,
  getCustomerReportDownloadInit,
  getCustomerReportDownloadSuccess,
  getCustomerReportDownloadFailure,
  resetCustomerReportDownload,
} = customerSlice.actions;

export default customerSlice.reducer;
