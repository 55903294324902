import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';

import { buildRoute } from 'routes';
import { getFundingSourceInfo } from 'app/features/funding-sources-info/fundingSourceInfoSaga';
import { hideFundingSourceInfoBriefDetails } from 'app/features/funding-sources-info/fundingSourceInfoSlice';
function FundingSourceInfoBriefDetailModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const viewData = useMemo(
    () => [
      {
        label: 'Partner Name',
        data: 'partner_name',
      },
      {
        label: 'Payment Name',
        data: 'payment_name',
      },
      {
        label: 'Payment Value',
        data: 'payment_value',
      },
      {
        label: 'Payment Funding Key',
        data: 'funding_key',
      },
      {
        label: 'Payment Funding Key Value',
        data: 'funding_key_value',
      },
      {
        label: 'Is Active ?',
        get: ({ is_active }) => (is_active ? 'Yes' : 'No'),
      },
    ],
    [],
  );

  const state = useSelector((state) => state.fundingSourcesInfo.show);

  const { showBriefDetailModal, fundingSourceInfoId, isLoading, data } = state;

  useEffect(() => {
    if (fundingSourceInfoId) dispatch(getFundingSourceInfo(fundingSourceInfoId));
  }, [fundingSourceInfoId]);

  if (!showBriefDetailModal && !fundingSourceInfoId) return <></>;

  return (
    <SidebarBriefModal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideFundingSourceInfoBriefDetails())}
      title="Funding Source Details"
      onClickView={() => (
        dispatch(hideFundingSourceInfoBriefDetails()),
        navigate(buildRoute('funding-sources-info.show', fundingSourceInfoId))
      )}
      viewData={viewData}
      item={data}
      isLoading={isLoading}
    />
  );
}

export default FundingSourceInfoBriefDetailModal;
