import { useSelector } from 'react-redux';
import Log from 'utils/Log';

const withAppState = (WrappedComponent) => {
  return (props) => {
    const { isNetworkError } = useSelector((state) => state.app);

    return <WrappedComponent {...props} isNetworkError={isNetworkError} />;
  };
};

export default withAppState;
