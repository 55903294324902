import { useEffect, useState, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import styled from '@mui/material/styles/styled';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import OutboundTwoToneIcon from '@mui/icons-material/OutboundTwoTone';

import Skeleton from 'core/skeleton/Skeleton';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import app from 'app/config/app';
import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import isEmpty from 'helpers/isEmpty';
import http from 'app/services/httpService';
import useCountries from 'hooks/useCountries';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

let defaultStyles = {
  position: 'absolute',
  top: 24,
  right: 0,
  left: 0,
  zIndex: 999,
  minWidth: '300px',
  maxWidth: '400px',
  bgcolor: 'background.paper',
  textOverflow: 'initial',
};

const Container = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  maxWidth: '300px',
  position: 'relative',
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 'inherit',
  color: 'inherit',
  cursor: 'pointer',
  '&:hover': {
    fontWeight: '600',
  },
}));

const Header = styled(Box, {
  shouldForwardProp: (prop) => 'imageUrl' !== prop,
})(({ theme, imageUrl }) => ({
  width: '100%',
  height: '100px',
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
  padding: theme.spacing(1, 2),
  backgroundImage: `url('${imageUrl}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
}));

const Content = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 2),
}));

const PartnerLabel = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  bottom: 4,
  right: 2,
  margin: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
}));

function PartnerClickAwayDetails({ partnerId, label }) {
  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [renderer, setRenderer] = useState(0);

  const [partner, setPartner] = useState(null);

  const styles = useMemo(() => {
    return { ...defaultStyles };
  }, [window.scrollY]);

  const fetchPartner = async () => {
    setIsLoading(true);
    try {
      let endpoint = app.apiBaseUrl + apiEndpoints.getPartner;
      let response = await http.get(buildApiRoute(endpoint, partnerId));
      setPartner(response?.data?.data ?? {});
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (partnerId && renderer === 1) fetchPartner();
  }, [partnerId, renderer]);

  useEffect(() => {
    if (open && renderer === 0) setRenderer(1);
  }, [open]);

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={() => setOpen(false)}
    >
      <Box sx={{ position: 'relative' }}>
        <Text onClick={() => setOpen((prev) => !prev)}>{label ?? partnerId ?? '-'}</Text>
        {open && (
          <Box className="partner-brief-details-sm__modal" sx={styles}>
            <Container elevation={3}>
              <Header
                imageUrl={
                  isLoading
                    ? ''
                    : `https://ui-avatars.com/api/?name=${ucwords(
                        partner.name,
                      )}&background=232D6C&color=fff`
                }
              >
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: 1,
                    right: 1,
                  }}
                  color="error"
                  onClick={() => setOpen(false)}
                >
                  <CancelIcon />
                </IconButton>
                <PartnerLabel size="medium" label="Partner" />
              </Header>
              <Content>
                {isLoading && <ProfileSkeleton />}
                {open && !isLoading && renderer === 1 && !isEmpty(partner) && (
                  <Profile partner={partner} />
                )}
              </Content>
            </Container>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
}

export default memo(PartnerClickAwayDetails);

PartnerClickAwayDetails.propTypes = {
  partnerId: PropTypes.string.isRequired,
  label: PropTypes.string,
};

const ProfileContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alginItems: 'center',
}));

function Profile({ partner }) {
  const navigate = useNavigate();

  const { getCountryNameByIso3Code } = useCountries();

  return (
    <ProfileContainer>
      <Stack flexDirection="row" justifyContent="space-between">
        <Box>
          <Typography variant="h6" fontWeight={600}>
            {ucwords(partner.name)}
          </Typography>
          <Box>
            <Typography> {partner.city}</Typography>
            <Typography>{getCountryNameByIso3Code(partner.country)}</Typography>
          </Box>
        </Box>
        <Box>
          <Tooltip title="View Full Details">
            <IconButton onClick={() => navigate(buildRoute('partners.show', partner.id))}>
              <OutboundTwoToneIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
    </ProfileContainer>
  );
}

Profile.propTypes = {
  partner: PropTypes.object.isRequired,
};

function ProfileSkeleton() {
  return (
    <ProfileContainer>
      <Box>
        <Skeleton sx={{ width: 70, borderRadius: '20px', mb: 1 }} />
      </Box>
      <Stack flexDirection="row" justifyContent="space-between" gap={1}>
        <Box sx={{ flex: 1, pr: 2 }}>
          <Skeleton sx={{ height: 25, width: '100%', borderRadius: '40px', mb: 1 }} />
          <Box>
            <Skeleton sx={{ width: '150px', borderRadius: '20px', mb: 1 }} />
            <Skeleton sx={{ width: '100px', borderRadius: '20px' }} />
          </Box>
        </Box>
        <Box>
          <Skeleton sx={{ width: 40, height: 40, borderRadius: '50%' }} />
        </Box>
      </Stack>
    </ProfileContainer>
  );
}
