import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { call, takeLatest, put } from 'redux-saga/effects';

import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getBeneficiariesInit,
  getBeneficiariesSuccess,
  getBeneficiariesFailure,
  getBeneficiaryInit,
  getBeneficiaryFailure,
  getBeneficiarySuccess,
  getBeneficiaryReportDownloadInit,
  getBeneficiaryReportDownloadSuccess,
  getBeneficiaryReportDownloadFailure,
  resetBeneficiaryReportDownload,
} from './beneficiarySlice';

const getBeneficiary = createAction('beneficiaries/get');
const getBeneficiaries = createAction('beneficiaries/list');
const getBeneficiariesDownloadReport = createAction('beneficiaries/downloadReport');

/**
 * get a single beneficiary
 */
function* getBeneficiarySaga({ payload: beneficiaryId }) {
  try {
    yield put(getBeneficiaryInit());
    let endpoint = buildApiRoute(apiEndpoints.getBeneficiary, beneficiaryId);
    let response = yield callWrapperSaga(() => http.get(endpoint, beneficiaryId));
    yield put(getBeneficiarySuccess(response.data));
  } catch (err) {
    yield put(getBeneficiaryFailure(err));
  }
}

/**
 * get a beneficiaries list
 *
 */
function* getBeneficiariesSaga({ payload }) {
  yield put(getBeneficiariesInit());
  try {
    let endpoint = `${apiEndpoints.getBeneficiaries}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getBeneficiariesSuccess(response.data));
  } catch (err) {
    yield put(getBeneficiariesFailure(err));
  }
}

function* downloadBeneficiaryReportSaga({ payload }) {
  yield put(getBeneficiaryReportDownloadInit());

  try {
    const endpoint = `${apiEndpoints.downloadBeneficiaryReport}?${qs.stringify(payload)}`;

    const response = yield callWrapperSaga(() => http.get(endpoint, { responseType: 'blob' }));

    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${Date.now()}.${payload.format === 'pdf' ? 'pdf' : 'xlsx'}`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    yield put(getBeneficiaryReportDownloadSuccess(response.data));
  } catch (error) {
    yield put(getBeneficiaryReportDownloadFailure(error.message));
  }
}

function* beneficiarySaga() {
  yield takeLatest(getBeneficiary, getBeneficiarySaga);
  yield takeLatest(getBeneficiaries, getBeneficiariesSaga);
  yield takeLatest(getBeneficiariesDownloadReport.type, downloadBeneficiaryReportSaga);
}

export default beneficiarySaga;
export { getBeneficiary, getBeneficiaries, getBeneficiariesDownloadReport };
