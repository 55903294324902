import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import ReplayIcon from '@mui/icons-material/Replay';

export default function ReloadButton({ color, size, disabled, sx, ...rest }) {
  return (
    <IconButton
      color={color}
      size={size}
      disabled={disabled}
      sx={{
        width: 40,
        ...(sx ?? {}),
      }}
      {...rest}
    >
      <ReplayIcon fontSize={size} />
    </IconButton>
  );
}

ReloadButton.propTypes = {
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  sx: PropTypes.object,
};

ReloadButton.defaultProps = {
  size: 'small',
  color: 'primary',
  disabled: false,
};
