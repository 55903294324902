import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';

import { resetGetFundingSourceInfoFailure } from 'app/features/funding-sources-info/fundingSourceInfoSlice';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import Auth from 'utils/Auth';
import AuthUserIsSuperAdmin from 'components/auth/AuthUserIsSuperAdmin';
import FundingSourceInfoBriefDetailModal from 'components/Funding-source-info/FundingSourceInfoBriefDetailModal';
import FundingSourcesInfo from 'components/Funding-source-info/FundingSourcesInfo';

function ListFundingSourceInfo({ isNetworkError }) {
  const FundingSourceState = useSelector((state) => state.fundingSourcesInfo.index);

  const { isError, errorCode } = FundingSourceState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetFundingSourceInfoFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetFundingSourceInfoFailure())}
      />
    );

  return (
    <PageContent
      title={
        Auth.isSuperAdmin() ? 'Partner Funding Sources Information' : 'Funding Sources Information'
      }
      rightTopEndContent={
        <AuthUserIsSuperAdmin>
          <Button
            onClick={() => navigate(buildRoute('funding-sources-info.create'))}
            color="primary"
            startIcon={<AddIcon />}
          >
            Add Funding Source Information
          </Button>
        </AuthUserIsSuperAdmin>
      }
    >
      <FundingSourcesInfo />
      <FundingSourceInfoBriefDetailModal />
    </PageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ListFundingSourceInfo);
