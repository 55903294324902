import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

import constants from 'app/config/constants';
import { unstable_generateUtilityClass } from '@mui/utils';
import acl from 'app/config/acl';

export default class Auth {
  /**
   * check is user is logged in base on token
   *
   * @return {boolean}
   */
  static isLoggedIn() {
    return (
      !!this.getAccessToken() &&
      this.isValidAccessToken() &&
      !!this.getRefreshToken() &&
      !this.isRefreshTokenExpired()
    );
  }

  /**
   * check if refresh token is valid
   *
   * @return {boolean}
   */
  static isValidAccessToken() {
    try {
      let validAccessToken = true;

      let validTokenHasTheseKeys = [
        'sub',
        'jti',
        'id',
        'role',
        'p_id',
        'scope',
        'exp',
        'iss',
        'aud',
      ];

      let decodedToken = jwtDecode(this.getAccessToken());

      if (Object.prototype.toString.call(decodedToken) !== '[object Object]') return false;

      validTokenHasTheseKeys.forEach((value) => {
        if (!Object.prototype.hasOwnProperty.call(decodedToken, value)) {
          validAccessToken = false;
        }
      });
      return validAccessToken;
    } catch (e) {
      return false;
    }
  }

  /**
   * check is access token is expired
   *
   * @return {Boolean}
   */
  static isAccessTokenExpired() {
    try {
      let { exp } = jwtDecode(this.getAccessToken());
      return Date.now() >= exp * 1000;
    } catch (e) {
      return true;
    }
  }

  /**
   * check is refresh token is expired
   *
   * @return {Boolean}
   */
  static isRefreshTokenExpired() {
    try {
      return new Date() > new Date(Cookies.get(constants.REFRESH_TOKEN_VALID_TO));
    } catch (err) {
      return false;
    }
  }

  /**
   * set access token
   *
   * @param {String} token
   * @return {void}
   */
  static setAccessToken(token) {
    Cookies.set(constants.ACCESS_TOKEN, token);
  }

  /**
   * set refresh token
   *
   * @param {String} token
   * @param {Date} expiryDate
   * @return {void}
   */
  static setRefreshToken(token, expiryDate = null) {
    Cookies.set(constants.REFRESH_TOKEN, token);
    if (expiryDate) Cookies.set(constants.REFRESH_TOKEN_VALID_TO, expiryDate);
  }

  /**
   * get a refresh token
   *
   * @return {String}
   */
  static getRefreshToken() {
    return Cookies.get(constants.REFRESH_TOKEN);
  }

  /**
   * get a access token
   *
   * @return {String}
   */
  static getAccessToken() {
    return Cookies.get(constants.ACCESS_TOKEN);
  }

  /**
   * remove tokens
   *
   * @return {void}
   */
  static destroyTokens() {
    Cookies.remove(constants.ACCESS_TOKEN);
    Cookies.remove(constants.REFRESH_TOKEN);
  }

  /**
   * remove tokens
   *
   * @return {void}
   */
  static logout() {
    Cookies.remove(constants.REFRESH_TOKEN_VALID_TO);
    Cookies.remove(constants.USER);
    this.destroyTokens();
  }

  /**
   * remove tokens
   * @param {object} value
   * @return {void}
   */
  static setUser(value) {
    try {
      Cookies.set(constants.USER, JSON.stringify(value));
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * remove tokens
   * @param {object} value
   * @return {void}
   */
  static getUser(value) {
    try {
      return JSON.parse(Cookies.get(constants.USER));
    } catch (err) {
      return null;
    }
  }

  /**
   * get a user role from access token
   * @return {string}
   */
  static role() {
    try {
      let { role } = jwtDecode(this.getAccessToken());
      return role;
    } catch (e) {
      return undefined;
    }
  }

  /**
   * check if user has SUPERADMIN role
   *
   * @return {boolean}
   */
  static isSuperAdmin() {
    try {
      let { role } = jwtDecode(this.getAccessToken());
      return role === acl.SUPERADMIN;
    } catch (e) {
      return false;
    }
  }

  /**
   * check if user has ADMIN role
   *
   * @return {boolean}
   */
  static isAdmin() {
    try {
      let { role } = jwtDecode(this.getAccessToken());
      return role === acl.ADMIN;
    } catch (e) {
      return false;
    }
  }

  /**
   * check if user has USER role
   *
   * @return {boolean}
   */
  static isUser() {
    try {
      let { role } = jwtDecode(this.getAccessToken());
      return role === acl.USER;
    } catch (e) {
      return false;
    }
  }

  static getPartnerId() {
    try {
      let { p_id } = jwtDecode(this.getAccessToken());
      return this.isSuperAdmin() ? null : p_id;
    } catch (e) {
      return undefined;
    }
  }

  static id() {
    try {
      let { id } = jwtDecode(this.getAccessToken());
      return id;
    } catch (e) {
      return undefined;
    }
  }

  static hasRole(roles) {
    return (Array.isArray(roles) && roles.includes(this.role())) || roles === this.role();
  }

  can() {}
}
