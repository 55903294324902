import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import Notify from 'utils/Notify';
import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';
import {
  addFundingSourceInfoFailure,
  addFundingSourceInfoInit,
  addFundingSourceInfoSuccess,
  deleteFundingSourceInfoFailure,
  deleteFundingSourceInfoInit,
  deleteFundingSourceInfoSuccess,
  getFundingSourceInfoFailure,
  getFundingSourceInfoInit,
  getFundingSourceInfoSuccess,
  getFundingSourcesInfoInit,
  getFundingSourcesInfoSuccess,
  updateFundingSourceInfoFailure,
  updateFundingSourceInfoInit,
  updateFundingSourceInfoSuccess,
} from './fundingSourceInfoSlice';

const getFundingSourceInfo = createAction('fundingSourcesInfo/get');
const createFundingSourceInfo = createAction('fundingSourcesInfo/create');
const updateFundingSourceInfo = createAction('fundingSourcesInfo/update');
const getFundingSourcesInfo = createAction('fundingSourcesInfo/list');
const deleteFundingSourceInfo = createAction('fundingSourcesInfo/delete');

function* createFundingSourceSaga({ payload: data }) {
  yield put(addFundingSourceInfoInit());
  try {
    let response = yield callWrapperSaga(() =>
      http.post(apiEndpoints.createFundingSourceInfo, data),
    );
    yield put(addFundingSourceInfoSuccess(response.data));

    Notify.success(response.data.message || 'Funding Source created successfully.');
  } catch (err) {
    yield put(addFundingSourceInfoFailure(err));

    Notify.error('Failed to create new funding source.');
  }
}

function* getFundingSourcesInfoSaga({ payload }) {
  yield put(getFundingSourcesInfoInit());
  try {
    let endpoint = `${apiEndpoints.getFundingSourcesInfo}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getFundingSourcesInfoSuccess(response.data));
  } catch (err) {
    yield put(getFundingSourcesInfoInit(err));
  }
}

function* getFundingSourceInfoSaga({ payload: fundingSourceId }) {
  try {
    yield put(getFundingSourceInfoInit());
    let endpoint = buildApiRoute(apiEndpoints.getFundingSourceInfo, fundingSourceId);
    let response = yield callWrapperSaga(() => http.get(endpoint, fundingSourceId));
    yield put(getFundingSourceInfoSuccess(response.data));
  } catch (err) {
    yield put(getFundingSourceInfoFailure(err));
  }
}

function* deleteFundingSourceInfoSaga({ payload: fundingSourceInfoId }) {
  try {
    yield put(deleteFundingSourceInfoInit(fundingSourceInfoId));
    let endpoint = buildApiRoute(apiEndpoints.deleteFundingSourceInfo, fundingSourceInfoId);
    yield callWrapperSaga(() => http.delete(endpoint));
    Notify.success('Funding Source deleted successfully.');
    yield put(deleteFundingSourceInfoSuccess(fundingSourceInfoId));
  } catch (err) {
    Notify.error('Failed to delete funding source');
    yield put(deleteFundingSourceInfoFailure(err));
  }
}

function* updateFundingSourceInfoSaga({ payload: { id, ...data } }) {
  try {
    yield put(updateFundingSourceInfoInit());
    let endpoint = buildApiRoute(apiEndpoints.updateFundingSourceInfo, id);
    let response = yield callWrapperSaga(() => http.put(endpoint, data));
    yield put(updateFundingSourceInfoSuccess(response.data));
  } catch (err) {
    console.log(err);

    yield put(updateFundingSourceInfoFailure(err));
    Notify.error('Funding Source update failed');
  }
}

function* fundingSourceInfoSaga() {
  yield takeLatest(getFundingSourceInfo, getFundingSourceInfoSaga);
  yield takeLatest(createFundingSourceInfo, createFundingSourceSaga);
  yield takeLatest(getFundingSourcesInfo, getFundingSourcesInfoSaga);
  yield takeLatest(deleteFundingSourceInfo, deleteFundingSourceInfoSaga);
  yield takeLatest(updateFundingSourceInfo, updateFundingSourceInfoSaga);
}

export default fundingSourceInfoSaga;

export {
  getFundingSourceInfo,
  createFundingSourceInfo,
  updateFundingSourceInfo,
  getFundingSourcesInfo,
  deleteFundingSourceInfo,
};
