import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';

import MoreActions from 'core/lab/MoreActions';
import ReloadButton from 'core/buttons/ReloadButton';
import ColumnVisibility from 'core/table/ColumnVisibility';
import TableActionsContainer from 'core/table/TableActionsContainer';

import useQueryParams from 'hooks/useQueryParams';
import { setParam } from 'app/features/api-configs/apiConfigSlice';
import { getApiConfigs } from 'app/features/api-configs/apiConfigSaga';

import App from 'app/config/app';
import Sheet from 'utils/Sheet';
import Notify from 'utils/Notify';

export default function ApiConfigActions({ table }) {
  const dispatch = useDispatch();

  const apiConfigState = useSelector((state) => state.apiConfigs.index);

  const [queryParams, updateQueryParams] = useQueryParams(App.ignoreFilterKeys);

  const { params: appliedFilters } = apiConfigState;

  const handleExportToCSV = () => {
    let data = table.getSelectedRowModel().rows.map((r) => r.original);
    if (data.length <= 0) {
      Notify.error('Select atleast 1(s) rows');
      return;
    }
    Sheet.downloadJsonToCsv(data, 'api-configs');
  };

  const handleExportToExcel = () => {
    let data = table.getSelectedRowModel().rows.map((r) => r.original);
    if (data.length <= 0) {
      Notify.error('Select atleast 1(s) rows');
      return;
    }
    Sheet.downloadJsonToExcel(data, 'api-configs');
  };

  useEffect(() => {
    updateQueryParams(appliedFilters);
  }, [JSON.stringify(appliedFilters)]);

  useEffect(() => {
    dispatch(setParam(queryParams));
  }, [JSON.stringify(queryParams)]);

  return (
    <TableActionsContainer>
      <Box></Box>
      <Box display="flex" gap={1}>
        {table && <ColumnVisibility table={table} />}
        <ReloadButton
          onClick={() => {
            dispatch(getApiConfigs({ ...appliedFilters, page_number: 1 }));
          }}
        />
        <MoreActions onExportToCsv={handleExportToCSV} onExportToExcel={handleExportToExcel} />
      </Box>
    </TableActionsContainer>
  );
}

ApiConfigActions.propTypes = {
  table: PropTypes.object,
};
