import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import React, { useCallback, useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import FormHelperText from '@mui/material/FormHelperText';
import { Controller, useFormContext, get } from 'react-hook-form';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';

const FilePreview = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '60px',
  minHeight: '100px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  borderColor: 'rgba(35,45,108)',
  overflow: 'auto',
  '& img': {
    width: '50%',
    height: '100%',
    objectFit: 'cover',
  },
}));

function FormFileField({
  name,
  label,
  required,
  disabled,
  acceptedFiles = [],
  onImageCallback,
  ...rest
}) {
  const inputRef = useRef();

  const {
    control,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const onDrop = useCallback(
    (acceptedFiles) => {
      setValue(name, acceptedFiles[0]);
      if (typeof onImageCallback === 'function') {
        onImageCallback(acceptedFiles[0]);
      }
      clearErrors(name);
      inputRef.current.value = '';
    },
    [name, setValue],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptedFiles,
  });

  const file = watch(name);

  const handleRemove = () => {
    setValue(name, null);
    inputRef.current.value = '';
  };

  const errorMessage = get(errors, name)?.message;

  return (
    <Box display="flex" flexDirection="column">
      <Box
        {...getRootProps()}
        sx={{
          border: '1px solid rgba(35,45,108, 0.6)',
          padding: '6px',
          borderRadius: '8px',
          position: 'relative',
        }}
        onClick={() => inputRef.current.click()}
      >
        <Box mb="16px" display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontWeight={600} fontSize="1.143rem" lineHeight="1.714rem">
            {label}
          </Typography>
          {file && (
            <Tooltip placement="top" title="Remove" arrow>
              <IconButton
                sx={{
                  position: 'absolute',
                  right: { xs: '5%', sm: '8%', md: '5%' },
                  top: { xs: '5%', sm: '5%', md: '5%' },
                  padding: '0',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleRemove();
                }}
                color="error"
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <>
                <input {...getInputProps()} ref={inputRef} accept={acceptedFiles.join(', ')} />
                {file ? (
                  <FilePreview>
                    <img src={URL.createObjectURL(file)} />
                  </FilePreview>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    gap="10px"
                  >
                    <Box
                      sx={{
                        height: '36px',
                        width: '26px',
                        borderRadius: '20%',
                      }}
                    >
                      <UploadRoundedIcon />
                    </Box>
                    {isDragActive ? (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        gap="4px"
                      >
                        <Typography
                          fontWeight={500}
                          textAlign="center"
                          fontSize="1.143rem"
                          lineHeight="3.714rem"
                        >
                          Drop Here
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        gap="2px"
                      >
                        <Typography
                          fontWeight={500}
                          textAlign="center"
                          fontSize="1.143rem"
                          lineHeight="1.714rem"
                        >
                          Drag and Drop or{' '}
                          <Typography
                            component="span"
                            color="primary.main"
                            fontWeight={500}
                            sx={{
                              '&:hover': {
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              },
                            }}
                            fontSize="1.143rem"
                            lineHeight="1.714rem"
                          >
                            choose your file{' '}
                          </Typography>
                          for upload
                        </Typography>
                        <Typography textAlign="center" fontSize="1.143rem" lineHeight="1.714rem">
                          JPG or PNG
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </>
            )}
          />
        </Box>
      </Box>
      <FormHelperText error>{errorMessage}</FormHelperText>
    </Box>
  );
}

FormFileField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  acceptedFiles: PropTypes.string,
};

export default FormFileField;
