import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import DateUtils from 'utils/DateUtils';
import ActiveBadge from 'core/badges/ActiveBadge';
import FormCheckbox from 'core/forms/FormCheckbox';
import TableContainer from 'core/table/TableContainer';
import TanstackReactTable from 'core/table/TanstackReactTable';

import Auth from 'utils/Auth';
import constants from 'app/config/constants';
import CustomerKycLogsActions from './CustomerKycLogsActions';
import CustomerKycLogsPagination from './CustomerKycLogsPagination';
import { getCustomerKycLogs } from 'app/features/customer-kyc-logs/customerKycLogsSaga';

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '200px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export default function CustomerKycLogs({ filterByCustomer, customer_id }) {
  const dispatch = useDispatch();

  const [columnVisibility, setColumnVisibility] = useState({});

  const customerKycLogsState = useSelector((state) => state.customerKycLogs.index);

  const { data, params, isLoading } = customerKycLogsState;

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      ...(Auth.isSuperAdmin()
        ? [
            {
              header: 'Verification Url',
              accessorFn: (row) => row.verification_url,
              cell: ({ getValue }) => <ContentWrapper>{getValue()}</ContentWrapper>,
            },
          ]
        : []),
      {
        header: 'Reference',
        accessorFn: (row) => row.reference,
        cell: ({ getValue }) => <ContentWrapper>{getValue()}</ContentWrapper>,
      },
      {
        header: 'Is Active ?',
        accessorFn: (row) => row.is_active,
        cell: ({ getValue }) => <ActiveBadge isFailed={!!getValue()} />,
      },
      {
        header: 'Is Failed ?',
        accessorFn: (row) => row.is_failed,
        cell: ({ getValue }) => <ActiveBadge isFailed={!!getValue()} />,
      },
      {
        header: 'Is Expired ?',
        accessorFn: (row) => row.is_expired,
        cell: ({ getValue }) => <ActiveBadge isFailed={!!getValue()} />,
      },
      {
        header: 'Is Deleted ?',
        accessorFn: (row) => row.is_deleted,
        cell: ({ getValue }) => <ActiveBadge isFailed={!!getValue()} />,
      },
      {
        header: 'Is Document Uploaded ?',
        accessorFn: (row) => row.is_document_uploaded,
        cell: ({ getValue }) => <ActiveBadge isFailed={!!getValue()} />,
      },
      {
        header: 'Status Message',
        cell: ({ row }) => (
          <ContentWrapper>{row.status_message ? row.status_message : '-'}</ContentWrapper>
        ),
      },
      {
        header: 'Created At',
        accessorFn: (row) => row.created_ts,
        cell: ({ getValue }) => <>{DateUtils.isoToString(getValue())}</>,
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(
      getCustomerKycLogs({
        ...params,
        user_id: customer_id,
      }),
    );
  }, [JSON.stringify(params), customer_id]);

  return (
    <TableContainer>
      <CustomerKycLogsActions
        table={table}
        enableFilterByPartner={!filterByCustomer}
        defaultFilterQueries={{}}
      />
      <TanstackReactTable
        table={table}
        isLoading={isLoading}
        data={data}
        columnsCount={columns.length}
      />
      <CustomerKycLogsPagination />
    </TableContainer>
  );
}

CustomerKycLogs.propTypes = {
  customer_id: PropTypes.string.isRequired,
};
