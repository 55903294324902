import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import FinmoPayin from 'components/finmo-payins/FinmoPayin';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import withRole from 'hoc/withRole';
import { resetGetFinmoPayinFailure } from 'app/features/finmo-payins/finmoPayinSlice';

function ViewFinmoPayin({ isNetworkError }) {
  const params = useParams();

  const { isError, errorCode } = useSelector((state) => state.finmoPayins.show);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetFinmoPayinFailure());
  }, [isNetworkError]);

  if (isError)
    return <Error status={errorCode || 0} onRetry={() => dispatch(resetGetFinmoPayinFailure())} />;

  return (
    <PageContent title="Finmo Payin Details">
      <FinmoPayin finmoPayinId={params.finmoPayinId} />
    </PageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN]))(ViewFinmoPayin);
