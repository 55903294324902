/**
 * Funding Sources
 *
 * Below config values must be same as the values in the database
 *
 */

export const fundingSources = {
  payNowQR: 'PAYNOWQR',
  bankTransfer: 'BNKTFR',
  payID: 'PAYID',
  poli: 'POLI',
};

/**
 *  Funding Source options setup is for html select
 *
 */

export const fundingSourceOptions = [
  { value: fundingSources.payNowQR, label: 'PayNow QR' },
  { value: fundingSources.bankTransfer, label: 'Bank Transfer' },
  { value: fundingSources.payID, label: 'PayID' },
  { value: fundingSources.poli, label: 'POLi Payment' },
];

export const fundingSourceOptionsPendingBankTransactions = [
  { value: fundingSources.bankTransfer, label: 'Bank Transfer' },
  { value: fundingSources.payID, label: 'PayID' },
  { value: fundingSources.payNowQR, label: 'PayNow QR' },
];
