import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import styled from '@mui/material/styles/styled';
import FilterListIcon from '@mui/icons-material/FilterList';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import Button from 'core/buttons/Button';
import FormCheckbox from 'core/forms/FormCheckbox';
import constants from 'app/config/constants';

const SelectColumnButton = styled(Button)(({ theme }) => ({
  border: `inherit solid ${theme.palette.grey[100]}`,
  textTransform: 'uppercase',
}));

const CheckboxContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, isActive }) => ({
  padding: theme.spacing(1.5, 4),
  backgroundColor: isActive ? theme.palette.grey[300] : theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.grey[200],
  },
  '& .MuiTypography-root': {
    marginLeft: theme.spacing(2),
  },
}));

export default function ColumnPopup({ table }) {
  return (
    <PopupState variant="popover" popupId="demo-popup-popover">
      {(popupState) => (
        <div>
          <SelectColumnButton
            variant="outlined"
            endIcon={<FilterListIcon />}
            {...bindTrigger(popupState)}
          >
            Column
          </SelectColumnButton>
          <Popover
            elevation={3}
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box sx={{ maxWidth: '240px', width: '100%' }}>
              {table.getAllLeafColumns().map((column, k) => {
                if (
                  [
                    constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
                    constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
                    constants.TABLE_ACTION_COLUMN_ID,
                  ].includes(column.id)
                )
                  return <Fragment key={column.id}></Fragment>;
                return (
                  <CheckboxContainer key={column.id}>
                    <FormCheckbox
                      label={column.columnDef.header}
                      {...{
                        type: 'checkbox',
                        checked: column.getIsVisible(),
                        onChange: column.getToggleVisibilityHandler(),
                      }}
                    />
                  </CheckboxContainer>
                );
              })}
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  );
}

ColumnPopup.propTypes = {
  table: PropTypes.object,
};
