import { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';

import ActiveBadge from 'core/badges/ActiveBadge';
import FormContainer from 'core/forms/FormContainer';
import GroupedTableView from 'core/table/GroupedTableView';

import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import useAuthUser from 'hooks/useAuthUser';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

export default function AccountDetailsTab() {
  const { isLoading, data: account } = useSelector((state) => state.accounts.show);

  const user = useAuthUser();

  const viewData = useMemo(
    () => [
      {
        groupName: 'Basic Information',
        groupData: [
          {
            label: 'Name',
            data: 'name'
          },
          {
            label: 'Email',
            data: 'email'
          },
          {
            label: 'Phone Number',
            data: 'phone_number'
          },
          {
            label: 'Role',
            data: 'user_type'
          },
          {
            label: 'Is Active ?',
            data: 'is_active',
            get: ({ is_active }) => <ActiveBadge isActive={!!is_active} />
          }
        ]
      },
      {
        groupName: 'Others Information',
        groupData: [
          ...(user?.isSuperAdmin()
            ? [
                {
                  label: 'Partner Id',
                  data: 'partner_id',
                  get: ({ partner_id, partner_name }) => (
                    <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                  )
                }
              ]
            : []),
          {
            label: 'Last Login at',
            data: 'last_login_ts',
            get: ({ last_login_ts }) =>
              !isEmpty(last_login_ts) ? DateUtils.isoToString(last_login_ts) : '-'
          },
          {
            label: 'Created At',
            data: 'created_ts',
            get: ({ created_ts }) => (!isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '')
          },
          {
            label: 'Created By',
            data: 'created_by'
          },
          {
            label: 'Updated At',
            data: 'updated_ts',
            get: ({ updated_ts }) => (!isEmpty(updated_ts) ? DateUtils.isoToString(updated_ts) : '')
          },
          {
            label: 'Updated By',
            data: 'updated_by'
          }
        ]
      }
    ],
    []
  );

  return (
    <FormContainer>
      <Grid container spacing={2}>
        {viewData.map((group, i) => (
          <GroupedTableView
            key={i}
            title={group.groupName}
            data={group.groupData}
            item={account}
            isLoading={isLoading}
          />
        ))}
      </Grid>
    </FormContainer>
  );
}
