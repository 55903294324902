import { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import CircleIcon from '@mui/icons-material/Circle';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import ListItem from './ListItem';
import ListItemLink from './ListItemLink';
import ListItemContexts from './ListItemContexts';

function DropdownList({
  isDrawerOpen,
  icon,
  label,
  children,
  open,
  onClick,
  isActive,
  items,
  ...rest
}) {
  const dropdownRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    console.log('ok');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'simple-popover' : undefined;

  if (isDrawerOpen)
    return (
      <Fragment {...rest}>
        <ListItem button onClick={onClick} isActive={isActive} context={ListItemContexts.item}>
          {icon && <ListItemIcon sx={{ minWidth: '55px' }}>{icon}</ListItemIcon>}
          {label && <ListItemText primary={label} />}
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse ref={dropdownRef} in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {items.map((child, idx) => (
              <ListItemLink
                isDrawerOpen={true}
                key={idx}
                button
                icon={<CircleIcon sx={{ width: '10px' }} />}
                primary={child.name}
                to={child.path}
                isActive={child.isActive ?? false}
                context={ListItemContexts.dropdownItem}
              />
            ))}
          </List>
        </Collapse>
      </Fragment>
    );

  return (
    <Fragment {...rest}>
      <Tooltip title={label} placement="right">
        <ListItem
          button
          aria-describedby={id}
          onClick={handleClick}
          isActive={isActive}
          context={ListItemContexts.item}
        >
          {icon && <ListItemIcon sx={{ minWidth: '55px' }}>{icon}</ListItemIcon>}
          {label && <ListItemText primary={label} />}
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      </Tooltip>
      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{ ml: '10px' }}
      >
        <Box sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
          {items.map((child, idx) => (
            <ListItemLink
              isDrawerOpen={true}
              key={idx}
              button
              onClick={handleClose}
              icon={<CircleIcon sx={{ width: '10px' }} />}
              primary={child.name}
              to={child.path}
              isActive={child.isActive ?? false}
              context={ListItemContexts.dropdownItem}
            />
          ))}
        </Box>
      </Popover>
    </Fragment>
  );
}

export default DropdownList;

DropdownList.propTypes = {
  isDrawerOpen: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  open: PropTypes.bool,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  items: PropTypes.array,
};

DropdownList.defaultProps = {
  isDrawerOpen: false,
};
