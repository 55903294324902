import { createSlice } from '@reduxjs/toolkit';

import isEmpty from 'helpers/isEmpty';

const initialState = {
  show: {
    data: [],
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
};

const customerKycDocumentSlice = createSlice({
  name: 'customerKycDocument',
  initialState,
  reducers: {
    getCustomerKycDocumentInit: (state) => {
      state.show = { ...state.show, isLoading: true, isError: false };
    },
    getCustomerKycDocumentSuccess: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        data: isEmpty(payload?.data) ? [] : payload.data,
      };
    },
    getCustomerKycDocumentFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status || 0,
        errorMessage: payload?.response?.message || 'Failed to load document',
        data: [],
      };
    },
  },
});

export const {
  getCustomerKycDocumentInit,
  getCustomerKycDocumentSuccess,
  getCustomerKycDocumentFailure,
} = customerKycDocumentSlice.actions;

export default customerKycDocumentSlice.reducer;
