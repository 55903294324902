import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

import Button from 'core/buttons/Button';
import WebhookHeaders from 'components/webhook-headers/WebhookHeaders';
import WebhookBriefDetailsModal from 'components/webhooks/WebhookBriefDetailsModal';

import Auth from 'utils/Auth';
import { buildRoute } from 'routes';
import WebhookHeaderBriefDetailsModal from 'components/webhook-headers/WebhookHeaderBriefDetailsModal';

export default function PartnerWebhookHeadersTab({ partnerId }) {
  const navigate = useNavigate();

  return (
    <>
      <Box display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
        <Button
          onClick={() => {
            navigate(
              buildRoute(
                'webhook-headers.create',
                Auth.isSuperAdmin() ? { partner_id: partnerId } : {},
              ),
            );
          }}
        >
          Add New Webhook Header
        </Button>
      </Box>
      <WebhookHeaders preFilterByPartner={true} partnerId={partnerId} />
      <WebhookHeaderBriefDetailsModal />
    </>
  );
}

PartnerWebhookHeadersTab.propTypes = {
  partnerId: PropTypes.string.isRequired,
};
