import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import Clipboard from 'core/clipboard/Clipboard';
import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import GroupedTableView from 'core/table/GroupedTableView';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import CustomerClickAwayDetails from 'components/customers/CustomerClickAwayDetails';

import Auth from 'utils/Auth';
import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import useCountries from 'hooks/useCountries';
import { getZaiTransaction } from 'app/features/transactions/zaiTransactionSaga';

export default function ZaiTransaction({ transactionId }) {
  const dispatch = useDispatch();

  const { countries, getCountryNameByIso3Code } = useCountries();

  const viewTransactionData = useMemo(
    () => [
      {
        groupName: 'Brief Details',
        groupData: [
          {
            label: 'Transaction Id',
            data: 'id',
          },
          {
            label: 'Order Id',
            data: 'order_id',
          },

          ...(Auth.isSuperAdmin()
            ? [
                {
                  label: 'Partner',
                  data: 'partner_id',
                  get: ({ partner_id, partner_name }) => (
                    <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                  ),
                },
              ]
            : []),
          {
            label: 'Funding Id',
            data: 'funding_id',
          },
          {
            label: 'Funding Type',
            data: 'funding_type',
          },
          {
            label: 'Sending Country',
            get: ({ sending_country }) => getCountryNameByIso3Code(sending_country),
          },
          {
            label: 'Sending Amount',
            data: 'sending_amount',
          },
          {
            label: 'Sending Currency',
            data: 'sending_currency',
          },
          {
            label: 'Payout Country',
            get: ({ payout_country }) => getCountryNameByIso3Code(payout_country),
          },
          {
            label: 'Payout Amount',
            data: 'payout_amount',
          },
          {
            label: 'Payout Currency',
            data: 'payout_currency',
          },
          {
            label: 'Purpose',
            data: 'txn_purpose',
          },
          {
            label: 'Status',
            data: 'status',
          },
          {
            label: 'Transaction/Reference ID',
            data: 'transaction_reference_number',
            get: ({ transaction_reference_number }) =>
              transaction_reference_number ? (
                <Clipboard content={transaction_reference_number} />
              ) : (
                'N/A'
              ),
          },
          {
            label: 'Remarks',
            data: 'remarks',
          },
          {
            label: 'Is Active ?',
            data: 'is_active',
            get: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
          },
          {
            label: 'Is Verified ?',
            data: 'is_verified',
            get: ({ is_verified }) => <VerifiedBadge isVerified={!!is_verified} />,
          },

          {
            label: 'Created At',
            data: 'created_ts',
            get: ({ created_ts }) =>
              !isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '-',
          },
          {
            label: 'Created By',
            data: 'created_by',
          },
          {
            label: 'Updated At',
            data: 'updated_ts',
            get: ({ updated_ts }) =>
              !isEmpty(updated_ts) ? DateUtils.isoToString(updated_ts) : '-',
          },
          {
            label: 'Updated By',
            data: 'updated_by',
          },
        ],
      },
      {
        groupName: 'Customer Details',
        groupData: [
          {
            label: 'Customer',
            data: 'user_name',
          },
          {
            label: 'Customer ID',
            data: 'user_id',
            get: ({ user_id }) => <CustomerClickAwayDetails customerId={user_id} />,
          },
          {
            label: 'ID Type',
            data: 'id_type',
          },
          {
            label: 'Email',
            data: 'email',
          },
          {
            label: 'Date of Birth',
            data: 'dob',
          },
          {
            label: 'Mobile Number',
            data: 'mobile_number',
          },
          {
            label: 'Suburb',
            data: 'suburb',
          },
          {
            label: 'Street Type',
            data: 'street',
          },
          {
            label: 'Street Number',
            data: 'street_number',
          },
        ],
      },
      {
        groupName: 'Beneficiary Details',
        groupData: [
          {
            label: 'ID',
            data: 'beneficiary_id',
          },
          {
            label: 'Name',
            data: 'beneficiay_name',
          },
          {
            label: 'Type',
            data: 'beneficiary_type',
          },
          {
            label: 'Relation',
            data: 'beneficiary_relation',
          },
          {
            label: 'Address',
            data: 'beneficiary_address',
          },
          {
            label: 'Country',
            get: ({ beneficiary_country }) => getCountryNameByIso3Code(beneficiary_country),
          },
          {
            label: 'Currency',
            data: 'beneficiary_currency',
          },
          {
            label: 'Mobile Number',
            data: 'beneficiary_mobile_number',
          },
          {
            label: 'Payment Type',
            data: 'beneficiary_payment_type',
          },
          {
            label: 'Account Number',
            data: 'beneficiary_account_number',
          },
          {
            label: 'Branch',
            data: 'beneficiary_branch',
          },
        ],
      },
    ],
    [countries],
  );

  const { isLoading, data: transaction } = useSelector((state) => state.zaiTransactions.show);

  useEffect(() => {
    dispatch(getZaiTransaction(transactionId));
  }, [transactionId]);

  return (
    <Grid container spacing={2}>
      {viewTransactionData.map((group, i) => (
        <GroupedTableView
          key={i}
          title={group.groupName}
          data={group.groupData}
          item={transaction}
          isLoading={isLoading}
        />
      ))}
    </Grid>
  );
}

ZaiTransaction.propTypes = {
  transactionId: PropTypes.string,
};
