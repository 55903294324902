import React from 'react';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';

import Row from 'core/row/Row';
import Column from 'core/column/Column';
import Spacer from 'core/spacer/Spacer';
import SpringModal from 'core/modal/SpringModal';
import Clipboard from 'core/clipboard/Clipboard';

import MakePaymentWebhookLogs from './MakePaymentWebhookLogs';
import { ZaiWebhookLogStatus } from 'app/config/ZaiWebHookLogConfig';
import { closeMakePaymentModal } from 'app/features/transactions/zaiTransactionSlice';


export default function MakePaymentModal() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.zaiTransactions.makePayment);

  const { isLoading, transactionId, customerName, customerId } = state;

  const handleClose = () => {
    dispatch(closeMakePaymentModal())
  }

  return (
    <SpringModal
      header="Make Payment"
      isOpen={state.isModalOpen}
      onClose={isLoading ? undefined : handleClose}
      enableMaximizeButton={false}
      enableDefaultPadding
      sx={{
        minWidth: '90svw',
      }}
    >
      <Column>
        <Row>
          <Row>
            <Typography fontWeight={600}>Customer Name : </Typography> &nbsp;
            <Typography>{customerName}</Typography>
          </Row>
          <Row alignItems="center">
            <Typography fontWeight={600}>Customer ID : </Typography> &nbsp;
            <Clipboard content={customerId} />
          </Row>
          <Row alignItems="center">
            <Typography fontWeight={600}>Transaction ID : </Typography> &nbsp;
            <Clipboard content={transactionId} />
          </Row>
        </Row>
        <Spacer />
        {customerId && (
          <MakePaymentWebhookLogs
            status={ZaiWebhookLogStatus.PENDING}
            customerId={customerId}
            transactionId={transactionId}
          />
        )}
      </Column>
    </SpringModal>
  );
}
