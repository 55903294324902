import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import Partners from 'components/partners/Partners';
import { resetGetPartnersFailure } from 'app/features/partners/partnerSlice';
import PartnerBriefDetailsModal from 'components/partners/PartnerBriefDetailsModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import withRole from 'hoc/withRole';

function ListPartner({ isNetworkError }) {
  const partnerState = useSelector((state) => state.partners.index);

  const { isError, errorCode } = partnerState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetPartnersFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetPartnersFailure())}
      />
    );

  return (
    <PageContent
      title="Partners"
      rightTopEndContent={
        <Button
          onClick={() => navigate(buildRoute('partners.create'))}
          color="primary"
          startIcon={<AddIcon />}
        >
          ADD PARTNER
        </Button>
      }
    >
      <Partners />
      <PartnerBriefDetailsModal />
    </PageContent>
  );
}

export default compose(withAppState, withRole(acl.SUPERADMIN))(ListPartner);
