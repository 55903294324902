import BasicInformation from '../partner-form-steps/BasicInformation';
import { basicInformationSchema } from '../partner-form-steps/BasicInformation';
import ContactPersonInformation from '../partner-form-steps/ContactPersonInformation';
import { contactPersonInformationSchema } from '../partner-form-steps/ContactPersonInformation';

const partnerFormSteps = [
  {
    label: 'Basic Information',
    component: <BasicInformation />,
    isSkippable: false,
    validationSchema: basicInformationSchema
  },
  {
    label: 'Contact Person Information',
    component: <ContactPersonInformation />,
    isSkippable: false,
    validationSchema: contactPersonInformationSchema
  }
];

export default partnerFormSteps;
