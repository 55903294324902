import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import styled from '@mui/material/styles/styled';
import FormControlLabel from '@mui/material/FormControlLabel';

const FormGroupStyled = styled(FormGroup, {
  shouldForwardProp: (prop) => prop !== 'forTableHead',
})(({ theme, forTableHead }) => ({
  ...(forTableHead
    ? {
        '& .Mui-checked': {
          color: theme.palette.background.paper,
        },
        '& .MuiSvgIcon-root': {
          fill: theme.palette.background.paper,
        },
      }
    : {}),
}));

export default function FormCheckbox({
  disabled,
  checked,
  label,
  size,
  value,
  onChange,
  onClick,
  forTableHead,
  sx,
  ...rest
}) {
  return (
    <FormGroupStyled forTableHead={forTableHead}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            checked={checked}
            size={size}
            sx={{ m: 0, p: 0, ...(sx ?? {}) }}
            onClick={onClick}
            onChange={onChange}
            value={value}
            {...rest}
          />
        }
        label={label}
      />
    </FormGroupStyled>
  );
}

FormCheckbox.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  forTableHead: PropTypes.bool,
  sx: PropTypes.object,
};

FormCheckbox.defaultProps = {
  disabled: false,
  checked: false,
  label: '',
  size: 'small',
  value: '',
  forTableHead: false,
  onChange: () => {},
  onClick: () => {},
};
