import Box from '@mui/material/Box';
import { Outlet } from 'react-router-dom';

export default function AuthLayout() {
  return (
    <Box
      component="div"
      sx={{
        height: '100vh',
        overflow: 'hidden',
        // backgroundColor: '#FFFFFF',
        // backgroundSize: 'contain',
        // backgroundRepeat: 'no-repeat',
        // backgroundOrigin: 'content-box',
        // backgroundPosition: 'center bottom',
        // boxSizing: 'border-box',
      }}
    >
      <Outlet />
    </Box>
  );
}
