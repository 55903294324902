import axios from 'axios';
import app from 'app/config/app';
import Notify from 'utils/Notify';
import constants from 'app/config/constants';

axios.defaults.timeoutErrorMessage = constants.REQUEST_TIMEOUT_ERROR;
// -----------------------------
// ********** README **********
// -----------------------------
// Usually this is not needed,
// but in this project api throws an error
// when i pass auth token (Bearer <token>) in guest api (doesn't requires authorization) in header
// below axiosInstance2 is declared which don't pass Bearer <token> in header in request interceptor

const axiosInstance = axios.create({
  baseURL: app.apiBaseUrl,
  timeout: app.requestTimeout
});

// response interceptor
axiosInstance.interceptors.response.use(null, (err) => {
  if (err?.message === constants.REQUEST_TIMEOUT_ERROR) {
    Notify.error('Request Timeout');
  }

  return Promise.reject(err);
});

const guestHttp = {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  delete: axiosInstance.delete,
  patch: axiosInstance.patch
};

export default guestHttp;
