import React from 'react';
import PropTypes from 'prop-types';

import CustomerKycs from 'components/customer-kycs/CustomerKycs';
import CustomerKycFilterValues from 'components/customer-kycs/CustomerKycFilterValues';
import CustomerKycBriefDetailsModal from 'components/customer-kycs/CustomerKycBriefDetailsModal';

export default function CustomerKycsTab({ customerId }) {
  return (
    <>
      <CustomerKycFilterValues />
      <CustomerKycs filterByCustomer={true} customerId={customerId} />
      <CustomerKycBriefDetailsModal />
    </>
  );
}

CustomerKycsTab.propTypes = {
  customerId: PropTypes.string.isRequired,
};
