import React from 'react';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import ZaiUserStatus from 'app/config/ZaiUserStatus';

export default function ZaiUserStatusBadge({ status }) {
  const theme = useTheme();

  const color =
    {
      [ZaiUserStatus.Active.toUpperCase()]: 'success',
      [ZaiUserStatus.InActive.toUpperCase()]: theme.palette.disabled,
    }[status?.toUpperCase()] ?? theme.palette.disabled;

  return (
    <Chip
      size="small"
      label={status}
      color={color}
    />
  );
}
