import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import GroupedTableView from 'core/table/GroupedTableView';

import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import { getApiConfig } from 'app/features/api-configs/apiConfigSaga';

export default function ApiConfig({ apiConfigId }) {
  const dispatch = useDispatch();

  const viewApiConfigData = useMemo(
    () => [
      {
        groupName: '',
        groupData: [
          {
            label: 'Partner Code',
            data: 'api_partner_code',
          },
          {
            label: 'Url',
            data: 'api_url',
          },
          {
            label: 'User ID',
            data: 'api_user_id',
          },
          {
            label: 'User Secret',
            data: 'api_user_secret',
          },
          {
            label: 'Ref1',
            data: 'ref1',
          },
          {
            label: 'Ref2',
            data: 'ref2',
          },
          {
            label: 'Ref3',
            data: 'ref3',
          },
          {
            label: 'Remarks',
            data: 'remarks',
          },
          {
            label: 'Created At',
            data: 'created_ts',
            get: ({ created_ts }) =>
              !isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '',
          },
        ],
      },
    ],
    [],
  );

  const {
    isError,
    errorCode,
    isLoading,
    data: apiConfig,
  } = useSelector((state) => state.apiConfigs.show);

  useEffect(() => {
    dispatch(getApiConfig(apiConfigId));
  }, [apiConfigId]);

  return (
    <Grid container spacing={2}>
      {viewApiConfigData.map((group, i) => (
        <GroupedTableView
          key={i}
          title={group.groupName}
          data={group.groupData}
          item={apiConfig}
          isLoading={isLoading}
        />
      ))}
    </Grid>
  );
}

ApiConfig.propTypes = {
  apiConfigId: PropTypes.string,
};
