const Dimensions = {
  padding: {
    extraSmall: 4,
    small: 6,
    normal: 8,
    large: 10,
    extraLarge: 12,
  },

  table: {
    iconButton: {
      size: 'small', // large, medium, small
      iconSize: 'small', // inherit, large, medium, small
    },
  },
};

export default Dimensions;
