import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    data: [],
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null
}

export const transactionLogSlice = createSlice({
    name: 'transactionLogs',
    initialState,
    reducers: {
        getTransactionLogInit: (state) => {
            return {
                ...state,
                isLoading: true,
                isError: false,
                errorCode: null,
                errorMessage: null
            }
        },
        getTransactionLogSuccess: (state, { payload }) => {
            return {
                ...state,
                data: payload.data,
                isLoading: false,
                isError: false,
                errorCode: null,
                errorMessage: null
            }
        },
        getTransactionLogFailure: (state, { payload }) => {
            return {
                ...state,
                data: payload.data,
                isLoading: false,
                isError: true,
                errorCode: payload?.response?.status ?? 0,
                errorMessage: payload?.response?.message,
            };
        },
    }
})

export const {
    getTransactionLogInit,
    getTransactionLogSuccess,
    getTransactionLogFailure
} = transactionLogSlice.actions

export default transactionLogSlice.reducer;
