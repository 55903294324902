import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import Accounts from 'components/accounts/Accounts';
import { resetGetAccountsFailure } from 'app/features/accounts/accountSlice';
import AccountBriefDetailsModal from 'components/accounts/AccountBriefDetailsModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import { compose } from '@reduxjs/toolkit';

function ListAccount({ isNetworkError }) {
  const accountState = useSelector((state) => state.accounts.index);

  const { isError, errorCode, isLoading } = accountState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetAccountsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetAccountsFailure())}
      />
    );

  return (
    <PageContent
      title="Accounts"
      rightTopEndContent={
        <Button
          onClick={() => navigate(buildRoute('accounts.create'))}
          color="primary"
          startIcon={<AddIcon />}
        >
          Add Account
        </Button>
      }
    >
      <Accounts />
      <AccountBriefDetailsModal />
    </PageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ListAccount);
