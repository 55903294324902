import { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { FormProvider, useForm } from 'react-hook-form';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';

import Button from 'core/buttons/Button';

import isEmpty from 'helpers/isEmpty';
import cleanObject from 'helpers/cleanObject';
import BuildFilterInputValue from 'core/filter/BuildFilterInputValue';

function FilterPopup({ id, availableFilters, appliedFilters, onReset, onFilter }) {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'filter-popup-state__container' + id,
  });

  const methods = useForm();

  const { handleSubmit, reset, setValue } = methods;

  const handleReset = () => {
    // reset({});
    availableFilters.forEach((filter) => {
      setValue(filter.value, '');
    });
    popupState.close();

    if (Object.prototype.toString.call(onReset) === '[object Function]') onReset();
  };

  const onSubmit = async (data) => {
    popupState.close();
    if (Object.prototype.toString.call(onFilter) === '[object Function]')
      onFilter(cleanObject(data));
  };

  useEffect(() => {
    let availableFilterKeys = availableFilters.map((filter) => filter.value);
    for (let [key, value] of Object.entries(appliedFilters)) {
      if (availableFilterKeys.includes(key) && !isEmpty(value)) {
        setValue(key, value);
      }
    }
  }, [JSON.stringify(appliedFilters)]);

  return (
    <div>
      <Button variant="outlined" endIcon={<FilterAltIcon />} {...bindTrigger(popupState)}>
        FILTER
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ width: '380px', p: 2 }} display="flex" flexDirection="column" gap={2}>
              {availableFilters.map((filter, index) => {
                return (
                  <Grid container spacing={2} key={index}>
                    <Grid item xs={12}>
                      <BuildFilterInputValue filter={filter} filterByKey={filter.value} />
                    </Grid>
                  </Grid>
                );
              })}

              <Box display="flex" justifyContent="flex-end">
                <Box display="flex" gap={2}>
                  <Button type="submit" size="small" variant="contained">
                    Filter
                  </Button>
                  <Button type="button" onClick={handleReset} size="small" variant="outlined">
                    Reset
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Popover>
    </div>
  );
}

export default memo(FilterPopup);

FilterPopup.propTypes = {
  id: PropTypes.string,
  availableFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
  appliedFilters: PropTypes.object,
  onReset: PropTypes.func,
  onFilter: PropTypes.func,
};

FilterPopup.defaultValues = {
  availableFilters: [],
  appliedFilters: {},
};
