import { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

import Button from 'core/buttons/Button';
import ReloadButton from 'core/buttons/ReloadButton';
import TableActionsContainer from 'core/table/TableActionsContainer';

import { useConfirm } from 'core/mui-confirm';
import {
  createPartnerCredential,
  getPartnerCredentials,
} from 'app/features/partner-credentials/partnerCredentialSaga';

function PartnerCredentialsActions({ partnerId }) {
  const { isLoading } = useSelector((state) => state.partnerCredentials.create);

  const { params } = useSelector((state) => state.partnerCredentials.index);

  const confirm = useConfirm();

  const dispatch = useDispatch();

  const handleGenerate = () => {
    confirm({
      icon: 'warning',
      confirmationText: 'Yes, Generate',
      description: 'Do you want to generate a new partner credential.',
    })
      .then(() => dispatch(createPartnerCredential(partnerId)))
      .catch((err) => console.error(err));
  };

  return (
    <TableActionsContainer>
      <Box></Box>
      <Box display="flex" gap={2}>
        <Button
          variant="outlined"
          startIcon={<SettingsSuggestIcon />}
          onClick={handleGenerate}
          disabled={isLoading}
        >
          {isLoading ? 'Generating' : 'Generate'}
        </Button>
        <ReloadButton
          onClick={() =>
            dispatch(getPartnerCredentials({ ...params, page_number: 1, partner_id: partnerId }))
          }
        />
      </Box>
    </TableActionsContainer>
  );
}

export default memo(PartnerCredentialsActions);

PartnerCredentialsActions.propTypes = {
  partnerId: PropTypes.string,
};
