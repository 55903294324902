import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import styled from '@mui/material/styles/styled';
import { flexRender } from '@tanstack/react-table';
import TableContainer from '@mui/material/TableContainer';

import constants from 'app/config/constants';
import TableBodySkeleton from './TableBodySkeleton';

const TableCellFixedRight = styled(TableCell)({
  position: 'sticky',
  right: 0,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

const StickyColumnLeft = styled(TableCell)({
  position: 'sticky',
  left: 0,
  backgroundColor: 'white',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

// const TableRowHoverable = styled(TableRow)({
//   '&:hover': {
//     overflow: 'hidden',
//     position: 'relative',
//     'td,th': {
//       color: 'red',
//       '&:last-child': {
//         position: 'absolute',
//         right: '0',
//         background: 'white',
//         display: 'block !important',
//       },
//     },
//   },
// });

function TanstackReactTable({ table, data, isLoading, columnsCount }) {
  // const handleOnMouseEnter = (e) => {
  //   let row = e.target.closest('tr');
  //   console.log(row.offsetTop);
  //   let actionEle = document.createElement('div');
  //   actionEle.id = 'action';
  //   actionEle.innerHTML = row.querySelector('.action').innerHTML;
  //   actionEle.style.position = 'absolute';
  //   actionEle.style.display = 'block';
  //   actionEle.style.top = row.offsetTop + 'px';
  //   actionEle.style.right = 0;
  //   actionEle.style.backgroundColor = 'red';
  //   actionEle.style.maxHeight = row.clientHeight + 'px';
  //   actionEle.style.right = window.innerWidth - row.getBoundingClientRect().right + 'px';
  //   row.closest('table').closest('div').prepend(actionEle);
  //   console.log(row.closest('table').closest('div'));
  // };

  // const handelOnMouseLeave = (e) => {
  //   e.target.closest('tr').closest('table').closest('div').querySelector('#action').remove();
  // };

  const renderTableBody = () => {
    if (isLoading) return <TableBodySkeleton columnCount={columnsCount} rowCount={10} />;

    if (data?.length <= 0)
      return (
        <TableRow>
          <TableCell colSpan={columnsCount} align="center">
            No Data available
          </TableCell>
        </TableRow>
      );

    return table.getRowModel().rows.map((row, index) => {
      return (
        <TableRow key={row.id}>
          {row.getVisibleCells().map((cell) => {
            if (cell?.column?.id === constants.TABLE_ACTION_COLUMN_ID) {
              return (
                <TableCellFixedRight
                  sx={{
                    background: (theme) => theme.palette.background.paper,
                  }}
                  key={cell.id}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCellFixedRight>
              );
            }

            if (cell?.column?.id === constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID) {
              return (
                <StickyColumnLeft
                  sx={{
                    background: (theme) => theme.palette.background.paper,
                  }}
                  key={cell.id}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </StickyColumnLeft>
              );
            }
            return (
              <TableCell key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                if (header?.id === constants.TABLE_ACTION_COLUMN_ID) {
                  return (
                    <TableCellFixedRight
                      key={header.id}
                      sx={{
                        background: (theme) => theme.palette.primary.main,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableCellFixedRight>
                  );
                }

                if (header?.id === constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID) {
                  return (
                    <StickyColumnLeft
                      key={header.id}
                      sx={{
                        background: (theme) => theme.palette.primary.main,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </StickyColumnLeft>
                  );
                }

                return (
                  <TableCell key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>{renderTableBody()}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default TanstackReactTable;

TanstackReactTable.propTypes = {
  table: PropTypes.object,
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  columnsCount: PropTypes.number,
};
