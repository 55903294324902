import PropTypes from 'prop-types';

import PartnerCredentials from 'components/partner-credentials/PartnerCredentials';
import PartnerCredentialRecentlyCreated from 'components/partner-credentials/PartnerCredentialRecentlyCreated';

export default function PartnerCredentialsTab({ partnerId }) {
  return (
    <>
      <PartnerCredentialRecentlyCreated />
      <PartnerCredentials partnerId={partnerId} />
    </>
  );
}

PartnerCredentialsTab.propTypes = {
  partnerId: PropTypes.string.isRequired
};
