import { createAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';

import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';
import {
  getTransactionLogFailure,
  getTransactionLogInit,
  getTransactionLogSuccess,
} from './transactionLogSlice';

const getTransactionsLog = createAction('transactionLogs/get');

function* getTransactionLogsSaga({ payload: transactionId }) {
  try {
    yield put(getTransactionLogInit());
    let endpoint = buildApiRoute(apiEndpoints.getTransactionLogs, transactionId);
    let response = yield callWrapperSaga(() => http.get(endpoint, transactionId));
    yield put(getTransactionLogSuccess(response));
  } catch (err) {
    yield put(getTransactionLogFailure(err));
  }
}

function* transactionLogsSaga() {
  yield takeLatest(getTransactionsLog, getTransactionLogsSaga);
}

export default transactionLogsSaga;

export { getTransactionsLog };
