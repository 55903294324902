import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import Skeleton from 'core/skeleton/Skeleton';
import Clipboard from 'core/clipboard/Clipboard';
import GroupedTableView from 'core/table/GroupedTableView';

import isEmpty from 'helpers/isEmpty';
import ucwords from 'helpers/ucwords';
import DateUtils from 'utils/DateUtils';
import { getFinmoPayout } from 'app/features/finmo-payouts/finmoPayoutSaga';

const PrettyPrintContainer = styled('div')(({ theme }) => ({
  background: theme.palette.grey[200],
  borderRadius: '8px',
  padding: theme.spacing(3),
}));

export default function FinmoPayout({ finmoPayoutId }) {
  const dispatch = useDispatch();

  const {
    isError,
    errorCode,
    isLoading,
    data: finmoPayout,
  } = useSelector((state) => state.finmoPayouts.show);

  const viewFinmoPayoutData = useMemo(
    () => [
      {
        groupName: '',
        groupData: [
          {
            label: 'Payout ID',
            get: ({ payout_id }) => <Clipboard content={payout_id} />,
          },
          ...(finmoPayout?.payout_beneficiary_param?.type === 'individual'
            ? [
                {
                  label: 'First Name',
                  get: ({ payout_beneficiary_param }) =>
                    payout_beneficiary_param?.individual?.first_name,
                },
                {
                  label: 'Last Name',
                  get: ({ payout_beneficiary_param }) =>
                    payout_beneficiary_param?.individual?.last_name,
                },
              ]
            : []),
          ...(finmoPayout?.payout_beneficiary_param?.type === 'company'
            ? [
                {
                  label: 'Company Name',
                  get: ({ payout_beneficiary_param }) => payout_beneficiary_param?.company?.name,
                },
              ]
            : []),
          {
            label: 'Payout Method Name',
            data: 'payout_method_name',
          },
          {
            label: 'Description',
            data: 'description',
          },
          {
            label: 'Beneficiary Amount',
            data: 'beneficiary_amount',
          },
          {
            label: 'Beneficiary Currency',
            data: 'beneficiary_currency',
          },
          {
            label: 'Beneficiary Country',
            data: 'beneficiary_country',
          },
          {
            label: 'Payout Reference',
            data: 'payout_reference',
          },
          {
            label: 'Status',
            get: ({ status }) => ucwords(status),
          },
          {
            label: 'Pay ID',
            get: ({ payout_beneficiary_param }) => payout_beneficiary_param?.pay_id,
          },
          {
            label: 'Created At',
            data: 'created_at',
            get: ({ created_at }) =>
              !isEmpty(created_at) ? DateUtils.isoToString(created_at) : '-',
          },
          {
            label: 'Updated At',
            data: 'updated_at',
            get: ({ updated_at }) =>
              !isEmpty(updated_at) ? DateUtils.isoToString(updated_at) : '-',
          },
        ],
      },
    ],
    [JSON.stringify(finmoPayout)],
  );

  useEffect(() => {
    dispatch(getFinmoPayout(finmoPayoutId));
  }, [finmoPayoutId]);

  return (
    <>
      <Grid container spacing={2}>
        {viewFinmoPayoutData.map((group, i) => (
          <GroupedTableView
            key={i}
            title={group.groupName}
            data={group.groupData}
            item={finmoPayout}
            isLoading={isLoading}
          />
        ))}
      </Grid>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box sx={{ width: '100%' }} display="flex" justifyContent="space-between">
            <Typography>More Details</Typography>
            <Clipboard label="" content={JSON.stringify(finmoPayout)} />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {isLoading ? (
            <Skeleton />
          ) : (
            <PrettyPrintContainer>
              <pre>
                <code>{JSON.stringify(finmoPayout, undefined, 2)}</code>
              </pre>
            </PrettyPrintContainer>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

FinmoPayout.propTypes = {
  finmoPayoutId: PropTypes.string,
};
