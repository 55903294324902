import { useEffect } from 'react';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';

import TransactionLogs from 'components/transactions/TransactionLogs';
import { getTransaction } from 'app/features/transactions/transactionSaga';
import { getTransactionsLog } from 'app/features/transactions/transactionLogSaga';
import { getTransactionReceipt } from 'app/features/transactions/transactionDocumentSaga';

import ViewTransactionDocument from './ViewTransactionDocument';

export default function ViewTransactionDetailDocument({ transactionId, orderId }) {
  const dispatch = useDispatch();

  const { isLoading: isTransactionLoading, data: transactionLogData } = useSelector(
    (state) => state.transactionLogs,
  );

  const { l_Loading, data: transaction } = useSelector((state) => state.transactions.show);

  const { isLoading, data: transactionDocument } = useSelector(
    (state) => state.transactionDocument.show,
  );

  useEffect(() => {
    if (transactionId) dispatch(getTransaction(transactionId));

    dispatch(getTransactionsLog(transactionId));
  }, [transactionId]);

  useEffect(() => {
    const transctionOrderId = transaction?.order_id ?? [];

    if (transctionOrderId)
      dispatch(
        getTransactionReceipt({
          orderId: transctionOrderId,
        }),
      );
  }, [transaction]);

  return (
    <Box>
      <ViewTransactionDocument transactionDocument={transactionDocument} isLoading={isLoading} />

      <Box sx={{ mt: 5 }}>
        <TransactionLogs data={transactionLogData} isLoading={isTransactionLoading} />
      </Box>
    </Box>
  );
}
