import { useEffect, useCallback, useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';

import Button from 'core/buttons/Button';
import FilterButton from 'core/buttons/FilterButton';
import MoreActions from 'core/lab/MoreActions';
import ReloadButton from 'core/buttons/ReloadButton';
import TableGridFilter from 'core/table/TableGridFilter';
import ColumnVisibility from 'core/table/ColumnVisibility';
import SelectPartner from 'components/partners/SelectPartner';
import TableActionsContainer from 'core/table/TableActionsContainer';

import Auth from 'utils/Auth';
import Sheet from 'utils/Sheet';
import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import { currentRouteName } from 'routes';
import FilterType from 'app/config/FilterType';
import useQueryParams from 'hooks/useQueryParams';
import SelectCustomer from 'components/customers/SelectCustomer';
import { getZaiTransactions } from 'app/features/transactions/zaiTransactionSaga';
import { deleteFilterParams, setParam } from 'app/features/transactions/zaiTransactionSlice';

const schema = Yup.object().shape({
  fromDate: Yup.string().test({
    name: 'required_when_to_date_is_not_empty',
    message: 'Form Date is required',
    test: (value, context) => {
      if (
        (isEmpty(context.parent.toDate) && isEmpty(value)) ||
        (!isEmpty(context.parent.toDate) && !isEmpty(value))
      )
        return true;
      return isEmpty(context.parent.toDate) && !isEmpty(value);
    },
  }),
  toDate: Yup.string().test({
    name: 'required_when_to_date_is_not_empty',
    message: 'To Date is required',
    test: (value, context) => {
      if (
        (isEmpty(context.parent.fromDate) && isEmpty(value)) ||
        (!isEmpty(context.parent.fromDate) && !isEmpty(value))
      )
        return true;
      return isEmpty(context.parent.fromDate) && !isEmpty(value);
    },
  }),
});

export default function ZaiTransactionsActions({
  table,
  enableFilterByPartner,
  defaultFilterQueries,
}) {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const transactionState = useSelector((state) => state.zaiTransactions.index);

  const [queryParams, updateQueryParams] = useQueryParams(app.ignoreFilterKeys);

  let availableFilters = [
    {
      label: 'Transaction ID',
      value: 'id',
      type: FilterType.input,
    },
    {
      label: 'Currency',
      value: 'currency',
      type: FilterType.input,
    },
    {
      label: 'From Date',
      value: 'fromDate',
      type: FilterType.datepicker,
    },
    {
      label: 'To Date',
      value: 'toDate',
      type: FilterType.datepicker,
    },
  ];

  const { params: appliedFilters } = transactionState;

  const handleOnFilter = useCallback((data) => {
    dispatch(setParam({ ...data, page_number: 1 }));
  }, []);

  const handleOnReset = useCallback((e) => {
    dispatch(deleteFilterParams([...availableFilters.map((filter) => filter.value)]));
  });

  /**
   * If SUPERADMIN get partner id from query params
   * if not get from auth token
   */
  const partnerId = appliedFilters?.partner_id ?? Auth.getPartnerId();

  useEffect(() => {
    if (currentRouteName() === 'transactions.index') {
      updateQueryParams(appliedFilters);
    }
  }, [JSON.stringify(appliedFilters)]);

  useEffect(() => {
    dispatch(setParam(queryParams));
  }, [JSON.stringify(queryParams)]);

  return (
    <>
      <Box>
        <TableGridFilter
          formResolver={schema}
          showFilter={showFilter}
          appliedFilters={appliedFilters}
          availableFilters={availableFilters}
          onFilter={handleOnFilter}
          onReset={handleOnReset}
        />
      </Box>
      <TableActionsContainer>
        <Box></Box>
        <Box display="flex" gap={1}>
          {!isEmpty(partnerId) && (
            <SelectCustomer
              id="select-customer"
              onSelected={(value) => {
                if (!isEmpty(value)) {
                  dispatch(setParam({ user_id: value, page_number: 1 }));
                } else {
                  dispatch(deleteFilterParams('user_id'));
                }
              }}
              sx={{ width: '300px' }}
              partnerId={partnerId}
            />
          )}
          {Auth.isSuperAdmin() && enableFilterByPartner && (
            <SelectPartner
              id="select-partner"
              onSelected={(value) => {
                if (!isEmpty(value)) {
                  dispatch(setParam({ partner_id: value, page_number: 1 }));
                } else {
                  dispatch(deleteFilterParams('partner_id'));
                }
              }}
              sx={{ width: '400px' }}
            />
          )}

          <FilterButton onClick={() => setShowFilter(!showFilter)} isOpen={showFilter}>
            Filter
          </FilterButton>

          {table && <ColumnVisibility table={table} />}
          <ReloadButton
            onClick={() => {
              dispatch(
                getZaiTransactions({
                  ...appliedFilters,
                  page_number: 1,
                  ...defaultFilterQueries,
                }),
              );
            }}
          />
          <MoreActions
            onExportToCsv={() => Sheet.exportReactTableSelectedRowsToCsv(table, 'transactions_')}
            onExportToExcel={() =>
              Sheet.exportReactTableSelectedRowsToExcel(table, 'transactions_')
            }
          />
        </Box>
      </TableActionsContainer>
    </>
  );
}

ZaiTransactionsActions.propTypes = {
  table: PropTypes.object,
  enableFilterByPartner: PropTypes.bool,
  defaultFilterQueries: PropTypes.object,
};

ZaiTransactionsActions.defaultProps = {
  enableFilterByPartner: true,
};
