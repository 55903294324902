import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import ActiveBadge from 'core/badges/ActiveBadge';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';

import Clipboard from 'core/clipboard/Clipboard';
import FormCheckbox from 'core/forms/FormCheckbox';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import TableContainer from 'core/table/TableContainer';
import FinmoPayoutsActions from './FinmoPayoutsActions';
import TanstackReactTable from 'core/table/TanstackReactTable';
import FinmoPayoutPagination from './FinmoPayoutPagination';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import { buildRoute } from 'routes';
import FinmoUtils from 'utils/FinmoUtils';
import constants from 'app/config/constants';
import { useConfirm } from 'core/mui-confirm';
import Dimensions from 'app/config/Dimensions';
import useQueryParams from 'hooks/useQueryParams';
import { getFinmoPayouts } from 'app/features/finmo-payouts/finmoPayoutSaga';
import { getFinmoPayoutBriefDetailsInit } from 'app/features/finmo-payouts/finmoPayoutSlice';

export default function FinmoPayouts() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const confirm = useConfirm();

  const finmoPayoutsState = useSelector((state) => state.finmoPayouts.index);

  const { params, data, isLoading } = finmoPayoutsState;

  const [queryParams] = useQueryParams(app.ignoreFilterKeys);

  const [columnVisibility, setColumnVisibility] = useState({});

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        header: 'Payout ID',
        cell: ({ row }) => <Clipboard content={row.original.payout_id} />,
      },
      {
        header: 'Payout Method Name',
        accessorFn: (row) => row.payout_method_name,
      },
      {
        header: 'Beneficiary',
        accessorFn: (row) => FinmoUtils.getPayoutBeneficiaryName(row.payout_beneficiary_param),
      },
      {
        header: 'Beneficiary Amount',
        accessorFn: (row) => row.beneficiary_amount + ' ' + row.beneficiary_currency,
      },
      {
        header: 'Beneficiary Country',
        accessorFn: (row) => row.beneficiary_country,
      },
      {
        header: 'Payout Reference',
        accessorFn: (row) => row.payout_reference,
      },
      {
        header: 'Status',
        accessorFn: (row) => row.status,
      },
      {
        header: 'Pay ID',
        accessorFn: (row) => row.payout_beneficiary_param?.pay_id,
      },
      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,
        cell: ({ row }) => (
          <Box display="flex" gap={1}>
            <IconButton
              size={Dimensions.table.iconButton.size}
              onClick={() => {
                dispatch(
                  getFinmoPayoutBriefDetailsInit({
                    showBriefDetailModal: true,
                    finmoPayoutId: row.original.payout_id,
                  }),
                );
              }}
            >
              <AspectRatioIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
            <IconButton
              size={Dimensions.table.iconButton.size}
              onClick={() => navigate(buildRoute('finmo-payouts.show', row.original.payout_id))}
            >
              <FullscreenIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
          </Box>
        ),
      },
    ],
    [JSON.stringify(data)],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(getFinmoPayouts({ ...queryParams, ...params }));
  }, [JSON.stringify(params)]);

  return (
    <TableContainer>
      <FinmoPayoutsActions table={table} />
      <TanstackReactTable
        table={table}
        data={data}
        isLoading={isLoading}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <FinmoPayoutPagination />
    </TableContainer>
  );
}

FinmoPayouts.propTypes = {
  partnerId: PropTypes.string,
};
