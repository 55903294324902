import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import CustomerClickAwayDetails from 'components/customers/CustomerClickAwayDetails';

import Auth from 'utils/Auth';
import { buildRoute } from 'routes';
import { getTransaction } from 'app/features/transactions/transactionSaga';
import { hideTransactionBriefDetails } from 'app/features/transactions/transactionSlice';
import DateUtils from 'utils/DateUtils';

function TransactionBriefDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const state = useSelector((state) => state.transactions.show);

  const { showBriefDetailModal, transactionId, isLoading, data } = state;

  const viewData = useMemo(
    () => [
      {
        label: 'Created Date',
        get: (row) => DateUtils.isoToString(row.created_ts),
      },
      {
        label: 'Updated Date',
        get: (row) => (row.updated_ts ? DateUtils.isoToString(row.updated_ts) : '-'),
      },
      {
        label: 'Transaction Id',
        data: 'id',
      },
      {
        label: 'Order Id',
        data: 'order_id',
      },
      {
        label: 'Customer Name',
        data: 'user_name',
      },
      ...(Auth.isSuperAdmin()
        ? [
            {
              label: 'Partner',
              data: 'partner_id',
              get: ({ partner_id, partner_name }) => (
                <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
              ),
            },
          ]
        : []),

      {
        label: 'Purpose',
        data: 'txn_purpose',
      },
      {
        label: 'Sending Amount',
        data: 'amount',
      },
      {
        label: 'Payout Amount',
        data: 'payout_amount',
      },
      {
        label: 'Status',
        data: 'status',
      },
    ],
    [],
  );

  useEffect(() => {
    if (transactionId) dispatch(getTransaction(transactionId));
  }, [transactionId]);

  if (!showBriefDetailModal && !transactionId) return <></>;

  return (
    <SidebarBriefModal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideTransactionBriefDetails())}
      title="Transaction Details"
      onClickView={() => (
        dispatch(hideTransactionBriefDetails()),
        navigate(buildRoute('transactions.show', transactionId))
      )}
      viewData={viewData}
      item={data}
      isLoading={isLoading}
    ></SidebarBriefModal>
  );
}

export default TransactionBriefDetailsModal;
