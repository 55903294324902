import { createSlice } from '@reduxjs/toolkit';

import Auth from 'utils/Auth';
import Response from 'app/config/Response';

const initialState = {
  isLoggedIn: false || Auth.isLoggedIn(),
  isLoggingIn: false,
  isLoggingOut: false,
  user: Auth.isLoggedIn() ? Auth.getUser() : null,
  errors: null,
  errorCode: null,
  errorMessage: null,
  isLogoutModalOpen: false,
  isSuspiciousLogin: false,
  changePasswordForm: {
    isProcessing: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    isSuccess: false,
    errors: null,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginInit: (state) => {
      state.isLoggedIn = false;
      state.isLoggingIn = true;
      state.user = null;
      state.errors = null;
      state.errorMessage = null;
    },

    loginSuccess: (state) => {
      state.isLoggedIn = true;
      state.isLoggingIn = false;
      state.errors = null;
      state.errorMessage = null;
    },

    loginFailure: (state, { payload: err }) => {
      state.isLoggedIn = false;
      state.isLoggingIn = false;
      let errorCode = err?.response?.data?.code ?? err?.response?.status;
      state.errorCode = errorCode;
      state.errorMessage = err?.response?.data?.message;
      if (errorCode === Response.HTTP_BAD_REQUEST) {
        state.errors = {
          email: {
            message: err?.response?.data?.message ?? 'Invalid credentials',
          },
        };
      }
      if (errorCode === Response.HTTP_NOT_ACCEPTABLE) {
        state.errorMessage = err?.response?.data?.message || 'Invalid request';
      }
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setIsLoggingOut: (state, { payload }) => {
      state.isLoggingOut = payload;
    },
    setIsLogoutModalOpen: (state, { payload }) => {
      state.isLogoutModalOpen = payload;
    },

    changePasswordInit: (state) => {
      state.changePasswordForm = {
        ...state.changePasswordForm,
        isProcessing: true,
        isError: false,
        isSuccess: false,
        errors: null,
      };
    },

    changePasswordSuccess: (state) => {
      state.changePasswordForm = {
        ...state.changePasswordForm,
        isProcessing: false,
        isSuccess: true,
        errors: null,
      };
    },

    changePasswordFailure: (state, { payload: err }) => {
      state.changePasswordForm = {
        ...state.changePasswordForm,
        isProcessing: false,
        isError: true,
        errorCode: err?.response?.status || 0,
        errorMessage: err?.response?.data?.message || null,
        ...(err?.response?.status === Response.HTTP_BAD_REQUEST
          ? {
              errors: {
                current_password: {
                  message: err?.response?.data?.message ?? 'Failed to update new password',
                },
              },
            }
          : {}),
      };
    },

    resetChangePasswordForm: (state) => {
      state.changePasswordForm = {
        ...state.changePasswordForm,
        isProcessing: false,
        isError: false,
        isSuccess: false,
        errors: null,
      };
    },
  },
});

export const {
  setIsLoggedIn,
  setIsLoggingIn,
  setError,
  clearErrors,
  setUser,
  setIsLoggingOut,
  setIsLogoutModalOpen,
  loginInit,
  loginSuccess,
  loginFailure,
  changePasswordInit,
  changePasswordSuccess,
  changePasswordFailure,
  resetChangePasswordForm,
} = authSlice.actions;

export default authSlice.reducer;
