import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import LicensingPartnerForm from 'components/LicensingPartner/LicensingPartnerForm';
import LicensingPartnerProgressIndicatorModal from 'components/LicensingPartner/LicensingPartnerProgressIndicatorModal';

import acl from 'app/config/acl';
import withRole from 'hoc/withRole';
import { buildRoute } from 'routes';
import PageContent from 'layouts/PageContent';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPartnerFundingSources } from 'app/features/partner-funding-sources/partnerFundingSourceSaga';

function AddLicensingPartner() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <PageContent
      title="Add Licensing Partner"
      rightTopEndContent={
        <Button
          variant="contained"
          onClick={() => navigate(buildRoute('licensing-partners.index'))}
        >
          List Licensing Partner
        </Button>
      }
    >
      <LicensingPartnerForm isAddMode={true} />
      <LicensingPartnerProgressIndicatorModal isAddMode={true} />
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddLicensingPartner);
