import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { buildRoute } from 'routes';
import Spacer from 'core/spacer/Spacer';
import Button from 'core/buttons/Button';
import LinkExpired from 'components/auth/LinkExpired';
import LoginVerified from 'components/auth/LoginVerified';
import SomethingWentWrong from 'core/errors/SomethingWentWrong';
import IpAlreadyConfirmed from 'components/auth/IpAlreadyConfirmed';

const Wrapper = styled(Box)(({ theme }) => ({
  height: '100vh',
  width: '100vw',
  zIndex: 999,
  background: theme.palette.grey[100],
  display: 'grid',
  placeItems: 'center',
}));

const Content = styled(Box)(({ theme }) => ({
  display: 'grid',
  placeItems: 'center',
  gap: theme.spacing(1),
}));

export default function VerifyIpAddress() {
  const params = useParams();

  const navigate = useNavigate();

  // case 1 : Login has been verified successfully
  // case 2 : IP Address already confirmed
  // case 3 : link already expired, please contact support

  let message = params.message;

  switch (message) {
    case 'Login has been verified successfully':
      return <LoginVerified />;
    case 'IP Address already confirmed':
      return <IpAlreadyConfirmed />;
    case 'link already expired, please contact support':
      return <LinkExpired />;
    default:
      return (
        <Wrapper>
          <Content>
            <Spacer />
            <Typography color="grey.600">{message ?? ''}</Typography>
            <Spacer />
            <Button
              size="medium"
              onClick={() => navigate(buildRoute('login'))}
              sx={{ textTransform: 'none' }}
            >
              Goto Login
            </Button>
          </Content>
        </Wrapper>
      );
    // return <SomethingWentWrong />;
  }
}
