import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Skeleton from 'core/skeleton/Skeleton';
import FormContainer from 'core/forms/FormContainer';

import range from 'helpers/range';

function WebhookHeaderFormSkeleton() {
  return (
    <FormContainer>
      <Grid container rowSpacing={3} columnSpacing={4}>
        <Grid item sm={12}>
          <Skeleton sx={{ height: '40px' }} />
        </Grid>
        {range(1, 2).map((i) => (
          <Grid key={i} item sm={12} md={6}>
            <Skeleton sx={{ height: '40px' }} />
          </Grid>
        ))}
        <Grid item sm={12}>
          <Skeleton sx={{ height: '40px' }} />
        </Grid>
        <Grid item sm={12} md={12}>
          <Box display="flex" justifyContent="flex-end">
            <Skeleton sx={{ height: '40px', width: '200px' }} />
          </Box>
        </Grid>
      </Grid>
    </FormContainer>
  );
}

export default WebhookHeaderFormSkeleton;
