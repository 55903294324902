import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100vh',
}));

const LeftContainer = styled('div')(({ theme }) => ({
  display: 'none',
  backgroundColor: theme.palette.primary.main,
  width: `calc(100vw/2.5)`,
  minWidth: '380px',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const RightContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
}));

export default function GuestContainer({ lTitle, lSubtitle, lImage, rChildren }) {
  return (
    <Container>
      <LeftContainer>
        <Stack textAlign="center" gap={10}>
          <Typography
            sx={{
              color: (theme) => theme.palette.background.paper,
              fontWeight: 800,
              fontSize: '2rem',
            }}
          >
            {lTitle}
          </Typography>
          {Object.prototype.toString.call(lImage) === '[object String]' ? (
            <img src={lImage} style={{ maxWidth: '400px' }} />
          ) : (
            lImage
          )}
        </Stack>
      </LeftContainer>
      <RightContainer>{rChildren}</RightContainer>
    </Container>
  );
}

GuestContainer.propTypes = {
  lTitle: PropTypes.string,
  lSubtitle: PropTypes.string,
  lImage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  rChildren: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};
