import React from 'react';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material';
import Button from 'core/buttons/Button';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';

import Spacer from 'core/spacer/Spacer';
import { logout } from 'app/features/auth/authSaga';
import { resetChangePasswordForm } from 'app/features/auth/authSlice';

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: '100vh',
  width: '100vw',
  backgroundColor: alpha(theme.palette.grey[800], 0.5),
  top: 0,
  right: 0,
  zIndex: 1200,
  display: 'grid',
  placeItems: 'center'
}));

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  textAlign: 'center'
}));

export default function PasswordChangeSuccessOverlay() {
  const dispatch = useDispatch();

  const {
    changePasswordForm: { isSuccess },
    isLoggingOut
  } = useSelector((state) => state.auth);

  if (!isSuccess) return <></>;

  return (
    <Wrapper>
      <Container>
        <Box>
          <Typography variant="h6">You have changed password successfully.</Typography>
          <Typography variant="subtitle1">Do you want to continue current session ?</Typography>
        </Box>
        <Box sx={{ width: '100%' }} display="flex" justifyContent="space-between">
          <Button
            onClick={() => dispatch(resetChangePasswordForm())}
            size="large"
            variant="outlined"
            sx={{ textTransform: 'none' }}
            disabled={isLoggingOut}
            endIcon={<ArrowForwardTwoToneIcon />}
          >
            Continue
          </Button>
          <Button
            onClick={() => dispatch(logout())}
            size="large"
            sx={{ textTransform: 'none' }}
            disabled={isLoggingOut}
            startIcon={<LogoutTwoToneIcon />}
          >
            {!isLoggingOut ? 'Logout current session' : 'Logging out current session'}
          </Button>
        </Box>
      </Container>
    </Wrapper>
  );
}
