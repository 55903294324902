import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import PartnerFundingSources from 'components/partner-funding-sources/PartnerFundingSources';
import PartnerFundingSourceFilterValues from 'components/partner-funding-sources/PartnerFundingSourceFilterValues';
import { resetGetPartnerFundingSourceFailure } from 'app/features/partner-funding-sources/partnerFundingSourceSlice';
import PartnerFundingSourceBriefDetailsModal from 'components/partner-funding-sources/PartnerFundingSourceBriefDetailsModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import Auth from 'utils/Auth';
import AuthUserIsSuperAdmin from 'components/auth/AuthUserIsSuperAdmin';

function ListPartnerFundingSource({ isNetworkError }) {
  const partnerFundingSourceState = useSelector((state) => state.partnerFundingSources.index);

  const { isError, errorCode } = partnerFundingSourceState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetPartnerFundingSourceFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetPartnerFundingSourceFailure())}
      />
    );

  return (
    <PageContent
      title={Auth.isSuperAdmin() ? 'Partner Funding Sources' : 'Funding Sources'}
      rightTopEndContent={
        <AuthUserIsSuperAdmin>
          <Button
            onClick={() => navigate(buildRoute('partner-funding-sources.create'))}
            color="primary"
            startIcon={<AddIcon />}
          >
            Add Partner Funding Source
          </Button>
        </AuthUserIsSuperAdmin>
      }
    >
      <PartnerFundingSourceFilterValues />
      <PartnerFundingSources />
      <PartnerFundingSourceBriefDetailsModal />
    </PageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ListPartnerFundingSource);
