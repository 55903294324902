import { useLocation, useNavigate } from 'react-router-dom';

import acl from 'app/config/acl';
import withRole from 'hoc/withRole';
import { buildRoute } from 'routes';
import PageContent from 'layouts/PageContent';
import LicensePartnerMapperForm from './LicensePartnerMapperForm';
import LicensePartnerMapperProgressIndicatorModal from './LicensePartnerMapperProgressIndicatorModal';
import Button from 'core/buttons/Button';

function AddLicensePartnerMapper() {
  const navigate = useNavigate();
  const location = useLocation();

  const partnerId = location.state?.partnerId || '';

  return (
    <PageContent
      title="Add License Partner Mapper"
      rightTopEndContent={
        <Button
          onClick={() => {
            navigate(buildRoute('license-partner-mapper.index', partnerId), {
              state: {
                partnerId,
              },
            });
          }}
        >
          View License Partner
        </Button>
      }
    >
      <LicensePartnerMapperForm licensingPartnerId={partnerId} />
      <LicensePartnerMapperProgressIndicatorModal />
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddLicensePartnerMapper);
