import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

const TableActionsContainer = styled(Box)(({ theme }) => ({
  // border: `1px solid ${theme.palette.grey[400]}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1),
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
  },
}));

export default TableActionsContainer;
