import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import Notify from 'utils/Notify';
import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';
import {
  getPartnerCredentialsInit,
  getPartnerCredentialsSuccess,
  getPartnerCredentialsFailure,
  getPartnerCredentialInit,
  getPartnerCredentialSuccess,
  getPartnerCredentialFailure,
  deletePartnerCredentialInit,
  deletePartnerCredentialSuccess,
  deletePartnerCredentialFailure,
  createPartnerCredentialInit,
  createPartnerCredentialSuccess,
  createPartnerCredentialFailure,
} from './partnerCredentialSlice';

const getPartnerCredential = createAction('partnerCredentials/show');
const getPartnerCredentials = createAction('partnerCredentials/index');
const deletePartnerCredential = createAction('partnerCredentials/delete');
const createPartnerCredential = createAction('partnerCredentials/create');

function* getPartnerCredentialsSaga({ payload: params }) {
  try {
    yield put(getPartnerCredentialsInit());
    let endpoint = `${apiEndpoints.getPartnerCredentials}?${new URLSearchParams(params)}`;

    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getPartnerCredentialsSuccess(response.data));
  } catch (err) {
    yield put(getPartnerCredentialsFailure(err));
  }
}

function* getPartnerCredentialSaga({ payload: partnerCredentialId }) {
  try {
    yield put(getPartnerCredentialInit());
    let endpoint = buildApiRoute(apiEndpoints.getPartnerCredential, partnerCredentialId);
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getPartnerCredentialSuccess(response.data));
  } catch (err) {
    yield put(getPartnerCredentialFailure(err));
  }
}

function* createPartnerCredentialSaga({ payload: partnerId }) {
  try {
    yield put(createPartnerCredentialInit());
    let endpoint = buildApiRoute(apiEndpoints.createPartnerCredential, partnerId);
    let response = yield callWrapperSaga(() => http.post(endpoint));
    yield put(createPartnerCredentialSuccess(response.data));
    Notify.success('Partner Credential generated successfully.');
  } catch (err) {
    Notify.error(err.response?.data?.message || 'Failed to generate partner credential');
    yield put(createPartnerCredentialFailure(err));
  }
}

function* deletePartnerCredentialSaga({ payload: partnerCredentialId }) {
  try {
    yield put(deletePartnerCredentialInit(partnerCredentialId));
    let endpoint = buildApiRoute(apiEndpoints.deletePartnerCredential, partnerCredentialId);
    yield callWrapperSaga(() => http.delete(endpoint));
    Notify.success('Partner Credential deleted successfully.');
    yield put(deletePartnerCredentialSuccess(partnerCredentialId));
  } catch (err) {
    Notify.error('Failed to generate partner credential');
    yield put(deletePartnerCredentialFailure(err));
  }
}

function* partnerCredentialSaga() {
  yield takeLatest(getPartnerCredential, getPartnerCredentialSaga);
  yield takeLatest(getPartnerCredentials, getPartnerCredentialsSaga);
  yield takeLatest(createPartnerCredential, createPartnerCredentialSaga);
  yield takeLatest(deletePartnerCredential, deletePartnerCredentialSaga);
}

export default partnerCredentialSaga;
export {
  getPartnerCredentials,
  getPartnerCredential,
  createPartnerCredential,
  deletePartnerCredential,
};
