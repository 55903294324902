import { useEffect } from 'react';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import Button from 'core/buttons/Button';
import AuthFormWrapper from 'core/forms/AuthFormWrapper';
import FormTextField from 'hook-form-controls/FormTextField';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import constants from 'app/config/constants';
import { login } from 'app/features/auth/authSaga';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Enter a valid email address').required('Enter a email address'),
  password: Yup.string().required('Enter a password'),
  fcm_id: Yup.string(),
});

function LoginForm() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const authState = useSelector((state) => state.auth);

  const { isLoggingIn, isLoggedIn, errorMessage } = authState;

  const methods = useForm({
    defaultValues: {
      ...(app.isDevelopmentMode()
        ? {
            email: 'milantarami.dev@gmail.com',
            password: 'Kathmandu@123',
          }
        : {}),
    },
    resolver: yupResolver(loginSchema),
  });

  const { formState, handleSubmit } = methods;

  const errors = { ...formState.errors, ...(authState.errors ?? {}) };

  const onSubmit = async (data) => {
    dispatch(login(data));
  };

  useEffect(() => {
    if (isLoggedIn && Auth.isLoggedIn()) {
      let intendedPath = sessionStorage.getItem(constants.INTENDED_PATH);
      isEmpty(intendedPath) ? navigate(buildRoute('dashboard')) : navigate(intendedPath);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    Auth.logout();
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ zIndex: 9999 }}>
        <AuthFormWrapper title="Login" subtitle="Enter Credentials To Login">
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <FormTextField
                label="Email Address"
                name="email"
                error={!!errors?.email || !!errorMessage}
                helperText={errors?.email?.message ?? errorMessage ?? ''}
                size="large"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                name="password"
                label="Password"
                type="password"
                error={!!errors?.password}
                helperText={errors?.password?.message ?? ''}
                size="large"
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                size="large"
                disabled={isLoggingIn}
                sx={{ textTransform: 'none' }}
                fullWidth
              >
                {isLoggingIn ? 'Processing' : 'Login'}
              </Button>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Link to={buildRoute('forgot_password')}>
                <Typography variant="body1">Forgot Password ?</Typography>
              </Link>
            </Grid>
          </Grid>
        </AuthFormWrapper>
      </form>
    </FormProvider>
  );
}

export default LoginForm;
