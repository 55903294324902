import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import { useFormContext } from 'react-hook-form';

import FormTextField from 'hook-form-controls/FormTextField';

export default function ContactPersonInformation() {
  const {
    formState: { errors }
  } = useFormContext();

  return (
    <Grid container rowSpacing={3} columnSpacing={4}>
      <Grid item sm={12} md={6}>
        <FormTextField
          name="cnt_name"
          label="Name"
          error={!!errors?.cnt_name}
          helperText={errors?.cnt_name?.message ?? ''}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <FormTextField
          name="cnt_email"
          label="Email"
          error={!!errors?.cnt_email}
          helperText={errors?.cnt_email?.message ?? ''}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <FormTextField
          name="cnt_phone_number"
          label="Phone Number"
          error={!!errors?.cnt_phone_number}
          helperText={errors?.cnt_phone_number?.message ?? ''}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <FormTextField
          name="cnt_mobile_number"
          label="Mobile Number"
          error={!!errors?.cnt_mobile_number}
          helperText={errors?.cnt_mobile_number?.message ?? ''}
        />
      </Grid>
    </Grid>
  );
}

export const contactPersonInformationSchema = Yup.object().shape({
  cnt_name: Yup.string().max(100).required('Contact Name is required'),
  cnt_email: Yup.string().email().max(50).required('Contact Email is required'),
  cnt_phone_number: Yup.string().max(20).required('Contact Phone Number is required'),
  cnt_mobile_number: Yup.string().max(20).required('Mobile Number is required')
});
