import React from 'react';

import TextField from '@mui/material/TextField';

function AmountField(props) {
  return (
    <TextField
      type="text"
      size="small"
      fullWidth
      {...props}
      onKeyDown={(e) => {
        const key = e.charCode || e.keyCode || e.code;
        if (key == 38 || key == 40) {
          e.preventDefault();
        } else {
          return;
        }
      }}
      onChange={(e) => {
        if (e.target.value && !/^\d*\.?\d{0,2}$/.test(e.target.value)) return;
        props?.onChange(e);
      }}
    />
  );
}

export default AmountField;
