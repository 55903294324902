import { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';

import FormContainer from 'core/forms/FormContainer';
import GroupedTableView from 'core/table/GroupedTableView';

export default function PartnerDetails() {
  const { isLoading, data: partner } = useSelector((state) => state.partners.show);

  const viewPartnerData = useMemo(
    () => [
      {
        groupName: 'Basic Information',
        groupData: [
          {
            label: 'Partner Name',
            data: 'name'
          },
          {
            label: 'Postal Code',
            data: 'post_code'
          },
          {
            label: 'Address',
            data: 'address'
          },
          {
            label: 'City',
            data: 'city'
          },
          {
            label: 'State',
            data: 'state'
          },
          {
            label: 'Country',
            data: 'country'
          },
          {
            label: 'Currency',
            data: 'currency'
          }
        ]
      },
      {
        groupName: 'Contact Person Details',
        groupData: [
          {
            label: 'Name',
            data: 'cnt_name'
          },
          {
            label: 'Email',
            data: 'cnt_email'
          },
          {
            label: 'Phone Number',
            data: 'cnt_phone_number'
          },
          {
            label: 'Mobile Number',
            data: 'cnt_mobile_number'
          }
        ]
      }
    ],
    []
  );

  return (
    <FormContainer>
      <Grid container spacing={2}>
        {viewPartnerData.map((group, i) => (
          <GroupedTableView
            key={i}
            title={group.groupName}
            data={group.groupData}
            item={partner}
            isLoading={isLoading}
          />
        ))}
      </Grid>
    </FormContainer>
  );
}
