import { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

import AuthFormWrapper from 'core/forms/AuthFormWrapper';

import useInterval from 'hooks/useInterval';

const ResendTextButton = styled('button')(({ theme }) => ({
  cursor: 'pointer',
  margin: theme.spacing(0),
  color: theme.palette.primary.main,
  '&:hover': {
    textDecoration: 'underline',
  },
  outline: 'none',
  background: 'none',
  border: 0,
  fontSize: '1.1rem',
}));

function ResetLinkSent({ onResend, isProcessing, email }) {
  const [timer, setTimer] = useState(59);

  useInterval(() => {
    setTimer(timer - 1);
  }, [1000]);

  return (
    <AuthFormWrapper>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <Typography color="success.main" fontSize="1.1rem">
            A reset password instructions is sent to your email. Please check your mail.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack
            display="flex"
            flexDirection="column"
            justifyContent="start"
            alignItems="start"
            gap={1}
          >
            <Typography fontSize="1.1rem">Didn&apos;t get an email ?</Typography>
            <ResendTextButton
              onClick={() => (onResend(email), setTimer(59))}
              disabled={isProcessing || timer > 0}
            >
              {timer > 0 ? <>Resend in {timer}s</> : isProcessing ? 'Resending' : 'Resend Email'}
            </ResendTextButton>
          </Stack>
        </Grid>
      </Grid>
    </AuthFormWrapper>
  );
}

export default ResetLinkSent;

ResetLinkSent.propTypes = {
  onResend: PropTypes.func.isRequired,
  isProcessing: PropTypes.bool,
  email: PropTypes.string.isRequired,
};
