/* eslint-disable no-dupe-keys */
import PageContent from 'layouts/PageContent';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import HookForm from 'hook-form-controls/HookForm';
import SubmitButton from 'core/buttons/SubmitButton';
import CancelButton from 'core/buttons/CancelButton';
import FormDatePicker from 'hook-form-controls/FormDatePicker';

import Sheet from 'utils/Sheet';
import Notify from 'utils/Notify';
import { format } from 'date-fns';
import FormSelect from 'hook-form-controls/FormSelect';
import AusReportObjectDefinition from 'app/data/ausReportDefinition';
import FormSelectCountry from 'hook-form-controls/FormSelectCountry';
import { resetReportState } from 'app/features/ausTrackReports/ausTrackReportsSlice';
import { getAusTrackReports } from 'app/features/ausTrackReports/ausTrackReportsSaga';
import AusTrackReportsProgressModal from 'components/ausTrackReports/ausTrackReportProgressModal';

const statusOptions = [
  {
    label: 'PAID',
    value: 'PAID',
  },
  {
    label: 'COMPLETED',
    value: 'COMPLETED',
  },
  // {
  //   label: 'FAILED',
  //   value: 'FAILED',
  // },
  // {
  //   label: 'REJECTED',
  //   value: 'REJECTED',
  // },

  // {
  //   label: 'CREATED',
  //   value: 'CREATED',
  // },
];

export default function AusTrackReports() {
  const methods = useForm({
    defaultValues: {
      iso3Country: 'AUS',
    },
  });

  const { getValues } = methods

  const ausTrackReportsState = useSelector((state) => state.ausTrackReports.create);

  const dispatch = useDispatch();

  useEffect(() => {
    const data = [...(ausTrackReportsState?.data ?? [])];

    if (data.length === 0 && ausTrackReportsState?.isCreated === true) {
      return Notify.error('No Transaction log found.');
    }

    if (ausTrackReportsState?.isCreated === true && data && data.length > 0) {
      dispatch(resetReportState());
      Sheet.downloadTableToExcel(
        data,
        AusReportObjectDefinition,
        `AusTrackReport_${format(new Date(getValues('fromDate')), 'yyyy-MM-dd')}__${format(new Date(getValues('toDate')), 'yyyy-MM-dd')}`,
      );
    }
  }, [ausTrackReportsState?.isCreated]);

  const onSubmit = (data) => {
    dispatch(getAusTrackReports(data));
  };

  const handleReset = () => {
    methods.reset();
  };

  return (
    <PageContent title="AusTrack Transaction Report">
      <Grid container spacing={2} gap={2}>
        <Grid
          item
          xs={12}
          md={9}
          px={4}
          sx={{
            border: '1px solid  rgba(0, 0, 0, 0.20)',
            borderRadius: '4px',
            backgroundColor: '#fff',
          }}
        >
          <HookForm {...methods} onSubmit={onSubmit}>
            <Box>
              <Typography fontSize={22} fontWeight={600}>
                Export Transaction Details
              </Typography>
            </Box>
            <Grid container spacing={4} mt={1}>
              <Grid item xs={12} md={6}>
                <FormSelectCountry id="select-country" name="iso3Country" disabled />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormSelect
                  name="status"
                  label="Status"
                  variant="outlined"
                  options={statusOptions}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormDatePicker name="fromDate" label="From" fullWidth disableFuture />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormDatePicker name="toDate" label="To" fullWidth disableFuture />
              </Grid>
              <Grid item xs={12} my={2}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <CancelButton
                    variant="outlined"
                    type="button"
                    onClick={() => handleReset()}
                    disabled={false}
                  >
                    Reset
                  </CancelButton>
                  <SubmitButton type="submit" isLoading={false}>
                    Export Transaction
                  </SubmitButton>
                </Box>
              </Grid>
            </Grid>
          </HookForm>
        </Grid>
      </Grid>
      <AusTrackReportsProgressModal />
    </PageContent>
  );
}
