import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getFinmoPayinsInit,
  getFinmoPayinsSuccess,
  getFinmoPayinsFailure,
  getFinmoPayinInit,
  getFinmoPayinFailure,
  getFinmoPayinSuccess,
} from './finmoPayinSlice';

const getFinmoPayin = createAction('finmoPayins/get');
const getFinmoPayins = createAction('finmoPayins/list');

/**
 * get a single finmoPayin
 */
function* getFinmoPayinSaga({ payload: finmoPayinId }) {
  try {
    yield put(getFinmoPayinInit());
    let endpoint = buildApiRoute(apiEndpoints.getFinmoPayin, finmoPayinId);
    let response = yield callWrapperSaga(() => http.get(endpoint, finmoPayinId));
    yield put(getFinmoPayinSuccess(response.data));
  } catch (err) {
    yield put(getFinmoPayinFailure(err));
  }
}

/**
 * get a finmoPayins list
 *
 */
function* getFinmoPayinsSaga({ payload }) {
  yield put(getFinmoPayinsInit());
  try {
    let endpoint = `${apiEndpoints.getFinmoPayins}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getFinmoPayinsSuccess(response.data));
  } catch (err) {
    yield put(getFinmoPayinsFailure(err));
  }
}

function* finmoPayinSaga() {
  yield takeLatest(getFinmoPayin, getFinmoPayinSaga);
  yield takeLatest(getFinmoPayins, getFinmoPayinsSaga);
}

export default finmoPayinSaga;
export { getFinmoPayin, getFinmoPayins };
