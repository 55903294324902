import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';

import { buildRoute } from 'routes';
import useAuthUser from 'hooks/useAuthUser';
import { getWebhookHeader } from 'app/features/webhook-headers/webhookHeaderSaga';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import { hideWebhookHeaderBriefDetails } from 'app/features/webhook-headers/webhookHeaderSlice';

function WebhookHeaderBriefDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useAuthUser();

  const viewData = useMemo(
    () => [
      ...(user?.isSuperAdmin()
        ? [
            {
              label: 'Partner Id',
              data: 'partner_id',
              get: ({ partner_id, partner_name }) => (
                <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
              ),
            },
          ]
        : []),
      {
        label: 'Customer Header',
        data: 'description',
      },
      {
        label: 'Value',
        data: 'value',
      },
      {
        label: 'Description',
        data: 'description',
      },
      {
        label: 'Is Verified ?',
        get: ({ is_verified }) => (is_verified ? 'Yes' : 'No'),
      },
      {
        label: 'Is Active ?',
        get: ({ is_active }) => (is_active ? 'Yes' : 'No'),
      },
    ],
    [],
  );

  const state = useSelector((state) => state.webhookHeaders.show);

  const { showBriefDetailModal, webhookHeaderId, isLoading, data } = state;

  useEffect(() => {
    if (webhookHeaderId) dispatch(getWebhookHeader(webhookHeaderId));
  }, [webhookHeaderId]);

  if (!showBriefDetailModal && !webhookHeaderId) return <></>;

  return (
    <SidebarBriefModal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideWebhookHeaderBriefDetails())}
      title="Webhook Header Details"
      onClickView={() => (
        dispatch(hideWebhookHeaderBriefDetails()),
        navigate(buildRoute('webhook-headers.show', webhookHeaderId))
      )}
      viewData={viewData}
      item={data}
      isLoading={isLoading}
    />
  );
}

export default WebhookHeaderBriefDetailsModal;
