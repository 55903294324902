import { useEffect, useCallback, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import Button from 'core/buttons/Button';
import MoreActions from 'core/lab/MoreActions';
import ReloadButton from 'core/buttons/ReloadButton';
import TableGridFilter from 'core/table/TableGridFilter';
import ColumnVisibility from 'core/table/ColumnVisibility';
import SelectPartner from 'components/partners/SelectPartner';
import TableActionsContainer from 'core/table/TableActionsContainer';

import Auth from 'utils/Auth';
import Sheet from 'utils/Sheet';
import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import { currentRouteName } from 'routes';
import FilterType from 'app/config/FilterType';
import useQueryParams from 'hooks/useQueryParams';
import SelectCustomer from 'components/customers/SelectCustomer';
import { exportTransactions, getTransactions } from 'app/features/transactions/transactionSaga';
import PendingTransactionsContext from 'app/context/PendingTransactionsContext';
import { deleteFilterParams, setParam } from 'app/features/transactions/transactionSlice';
import {
  fundingSourceOptions,
  fundingSourceOptionsPendingBankTransactions,
  fundingSources,
} from 'app/config/FundingSource';
import { transactionStatusOptions, transactionStatuses } from 'app/config/TransactionStatus';

export default function TransactionActions({ table, enableFilterByPartner, defaultFilterQueries }) {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const isPendingTransactionsContext = useContext(PendingTransactionsContext);

  const transactionState = useSelector((state) => state.transactions.index);

  const { isLoading: isExporting } = useSelector((state) => state.transactions.export);

  const [queryParams, updateQueryParams] = useQueryParams(app.ignoreFilterKeys);

  let availableFilters = [
    {
      label: 'Transaction ID',
      value: 'id',
      type: FilterType.input,
    },
    {
      label: 'Currency',
      value: 'currency',
      type: FilterType.input,
    },
    {
      label: 'Funding Type',
      value: 'funding_type',
      type: FilterType.multiSelect,
      options: isPendingTransactionsContext
        ? fundingSourceOptionsPendingBankTransactions
        : fundingSourceOptions,
    },
    {
      label: 'From Date',
      value: 'fromDate',
      type: FilterType.datepicker,
    },
    {
      label: 'To Date',
      value: 'toDate',
      type: FilterType.datepicker,
    },
    ...(!isPendingTransactionsContext
      ? [
          {
            label: 'Status',
            value: 'status',
            type: FilterType.select,
            options: transactionStatusOptions,
          },
        ]
      : []),
  ];

  const { params: appliedFilters } = transactionState;

  const handleOnFilter = useCallback((data) => {
    dispatch(setParam({ ...data, page_number: 1 }));
  }, []);

  const handleOnReset = useCallback((e) => {
    dispatch(deleteFilterParams([...availableFilters.map((filter) => filter.value)]));
  });

  /**
   * If SUPERADMIN get partner id from query params
   * if not get from auth token
   */
  const partnerId = appliedFilters?.partner_id ?? Auth.getPartnerId();

  useEffect(() => {
    if (currentRouteName() === 'transactions.index') {
      updateQueryParams(appliedFilters);
    }
  }, [JSON.stringify(appliedFilters)]);

  useEffect(() => {
    dispatch(setParam(queryParams));
  }, [JSON.stringify(queryParams)]);

  const handleExportAll = () => {
    dispatch(exportTransactions(transactionState.params));
  };

  return (
    <>
      <Box>
        <TableGridFilter
          showFilter={showFilter}
          appliedFilters={appliedFilters}
          availableFilters={availableFilters}
          onFilter={handleOnFilter}
          onReset={handleOnReset}
        />
      </Box>
      <TableActionsContainer>
        <Box></Box>
        <Box display="flex" gap={1}>
          {!isEmpty(partnerId) && (
            <SelectCustomer
              id="select-customer"
              onSelected={(value) => {
                if (!isEmpty(value)) {
                  dispatch(setParam({ user_id: value, page_number: 1 }));
                } else {
                  dispatch(deleteFilterParams('user_id'));
                }
              }}
              sx={{ width: '300px' }}
              partnerId={partnerId}
            />
          )}
          {Auth.isSuperAdmin() && enableFilterByPartner && (
            <SelectPartner
              id="select-partner"
              onSelected={(value) => {
                if (!isEmpty(value)) {
                  dispatch(setParam({ partner_id: value, page_number: 1 }));
                } else {
                  dispatch(deleteFilterParams('partner_id'));
                }
              }}
              sx={{ width: '400px' }}
            />
          )}

          <Button
            variant="outlined"
            endIcon={<FilterAltIcon />}
            onClick={() => setShowFilter(!showFilter)}
            uppercase
          >
            Filter
          </Button>

          {table && <ColumnVisibility table={table} />}
          <ReloadButton
            onClick={() => {
              dispatch(
                getTransactions({
                  ...appliedFilters,
                  page_number: 1,
                  ...(isPendingTransactionsContext
                    ? {
                        status: transactionStatuses.created,
                        funding_type: isEmpty(appliedFilters.funding_type)
                          ? [fundingSources.payID, fundingSources.bankTransfer]
                          : appliedFilters.funding_type,
                      }
                    : {}),
                  ...defaultFilterQueries,
                }),
              );
            }}
          />
          <MoreActions
            isLoading={isExporting}
            onExportAll={handleExportAll}
            onExportToCsv={() => Sheet.exportReactTableSelectedRowsToCsv(table, 'transactions_')}
            onExportToExcel={() =>
              Sheet.exportReactTableSelectedRowsToExcel(table, 'transactions_')
            }
          />
        </Box>
      </TableActionsContainer>
    </>
  );
}

TransactionActions.propTypes = {
  table: PropTypes.object,
  enableFilterByPartner: PropTypes.bool,
  defaultFilterQueries: PropTypes.object,
};

TransactionActions.defaultProps = {
  enableFilterByPartner: true,
};
