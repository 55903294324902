import { Component } from 'react';
import PropTypes from 'prop-types';

import Log from 'utils/Log';
import app from 'app/config/app';
import ErrorContainer from 'core/errors/ErrorContainer';
import SomethingWentWrong from 'core/errors/SomethingWentWrong';
import Spacer from 'core/spacer/Spacer';
import Button from 'core/buttons/Button';

import { poppins } from 'app/themes/defaultTheme';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    Log.debug(error, errorInfo);

    this.setState({
      ...this.state,
      errorMessage: error.message
    });
  }

  render() {
    // You can render any custom fallback UI
    if (this.state.hasError && app.isDevelopmentMode()) {
      return (
        <>
          <h1>Something went wrong.</h1>
          {app.debug && <p>{this.state.errorMessage}</p>}
        </>
      );
    } else if (this.state.hasError && app.isProductionMode()) {
      return (
        <ErrorContainer withInDashboard={false}>
          <SomethingWentWrong />
          <Spacer />
          <Button
            sx={{ textTransform: 'none', fontFamily: poppins, backgroundColor: '#232D6C' }}
            onClick={() => window.location.reload()}
          >
            Reload Page
          </Button>
        </ErrorContainer>
      );
    }
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
};
