import PropTypes from 'prop-types';
import { poppins } from 'app/themes/defaultTheme';

export default function Title({ children }) {
  return (
    <span
      style={{
        fontFamily: poppins,
        fontSize: '2rem',
        fontWeight: '600',
        color: '#637381'
      }}
    >
      {children}
    </span>
  );
}

Title.propTypes = {
  children: PropTypes.string.isRequired
};
