import React from 'react';
import { useNavigate } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Button from 'core/buttons/Button';
import PageContent from 'layouts/PageContent';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import WebhookHeaderForm from 'components/webhook-headers/WebhookHeaderForm';
import WebhookHeaderProgressIndicatorModal from 'components/webhook-headers/WebhookHeaderProgressIndicatorModal';

function AddWebhookHeader() {
  const navigate = useNavigate();

  return (
    <PageContent
      title="Add Webhook Header"
      rightTopEndContent={
        <Button onClick={() => navigate(buildRoute('webhook-headers.index'))}>List Webhook Header</Button>
      }
    >
      <WebhookHeaderForm isAddMode={true} />
      <WebhookHeaderProgressIndicatorModal isAddMode={true} />
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddWebhookHeader);
