import PropTypes from 'prop-types';

import Title from './Title';
import Description from './Description';
import Svg403 from 'assets/svg/403.svg';

export default function _403({ title, description }) {
  return (
    <>
      <img src={Svg403} style={{ height: '40%' }} />
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
    </>
  );
}

_403.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};
