import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import GroupedTableView from 'core/table/GroupedTableView';

import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import { getFundingSourceInfo } from 'app/features/funding-sources-info/fundingSourceInfoSaga';

export default function FundingSourceInfo({ fundingSourceId }) {
  const dispatch = useDispatch();

  const viewPartnerFundingSourceData = useMemo(
    () => [
      {
        groupName: '',
        groupData: [
          {
            label: 'Partner Name',
            data: 'partner_name',
          },
          {
            label: 'Partner Payment Name',
            data: 'payment_name',
          },
          {
            label: 'Payment Value',
            data: 'payment_value',
          },
          {
            label: 'Account Number',
            data: 'account_number',
          },
          {
            label: 'Is Active ?',
            data: 'is_active',
            get: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
          },
          {
            label: 'Is Verified ?',
            data: 'is_verified',
            get: ({ is_verified }) => <VerifiedBadge isVerified={!!is_verified} />,
          },
          {
            label: 'Created At',
            data: 'created_ts',
            get: ({ created_ts }) =>
              !isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '-',
          },

          {
            label: 'Updated At',
            data: 'updated_ts',
            get: ({ updated_ts }) =>
              !isEmpty(updated_ts) ? DateUtils.isoToString(updated_ts) : '-',
          },
        ],
      },
    ],
    [],
  );

  const {
    isError,
    errorCode,
    isLoading,
    data: fundingSourceInfo,
  } = useSelector((state) => state.fundingSourcesInfo.show);

  useEffect(() => {
    dispatch(getFundingSourceInfo(fundingSourceId));
  }, [fundingSourceId]);

  return (
    <Grid container spacing={2}>
      {viewPartnerFundingSourceData.map((group, i) => (
        <GroupedTableView
          key={i}
          title={group.groupName}
          data={group.groupData}
          item={fundingSourceInfo}
          isLoading={isLoading}
        />
      ))}
    </Grid>
  );
}

FundingSourceInfo.propTypes = {
  fundingSourceId: PropTypes.string,
};
