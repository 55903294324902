import PropTypes from 'prop-types';

import MuiSkeleton from '@mui/material/Skeleton';

function Skeleton({ animation, component, sx, variant, width, ...rest }) {
  return (
    <MuiSkeleton
      animation={animation}
      component={component}
      sx={{ width: width, ...sx }}
      variant={variant}
      {...rest}
    />
  );
}

export default Skeleton;

Skeleton.propTypes = {
  animation: PropTypes.oneOf(['pulse', 'wave']),
  component: PropTypes.elementType,
  sx: PropTypes.object,
  variant: PropTypes.oneOf(['circular', 'text', 'rectangular']),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

Skeleton.defaultProps = {
  animation: 'pulse',
  component: null,
  sx: {},
  variant: 'rectangular',
  width: '100%'
};
