const AusReportObjectDefinition = {
  date_Money_Received_From_Customer: 'Date money/property received from the ordering customer',
  date_Money_Made_Available_Beneficiary_customer:
    'Date money/property made available to the beneficiary customer',
  currencyCode: 'Currency code',
  totalAmountValue: 'Total amount/value',
  typeOfTransfer: 'Type of transfer',
  descriptionOfProperty: 'Description of property',
  txnReferenceNumber: 'Transaction reference number',
  full_Name: 'Full name',
  knownOtherName: 'If known by any other name',
  doB_Individual: 'Date of birth (if an individual)',
  businessResidentialAddress: 'Business/residential address (not a post box ADDRESS)',
  city_Town_Suburb: 'City/town/suburb',
  state: 'State',
  postcode: 'Postcode',
  country: 'Country',
  postal_Address: 'Postal address',
  city_Town_Suburb_2: 'City/town/suburb',
  state_2: 'State',
  postcode_2: 'Postcode',
  country_2: 'Country',
  phone: 'Phone',
  email: 'Email',
  occupation_Business_Principal_Activity: 'Occupation, business or principal activity',
  abN_ACN_ARBN: 'ABN, ACN or ARBN',
  customer_Number_By_Remitter: 'Customer number (allocated by remitter)',
  account_Number_Held_By_Remitter: 'Account number (held by remitter)',
  business_Structure: 'Business structure (if not an individual)',
  iD_Type_1: 'ID type (1)',
  iD_Type_Other: 'ID type (if Other)',
  number: 'Number',
  issuer: 'Issuer',
  iD_Type_2: 'ID type (2)',
  iD_Type_Other_2: 'ID type (if Other)',
  number_2: 'Number',
  issuer_2: 'Issuer',
  electronic_Data_Source: 'Electronic data source',
  full_Name_2: 'Full name',
  doB_Individual_1: 'Date of birth (if an individual)',
  business_Name_Customer_Operating:
    'Any business name under which the beneficiary customer is operating',
  business_Residential_Address_2: 'Business/residential address (not a post box address)',
  city_Town_Suburb_3: 'City/town/suburb',
  state_3: 'State',
  postcode_3: 'Postcode',
  country_3: 'Country',
  postal_Address_2: 'Postal address',
  city_Town_Suburb_4: 'City/town/suburb',
  state_4: 'State',
  postcode_4: 'Postcode',
  country_4: 'Country',
  phone_2: 'Phone',
  email_2: 'Email',
  occupation_Business_Principal_Activity_2: 'Occupation, business or principal activity',
  abN_ACN_ARBN_2: 'ABN, ACN or ARBN',
  business_Structure_2: 'Business structure (if not an individual)',
  account_Number: 'Account number',
  institution_Name_Account_Held: 'Name of institution (where account is held)',
  city: 'City',
  country_5: 'Country',
  identification_No_Retail_Outlet_Location:
    'Identification number of the retail outlet/business location',
  full_Name_3: 'Full name',
  business_Residential_Address_3: 'Business/residential address (not a post box address)',
  city_Town_Suburb_5: 'City/town/suburb',
  state_5: 'State',
  postcode_5: 'Postcode',
  is_Person_Org_Accepting_Money: 'Is this person/organisation accepting the money or property?',
  is_Person_Org_Sending_Transfer: 'Is this person/organisation sending the transfer instruction?',
  full_Name_4: 'Full name',
  business_Residential_Address_4: 'Business/residential address (not a post box address)',
  city_Town_Suburb_6: 'City/town/suburb',
  state_6: 'State',
  postcode_6: 'Postcode',
  full_Name_5: 'Full name',
  known_Other_Name_2: 'If known by any other name Date of birth (if an individual)',
  business_Residential_Address_5: 'Business/residential address (not a post box address)',
  city_Town_Suburb_7: 'City/town/suburb',
  state_7: 'State',
  postcode_7: 'Postcode',
  postal_Address_3: 'Postal address',
  city_Town_Suburb_8: 'City/town/suburb',
  state_8: 'State',
  postcode_8: 'Postcode',
  phone_3: 'Phone',
  email_3: 'Email',
  occupation_2: 'Occupation',
  business_Principal_Activity: 'business or principal activity',
  abN_ACN_ARBN_3: 'ABN, ACN or ARBN',
  business_Structure_3: 'Business structure (if not an individual)',
  full_Name_6: 'Full Name',
  business_Residential_Address_6: 'Business/residential address (not a post box address)',
  city_Town_Suburb_9: 'City/town/suburb',
  state_9: 'State',
  postcode_9: 'Postcode',
  country_6: 'Country',
  is_Person_Distributing_Money: 'is this person/organisation distributing money or property?',
  is_Separate_Retail_Business:
    'Is there a separate retail outlet/business location at which the money or property is being distributed?',
  full_Name_7: 'Full name',
  business_Residential_Address_7: 'Business/residential address (not a post box address)',
  city_Town_Suburb_10: 'City/town/suburb',
  state_10: 'State',
  postcode_10: 'Postcode',
  country_7: 'Country',
  full_Name_8: 'Full name',
  business_Residential_Address_8: 'Business/residential address (not a post box address)',
  city_Town_Suburb_11: 'City/town/suburb',
  state_11: 'State',
  postcode_11: 'Postcode',
  country_8: 'Country',
  transfer_Reason: 'Reason for the transfer',
  full_Name_9: 'Full name',
  job_Title: 'Job title',
  phone_4: 'Phone',
  email_4: 'Email',
};

export default AusReportObjectDefinition;
