import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { compose } from '@reduxjs/toolkit';
import { useDispatch, connect } from 'react-redux';

import Pagination from 'core/pagination/Pagination';
import { setWebhookLogsParam } from 'app/features/transactions/zaiTransactionSlice';

function WebhookLogPagination({ totalCount, pageSize, pageNumber }) {
  const dispatch = useDispatch();

  return (
    <Box component="div" sx={{ py: 2 }}>
      <Pagination
        count={Math.ceil(totalCount / pageSize)}
        page={pageNumber}
        onChange={(currentPage) => dispatch(setWebhookLogsParam({ PageNumber: currentPage }))}
        onRowPerPageChange={(rowPerPage) =>
          dispatch(setWebhookLogsParam({ PageSize: rowPerPage, PageNumber: 1 }))
        }
        perPageValue={pageSize}
      />
    </Box>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    totalCount: state.zaiTransactions.webhookLogs.totalCount,
    pageSize: state.zaiTransactions.webhookLogs.params.PageSize,
    pageNumber: state.zaiTransactions.webhookLogs.params.PageNumber,
  };
}

export default compose(connect(mapStateToProps))(WebhookLogPagination);

WebhookLogPagination.propTypes = {
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  pageNumber: PropTypes.number,
};
