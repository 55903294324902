import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import FormSelectCountryComponent from 'core/forms/FormSelectCountry';
import isEmpty from 'helpers/isEmpty';

export default function FormSelectCountry(props) {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const { id, name, label, required, size, fullWidth, disabled, variant, onSelected } = props;

  const value = watch(name);

  return (
    <Controller
      name={name}
      control={control}
      render={() => {
        return (
          <FormSelectCountryComponent
            id={id}
            name={name}
            error={!!errors[name]}
            helperText={errors[name]?.message ?? ''}
            onSelected={(country) => {
              if (!isEmpty(country)) {
                setValue(name, country.iso3);
                onSelected?.(country);
              } else {
                setValue(name, '');
                onSelected?.(undefined);
              }
            }}
            label={label}
            variant={variant}
            fullWidth={fullWidth}
            required={required}
            size={size}
            disabled={disabled}
            value={value}
          />
        );
      }}
    />
  );
}

FormSelectCountry.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small', 'large']),
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning']),
  onSelected: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

FormSelectCountry.defaultProps = {
  label: 'Select a country',
  error: false,
  helperText: '',
  required: false,
  disabled: false,
  fullWidth: true,
  size: 'small',
  variant: 'outlined',
};
