import React from 'react';
import PropTypes from 'prop-types';

import Beneficiaries from 'components/beneficiaries/Beneficiaries';
import BeneficiaryBriefDetailsModal from 'components/beneficiaries/BeneficiaryBriefDetailsModal';

export default function CustomerBeneficiariesTab({ customerId }) {
  return (
    <>
      <Beneficiaries filterByCustomer={true} customerId={customerId} />
      <BeneficiaryBriefDetailsModal />
    </>
  );
}

Beneficiaries.propTypes = {
  customerId: PropTypes.string.isRequired,
};
