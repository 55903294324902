import PropTypes from 'prop-types';
import MuiListItem from '@mui/material/ListItem';
import styled from '@mui/material/styles/styled';

import ListItemContexts from './ListItemContexts';

/**
 * Reference : MUI 5 styled() pass props along with theme object
 *
 * https://github.com/mui/material-ui/issues/29207#issuecomment-949283534
 */

const ListItem = styled(MuiListItem, {
  shouldForwardProp: (prop) => !['isActive', 'context'].includes(prop),
})(({ theme, isActive, context }) => ({
  padding: theme.spacing(1.5, 1, 1.5, 0),
  backgroundColor: isActive
    ? context === ListItemContexts.item
      ? theme.palette['menuActive']['main']
      : context === ListItemContexts.dropdownItem
      ? theme.palette['subMenuActive']['main']
      : theme.palette.transparent
    : theme.palette.transparent,
  '&:hover': {
    backgroundColor:
      context === ListItemContexts.item
        ? theme.palette['menuActive']['main']
        : context === ListItemContexts.dropdownItem
        ? theme.palette['subMenuActive']['main']
        : theme.palette.transparent,
  },
  '& .MuiListItemIcon-root': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.background.paper,
    fontSize: '1.5rem',
  },
  '& .MuiListItemText-primary': {
    color: theme.palette.background.paper,
    fontSize:
      context === ListItemContexts.item
        ? '1rem'
        : context === ListItemContexts.dropdownItem
        ? '0.95rem'
        : '0.95rem',
  },
}));

export default ListItem;

ListItem.propTypes = {
  isActive: PropTypes.bool,
  context: PropTypes.oneOf([ListItemContexts.item, ListItemContexts.dropdownItem]),
};

ListItem.defaultProps = {
  isActive: false,
};
