import Auth from 'utils/Auth';
import { buildRoute } from 'routes';
import { Navigate, Outlet } from 'react-router-dom';

import useAuthUser from 'hooks/useAuthUser';
import constants from 'app/config/constants';

export default function ProtectedRoutes() {
  if (!Auth.isLoggedIn()) {
    sessionStorage.setItem(constants.INTENDED_PATH, window.location.pathname);
    Auth.logout();
  }

  const user = useAuthUser();

  // return <Error status={Response.HTTP_FORBIDDEN} />;

  return Auth.isLoggedIn() ? <Outlet /> : <Navigate to={buildRoute('login')} />;
}
