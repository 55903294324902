import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import isEmpty from 'helpers/isEmpty';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { connect, useDispatch, useSelector } from 'react-redux';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Button from 'core/buttons/Button';
import TextButton from 'core/buttons/TextButton';
import useQueryParams from 'hooks/useQueryParams';
import FormFileField from 'core/forms/FormFileField';
import FormContainer from 'core/forms/FormContainer';
import FormSelect from 'hook-form-controls/FormSelect';
import FormCheckbox from 'hook-form-controls/FormCheckbox';
import FormTextField from 'hook-form-controls/FormTextField';
import FormSelectCountry from 'hook-form-controls/FormSelectCountry';

import {
  createLicensingPartner,
  updateLicensingPartner,
} from 'app/features/licensing-partners/licensingPartnerSaga';
import { getPartnerFundingSources } from 'app/features/partner-funding-sources/partnerFundingSourceSaga';

const licensingPartnerSchema = Yup.object().shape({
  name: Yup.string().required('Licensing Partner Name is Required'),
  country: Yup.string().required('Licensing Partner Country is Required'),
  license_number: Yup.string()
    .required('Licensing Partner License Number is Required')
    .optional()
    .transform((value) => (value === undefined || value === null ? '' : value))
    .nullable(),
  privacy_policy_link: Yup.string()
    .url()
    .required('Licensing Partner Policy Link is Required')
    .optional()
    .nullable()
    .transform((value) => (value === undefined || value === null ? '' : value)),

  website_link: Yup.string()
    .url()
    .required('Licensing Partner Website Link is Required')
    .optional()
    .nullable()
    .transform((value) => (value === undefined || value === null ? '' : value)),

  short_code: Yup.string()
    .required('Licensing Partner Short Code is Required')
    .optional()
    .nullable()
    .transform((value) => (value === undefined || value === null ? '' : value)),

  terms_and_conditions_link: Yup.string()
    .url()
    .required('Licensing Partner Terms and Conditions Link is Required')
    .optional()
    .nullable()
    .transform((value) => (value === undefined || value === null ? '' : value)),
});

function LicensingPartnerForm({ isAddMode, licensingPartner, retries, isCreated }) {
  const submitButtonRef = useRef(null);
  const [query] = useQueryParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoImage, setLogoImage] = useState(null);

  const partnerFundingSourcesState = useSelector((state) => state.partnerFundingSources.index);

  const { data: fundingSourceData, isLoading: isFundingSourceLoading } = partnerFundingSourcesState;

  const fundingSourceOptions =
    Array.from(
      new Map(
        fundingSourceData?.map((item) => [
          item.funding_source_name,
          {
            label: item.funding_source_name,
            value: item.id,
          },
        ]),
      ).values(),
    ) || [];

  const methods = useForm({
    defaultValues: {
      ...licensingPartner,
      ...(isAddMode && query.licensingPartnerId
        ? {
            licensing_partner_id: query.licensingPartnerId,
          }
        : {}),
      logo: null,
      license_number: '',
      website_link: '',
      privacy_policy_link: '',
      terms_and_conditions_link: '',
      short_code: '',
      payment_method_id: '',
      is_deleted: false,
    },
    resolver: yupResolver(licensingPartnerSchema),
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = methods;

  useEffect(() => {
    if (!isAddMode) {
      setValue('name', licensingPartner?.name);
      setValue('country', licensingPartner?.country);
      setValue('license_number', licensingPartner?.license_number);
      setValue('website_link', licensingPartner?.website_link);
      setValue('privacy_policy_link', licensingPartner?.privacy_policy_link);
      setValue('terms_and_conditions_link', licensingPartner?.terms_and_conditions_link);
      setValue('short_code', licensingPartner?.short_code);
      setValue('is_deleted', licensingPartner?.is_deleted);
      setValue('is_active', licensingPartner?.is_active);
      setValue('payment_method_id', licensingPartner?.payment_method_id);
    }
  }, [licensingPartner]);

  const onSubmit = (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (key !== 'logo') {
        formData.append(key, data[key]);
      }
    });

    if (logoImage) {
      formData.append('logo', logoImage);
    }

    dispatch(
      isAddMode
        ? createLicensingPartner(formData)
        : updateLicensingPartner({
            licensingPartnerId: licensingPartner?.id,
            data: formData,
          }),
    );
  };

  useEffect(() => {
    if (retries > 0) submitButtonRef.current.click();
  }, [retries]);

  const logoImagePreview = licensingPartner?.logo;

  useEffect(() => {
    dispatch(
      getPartnerFundingSources({
        page_size: 500,
      }),
    );
  }, [dispatch]);

  return (
    <FormContainer>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={3} columnSpacing={4}>
            <Grid item sm={12} md={6}>
              <FormSelectCountry id="select-country" name="country" />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormTextField name="name" label="Licensing Partner name" />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormTextField name="license_number" label="License Number" />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormTextField name="website_link" label="Website Link" />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormTextField name="privacy_policy_link" label="Privacy Policy Link" />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormTextField name="terms_and_conditions_link" label="Terms and conditions Link" />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormTextField name="short_code" label="Short Code" />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormSelect
                name="payment_method_id"
                label="Select Payment Method"
                options={fundingSourceOptions}
                fullWidth={true}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <FormCheckbox
                name="is_deleted"
                label="Is Deleted ?"
                error={!!errors?.is_deleted}
                helperText={errors?.is_deleted?.message ?? ''}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={7}>
                  <FormFileField
                    name="logo"
                    accept="image/*"
                    onImageCallback={(file) => {
                      setLogoImage(file);
                    }}
                  />
                  <FormLabel htmlFor="logo" component="label">
                    Licensing Partner Logo
                  </FormLabel>
                </Grid>
                <Grid item xs={12} sm={5}>
                  {!isEmpty(logoImagePreview) ? (
                    <Box>
                      <img
                        src={logoImagePreview}
                        alt="Licensing Partner Logo"
                        height={150}
                        width={200}
                        style={{ maxWidth: '100%', objectFit: 'contain' }}
                      />
                    </Box>
                  ) : (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height={150}
                      width={200}
                      border="1px solid black"
                      borderRadius="4px"
                    >
                      <Typography variant="body1" color="textSecondary">
                        No image available
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12} md={12}>
              <Box display="flex" justifyContent="space-between">
                <TextButton type="button" color="error" onClick={() => navigate(-1)}>
                  Cancel
                </TextButton>
                <Button
                  ref={submitButtonRef}
                  type="submit"
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                >
                  {isAddMode ? 'Create New Licensing Partner' : 'Update Licensing Partner'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </FormContainer>
  );
}

export default compose(connect(mapStateToProps))(LicensingPartnerForm);

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    retries: ownProps.isAddMode
      ? state.licensingPartners.create.retries
      : state.licensingPartners.update.retries,
    isCreated: state.licensingPartners.create.isCreated,
  };
}

LicensingPartnerForm.propTypes = {
  isAddMode: PropTypes.bool.isRequired,
  licensingPartner: PropTypes.object,
  retries: PropTypes.number,
  isCreated: PropTypes.bool,
};
