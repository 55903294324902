import Grid from '@mui/material/Grid';
import React from 'react';
import Skeleton from 'core/skeleton/Skeleton';
import FormContainer from 'core/forms/FormContainer';

import range from 'helpers/range';

export default function LicensingPartnerFormSkeleton() {
  return (
    <FormContainer>
      <Grid container rowSpacing={3} columnSpacing={4}>
        {range(1, 3).map((rowIndex) => (
          <React.Fragment key={rowIndex}>
            <Grid item xs={12} md={6}>
              <Skeleton sx={{ height: '40px' }} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Skeleton sx={{ height: '40px' }} />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </FormContainer>
  );
}
