import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import FallbackImg from '../../assets/images/FallbackImage.png';

const FallbackImageLoader = ({ imageUrl }) => {
  const [imageSrc, setImageSrc] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    const img = new Image();

    img.src = imageUrl;

    img.onload = () => {
      setImageSrc(imageUrl);
      setError(false);
    };

    img.onerror = () => {
      setImageSrc(FallbackImg);
      setError(true);
    };

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [imageUrl]);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={imageSrc || FallbackImg}
          height={80}
          width={80}
          style={{
            objectFit: 'contain',
          }}
          alt={error ? 'Image not found' : 'Loaded Image'}
        />
      </Box>
    </Box>
  );
};

export default FallbackImageLoader;
