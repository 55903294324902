import { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import Inputmask from 'inputmask';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useFormContext, get } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import FormSelectCountry from 'core/forms/FormSelectCountry';

import FilterType from 'app/config/FilterType';
import DateUtils from 'utils/DateUtils';

export default function BuildFilterInputValue({ filter, filterByKey }) {
  const refDOB = useRef(null);

  const [openMultiSelectOption, setOpenMultiSelectOption] = useState(false);

  const handleCloseMultiSelectOption = () => setOpenMultiSelectOption(false);

  const handleOpenMultiSelectOption = () => setOpenMultiSelectOption(true);

  const {
    register,
    getValues,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const inputId = 'input_' + filter.value;

  const value = watch(filter.value);

  useEffect(() => {
    if (refDOB.current) {
      Inputmask({ mask: '9999-99-99' }).mask(refDOB.current);
    }
  }, [refDOB.current]);

  /**
   * ---------------------------------------------
   * Select
   * ---------------------------------------------
   *
   * Note: options is required for select type filter
   * @example
   * {
   *    label: 'KYC Type',
   *    value: 'kyc_type',
   *    type: 'select',
   *    options: [
   *      {
   *        label: 'Individual',
   *        value: 'individual',
   *      },
   *      {
   *        label: 'Company',
   *        value: 'company',
   *       },
   *    ],
   * }
   *
   */
  if (filter?.type === FilterType.select) {
    if (filter?.options === undefined && !Array.isArray(filter?.options))
      throw new Error(
        'options is required and must be array of object {label: "Label, value: "Value"} for select type filter',
      );

    return (
      <FormControl fullWidth size="small">
        <InputLabel id={inputId}>{filter.label}</InputLabel>
        <Select
          labelId={inputId}
          label={filter.label}
          fullWidth
          inputProps={{ ...register(filter.value) }}
          value={value ?? ''}
        >
          {filter.options.map((option, key) => (
            <MenuItem key={key} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  /**
   * ---------------------------------------------
   * Multi Select
   * ---------------------------------------------
   *
   * Note: options is required for select type filter
   * @example
   * {
   *    label: 'KYC Type',
   *    value: 'kyc_type',
   *    type: 'select',
   *    options: [
   *      {
   *        label: 'Individual',
   *        value: 'individual',
   *      },
   *      {
   *        label: 'Company',
   *        value: 'company',
   *       },
   *    ],
   * }
   *
   */
  if (filter?.type === FilterType.multiSelect) {
    if (filter?.options === undefined && !Array.isArray(filter?.options))
      throw new Error(
        'options is required and must be array of object {label: "Label, value: "Value"} for select type filter',
      );

    return (
      <FormControl fullWidth size="small">
        <InputLabel id={inputId}>{filter.label}</InputLabel>
        <Select
          labelId={inputId}
          multiple
          open={openMultiSelectOption}
          onClose={handleCloseMultiSelectOption}
          onOpen={handleOpenMultiSelectOption}
          label={filter.label}
          displayEmpty
          value={!Array.isArray(value) ? [] : value}
          onChange={(e) => {
            const value = e.target.value;
            setValue(filter.value, typeof value === 'string' ? value.split(',') : value);
          }}
          input={<OutlinedInput label={filter.label} />}
          renderValue={(selected) => {
            // if (selected.length === 0) {
            //   return <em>Choose</em>;
            // }

            if (Array.isArray(selected)) {
              return selected.join(', ');
            }

            return [].join(', ');
          }}
          InputProps={{ 'aria-label': 'Without label' }}
          fullWidth
        >
          <Box sx={{ p: 1 }}></Box>
          <Box display="flex" justifyContent="flex-end" px={2}>
            <Button
              startIcon={<CheckIcon />}
              variant="contained"
              color="primary"
              size="small"
              disableElevation
              onClick={handleCloseMultiSelectOption}
            >
              OK
            </Button>
            {/* <Button
              startIcon={<CloseIcon />}
              variant="contained"
              color="error"
              disableElevation
              size="small"
              onClick={() => {
                setValue(filter.value, undefined);
                handleCloseMultiSelectOption();
              }}
            >
              Clear
            </Button> */}
          </Box>
          <Box sx={{ p: 1 }}></Box>
          <Divider />
          {filter.options.map((option, key) => (
            <MenuItem key={key} value={option.value}>
              <Checkbox
                checked={value ? value?.findIndex((v) => v === option.value) > -1 : false}
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  /**
   * ---------------------------------------------
   * Input
   * ---------------------------------------------
   */
  if (filter?.type === FilterType.input) {
    return (
      <FormControl fullWidth variant="outlined" size="small">
        <InputLabel htmlFor={inputId}>{filter.label}</InputLabel>
        <OutlinedInput
          id={inputId}
          label={filter.label}
          size="small"
          inputProps={{ ...register(filter.value) }}
        />
      </FormControl>
    );
  }

  /**
   * ---------------------------------------------
   * Search with Search Icon
   * ---------------------------------------------
   */
  if (filter?.type === FilterType.search) {
    return (
      <FormControl fullWidth variant="outlined" size="small">
        <InputLabel htmlFor={inputId}>{filter.label}</InputLabel>
        <OutlinedInput
          startAdornment={<SearchIcon />}
          id={inputId}
          label={filter.label}
          size="small"
          inputProps={{ ...register(filter.value) }}
          placeholder="Search"
        />
      </FormControl>
    );
  }

  /**
   * ---------------------------------------------
   * Country
   * ---------------------------------------------
   */
  if (filter?.type === FilterType.selectCountry) {
    return (
      <FormSelectCountry
        id="select-country-filter"
        name="country_id"
        label={filter.label}
        onSelected={(value) => setValue(filter.value, value?.iso3 ?? '')}
        InputProps={{ ...register(filter.value) }}
      />
    );
  }

  /**
   * ---------------------------------------------
   * Datepicker
   * ---------------------------------------------
   */
  if (filter?.type === FilterType.datepicker) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label={filter.label}
          value={value ? dayjs(value) : null}
          onChange={(newValue) => {
            let date = dayjs(newValue).format('YYYY-MM-DD');
            if (filter?.props?.withStartDayTimezone) {
              date = DateUtils.getFromDate(date);
            } else if (filter?.props?.withEndDayTimezone) {
              date = DateUtils.getToDate(date);
            }
            setValue(filter.value, date);
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              label={filter.label}
              {...params}
              error={!!get(errors, filter.value)?.message}
              helperText={get(errors, filter.value)?.message}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  /**
   * ---------------------------------------------
   * Epoch timestamp picker
   * ---------------------------------------------
   */
  if (filter?.type === FilterType.epochTimestampPicker) {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          label={filter.label}
          value={value ? dayjs.unix(value) : ''}
          onChange={(newValue) => {
            if (newValue) {
              setValue(filter.value, dayjs(newValue).unix());
            } else {
              setValue(filter.value, '');
            }
          }}
          renderInput={(params) => (
            <TextField size="small" label={filter.label} {...params} error={false} />
          )}
        />
      </LocalizationProvider>
    );
  }
}

BuildFilterInputValue.propTypes = {
  filter: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  }).isRequired,
  filterByKey: PropTypes.string,
};
