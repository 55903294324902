import { useSearchParams } from 'react-router-dom';
import qs from 'qs';

import { history } from 'routes';

/**
 * React Hooks
 *
 * required - react-router-dom
 *
 * mapping the query params to the state
 * or
 * mapping the state to the query params
 *
 *
 */
const useQueryParams = (ignoreKeys = null) => {
  /**
   * NOTE: For React Router below v6
   */
  // const { pathname, search } = useLocation();

  if (ignoreKeys !== null && (!Array.isArray(ignoreKeys) || typeof ignoreKeys === 'string'))
    throw new Error(`${ignoreKeys} must be a string or an array`);

  // let [searchParams, setSearchParams] = useSearchParams();

  let [searchParams] = useSearchParams();

  // NOTE: this code cannot parse array values
  // const params = searchParams.toString()
  //   ? // converting the query string to object
  //     JSON.parse(
  //       '{"' +
  //         decodeURI(searchParams.toString())
  //           .replace(/"/g, '\\"')
  //           .replace(/&/g, '","')
  //           .replace(/=/g, '":"') +
  //         '"}',
  //     )
  //   : {};

  const params = qs.parse(searchParams.toString());

  /**
   * update a query params in the url
   *
   * @param {Object} updatedParams
   * @param {String|Object} ignoreKeys
   */
  const updateQuery = (updatedParams) => {
    if (Object.prototype.toString.call(updatedParams) !== '[object Object]')
      throw new Error(`${updatedParams} must be an object`);

    // remove blank attributes from object
    updatedParams = Object.keys(updatedParams).reduce((acc, key) => {
      if (
        !['', null, undefined].includes(updatedParams[key]) &&
        ![...(typeof ignoreKeys === 'string' ? [ignoreKeys] : ignoreKeys ?? [])].includes(key)
      ) {
        if (Array.isArray(updatedParams[key])) {
          acc[key] = updatedParams[key];
        } else {
          acc[key] = decodeURIComponent(updatedParams[key]);
        }
      }
      return acc;
    }, {});

    /**
     * NOTE: For React Router below v6
     */

    /**
     * NOTE : react hook useSearchParams
     *
     * setSearchParams is converting space to %2B in url
     * so it didn't work  for mapping a query params to state
     * or state to query params
     */
    // setSearchParams(updatedParams);

    // so, solution for react-router below v6 is used
    Object.assign(params, updatedParams);
    history.replace(window.location.pathname + '?' + qs.stringify(updatedParams));
  };

  return [params, updateQuery];
};

export default useQueryParams;
