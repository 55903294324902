import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Toolbar from '@mui/material/Toolbar';
import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';

import AccountMenu from 'components/layouts/AccountMenu';

import app from 'app/config/app';
import { setIsDrawerOpen } from 'app/features/app/appSlice';
import { localStorageSave } from 'helpers/localStorage';
import constants from 'app/config/constants';

function Topbar({ drawerWidth, children }) {
  const dispatch = useDispatch();

  const { isDrawerOpen: open } = useSelector((state) => state.app);

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const handleDrawerToggle = () => {
    localStorageSave(constants.IS_DRAWER_OPEN, (!open).toString());
    dispatch(setIsDrawerOpen(!open));
  };

  return (
    <AppBar position="fixed" open={open} elevation={0}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Box>
            {open && (
              <MenuIcon sx={{ '&:hover': { cursor: 'pointer' } }} onClick={handleDrawerToggle} />
            )}
          </Box>

          <Box display="flex" gap="6">
            <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={0} color="warning">
                <MailIcon />
              </Badge>
            </IconButton>
            <AccountMenu />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Topbar;

Topbar.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
  open: PropTypes.bool,
  onDrawerOpen: PropTypes.func,
  drawerWidth: PropTypes.number,
};

Topbar.defaultProps = {
  children: null,
  open: false,
  onDrawerOpen: () => {},
  drawerWidth: app.drawerWith,
};
