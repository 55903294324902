import React from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import HookForm from 'hook-form-controls/HookForm';
import CancelButton from 'core/buttons/CancelButton';
import SubmitButton from 'core/buttons/SubmitButton';
import FormTextField from 'hook-form-controls/FormTextField';

import { transactionStatuses } from 'app/config/TransactionStatus';

const validationSchema = Yup.object().shape({
  status: Yup.string().required('Status is required'),
  remarks: Yup.string().required('Remarks is required'),
});

export default function ApproveTransactionForm({ onSubmit, onCancel }) {
  const { isLoading } = useSelector((state) => state.transactions.approve);

  const methods = useForm({
    defaultValues: {
      status: transactionStatuses.completed,
    },
    resolver: yupResolver(validationSchema),
  });

  return (
    <HookForm {...methods} onSubmit={onSubmit}>
      <Grid container gap={3}>
        <Grid item xs={12}>
          <Alert severity="warning">
            Make sure you have a valid reason for approving the transaction. This action cannot be
            undone.
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="transaction_reference_number"
            label="Transaction/Reference ID"
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextField name="remarks" label="Remarks" multiline />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <SubmitButton type="submit" isLoading={isLoading}>
              Submit
            </SubmitButton>
            <CancelButton type="button" onClick={onCancel} disabled={isLoading}>
              Cancel
            </CancelButton>
          </Box>
        </Grid>
      </Grid>
    </HookForm>
  );
}

ApproveTransactionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
