import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countries: {
    data: [],
    isLoading: false
  }
};
const constantSlice = createSlice({
  name: 'constants',
  initialState,
  reducers: {
    setCountriesData: (state, { payload }) => {
      state.countries.data = payload ?? [];
    },
    setCountriesIsLoading: (state, { payload }) => {
      state.countries.isLoading = payload;
    }
  }
});

export default constantSlice.reducer;

export const { setCountriesData, setCountriesIsLoading } = constantSlice.actions;
