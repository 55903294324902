import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import withRole from 'hoc/withRole';

import { resetGetFundingSourceInfoFailure } from 'app/features/funding-sources-info/fundingSourceInfoSlice';
import FundingSourceInfo from 'components/Funding-source-info/FundingSourceInfo';

function ViewFundingSourceInfo({ isNetworkError }) {
  const params = useParams();

  const { isError, errorCode } = useSelector((state) => state.fundingSourcesInfo.show);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetFundingSourceInfoFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error status={errorCode || 0} onRetry={() => dispatch(resetGetFundingSourceInfoFailure())} />
    );

  return (
    <PageContent
      title="Partner Funding Source Information Details"
      rightTopEndContent={
        <Button
          onClick={() =>
            navigate(buildRoute('funding-sources-info.edit', params.fundingSourceInfoId))
          }
          color="primary"
          startIcon={<EditIcon />}
        >
          Edit Partner Funding Source Info
        </Button>
      }
    >
      <FundingSourceInfo fundingSourceId={params.fundingSourceInfoId} />
    </PageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ViewFundingSourceInfo);
