import React from 'react';
import PropTypes from 'prop-types';
import Customers from 'components/customers/Customers';
import CustomerFilterValues from 'components/customers/CustomerFilterValues';
import CustomerBriefDetailsModal from 'components/customers/CustomerBriefDetailsModal';

export default function PartnerCustomersTab({ partnerId }) {
  return (
    <>
      <CustomerFilterValues />
      <Customers partnerId={partnerId} preFilterByPartner={true} />
      <CustomerBriefDetailsModal />
    </>
  );
}

PartnerCustomersTab.propTypes = {
  partnerId: PropTypes.string.isRequired,
};
