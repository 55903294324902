import { createSlice } from '@reduxjs/toolkit';

import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import Response from 'app/config/Response';

const initialState = {
  index: {
    data: [],
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc',
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    success: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
  show: {
    showBriefDetailModal: false,
    beneficiaryId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
};

export const beneficiarySlice = createSlice({
  name: 'beneficiaries',
  initialState,
  reducers: {
    getBeneficiariesInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        success: false,
        errorMessage: null,
      };
    },

    getBeneficiariesSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getBeneficiariesFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetBeneficiariesFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getBeneficiaryReportDownloadInit: (state) => {
      state.index.isLoading = false;
      state.index.isError = false;
      state.index.errorCode = null;
      state.index.errorMessage = null;
      state.index.success = false;
    },

    getBeneficiaryReportDownloadSuccess: (state, { payload }) => {
      let { data } = payload;

      state.index = {
        ...state.index,
        isLoading: false,
        success: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
      };
    },

    getBeneficiaryReportDownloadFailure: (state, { payload }) => {
      state.index.isLoading = false;
      state.index.isError = true;
      state.index.errorCode = payload?.response?.status ?? 0;
      state.index.errorMessage = payload?.response?.message;
    },

    resetBeneficiaryReportDownload: (state) => {
      state.index.isLoading = false;
      state.index.isError = false;
      state.index.errorCode = null;
      state.index.errorMessage = null;
      state.index.success = false;
    },

    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParam: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    getBeneficiaryInit: (state) => {
      state.show = {
        ...state.show,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getBeneficiarySuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          errorMessage: 'Beneficiary not found',
          data: null,
        };
      } else {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: false,
          errorCode: null,
          errorMessage: null,
          data: payload.data,
        };
      }
    },

    getBeneficiaryFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: null,
      };
    },

    resetGetBeneficiaryFailure: (state) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getBeneficiaryBriefDetailsInit: (state, { payload }) => {
      state.show = {
        ...state.show,
        showBriefDetailModal: payload.showBriefDetailModal,
        beneficiaryId: payload.beneficiaryId,
      };
    },

    hideBeneficiaryBriefDetails: (state) => {
      state.show = {
        showBriefDetailModal: false,
        beneficiaryId: null,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },
  },
});

export const {
  getBeneficiariesInit,
  getBeneficiariesSuccess,
  getBeneficiariesFailure,
  resetGetBeneficiariesFailure,
  setParam,
  resetParam,
  deleteFilterParams,
  setShowCreateProcessingModal,
  setShowEditProcessingModal,
  getBeneficiaryInit,
  getBeneficiarySuccess,
  getBeneficiaryFailure,
  resetGetBeneficiaryFailure,
  getBeneficiaryBriefDetailsInit,
  hideBeneficiaryBriefDetails,
  getBeneficiaryReportDownloadInit,
  getBeneficiaryReportDownloadSuccess,
  getBeneficiaryReportDownloadFailure,
  resetBeneficiaryReportDownload,
} = beneficiarySlice.actions;

export default beneficiarySlice.reducer;
