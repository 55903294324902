import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function ActiveBadge({ isActive, onClick, tooltipTitle, tooltipPlacement, size }) {
  return (
    <>
      {tooltipTitle ? (
        <>
          <Tooltip title={tooltipTitle} placement={tooltipPlacement} arrow>
            <IconButton onClick={onClick}>
              {isActive ? (
                <CheckCircleIcon fontSize={size} color="success" />
              ) : (
                <CancelIcon fontSize={size} color="error" />
              )}
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          {isActive ? (
            <CheckCircleIcon fontSize={size} color="success" />
          ) : (
            <CancelIcon fontSize={size} color="error" />
          )}
        </>
      )}
    </>
  );
}

ActiveBadge.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  tooltipTitle: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  size: PropTypes.oneOf(['inherit', 'large', 'medium', 'small']),
};

ActiveBadge.defaultProps = {
  isActive: false,
  tooltipPlacement: 'top',
  size: 'small',
};
