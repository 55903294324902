import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';

import { buildRoute } from 'routes';
import { getPartner } from 'app/features/partners/partnerSaga';
import { hidePartnerBriefDetails } from 'app/features/partners/partnerSlice';
import Button from 'core/buttons/Button';

function PartnerBriefDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const viewData = useMemo(
    () => [
      {
        label: 'Partner Name',
        data: 'name',
      },
      {
        label: 'Postal Code',
        data: 'post_code',
      },
      {
        label: 'Address',
        data: 'address',
      },
      {
        label: 'City',
        data: 'city',
      },
      {
        label: 'State',
        data: 'state',
      },
      {
        label: 'Country',
        data: 'country',
      },
      {
        label: 'Currency',
        data: 'currency',
      },
      {
        label: 'Name',
        data: 'cnt_name',
      },
      {
        label: 'Email',
        data: 'cnt_email',
      },
      {
        label: 'Phone Number',
        data: 'cnt_phone_number',
      },
      {
        label: 'Mobile Number',
        data: 'cnt_mobile_number',
      },
    ],
    [],
  );

  const state = useSelector((state) => state.partners.show);

  const { showBriefDetailModal, partnerId, isLoading, data, isError } = state;

  useEffect(() => {
    if (partnerId) dispatch(getPartner(partnerId));
  }, [partnerId]);

  if (!showBriefDetailModal && !partnerId) return <></>;

  return (
    <SidebarBriefModal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hidePartnerBriefDetails())}
      viewData={viewData}
      item={data}
      isLoading={isLoading}
      title="Partner Details"
      onClickView={() => (
        dispatch(hidePartnerBriefDetails()), navigate(buildRoute('partners.show', partnerId))
      )}
    ></SidebarBriefModal>
  );
}

export default PartnerBriefDetailsModal;
