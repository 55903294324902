import './App.css';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

import Error from 'core/errors/Error';
import AppLayout from 'layouts/AppLayout';
import AuthLayout from 'layouts/AuthLayout';
import GuestRoutes from 'routes/GuestRoutes';
import ProtectedRoutes from 'routes/ProtectedRoutes';
import ScrollToTop from 'core/scroll-to-top/ScrollToTop';

import store from 'app/store';
import Response from 'app/config/Response';
import defaultTheme from 'app/themes/defaultTheme';
import { ConfirmProvider } from 'core/mui-confirm';
import { SnackbarUtilsConfigurator } from 'utils/Notify';
import { GUEST_ROUTES, PROTECTED_ROUTES, history } from 'routes';
import NoInternetConnection from 'components/app/NoInternetConnection';

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Provider store={store}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={1000}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <CssBaseline />
          <SnackbarUtilsConfigurator />
          <ConfirmProvider>
            <Router history={history}>
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<GuestRoutes />}>
                  <Route path="/" element={<AuthLayout />}>
                    {Object.entries(GUEST_ROUTES).map(([index, route]) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          index={route.index}
                          element={route.component}
                        />
                      );
                    })}
                  </Route>
                </Route>
                <Route path="/" element={<ProtectedRoutes />}>
                  <Route path="/" element={<AppLayout />}>
                    {Object.entries(PROTECTED_ROUTES).map(([index, route]) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          index={route.index}
                          element={route.component}
                        />
                      );
                    })}
                  </Route>
                </Route>
                <Route
                  path="*"
                  element={<Error status={Response.HTTP_NOT_FOUND} withInDashboard={false} />}
                />
              </Routes>
            </Router>
            <NoInternetConnection />
          </ConfirmProvider>
        </SnackbarProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
