import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import Customers from 'components/customers/Customers';
// import CustomerFilterValues from 'components/customers/CustomerFilterValues';
import { resetGetCustomersFailure } from 'app/features/customers/customerSlice';
import CustomerBriefDetailsModal from 'components/customers/CustomerBriefDetailsModal';

import acl from 'app/config/acl';

function ListCustomer({ isNetworkError }) {
  const customerState = useSelector((state) => state.customers.index);

  const { isError, errorCode } = customerState;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetCustomersFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetCustomersFailure())}
      />
    );

  return (
    <PageContent title="Customers">
      {/* <CustomerFilterValues /> */}
      <Customers />
      <CustomerBriefDetailsModal />
    </PageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]))(ListCustomer);
