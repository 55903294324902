import PropTypes from 'prop-types';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

function FormSectionTitle({ enableDivider, children, ...rest }) {
  return (
    <div>
      <Typography
        variant="body1"
        sx={{
          fontWeight: 600,
          lineHeight: '26.5px'
        }}
        {...rest}
      >
        {children}
      </Typography>
      {enableDivider && <Divider light />}
    </div>
  );
}

export default FormSectionTitle;

FormSectionTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func
  ]),
  enableDivider: PropTypes.bool
};

FormSectionTitle.defaultProps = {
  enableDivider: false
};
