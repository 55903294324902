import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import MoreActions from 'core/lab/MoreActions';
import FilterPopup from 'core/table/FilterPopup';
import ReloadButton from 'core/buttons/ReloadButton';
import ColumnVisibility from 'core/table/ColumnVisibility';
import TableActionsContainer from 'core/table/TableActionsContainer';

import Sheet from 'utils/Sheet';
import app from 'app/config/app';
import ucwords from 'helpers/ucwords';
import useQueryParams from 'hooks/useQueryParams';
import FinmoPayoutStatus from 'app/config/FinmoPayoutStatus';
import { getFinmoPayouts } from 'app/features/finmo-payouts/finmoPayoutSaga';
import { setParam, deleteFilterParams } from 'app/features/finmo-payouts/finmoPayoutSlice';
import FilterType from 'app/config/FilterType';
import TableGridFilter from 'core/table/TableGridFilter';
import Button from 'core/buttons/Button';

export default function FinmoPayoutsActions({ table }) {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const finmoPayoutState = useSelector((state) => state.finmoPayouts.index);

  const [queryParams, updateQueryParams] = useQueryParams(app.ignoreFilterKeys);

  let availableFilters = [
    {
      label: 'Status',
      value: 'status',
      type: FilterType.select,
      options: Object.entries(FinmoPayoutStatus).map(([key, value]) => ({
        label: ucwords(value),
        value: value,
      })),
    },
    {
      label: 'Payout Method Name',
      value: 'payout_method_name',
      type: FilterType.input,
    },
    {
      label: 'Created At',
      value: 'created_at',
      type: FilterType.datepicker,
    },
    {
      label: 'Start Time',
      value: 'start_time',
      type: FilterType.epochTimestampPicker,
    },
    {
      label: 'End Time',
      value: 'end_time',
      type: FilterType.epochTimestampPicker,
    },
  ];

  const { params: appliedFilters } = finmoPayoutState;

  const handleOnFilter = useCallback((data) => {
    dispatch(setParam({ ...data, page: 1 }));
  }, []);

  const handleOnReset = useCallback((e) => {
    dispatch(deleteFilterParams([...availableFilters.map((filter) => filter.value)]));
  });

  useEffect(() => {
    updateQueryParams(appliedFilters);
  }, [JSON.stringify(appliedFilters)]);

  useEffect(() => {
    dispatch(setParam(queryParams));
  }, [JSON.stringify(queryParams)]);

  return (
    <>
      <Box>
        <TableGridFilter
          showFilter={showFilter}
          appliedFilters={appliedFilters}
          availableFilters={availableFilters}
          onFilter={handleOnFilter}
          onReset={handleOnReset}
        />
      </Box>
      <TableActionsContainer>
        <Box></Box>
        <Box display="flex" gap={1}>
          <Button
            variant="outlined"
            endIcon={<FilterAltIcon />}
            onClick={() => setShowFilter(!showFilter)}
            uppercase
          >
            Filter
          </Button>

          {table && <ColumnVisibility table={table} />}
          <ReloadButton
            onClick={() => {
              dispatch(
                getFinmoPayouts({
                  ...appliedFilters,
                  page: 1,
                }),
              );
            }}
          />
          <MoreActions
            onExportToCsv={() => Sheet.exportReactTableSelectedRowsToCsv(table, 'finmo_payouts_')}
            onExportToExcel={() =>
              Sheet.exportReactTableSelectedRowsToExcel(table, 'finmo_payouts_')
            }
          />
        </Box>
      </TableActionsContainer>
    </>
  );
}

FinmoPayoutsActions.propTypes = {
  table: PropTypes.object,
};
