import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';

import range from 'helpers/range';
import Spacer from 'core/spacer/Spacer';
import Skeleton from 'core/skeleton/Skeleton';
import FormContainer from 'core/forms/FormContainer';

import steps from './data/partnerFormSteps';

export default function PartnerFormSkeleton() {
  return (
    <FormContainer>
      <Box sx={{ width: '100%' }}>
        <Stepper>
          {steps.map((step, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={step.label} {...stepProps}>
                <StepLabel {...labelProps}>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Spacer height={2} />

        <Grid container rowSpacing={3} columnSpacing={4}>
          {range(0, 8).map((i) => (
            <Grid key={i} item sm={12} md={6}>
              <Skeleton sx={{ height: '40px' }} />
            </Grid>
          ))}
        </Grid>
        <Spacer />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
          <Skeleton sx={{ height: '32px', width: '100px' }} />
          <Skeleton sx={{ height: '32px', width: '100px' }} />
        </Box>
      </Box>
    </FormContainer>
  );
}
