export const ZaiWebhookStatus = {
  Not_Started: 'n',
  Verified: 'v',
  Item_Created: 'i',
  Completed: 'c',
  Refunded: 'r',
};

export const ZaiWebhookStatusOptions = [
  {
    value: ZaiWebhookStatus.Not_Started,
    label: 'Not Started',
  },
  {
    value: ZaiWebhookStatus.Verified,
    label: 'Verified',
  },
  {
    value: ZaiWebhookStatus.Item_Created,
    label: 'Item Created',
  },
  {
    value: ZaiWebhookStatus.Completed,
    label: 'Completed',
  },
  {
    value: ZaiWebhookStatus.Refunded,
    label: 'Refunded',
  },
];
