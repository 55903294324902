import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { useSelector, useDispatch, connect } from 'react-redux';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import Error from 'core/errors/Error';
import ApiConfigsActions from './ApiConfigsActions';
import ActiveBadge from 'core/badges/ActiveBadge';
import FormCheckbox from 'core/forms/FormCheckbox';
import ApiConfigPagination from './ApiConfigPagination';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import TableContainer from 'core/table/TableContainer';
import TanstackReactTable from 'core/table/TanstackReactTable';

import { buildRoute } from 'routes';
import withAppState from 'hoc/withAppState';
import constants from 'app/config/constants';
import Dimensions from 'app/config/Dimensions';
import { getApiConfigs } from 'app/features/api-configs/apiConfigSaga';
import { getApiConfigBriefDetailsInit } from 'app/features/api-configs/apiConfigSlice';

function ApiConfigs() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const apiConfigState = useSelector((state) => state.apiConfigs.index);

  const { data, params, isLoading, isError, errorCode } = apiConfigState;

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        id: 'api_partner_code',
        header: 'Partner Code',
        accessorFn: (row) => row.api_partner_code,
      },
      {
        id: 'api_url',
        header: 'API URL',
        accessorFn: (row) => row.api_url,
        isVisible: true,
      },
      {
        id: 'api_user_id',
        header: 'User ID',
        accessorFn: (row) => row.api_user_id,
        isVisible: true,
      },
      {
        id: 'ref1',
        header: 'Ref1',
        accessorFn: (row) => row.ref1,
        isVisible: false,
      },
      {
        id: 'ref2',
        header: 'Ref2',
        accessorFn: (row) => row.ref2,
        isVisible: false,
      },
      {
        id: 'ref3',
        header: 'Ref3',
        accessorFn: (row) => row.ref3,
        isVisible: false,
      },

      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.config_id,
        cell: (info) => (
          <Box display="flex" gap={1}>
            <IconButton
              size={Dimensions.table.iconButton.size}
              onClick={() => {
                dispatch(
                  getApiConfigBriefDetailsInit({
                    showBriefDetailModal: true,
                    apiConfigId: info.getValue(),
                  }),
                );
              }}
            >
              <AspectRatioIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
            <IconButton
              size={Dimensions.table.iconButton.size}
              onClick={() => navigate(buildRoute('api-configs.show', info.getValue()))}
            >
              <FullscreenIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
          </Box>
        ),
      },
    ],
    [],
  );

  const [columnVisibility, setColumnVisibility] = useState(
    columns
      .filter((c) => c?.isVisible === false)
      .map((c) => c.id)
      .reduce((a, k) => ({ ...a, [k]: false }), {}),
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(getApiConfigs({ ...params }));
  }, [JSON.stringify(params)]);

  if (isError) return <Error status={errorCode} />;

  return (
    <TableContainer>
      <ApiConfigsActions table={table} />
      <TanstackReactTable
        table={table}
        isLoading={isLoading}
        data={data}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <ApiConfigPagination />
    </TableContainer>
  );
}

export default withAppState(ApiConfigs);
