import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import styled from '@mui/material/styles/styled';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import ContentCopyTwoToneIcon from '@mui/icons-material/ContentCopyTwoTone';

import SpringModal from 'core/modal/SpringModal';

import Notify from 'utils/Notify';
import isEmpty from 'helpers/isEmpty';
import Skeleton from 'core/skeleton/Skeleton';
import { useConfirm } from 'core/mui-confirm';
import useCopyTextToClipboard from 'hooks/useCopyTextToClipboard';
import { clearRecentlyCreatedCredentials } from 'app/features/partner-credentials/partnerCredentialSlice';

const CredentialContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  display: 'flex',
  color: theme.palette.grey[700],
  gap: 6,
  fontSize: '1rem',
  height: '80px',
  width: '100%'
}));

export default function PartnerCredentialRecentlyCreated() {
  const { data, isLoading } = useSelector((state) => state.partnerCredentials.create);

  if (!isLoading && isEmpty(data)) return <></>;

  const dispatch = useDispatch();

  const copyText = useCopyTextToClipboard();

  const confirm = useConfirm();

  const handleClose = () => {
    confirm({
      confirmationText: 'Yes, I copied already.',
      cancellationText: "No, I didn't",
      description: 'Have copied the credentials ?'
    })
      .then(() => dispatch(clearRecentlyCreatedCredentials()))
      .catch((err) => Notify.warning('Please copy your credentials'));
  };

  return (
    <SpringModal
      isOpen={true}
      enableMaximizeButton={false}
      enableDefaultPadding={true}
      onClose={handleClose}
      header={isLoading ? 'Generating new credentials' : 'Copy your credentials'}
      subtitle={isLoading ? '' : 'Please keep safe you credentials.'}
    >
      {isLoading ? (
        <Skeleton
          sx={{
            height: '80px',
            borderRadius: (theme) => theme.spacing(1)
          }}
        />
      ) : (
        <CredentialContainer>
          <Box>
            <blockquote>
              <pre>
                <code>
                  <b>Client ID:</b> <i>{data.client_id}</i> <br />
                  <b>Client Secret:</b> <i>{data.client_secret}</i>
                </code>
              </pre>
            </blockquote>
          </Box>
          <Box>
            <Tooltip title="Copy" placement="top" arrow>
              <IconButton
                onClick={() =>
                  copyText(`ClientID: ${data.client_id}, ClientSecret: ${data.client_secret}`)
                }
              >
                <ContentCopyTwoToneIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </CredentialContainer>
      )}
    </SpringModal>
  );
}
