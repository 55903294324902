import PropTypes from 'prop-types';

import CustomerKycFilterValues from 'components/customer-kycs/CustomerKycFilterValues';

import CustomerKycLogs from '../../../pages/customer-kyc-logs/CustomerKycLogs';

export default function CustomerKycLogsTab({ customer_id }) {
  return (
    <>
      <CustomerKycFilterValues />
      <CustomerKycLogs filterByCustomer={true} customer_id={customer_id} />
    </>
  );
}

CustomerKycLogsTab.propTypes = {
  customer_id: PropTypes.string.isRequired,
};
