import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import { useFormContext } from 'react-hook-form';

import FormCheckbox from 'hook-form-controls/FormCheckbox';
import FormTextField from 'hook-form-controls/FormTextField';
import FormSelectCountry from 'hook-form-controls/FormSelectCountry';

export default function BasicInformation() {
  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  return (
    <Grid container rowSpacing={3} columnSpacing={4}>
      <Grid item sm={12} md={6}>
        <FormTextField
          name="name"
          label="Name"
          error={!!errors?.name}
          helperText={errors?.name?.message ?? ''}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <FormTextField
          name="post_code"
          label="Post Code"
          error={!!errors?.post_code}
          helperText={errors?.post_code?.message ?? ''}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <FormTextField
          name="address"
          label="Address"
          error={!!errors?.address}
          helperText={errors?.address?.message ?? ''}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <FormTextField
          name="city"
          label="City"
          error={!!errors?.city}
          helperText={errors?.city?.message ?? ''}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <FormTextField
          name="state"
          label="State"
          error={!!errors?.state}
          helperText={errors?.state?.message ?? ''}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormSelectCountry
          id="select-country"
          name="country"
          error={!!errors?.country}
          helperText={errors?.country?.message ?? ''}
          onSelected={(c) => {
            setValue('currency', c?.currency ?? '');
          }}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <FormTextField
          name="currency"
          label="Currency"
          error={!!errors?.currency}
          helperText={errors?.currency?.message ?? ''}
          disabled={true}
        />
      </Grid>
      <Grid item sm={12} md={6}></Grid>
      <Grid item sm={12} md={6}>
        <FormCheckbox
          name="is_verified"
          label="Is Verified ?"
          error={!!errors?.is_verified}
          helperText={errors?.is_verified?.message ?? ''}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <FormCheckbox
          name="is_active"
          label="Is Active ?"
          error={!!errors?.is_active}
          helperText={errors?.is_active?.message ?? ''}
        />
      </Grid>
    </Grid>
  );
}

export const basicInformationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required').max(100),
  post_code: Yup.string().required('Post Code is required').max(10),
  address: Yup.string().required('Address is required').max(50),
  city: Yup.string().required('City is required').max(50),
  state: Yup.string().required('State is required').max(50),
  country: Yup.string().required('Country is required').max(50),
  currency: Yup.string().required('Currency is required').max(3),
  is_verified: Yup.boolean().required('Is partner verified or not ?'),
  is_active: Yup.boolean().required('Is partner active or not ?'),
});
