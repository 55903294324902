import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import GroupedTableView from 'core/table/GroupedTableView';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import Auth from 'utils/Auth';
import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import { getWebhook } from 'app/features/webhooks/webhookSaga';

export default function Webhook({ webhookId }) {
  const dispatch = useDispatch();

  const viewWebhookData = useMemo(
    () => [
      {
        groupName: '',
        groupData: [
          ...(Auth.isSuperAdmin()
            ? [
                {
                  label: 'Partner',
                  data: 'partner_id',
                  get: ({ partner_id, partner_name }) => (
                    <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
                  ),
                },
              ]
            : []),
          {
            label: 'URL',
            data: 'url',
          },
          {
            label: 'Object Type',
            data: 'object_type',
          },
          {
            label: 'Description',
            data: 'description',
          },
          {
            label: 'Is Active ?',
            data: 'is_active',
            get: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
          },
          {
            label: 'Is Verified ?',
            data: 'is_verified',
            get: ({ is_verified }) => <VerifiedBadge isVerified={!!is_verified} />,
          },
          {
            label: 'Created At',
            data: 'created_ts',
            get: ({ created_ts }) =>
              !isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '-',
          },
          {
            label: 'Created By',
            data: 'created_by',
          },
          {
            label: 'Updated At',
            data: 'updated_ts',
            get: ({ updated_ts }) =>
              !isEmpty(updated_ts) ? DateUtils.isoToString(updated_ts) : '-',
          },
          {
            label: 'Updated By',
            data: 'updated_by',
          },
        ],
      },
    ],
    [],
  );

  const {
    isError,
    errorCode,
    isLoading,
    data: webhook,
  } = useSelector((state) => state.webhooks.show);

  useEffect(() => {
    dispatch(getWebhook(webhookId));
  }, [webhookId]);

  return (
    <Grid container spacing={2}>
      {viewWebhookData.map((group, i) => (
        <GroupedTableView
          key={i}
          title={group.groupName}
          data={group.groupData}
          item={webhook}
          isLoading={isLoading}
        />
      ))}
    </Grid>
  );
}

Webhook.propTypes = {
  webhookId: PropTypes.string,
};
