import { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';

import Error from 'core/errors/Error';
import KycStatus from 'core/badges/KycStatus';
import CustomersActions from './CustomersActions';
import ActiveBadge from 'core/badges/ActiveBadge';
import FormCheckbox from 'core/forms/FormCheckbox';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import TableContainer from 'core/table/TableContainer';
import CustomersPagination from './CustomersPagination';
import TanstackReactTable from 'core/table/TanstackReactTable';

import Auth from 'utils/Auth';
import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import constants from 'app/config/constants';
import useCountries from 'hooks/useCountries';
import Dimensions from 'app/config/Dimensions';
import { getCustomers } from 'app/features/customers/customerSaga';
import { getCustomerBriefDetailsInit, setPartnerId } from 'app/features/customers/customerSlice';

export default function Customers({ preFilterByPartner, partnerId }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const customersState = useSelector((state) => state.customers.index);

  const { getCountryNameByIso3Code, countries } = useCountries();

  const {
    params,
    isError,
    data,
    isLoading,
    errorCode,
    partnerId: selectedPartnerIdFromSelect,
  } = customersState;

  const [columnVisibility, setColumnVisibility] = useState({});

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      ...(Auth.isSuperAdmin()
        ? [
            {
              header: 'Partner',
              accessorFn: (row) => row.partner_name,
            },
          ]
        : []),
      {
        header: 'Name',
        accessorFn: (row) => row.id,
        cell: ({ row }) => row.original.first_name + ' ' + row.original.last_name,
      },
      {
        header: 'Email',
        accessorFn: (row) => row.email,
      },
      {
        header: 'Mobile Number',
        accessorFn: (row) => row.mobile_number,
      },
      {
        header: 'KYC Status',
        accessorFn: (row) => row.kyc_status,
        cell: (info) => {
          return <KycStatus status={info.getValue()} />;
        },
      },
      {
        header: 'Nationality',
        accessorFn: (row) => row.nationality,
        cell: (row) => {
          return row.getValue() ? row.getValue() : 'N/A';
        },
      },
      {
        header: 'Country',
        accessorFn: (row) => getCountryNameByIso3Code(row.country),
      },
      {
        header: 'Is Verified ?',
        accessorFn: (row) => row.is_verified,
        cell: (info) => {
          return <VerifiedBadge isVerified={!!info.getValue()} />;
        },
      },
      {
        header: 'Is Active ?',
        accessorFn: (row) => row.is_active,
        cell: (info) => <ActiveBadge isActive={!!info.getValue()} />,
      },

      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,
        cell: (info) => (
          <Box display="flex" gap={1}>
            <IconButton
              size={Dimensions.table.iconButton.size}
              onClick={() => {
                dispatch(
                  getCustomerBriefDetailsInit({
                    showBriefDetailModal: true,
                    customerId: info.getValue(),
                  }),
                );
              }}
            >
              <AspectRatioIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
            <IconButton
              size={Dimensions.table.iconButton.size}
              onClick={() => navigate(buildRoute('customers.show', info.getValue()))}
            >
              <FullscreenIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
          </Box>
        ),
      },
    ],
    [JSON.stringify(countries)],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(
      getCustomers({
        ...params,
        ...(preFilterByPartner
          ? {
              partner_id: partnerId,
            }
          : {
              partner_id: selectedPartnerIdFromSelect,
            }),
      }),
    );
  }, [JSON.stringify(params), partnerId, selectedPartnerIdFromSelect]);

  useEffect(() => {
    if (!Auth.isSuperAdmin()) dispatch(setPartnerId(Auth.getPartnerId()));
  }, []);

  if (isError) return <Error status={errorCode} />;

  return (
    <TableContainer>
      <CustomersActions
        table={table}
        enableFilterByPartner={isEmpty(partnerId)}
        defaultFilterQueries={{
          ...(preFilterByPartner
            ? {
                partner_id: partnerId,
              }
            : {}),
        }}
      />
      <TanstackReactTable
        table={table}
        data={data}
        isLoading={isLoading}
        columnsCount={columns.length}
      />
      <CustomersPagination />
    </TableContainer>
  );
}

Customers.propTypes = {
  preFilterByPartner: PropTypes.bool,
  partnerId: (props, propName, componentName) => {
    if (props['preFilterByPartner'] === true && isEmpty(props[propName])) {
      return new Error(
        `${propName} is required when 'preFilterByPartner' props is 'true' in ${componentName} component`,
      );
    } else if (props['preFilterByPartner'] === true && typeof props[propName] !== 'string') {
      return new Error(`${propName} value must be string ${componentName} component`);
    }
  },
};
