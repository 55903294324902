import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getCustomerKycDocumentFailure,
  getCustomerKycDocumentInit,
  getCustomerKycDocumentSuccess,
} from './customerKycDocumentSlice';

const getCustomerKycDocument = createAction('customerKycDocument/get');

function* getCustomerKycDocumentSaga({ payload: customerId }) {
  try {
    yield put(getCustomerKycDocumentInit());
    const endpoint = buildApiRoute(apiEndpoints.getCustomerKycDocument, customerId);
    const response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getCustomerKycDocumentSuccess(response.data));
  } catch (err) {
    yield put(getCustomerKycDocumentFailure(err));
  }
}
function* customerKycDocumentSaga() {
  yield takeLatest(getCustomerKycDocument, getCustomerKycDocumentSaga);
}

export default customerKycDocumentSaga;
export { getCustomerKycDocument };
