import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';

import acl from 'app/config/acl';
import { resetGetTransactionsFailure } from 'app/features/transactions/transactionSlice';

import ZaiTransactions from 'components/zai-transactions/ZaiTransactions';
import CheckBalanceModal from 'components/zai-transactions/CheckBalanceModal';
import MakePaymentModal from 'components/zai-transactions/MakePaymentModal';
import RefundPaymentModal from 'components/zai-transactions/RefundPaymentModal';
import ZaiTransactionBriefDetailsModal from 'components/zai-transactions/ZaiTransactionBriefDetailsModal';

function ListZaiTransaction({ isNetworkError }) {
  const transactionState = useSelector((state) => state.transactions.index);

  const { isError, errorCode } = transactionState;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetTransactionsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetTransactionsFailure())}
      />
    );

  return (
    <PageContent title="Zai Transactions">
      <ZaiTransactions />
      <ZaiTransactionBriefDetailsModal />
      <CheckBalanceModal />
      <MakePaymentModal />
      <RefundPaymentModal />
    </PageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ListZaiTransaction);
