import React from 'react';

import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import FormSectionTitle from 'core/forms/FormSectionTitle';

import PropTypes from 'prop-types';
import DateUtils from 'utils/DateUtils';
import CircularProgress from 'core/progress/CircularProgress';

const StyledIconBox = styled(Stack)(({ theme }) => ({
  padding: 0.5,
  borderRadius: '50%',
  zIndex: 1,
}));

const StyledStatusStepper = styled(Stack)(({ theme }) => ({
  direction: 'column',
  alignItems: 'center',
  marginRight: theme.spacing(2),
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    left: '11px',
    top: '20px',
    bottom: '-40px',
    borderLeft: `3px solid ${theme.palette.primary.main}`,
  },
}));

const LogItem = ({ label, value = '-', borderVisible = true }) => (
  <Box sx={{ display: 'flex', mb: 3, mt: 2 }}>
    <StyledStatusStepper
      sx={{
        position: 'relative',
        '&::after': {
          display: borderVisible ? 'block' : 'none',
        },
      }}
    >
      <StyledIconBox>
        <CircleIcon color="primary" sx={{ fontSize: 25 }} />
      </StyledIconBox>
    </StyledStatusStepper>
    <Stack direction="column" spacing={0.5}>
      <Typography variant="body1"> {label}</Typography>
      <Typography variant="caption">{value}</Typography>
    </Stack>
  </Box>
);

const TransactionLogs = ({ data, isLoading }) => {
  return (
    <Paper elevation="2" sx={{ p: 2 }}>
      <FormSectionTitle>Transaction Status Logs</FormSectionTitle>
      {data.length === 0 && !isLoading ? (
        <>No logs found</>
      ) : isLoading ? (
        <CircularProgress />
      ) : (
        data.map((item, index) => (
          <LogItem
            key={'logItem' + index}
            label={item.new_status}
            value={`${DateUtils.isoToString(item?.created_ts)} by ${item?.created_by_name}`}
            borderVisible={data.length - 1 !== index}
          />
        ))
      )}
    </Paper>
  );
};

export default TransactionLogs;

TransactionLogs.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
};

LogItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  borderVisible: PropTypes.bool,
};
