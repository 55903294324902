import React from 'react';
import { useNavigate } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Button from 'core/buttons/Button';
import PageContent from 'layouts/PageContent';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import PartnerCountryForm from 'components/partner-countries/PartnerCountryForm';
import PartnerCountryProgressIndicatorModal from 'components/partner-countries/PartnerCountryProgressIndicatorModal';

function AddPartnerCountry() {
  const navigate = useNavigate();

  return (
    <PageContent
      title="Add Partner Country"
      rightTopEndContent={
        <Button onClick={() => navigate(buildRoute('partner-countries.index'))}>
          List Partner Country
        </Button>
      }
    >
      <PartnerCountryForm isAddMode={true} />
      <PartnerCountryProgressIndicatorModal isAddMode={true} />
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN])(AddPartnerCountry);
