import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import Clipboard from 'core/clipboard/Clipboard';
import SidebarBriefModal from 'core/modal/SidebarBriefModal';

import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import { getFinmoPayout } from 'app/features/finmo-payouts/finmoPayoutSaga';
import { hideFinmoPayoutBriefDetails } from 'app/features/finmo-payouts/finmoPayoutSlice';
import FinmoUtils from 'utils/FinmoUtils';

function FinmoPayoutBriefDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const viewData = useMemo(
    () => [
      {
        label: 'Payout ID',
        get: ({ payout_id }) => <Clipboard content={payout_id} />,
      },
      {
        label: 'Beneficiary',
        get: ({ payout_beneficiary_param }) =>
          FinmoUtils.getPayoutBeneficiaryName(payout_beneficiary_param),
      },
      {
        label: 'Payout Method Name',
        data: 'payout_method_name',
      },
      {
        label: 'Description',
        data: 'description',
      },
      {
        label: 'Beneficiary Amount',
        data: 'beneficiary_amount',
      },
      {
        label: 'Beneficiary Currency',
        data: 'beneficiary_currency',
      },
      {
        label: 'Beneficiary Country',
        data: 'beneficiary_country',
      },
      {
        label: 'Payout Reference',
        data: 'payout_reference',
      },
      {
        label: 'Status',
        data: 'status',
      },
      {
        label: 'Pay ID',
        get: ({ payout_beneficiary_param }) => payout_beneficiary_param?.pay_id,
      },
    ],
    [],
  );

  const state = useSelector((state) => state.finmoPayouts.show);

  const { showBriefDetailModal, finmoPayoutId, isLoading, data } = state;

  useEffect(() => {
    if (finmoPayoutId) dispatch(getFinmoPayout(finmoPayoutId));
  }, [finmoPayoutId]);

  if (!showBriefDetailModal && !finmoPayoutId) return <></>;

  return (
    <SidebarBriefModal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideFinmoPayoutBriefDetails())}
      title="Finmo Payout Details"
      onClickView={() => (
        dispatch(hideFinmoPayoutBriefDetails()),
        navigate(buildRoute('finmo-payouts.show', finmoPayoutId))
      )}
      viewData={viewData}
      item={data}
      isLoading={isLoading}
    />
  );
}

export default FinmoPayoutBriefDetailsModal;
