import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { styled, alpha } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import MuiPagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
const PaginationButton = styled(
  (props) => (
    <Button disableRipple disableElevation disableTouchRipple disableFocusRipple {...props} />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'disabled',
  },
)(({ theme, isActive, disabled }) => ({
  height: '32px',
  minWidth: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  padding: 0,
  color: isActive ? theme.palette.primary.main : theme.palette.grey[900],
  border: `1px solid ${
    !isActive ? theme.palette.grey[400] : alpha(theme.palette.primary.main, 0.5)
  }`,
  backgroundColor: !isActive
    ? theme.palette.background.paper
    : alpha(theme.palette.primary.main, 0.12),
  outline: 'none',
  '& .MuiSvgIcon-root': {
    fontSize: '1.5em',
    fill: disabled ? theme.palette.grey[500] : theme.palette.grey[700],
  },
}));

export default function FinmoPagination(props) {
  const {
    page,
    onChange,
    onRowPerPageChange,
    enableRowPerPage,
    perPageValue,
    sx,
    disableNextPage,
  } = props;

  const perPageOptions = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        p: 1,
        justifyContent: enableRowPerPage ? 'space-between' : 'flex-end',
        ...sx,
      }}
    >
      {enableRowPerPage && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography sx={{ color: 'grey.700' }}>Rows per page:</Typography>
          <div>
            <FormControl variant="standard">
              <Select
                value={perPageValue}
                defaultValue={perPageValue}
                onChange={(e) => onRowPerPageChange(e.target.value)}
              >
                {perPageOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    selected={parseInt(option.value) === parseInt(perPageValue)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Box>
      )}

      <Box display="flex" gap={1}>
        <PaginationButton
          disabled={page === 1}
          onClick={() => {
            if (page > 1) onChange(page - 1);
          }}
        >
          <ChevronLeftIcon />
        </PaginationButton>
        <PaginationButton isActive={true}>{page}</PaginationButton>
        <PaginationButton
          disabled={disableNextPage}
          onClick={() => {
            if (!disableNextPage) onChange(page + 1);
          }}
        >
          <ChevronRightIcon />
        </PaginationButton>
      </Box>
    </Box>
  );
}

FinmoPagination.propTypes = {
  page: PropTypes.number,
  disableNextPage: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onRowPerPageChange: PropTypes.func,
  enableRowPerPage: PropTypes.bool,
  perPageValue: PropTypes.number,
  sx: PropTypes.object,
};

FinmoPagination.defaultProps = {
  enableRowPerPage: true,
  sx: {},
};
