import PageContent from 'layouts/PageContent';
import Settings from 'components/settings/Settings';
import PasswordChangeSuccessOverlay from 'components/auth/PasswordChangeSuccessOverlay';

export default function ListSetting() {
  return (
    <>
      <PageContent title="Settings">
        <Settings />
      </PageContent>
      <PasswordChangeSuccessOverlay />
    </>
  );
}
