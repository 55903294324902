import app from 'app/config/app';

class Log {
  static debug(...values) {
    if (app.debug) {
      const style1 = 'background-color: #637381; color: white; font-style: italic; font-size: 1em;';
      const style2 = 'background-color: #637381; color: white; font-style: italic; font-size: 1em;';
      console.log('%c★★★★★★★★ DEBUG MODE LOG : START ★★★★★★★★', style1);
      values.forEach((value) => console.log(value));
      console.log('%c★★★★★★★★ DEBUG MODE LOG : END ★★★★★★★★', style2);
    }
  }
}

export default Log;
