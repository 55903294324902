import Error from 'core/errors/Error';
import React, { useEffect } from 'react';
import Button from 'core/buttons/Button';
import { compose } from '@reduxjs/toolkit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import withRole from 'hoc/withRole';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';

import { resetGetLicensingPartnerMapFailure } from 'app/features/licensing-partners-map/licensingPartnersMapSlice';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import LicensePartnerMapper from './LicensePartnerMapper';

const ViewLicensePartnerMapper = ({ isNetworkError, partnerId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const licensePartnerMapperState = useSelector((state) => state.licensingPartnersMap.index);

  const { isError, errorCode } = licensePartnerMapperState;

  useEffect(() => {
    if (isError) dispatch(resetGetLicensingPartnerMapFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetLicensingPartnerMapFailure())}
      />
    );

  return (
    <PageContent
      title="Licensing Partner"
      rightTopEndContent={
        <Button
          onClick={() => navigate(buildRoute('license-partner-mapper.create'))}
          color="primary"
          startIcon={<AddIcon />}
        >
          ADD LICENSING PARTNER
        </Button>
      }
    >
      <LicensePartnerMapper partnerId={partnerId} />
    </PageContent>
  );
};

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN]),
)(ViewLicensePartnerMapper);
