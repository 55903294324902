import React from 'react';
import { useNavigate } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Button from 'core/buttons/Button';
import PageContent from 'layouts/PageContent';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import PartnerFundingSourceForm from 'components/partner-funding-sources/PartnerFundingSourceForm';
import PartnerFundingSourceProgressIndicatorModal from 'components/partner-funding-sources/PartnerFundingSourceProgressIndicatorModal';

function AddPartnerFundingSource() {
  const navigate = useNavigate();

  return (
    <PageContent
      title="Add Partner Funding Source"
      rightTopEndContent={
        <Button onClick={() => navigate(buildRoute('partner-funding-sources.index'))}>List Partner Funding Source</Button>
      }
    >
      <PartnerFundingSourceForm isAddMode={true} />
      <PartnerFundingSourceProgressIndicatorModal isAddMode={true} />
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddPartnerFundingSource);
