import { useParams } from 'react-router-dom';

import Error from 'core/errors/Error';
import GuestContainer from 'layouts/GuestContainer';
import ResetPasswordForm from 'components/auth/ResetPasswordForm';

import isEmpty from 'helpers/isEmpty';
import Response from 'app/config/Response';

import MobilePasswordResetSvg from 'assets/svg/mobile-password-reset.svg';

export default function ResetPassword() {
  const params = useParams();

  if (isEmpty(params?.userId) || isEmpty(params?.code))
    return <Error status={Response.HTTP_NOT_FOUND} />;

  return (
    <GuestContainer
      lTitle="Recover your account"
      lImage={<img src={MobilePasswordResetSvg} height={250} />}
      rChildren={<ResetPasswordForm userId={params.userId} code={params.code} />}
    />
  );
}
