import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import FinmoPayins from 'components/finmo-payins/FinmoPayins';
// import FinmoPayinFilterValues from 'components/finmo-payins/FinmoPayinFilterValues';
import { resetGetFinmoPayinsFailure } from 'app/features/finmo-payins/finmoPayinSlice';
import FinmoPayinBriefDetailsModal from 'components/finmo-payins/FinmoPayinBriefDetailsModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';

function ListFinmoPayin({ isNetworkError }) {
  const finmoPayinState = useSelector((state) => state.finmoPayins.index);

  const { isError, errorCode } = finmoPayinState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetFinmoPayinsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetFinmoPayinsFailure())}
      />
    );

  return (
    <PageContent title="Finmo Payins">
      {/* <FinmoPayinFilterValues /> */}
      <FinmoPayins />
      <FinmoPayinBriefDetailsModal />
    </PageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN]))(ListFinmoPayin);
