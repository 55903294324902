import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import AuthUserIsSuperAdmin from 'components/auth/AuthUserIsSuperAdmin';
import PartnerCountries from 'components/partner-countries/PartnerCountries';
import PartnerCountryFilterValues from 'components/partner-countries/PartnerCountryFilterValues';
import { resetGetPartnerCountriesFailure } from 'app/features/partner-countries/partnerCountrySlice';
import PartnerCountryBriefDetailsModal from 'components/partner-countries/PartnerCountryBriefDetailsModal';

import Auth from 'utils/Auth';
import acl from 'app/config/acl';
import { buildRoute } from 'routes';

function ListPartnerCountry({ isNetworkError }) {
  const partnerCountryState = useSelector((state) => state.partnerCountries.index);

  const { isError, errorCode } = partnerCountryState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetPartnerCountriesFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetPartnerCountriesFailure())}
      />
    );

  return (
    <PageContent
      title={Auth.isSuperAdmin() ? 'Partner Countries' : 'Countries'}
      rightTopEndContent={
        <AuthUserIsSuperAdmin>
          <Button
            onClick={() => navigate(buildRoute('partner-countries.create'))}
            color="primary"
            startIcon={<AddIcon />}
          >
            Add Partner Country
          </Button>
        </AuthUserIsSuperAdmin>
      }
    >
      <PartnerCountryFilterValues />
      <PartnerCountries />
      <PartnerCountryBriefDetailsModal />
    </PageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ListPartnerCountry);
