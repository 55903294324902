import GuestContainer from 'layouts/GuestContainer';
import ForgotPassword from 'components/auth/ForgotPassword';

import WrongPasswordSvg from 'assets/svg/wrong-password.svg';

export default function ForgotPasswordPage() {
  return (
    <GuestContainer
      lTitle="Recover your account"
      lImage={WrongPasswordSvg}
      rChildren={<ForgotPassword />}
    />
  );
}
