import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import { buildRoute } from 'routes';
import useAuthUser from 'hooks/useAuthUser';
import useCountries from 'hooks/useCountries';
import { getPartnerFundingSource } from 'app/features/partner-funding-sources/partnerFundingSourceSaga';
import { hidePartnerFundingSourceBriefDetails } from 'app/features/partner-funding-sources/partnerFundingSourceSlice';
import Auth from 'utils/Auth';

function PartnerFundingSourceBriefDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useAuthUser();

  const { getCountryNameByIso3Code } = useCountries();

  const viewData = useMemo(
    () => [
      ...(Auth.isSuperAdmin()
        ? [
            {
              label: 'Partner',
              get: ({ partner_id, partner_name }) => (
                <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
              ),
            },
          ]
        : []),
      {
        label: 'Country',
        get: ({ country }) => getCountryNameByIso3Code(country),
      },
      {
        label: 'Funding Source Name',
        data: 'funding_source_name',
      },
      {
        label: 'Funding Source Value',
        data: 'funding_source',
      },
      {
        label: 'Is Active ?',
        get: ({ is_active }) => (is_active ? 'Yes' : 'No'),
      },
    ],
    [],
  );

  const state = useSelector((state) => state.partnerFundingSources.show);

  const { showBriefDetailModal, partnerFundingSourceId, isLoading, data } = state;

  useEffect(() => {
    if (partnerFundingSourceId) dispatch(getPartnerFundingSource(partnerFundingSourceId));
  }, [partnerFundingSourceId]);

  if (!showBriefDetailModal && !partnerFundingSourceId) return <></>;

  return (
    <SidebarBriefModal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hidePartnerFundingSourceBriefDetails())}
      title="Funding Source Details"
      onClickView={() => (
        dispatch(hidePartnerFundingSourceBriefDetails()),
        navigate(buildRoute('partner-funding-sources.show', partnerFundingSourceId))
      )}
      viewData={viewData}
      item={data}
      isLoading={isLoading}
    />
  );
}

export default PartnerFundingSourceBriefDetailsModal;
