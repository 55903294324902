import React from 'react';
import PropTypes from 'prop-types';

import Auth from 'utils/Auth';
import Error from 'core/errors/Error';
import Response from 'app/config/Response';

class InvalidValueExceptionForWithRoleHOC extends Error {}

const withRole = (roles) => {
  return (WrappedComponent) => {
    if (!Array.isArray(roles) && typeof roles !== 'string') {
      throw new InvalidValueExceptionForWithRoleHOC(
        'Invalid value supplied to withRoleOrPermission(). Expected types Array Or String',
      );
    }

    return (props) => {
      if (Auth.hasRole(roles)) {
        return <WrappedComponent {...props} />;
      }

      return <Error status={Response.HTTP_FORBIDDEN} />;
    };
  };
};

export default withRole;

withRole.propTypes = {
  role: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
