import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import EditIcon from '@mui/icons-material/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import Webhook from 'components/webhooks/Webhook';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import withRole from 'hoc/withRole';
import { resetGetWebhookFailure } from 'app/features/webhooks/webhookSlice';

function ViewWebhook({ isNetworkError }) {
  const params = useParams();

  const { isError, errorCode } = useSelector((state) => state.webhooks.show);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetWebhookFailure());
  }, [isNetworkError]);

  if (isError)
    return <Error status={errorCode || 0} onRetry={() => dispatch(resetGetWebhookFailure())} />;

  return (
    <PageContent
      title="Webhook Details"
      rightTopEndContent={
        <Button
          onClick={() => navigate(buildRoute('webhooks.edit', params.webhookId))}
          color="primary"
          startIcon={<EditIcon />}
        >
          Edit Webhook
        </Button>
      }
    >
      <Webhook webhookId={params.webhookId} />
    </PageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN, acl.ADMIN]))(ViewWebhook);
