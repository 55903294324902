import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import Notify from 'utils/Notify';
import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getPartnersInit,
  getPartnersSuccess,
  getPartnersFailure,
  addPartnerInit,
  addPartnerSuccess,
  addPartnerFailure,
  updatePartnerInit,
  updatePartnerSuccess,
  updatePartnerFailure,
  getPartnerInit,
  getPartnerFailure,
  getPartnerSuccess,
  editPartnerInit,
  editPartnerSuccess,
  editPartnerFailure,
  getPartnersReportDownloadInit,
  getPartnersReportDownloadSuccess,
  getPartnersReportDownloadFailure,
  resetPartnersReportDownload,
} from './partnerSlice';

const getPartner = createAction('partners/get');
const createPartner = createAction('partners/create');
const updatePartner = createAction('partners/update');
const getPartners = createAction('partners/list');
const editPartner = createAction('partners/edit');
const getPartnersReportDownload = createAction('partners/downloadReport');

/**
 * create a partner
 *
 * @param {object} data
 */
function* createPartnerSaga({ payload: data }) {
  yield put(addPartnerInit());
  try {
    let response = yield callWrapperSaga(() => http.post(apiEndpoints.createPartner, data));
    yield put(addPartnerSuccess(response.data));
    Notify.success('New partner created successfully.');
  } catch (err) {
    yield put(addPartnerFailure(err));
    Notify.error('Failed to create new partner.');
  }
}

/**
 * update a partner
 *
 * @param {object} { id, ...data }
 */
function* updatePartnerSaga({ payload: { id, ...data } }) {
  try {
    yield put(updatePartnerInit());
    let endpoint = buildApiRoute(apiEndpoints.updatePartner, id);
    let response = yield callWrapperSaga(() => http.put(endpoint, data));
    yield put(updatePartnerSuccess(response.data));
  } catch (err) {
    yield put(updatePartnerFailure(err));
    Notify.error('Partner update failed');
  }
}

/**
 * get a single partner
 */
function* getPartnerSaga({ payload: partnerId }) {
  try {
    yield put(getPartnerInit());
    let endpoint = buildApiRoute(apiEndpoints.getPartner, partnerId);
    let response = yield callWrapperSaga(() => http.get(endpoint, partnerId));
    yield put(getPartnerSuccess(response.data));
  } catch (err) {
    yield put(getPartnerFailure(err));
  }
}

/**
 * get a partners list
 *
 */
function* getPartnersSaga({ payload }) {
  yield put(getPartnersInit());
  try {
    let endpoint = `${apiEndpoints.getPartners}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getPartnersSuccess(response.data));
  } catch (err) {
    yield put(getPartnersFailure(err));
  }
}

/**
 * initiate edit partner
 * */
function* editPartnerSaga({ payload: partnerId }) {
  try {
    yield put(editPartnerInit());
    let endpoint = buildApiRoute(apiEndpoints.getPartner, partnerId);
    let response = yield callWrapperSaga(() => http.get(endpoint, partnerId));
    yield put(editPartnerSuccess(response.data));
  } catch (err) {
    yield put(editPartnerFailure(err));
  }
}

function* downloadPartnersReportSaga({ payload }) {
  yield put(getPartnersReportDownloadInit());

  try {
    const endpoint = `${apiEndpoints.downloadPartnerReport}?${qs.stringify(payload)}`;

    const response = yield callWrapperSaga(() =>
      http.get(endpoint, {
        responseType: 'blob',
      }),
    );

    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${Date.now()}.${payload.format === 'pdf' ? 'pdf' : 'xlsx'}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    yield put(getPartnersReportDownloadSuccess(response.data));
  } catch (error) {
    yield put(getPartnersReportDownloadFailure(error.message));
  }
}

function* partnerSaga() {
  yield takeLatest(createPartner, createPartnerSaga);
  yield takeLatest(updatePartner, updatePartnerSaga);
  yield takeLatest(getPartner, getPartnerSaga);
  yield takeLatest(getPartners, getPartnersSaga);
  yield takeLatest(editPartner, editPartnerSaga);
  yield takeLatest(getPartnersReportDownload.type, downloadPartnersReportSaga);
}

export default partnerSaga;
export {
  createPartner,
  updatePartner,
  getPartner,
  getPartners,
  editPartner,
  getPartnersReportDownload,
};
