import { useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import PageContent from 'layouts/PageContent';
import Account from 'components/accounts/Account';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import { resetGetAccountFailure } from 'app/features/accounts/accountSlice';
import AccountCard from 'components/accounts/AccountCard';

function ViewAccount() {
  const params = useParams();

  const { isError, errorCode, errorMessage } = useSelector((state) => state.accounts.show);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(resetGetAccountFailure());
  }, []);

  if (isError)
    return (
      <Error
        status={errorCode || 0}
        message={errorMessage}
        onRetry={() => dispatch(resetGetAccountFailure())}
      />
    );

  return (
    <PageContent title="Account Details">
      <AccountCard />
      <Account accountId={params.accountId} />
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(ViewAccount);
