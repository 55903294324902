import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getCustomersInit,
  getCustomersSuccess,
  getCustomersFailure,
  getCustomerInit,
  getCustomerFailure,
  getCustomerSuccess,
  getCustomerReportDownloadInit,
  getCustomerReportDownloadSuccess,
  getCustomerReportDownloadFailure,
  resetCustomerReportDownload,
} from './customerSlice';

const getCustomer = createAction('customers/get');
const getCustomers = createAction('customers/list');
const getCustomerDownloadReport = createAction('customers/downloadReport');

/**
 * get a single customer
 */
function* getCustomerSaga({ payload: customerId }) {
  try {
    yield put(getCustomerInit());
    let endpoint = buildApiRoute(apiEndpoints.getCustomer, customerId);
    let response = yield callWrapperSaga(() => http.get(endpoint, customerId));
    yield put(getCustomerSuccess(response.data));
  } catch (err) {
    yield put(getCustomerFailure(err));
  }
}

/**
 * get a customers list
 *
 */
function* getCustomersSaga({ payload: { ...params } }) {
  yield put(getCustomersInit());
  try {
    let endpoint = `${apiEndpoints.getCustomers}?${qs.stringify(params)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getCustomersSuccess(response.data));
  } catch (err) {
    yield put(getCustomersFailure(err));
  }
}

function* downloadCustomerReportSaga({ payload }) {
  yield put(getCustomerReportDownloadInit());

  try {
    const endpoint = `${apiEndpoints.downloadCustomerReport}?${qs.stringify(payload)}`;
    const response = yield callWrapperSaga(() => http.get(endpoint, { responseType: 'blob' }));

    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${Date.now()}.${payload.format === 'pdf' ? 'pdf' : 'xlsx'}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    yield put(getCustomerReportDownloadSuccess(response.data));
  } catch (error) {
    yield put(getCustomerReportDownloadFailure(error.message));
  }
}

function* customerSaga() {
  yield takeLatest(getCustomer, getCustomerSaga);
  yield takeLatest(getCustomers, getCustomersSaga);
  yield takeLatest(getCustomerDownloadReport.type, downloadCustomerReportSaga);
}

export default customerSaga;
export { getCustomer, getCustomers, getCustomerDownloadReport };
