import React from 'react';
import { useNavigate } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Button from 'core/buttons/Button';
import PageContent from 'layouts/PageContent';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import ApiConfigForm from 'components/api-configs/ApiConfigForm';
import ApiConfigProgressIndicatorModal from 'components/api-configs/ApiConfigProgressIndicatorModal';

function AddApiConfig() {
  const navigate = useNavigate();

  return (
    <PageContent
      title="Add API Config"
      rightTopEndContent={
        <Button onClick={() => navigate(buildRoute('api-configs.index'))}>List API Config</Button>
      }
    >
      <ApiConfigForm isAddMode={true} />
      <ApiConfigProgressIndicatorModal isAddMode={true} />
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddApiConfig);
