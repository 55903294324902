import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';

import MoreActions from 'core/lab/MoreActions';
import ReloadButton from 'core/buttons/ReloadButton';
import ColumnVisibility from 'core/table/ColumnVisibility';
import SelectPartner from 'components/partners/SelectPartner';
import TableActionsContainer from 'core/table/TableActionsContainer';

import Auth from 'utils/Auth';
import Sheet from 'utils/Sheet';
import app from 'app/config/app';
import useQueryParams from 'hooks/useQueryParams';
import { getFundingSources } from 'app/features/funding-sources/fundingSourceSaga';
import { setParam, deleteFilterParams } from 'app/features/funding-sources/fundingSourceSlice';

export default function FundingSourcesActions({ table }) {
  const dispatch = useDispatch();

  const fundingSourceState = useSelector((state) => state.fundingSources.index);

  const [queryParams, updateQueryParams] = useQueryParams(app.ignoreFilterKeys);

  const { params: appliedFilters } = fundingSourceState;

  useEffect(() => {
    updateQueryParams(appliedFilters);
  }, [JSON.stringify(appliedFilters)]);

  useEffect(() => {
    dispatch(setParam(queryParams));
  }, [JSON.stringify(queryParams)]);

  return (
    <TableActionsContainer>
      <Box></Box>
      <Box display="flex" gap={1}>
        {table && <ColumnVisibility table={table} />}
        <ReloadButton
          onClick={() => {
            dispatch(
              getFundingSources({
                ...appliedFilters,
                page_number: 1,
                partnerId: fundingSourceState.partnerId,
              }),
            );
          }}
        />
        <MoreActions
          onExportToCsv={() => Sheet.exportReactTableSelectedRowsToCsv(table, 'funding_sources_')}
          onExportToExcel={() =>
            Sheet.exportReactTableSelectedRowsToExcel(table, 'funding_sources_')
          }
        />
      </Box>
    </TableActionsContainer>
  );
}

FundingSourcesActions.propTypes = {
  table: PropTypes.object,
};
