const downloadBlobResponseAsFile = (blob, { fileName, blobType, fileExtension }) => {
  const filename = `${fileName}.${fileExtension}`;
  const blobData = new Blob([blob], { type: blobType });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blobData);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
};

export default downloadBlobResponseAsFile;
