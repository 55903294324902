import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MuiButton from '@mui/material/Button';

const ChipButton = forwardRef((props, ref) => {
  const {
    type,
    variant,
    children,
    isAddMode,
    isProcessing,
    disabled,
    size,
    uppercase,
    sx,
    ...rest
  } = props;

  return (
    <MuiButton
      ref={ref}
      type={type}
      variant={variant}
      {...rest}
      disableElevation={true}
      disabled={isProcessing || disabled}
      size={size}
      sx={{
        ...(uppercase
          ? {
              textTransform: 'uppercase',
            }
          : {
              textTransform: 'none',
            }),
        ...sx,
      }}
    >
      {!children
        ? isAddMode
          ? isProcessing
            ? 'Saving'
            : 'Save'
          : isProcessing
          ? 'Updating'
          : 'Update'
        : children}
    </MuiButton>
  );
});

export default ChipButton;

ChipButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
  ]),
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  isAddMode: PropTypes.bool,
  isProcessing: PropTypes.bool,
  disabled: PropTypes.bool,
  uppercase: PropTypes.bool,
  sx: PropTypes.object,
};

ChipButton.defaultProps = {
  size: 'small',
  variant: 'outlined',
  color: 'primary',
  type: 'button',
  isAddMode: false,
  isProcessing: false,
  disabled: false,
  uppercase: false,
};
