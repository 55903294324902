import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import PartnerCountry from 'components/partner-countries/PartnerCountry';

import acl from 'app/config/acl';
import withRole from 'hoc/withRole';
import { resetGetPartnerCountryFailure } from 'app/features/partner-countries/partnerCountrySlice';

function ViewPartnerCountry({ isNetworkError }) {
  const params = useParams();

  const { isError, errorCode } = useSelector((state) => state.partnerCountries.show);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetPartnerCountryFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error status={errorCode || 0} onRetry={() => dispatch(resetGetPartnerCountryFailure())} />
    );

  return (
    <PageContent title="Partner Country Details">
      <PartnerCountry partnerCountryId={params.partnerCountryId} />
    </PageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ViewPartnerCountry);
