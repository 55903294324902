import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { compose } from '@reduxjs/toolkit';
import { useDispatch, connect } from 'react-redux';

import Pagination from 'core/pagination/Pagination';
import { setParam } from 'app/features/customers/customerSlice';

function CustomerPagination({ totalCount, pageSize, pageNumber }) {
  const dispatch = useDispatch();

  return (
    <Box component="div" sx={{ py: 2 }}>
      <Pagination
        count={Math.ceil(totalCount / pageSize)}
        page={pageNumber}
        onChange={(currentPage) => dispatch(setParam({ page_number: currentPage }))}
        onRowPerPageChange={(rowPerPage) =>
          dispatch(setParam({ page_size: rowPerPage, page_number: 1 }))
        }
        perPageValue={pageSize}
      />
    </Box>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    totalCount: state.customers.index.totalCount,
    pageSize: state.customers.index.params.page_size,
    pageNumber: state.customers.index.params.page_number,
  };
}

export default compose(connect(mapStateToProps))(CustomerPagination);

CustomerPagination.propTypes = {
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  pageNumber: PropTypes.number,
};
