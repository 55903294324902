import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import Notify from 'utils/Notify';
import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getApiConfigsInit,
  getApiConfigsSuccess,
  getApiConfigsFailure,
  addApiConfigInit,
  addApiConfigSuccess,
  addApiConfigFailure,
  updateApiConfigInit,
  updateApiConfigSuccess,
  updateApiConfigFailure,
  getApiConfigInit,
  getApiConfigFailure,
  getApiConfigSuccess,
  editApiConfigInit,
  editApiConfigSuccess,
  editApiConfigFailure,
} from './apiConfigSlice';

const getApiConfig = createAction('apiConfigs/get');
const createApiConfig = createAction('apiConfigs/create');
const updateApiConfig = createAction('apiConfigs/update');
const getApiConfigs = createAction('apiConfigs/list');
const editApiConfig = createAction('apiConfigs/edit');

/**
 * create a apiConfig
 *
 * @param {object} data
 */
function* createApiConfigSaga({ payload: data }) {
  yield put(addApiConfigInit());
  try {
    let response = yield callWrapperSaga(() => http.post(apiEndpoints.createApiConfig, data));
    yield put(addApiConfigSuccess(response.data));
    Notify.success('New API config created successfully.');
  } catch (err) {
    yield put(addApiConfigFailure(err));
    Notify.error('Failed to create new API config.');
  }
}

/**
 * update a apiConfig
 *
 * @param {object} { id, ...data }
 */
function* updateApiConfigSaga({ payload: { id, ...data } }) {
  try {
    yield put(updateApiConfigInit());
    let endpoint = buildApiRoute(apiEndpoints.updateApiConfig, id);
    let response = yield callWrapperSaga(() => http.put(endpoint, data));
    yield put(updateApiConfigSuccess(response.data));
  } catch (err) {
    yield put(updateApiConfigFailure(err));
    Notify.error('API Config update failed');
  }
}

/**
 * get a single apiConfig
 */
function* getApiConfigSaga({ payload: apiConfigId }) {
  try {
    yield put(getApiConfigInit());
    let endpoint = buildApiRoute(apiEndpoints.getApiConfig, apiConfigId);
    let response = yield callWrapperSaga(() => http.get(endpoint, apiConfigId));
    yield put(getApiConfigSuccess(response.data));
  } catch (err) {
    yield put(getApiConfigFailure(err));
  }
}

/**
 * get a apiConfigs list
 *
 */
function* getApiConfigsSaga({ payload }) {
  yield put(getApiConfigsInit());
  try {
    let endpoint = `${apiEndpoints.getApiConfigs}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getApiConfigsSuccess(response.data));
  } catch (err) {
    yield put(getApiConfigsFailure(err));
  }
}

/**
 * initiate edit apiConfig
 * */
function* editApiConfigSaga({ payload: apiConfigId }) {
  try {
    yield put(editApiConfigInit());
    let endpoint = buildApiRoute(apiEndpoints.getApiConfig, apiConfigId);
    let response = yield callWrapperSaga(() => http.get(endpoint, apiConfigId));
    yield put(editApiConfigSuccess(response.data));
  } catch (err) {
    yield put(editApiConfigFailure(err));
  }
}

function* apiConfigSaga() {
  yield takeLatest(createApiConfig, createApiConfigSaga);
  yield takeLatest(updateApiConfig, updateApiConfigSaga);
  yield takeLatest(getApiConfig, getApiConfigSaga);
  yield takeLatest(getApiConfigs, getApiConfigsSaga);
  yield takeLatest(editApiConfig, editApiConfigSaga);
}

export default apiConfigSaga;
export { createApiConfig, updateApiConfig, getApiConfig, getApiConfigs, editApiConfig };
