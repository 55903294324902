import withRole from 'hoc/withRole';
import PageContent from 'layouts/PageContent';
import PartnerForm from 'components/partners/PartnerForm';
import PartnerProgressIndicatorModal from 'components/partners/PartnerProgressIndicatorModal';

import acl from 'app/config/acl';

function AddPartner() {
  return (
    <PageContent title="Add Partner">
      <PartnerForm isAddMode={true} />
      <PartnerProgressIndicatorModal isAddMode={true} />
    </PageContent>
  );
}

export default withRole(acl.SUPERADMIN)(AddPartner);
