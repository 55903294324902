import { useRef, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Button from 'core/buttons/Button';
import TextButton from 'core/buttons/TextButton';
import FormContainer from 'core/forms/FormContainer';
import FormTextField from 'hook-form-controls/FormTextField';
import FormSelectPartner from 'hook-form-controls/FormSelectPartner';
import ValidationErrorMessages from 'core/errors/ValidationErrorMessages';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import YupPassword from 'utils/YupPassword';
import {
  createPartnerCountry,
  updatePartnerCountry,
} from 'app/features/partner-countries/partnerCountrySaga';
import FormSelectCountry from 'hook-form-controls/FormSelectCountry';
import FormCheckbox from 'hook-form-controls/FormCheckbox';

YupPassword(Yup);

const partnerCountrySchema = Yup.object().shape({
  partner_id: Yup.string().required('Select a partner'),
  iso3: Yup.string().required('Country is required'),
  currency: Yup.string().required('Currency is required'),
  is_active: Yup.boolean().required(),
});

function PartnerCountryForm({ isAddMode, partnerCountry, retries, isCreated }) {
  const submitButtonRef = useRef(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      ...(isAddMode
        ? {
            is_active: true,
          }
        : {}),
      ...(partnerCountry ? partnerCountry : {}),
    },
    resolver: yupResolver(partnerCountrySchema),
    mode: 'onChange',
  });

  const {
    formState: { errors },
    handleSubmit,
    reset,
    getValues,
    setValue,
  } = methods;

  const onSubmit = async (data) => {
    dispatch(
      isAddMode
        ? createPartnerCountry(data)
        : updatePartnerCountry({ ...data, id: partnerCountry.id }),
    );
  };

  useEffect(() => {
    if (retries > 0) submitButtonRef.current.click();
  }, [retries]);

  useEffect(() => {
    if (isCreated) reset({ partner_id: getValues('partner_id') });
  }, [isCreated]);

  return (
    <FormContainer>
      {app.isDevelopmentMode() && <ValidationErrorMessages errors={errors} />}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container rowSpacing={3} columnSpacing={4}>
            <Grid item sm={12}>
              <FormSelectPartner
                id="select-partner"
                name="partner_id"
                label="Select Partner"
                disabled={isAddMode}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormSelectCountry
                id="select-country"
                name="iso3"
                onSelected={(country) => {
                  setValue('currency', country?.currency ?? '');
                }}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <FormTextField name="currency" label="Currency" disabled />
            </Grid>
            <Grid item sm={12}>
              <FormCheckbox name="is_active" label="Is Active ?" />
            </Grid>
            <Grid item sm={12} md={12}>
              <Box display="flex" justifyContent="space-between">
                <TextButton type="button" color="error" onClick={() => navigate(-1)}>
                  Cancel
                </TextButton>
                <Button
                  ref={submitButtonRef}
                  type="submit"
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                >
                  {isAddMode ? 'Create New Partner Country' : 'Update Partner Country'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </FormContainer>
  );
}

export default compose(connect(mapStateToProps))(PartnerCountryForm);

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    retries: ownProps.isAddMode
      ? state.partnerCountries.create.retries
      : state.partnerCountries.update.retries,
    isCreated: state.partnerCountries.create.isCreated,
  };
}

PartnerCountryForm.propTypes = {
  isAddMode: PropTypes.bool.isRequired,
  partnerCountry: PropTypes.object,
  retries: PropTypes.number,
  isCreated: PropTypes.bool,
};
