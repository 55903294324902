import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import SidebarBriefModal from 'core/modal/SidebarBriefModal';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';
import CustomerClickAwayDetails from 'components/customers/CustomerClickAwayDetails';

import Auth from 'utils/Auth';
import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import ucwords from 'helpers/ucwords';
import useCountries from 'hooks/useCountries';
import { getBeneficiary } from 'app/features/beneficiaries/beneficiarySaga';
import { hideBeneficiaryBriefDetails } from 'app/features/beneficiaries/beneficiarySlice';

function BeneficiaryBriefDetailsModal() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { getCountryNameByIso3Code } = useCountries();

  const state = useSelector((state) => state.beneficiaries.show);

  const { showBriefDetailModal, beneficiaryId, isLoading, data } = state;

  const viewData = useMemo(
    () => [
      {
        label: 'Beneficiary Name',
        get: (row) =>
          [row?.first_name, row?.middle_name, row?.last_name]
            .filter((v) => !isEmpty(v))
            .map((v) => ucwords(v))
            .join(' '),
      },
      {
        label: 'Customer',
        data: 'user_id',
        get: ({ user_id, user_name }) => (
          <CustomerClickAwayDetails customerId={user_id} label={user_name} />
        ),
      },
      ...(Auth.isSuperAdmin()
        ? [
            {
              label: 'Partner',
              data: 'partner_id',
              get: ({ partner_id, partner_name }) => (
                <PartnerClickAwayDetails partnerId={partner_id} label={partner_name} />
              ),
            },
          ]
        : []),
      {
        label: 'Mobile Number',
        data: 'mobile_number',
      },
      {
        label: 'Country',
        data: 'country',
        get: ({ country }) => getCountryNameByIso3Code(country),
      },
      {
        label: 'Is Verified ?',
        get: ({ is_verified }) => (is_verified ? 'Yes' : 'No'),
      },
      {
        label: 'Is Active ?',
        get: ({ is_active }) => (is_active ? 'Yes' : 'No'),
      },
    ],
    [],
  );

  useEffect(() => {
    if (beneficiaryId) dispatch(getBeneficiary(beneficiaryId));
  }, [beneficiaryId]);

  if (!showBriefDetailModal && !beneficiaryId) return <></>;

  return (
    <SidebarBriefModal
      open={showBriefDetailModal ?? false}
      onClose={() => dispatch(hideBeneficiaryBriefDetails())}
      title="Beneficiary Details"
      onClickView={() => (
        dispatch(hideBeneficiaryBriefDetails()),
        navigate(buildRoute('beneficiaries.show', beneficiaryId))
      )}
      viewData={viewData}
      item={data}
      isLoading={isLoading}
    ></SidebarBriefModal>
  );
}

export default BeneficiaryBriefDetailsModal;
