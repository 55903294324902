import { createSlice } from '@reduxjs/toolkit';
import Response from 'app/config/Response';
import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';

const initialState = {
  create: {
    showProcessingModal: false,
    isCreating: false,
    isCreated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },

  index: {
    data: [],
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
    },
    totalCount: 0,
    totalPage: 0,
    showBriefDetailModal: false,
    licensingPartnerId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
};

export const licensingPartnerMapSlice = createSlice({
  name: 'licensingPartnersMap',
  initialState,
  reducers: {
    getLicensingPartnerMapInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: [],
      };
    },

    getLicensingPartnerMapSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;
      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getLicensingPartnerMapFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: [],
      };
    },

    resetGetLicensingPartnerMapFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: [],
      };
    },

    addLicensingPartnerMapInit: (state) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: true,
        isCreated: false,
        isFailed: false,
        errors: null,
        data: null,
      };
    },

    addLicensingPartnerMapSuccess: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },

    addLicensingPartnerMapFailure: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: false,
        isFailed: true,
        errors: null,
        data: null,
        errorMessage: payload?.response?.message || '',
      };
    },

    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParams: (state) => {
      state.index.params = {
        pageSize: state.index.params.pageSize,
        page: 1,
      };
    },

    retryToCreate: (state) => {
      state.create.retries += 1;
    },

    setShowCreateProcessingModal: (state, { payload }) => {
      state.create.showProcessingModal = payload;
    },
  },
});

export const {
  setParam,
  resetParams,
  getLicensingPartnerMapInit,
  getLicensingPartnerMapSuccess,
  getLicensingPartnerMapFailure,
  resetGetLicensingPartnerMapFailure,
  addLicensingPartnerMapInit,
  addLicensingPartnerMapSuccess,
  addLicensingPartnerMapFailure,
  retryToCreate,
  setShowCreateProcessingModal,
} = licensingPartnerMapSlice.actions;

export default licensingPartnerMapSlice.reducer;
