import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getFinmoPayoutsInit,
  getFinmoPayoutsSuccess,
  getFinmoPayoutsFailure,
  getFinmoPayoutInit,
  getFinmoPayoutFailure,
  getFinmoPayoutSuccess,
} from './finmoPayoutSlice';

const getFinmoPayout = createAction('finmoPayouts/get');
const getFinmoPayouts = createAction('finmoPayouts/list');

/**
 * get a single finmoPayout
 */
function* getFinmoPayoutSaga({ payload: finmoPayoutId }) {
  try {
    yield put(getFinmoPayoutInit());
    let endpoint = buildApiRoute(apiEndpoints.getFinmoPayout, finmoPayoutId);
    let response = yield callWrapperSaga(() => http.get(endpoint, finmoPayoutId));
    yield put(getFinmoPayoutSuccess(response.data));
  } catch (err) {
    yield put(getFinmoPayoutFailure(err));
  }
}

/**
 * get a finmoPayouts list
 *
 */
function* getFinmoPayoutsSaga({ payload }) {
  yield put(getFinmoPayoutsInit());
  try {
    let endpoint = `${apiEndpoints.getFinmoPayouts}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getFinmoPayoutsSuccess(response.data));
  } catch (err) {
    yield put(getFinmoPayoutsFailure(err));
  }
}

function* finmoPayoutSaga() {
  yield takeLatest(getFinmoPayout, getFinmoPayoutSaga);
  yield takeLatest(getFinmoPayouts, getFinmoPayoutsSaga);
}

export default finmoPayoutSaga;
export { getFinmoPayout, getFinmoPayouts };
