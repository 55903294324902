import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  create: {
    showProcessingModal: false,
    isCreating: false,
    isCreated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },
};

export const ausTrackReportsSlice = createSlice({
  name: 'ausTrackReports',
  initialState,
  reducers: {
    getAusTrackReportsInit: (state) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: true,
        isCreated: false,
        isFailed: false,
        errors: null,
        data: null,
      };
    },

    getAusTrackReportsSuccess: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: true,
        isFailed: false,
        errors: null,
        data: payload,
      };
    },

    getAusTrackReportsFailure: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: false,
        isFailed: true,
        errors: null,
        data: null,
        errorMessage: payload?.response?.data?.message || '',
      };
    },

    retryToGetAusTrackReports: (state) => {
      state.create.retries += 1;
    },

    setShowCreateProcessingModal: (state, { payload }) => {
      state.create.showProcessingModal = payload;
    },

    resetReportState: (state) => {
      state.create = {
        ...initialState.create,
      };
    },
  },
});

export const {
  resetReportState,
  getAusTrackReportsInit,
  getAusTrackReportsFailure,
  getAusTrackReportsSuccess,
  setShowCreateProcessingModal,
  retryToGetAusTrackReports,
} = ausTrackReportsSlice.actions;

export default ausTrackReportsSlice.reducer;
