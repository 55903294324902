import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import ActiveBadge from 'core/badges/ActiveBadge';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useReactTable, getCoreRowModel } from '@tanstack/react-table';

import FormCheckbox from 'core/forms/FormCheckbox';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import TableContainer from 'core/table/TableContainer';
import WebhookHeadersActions from './WebhookHeadersActions';
import TanstackReactTable from 'core/table/TanstackReactTable';
import WebhookHeaderPagination from './WebhookHeaderPagination';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import constants from 'app/config/constants';
import { useConfirm } from 'core/mui-confirm';
import Dimensions from 'app/config/Dimensions';
import useQueryParams from 'hooks/useQueryParams';
import { getWebhookHeaders } from 'app/features/webhook-headers/webhookHeaderSaga';
import { deleteWebhookHeader } from 'app/features/webhook-headers/webhookHeaderSaga';
import { getWebhookHeaderBriefDetailsInit } from 'app/features/webhook-headers/webhookHeaderSlice';

export default function WebhookHeaders({ preFilterByPartner, partnerId }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const confirm = useConfirm();

  const webhookHeadersState = useSelector((state) => state.webhookHeaders.index);

  const { params, data, isLoading } = webhookHeadersState;

  const [queryParams] = useQueryParams(app.ignoreFilterKeys);

  const [columnVisibility, setColumnVisibility] = useState({});

  /**
   * handle delete webhookHeader
   *
   */
  const handleDelete = (webhookHeaderId) => {
    confirm({
      icon: 'warning',
      confirmationText: 'Yes, Delete it.',
      description: 'This will permanently delete this webhook header.',
    })
      .then(() => dispatch(deleteWebhookHeader(webhookHeaderId)))
      .catch(() => {});
  };

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      ...(Auth.isSuperAdmin()
        ? [
            {
              header: 'Partner Name',
              accessorFn: (row) => row.partner_name,
            },
          ]
        : []),
      {
        header: 'Custom Header',
        accessorFn: (row) => row.custom_header,
      },
      {
        header: 'Value',
        accessorFn: (row) => row.value,
      },
      {
        header: 'Is Active ?',
        accessorFn: (row) => row.is_active,
        cell: ({ row }) => <ActiveBadge isActive={!!row.original.is_active} />,
      },
      {
        header: 'Is Verified ?',
        accessorFn: (row) => row.is_verified,
        cell: ({ row }) => <VerifiedBadge isVerified={!!row.original.is_verified} />,
      },
      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,
        cell: ({ row }) => (
          <Box display="flex" gap={1}>
            <IconButton
              size={Dimensions.table.iconButton.size}
              onClick={() => {
                dispatch(
                  getWebhookHeaderBriefDetailsInit({
                    showBriefDetailModal: true,
                    webhookHeaderId: row.original.id,
                  }),
                );
              }}
            >
              <AspectRatioIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
            <IconButton
              size={Dimensions.table.iconButton.size}
              onClick={() => navigate(buildRoute('webhook-headers.show', row.original.id))}
            >
              <FullscreenIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
            <IconButton
              size={Dimensions.table.iconButton.size}
              color="error"
              onClick={() => handleDelete(row.original.id)}
              disabled={row.original?.isDeleting || false}
            >
              <DeleteForeverIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
          </Box>
        ),
      },
    ],
    [JSON.stringify(data)],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(
      getWebhookHeaders({
        ...queryParams,
        ...params,
        ...(preFilterByPartner
          ? {
              partner_id: partnerId,
            }
          : {}),
      }),
    );
  }, [JSON.stringify(params)]);

  return (
    <TableContainer>
      {!preFilterByPartner && <WebhookHeadersActions table={table} />}
      <TanstackReactTable
        table={table}
        data={data}
        isLoading={isLoading}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <WebhookHeaderPagination />
    </TableContainer>
  );
}

WebhookHeaders.propTypes = {
  preFilterByPartner: PropTypes.bool,
  partnerId: (props, propName, componentName) => {
    if (props['preFilterByPartner'] === true && isEmpty(props[propName])) {
      return new Error(
        `${propName} is required when 'preFilterByPartner' props is 'true' in ${componentName} component`,
      );
    } else if (props['preFilterByPartner'] === true && typeof props[propName] !== 'string') {
      return new Error(`${propName} value must be string ${componentName} component`);
    }
  },
};
