import { useState } from 'react';

import ResetLinkSent from './ResetLinkSent';
import ForgotPasswordForm from './ForgotPasswordForm';

import app from 'app/config/app';
import Notify from 'utils/Notify';
import http from 'app/services/httpService';
import apiEndpoints from 'routes/apiEndpoints';

export default function ForgotPassword() {
  const [isResetLinkSent, setIsResetLinkSent] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errors, setErrors] = useState({});

  const [email, setEmail] = useState(null);

  const handleOnSubmit = async (email) => {
    setEmail(email);
    setIsProcessing(true);
    try {
      let endpoint = app.apiBaseUrl + apiEndpoints.forgotPassword;
      await http.post(endpoint, { email });
      setIsResetLinkSent(true);
    } catch (err) {
      setErrors({ email: { message: err?.response?.data?.message } });
      console.error(err);
      Notify.error('Failed');
    }
    setIsProcessing(false);
  };

  if (isResetLinkSent)
    return <ResetLinkSent email={email} onResend={handleOnSubmit} isProcessing={isProcessing} />;

  return (
    <ForgotPasswordForm onSubmit={handleOnSubmit} isProcessing={isProcessing} errors={errors} />
  );
}
