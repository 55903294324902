import Box from '@mui/material/Box';
import SpringModal from 'core/modal/SpringModal';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'core/buttons/Button';
import CancelButton from 'core/buttons/CancelButton';

import { logout } from 'app/features/auth/authSaga';
import { setIsLogoutModalOpen } from 'app/features/auth/authSlice';
import Spacer from 'core/spacer/Spacer';

export default function LogoutModal() {
  const dispatch = useDispatch();

  const authState = useSelector((state) => state.auth);

  const { isLogoutModalOpen, isLoggingOut } = authState;

  const handleCloseModal = () => dispatch(setIsLogoutModalOpen(false));

  return (
    <SpringModal
      modalId="logout__modal"
      isOpen={isLogoutModalOpen}
      header="Are you sure ?"
      subtitle="You will get logged out from current session."
      enableDefaultPadding={true}
      enableMaximizeButton={false}
      onClose={isLoggingOut ? null : handleCloseModal}
    >
      <Box display="flex" justifyContent="space-between" sx={{ width: '100%' }}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => dispatch(logout())}
          disabled={isLoggingOut}
        >
          {isLoggingOut ? 'Logging out' : 'Logout'}
        </Button>
        <Spacer width={'300px'} />
        <CancelButton onClick={handleCloseModal} disabled={isLoggingOut}>
          Cancel
        </CancelButton>
      </Box>
    </SpringModal>
  );
}
