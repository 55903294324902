import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import ApiConfigs from 'components/api-configs/ApiConfigs';
import ApiConfigFilterValues from 'components/api-configs/ApiConfigFilterValues';
import { resetGetApiConfigsFailure } from 'app/features/api-configs/apiConfigSlice';
import ApiConfigBriefDetailsModal from 'components/api-configs/ApiConfigBriefDetailsModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import withRole from 'hoc/withRole';

function ListApiConfig({ isNetworkError }) {
  const apiConfigState = useSelector((state) => state.apiConfigs.index);

  const { isError, errorCode } = apiConfigState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetApiConfigsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetApiConfigsFailure())}
      />
    );

  return (
    <PageContent
      title="ApiConfigs"
      rightTopEndContent={
        <Button
          onClick={() => navigate(buildRoute('api-configs.create'))}
          color="primary"
          startIcon={<AddIcon />}
        >
          Add API Config
        </Button>
      }
    >
      <ApiConfigFilterValues />
      <ApiConfigs />
      <ApiConfigBriefDetailsModal />
    </PageContent>
  );
}

export default compose(withAppState, withRole(acl.SUPERADMIN))(ListApiConfig);
