import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import PageContent from 'layouts/PageContent';

import acl from 'app/config/acl';

import {
  updateFundingSourceInfo,
  getFundingSourceInfo,
} from 'app/features/funding-sources-info/fundingSourceInfoSaga';

import FundingSourceInfoFormSkeleton from 'components/Funding-source-info/FundingSourceInfoFormSkeleton';
import FundingSourceInfoForm from 'components/Funding-source-info/FundingSourcesInfoForm';
import FundingSourceInfoProgressIndicatorModal from 'components/Funding-source-info/FundingSourceInfoProgressIndicatorModal';

function EditFundingSourceInfo() {
  const params = useParams();

  const dispatch = useDispatch();

  const { data: fundingSourceInfo } = useSelector((state) => state.fundingSourcesInfo.show);

  const { isError, errorMessage, isLoading, errorCode } = useSelector(
    (state) => state.fundingSourcesInfo.update,
  );

  useEffect(() => {
    dispatch(getFundingSourceInfo(params.fundingSourceInfoId));
  }, [params.fundingSourceInfoId]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(updateFundingSourceInfo(params.fundingSourceInfoId))}
      />
    );

  return (
    <PageContent title="Edit Partner Funding Source Information">
      {isLoading ? (
        <FundingSourceInfoFormSkeleton />
      ) : (
        <FundingSourceInfoForm isAddMode={false} fundingSourcesInfo={fundingSourceInfo} />
      )}

      <FundingSourceInfoProgressIndicatorModal isAddMode={false} />
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(EditFundingSourceInfo);
