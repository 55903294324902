import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import CustomerKycs from 'components/customer-kycs/CustomerKycs';
import CustomerKycBriefDetailsModal from 'components/customer-kycs/CustomerKycBriefDetailsModal';

import acl from 'app/config/acl';
import { resetGetCustomerKycsFailure } from 'app/features/customer-kycs/customerKycSlice';

function ListCustomerKyc({ isNetworkError }) {
  const customerKycState = useSelector((state) => state.customerKycs.index);

  const { isError, errorCode } = customerKycState;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) dispatch(resetGetCustomerKycsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetCustomerKycsFailure())}
      />
    );

  return (
    <PageContent title="Customer KYCs">
      {/* <CustomerKycFilterValues /> */}
      <CustomerKycs />
      <CustomerKycBriefDetailsModal />
    </PageContent>
  );
}

export default compose(
  withAppState,
  withRole([acl.SUPERADMIN, acl.ADMIN, acl.USER]),
)(ListCustomerKyc);
