import { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import CircularProgress from '@mui/material/CircularProgress';

import isEmpty from 'helpers/isEmpty';
import ucwords from 'helpers/ucwords';
import http from 'app/services/httpService';
import apiEndpoints from 'routes/apiEndpoints';
import { getCountries } from 'app/features/constants/constantSaga';

export default function FormSelectPartnerCountry(props) {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const { id, name, label, required, size, fullWidth, disabled, variant, onSelected, partnerId } =
    props;

  const value = watch(name);

  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState({ label: '---SELECT---', value: '' });

  const { data: countries } = useSelector((state) => state.constants.countries);

  const [open, setOpen] = useState(false);

  const handleChange = (e, option) => {
    e.preventDefault();
    setSelectedOption(option);
    setValue(name, option?.value ?? '');
    if (typeof onSelected === 'function') onSelected(option);
  };

  const [partnerCountries, setPartnerCountries] = useState([]);

  const [isLoadingPartnerCountries, setIsLoadingPartnerCountries] = useState(false);

  async function getPartnerCountries() {
    setIsLoadingPartnerCountries(true);
    try {
      let params = {
        partner_id: partnerId,
        page_size: 1000,
      };
      let response = await http.get(
        apiEndpoints.getPartnerCountries + '?' + new URLSearchParams(params),
      );

      const options =
        response?.data?.data?.map((partnerCountry) => {
          const countryName = countries?.filter(
            (c) => c.iso3.toLowerCase() === partnerCountry.iso3.toLowerCase(),
          )?.[0]?.country;
          return {
            label: ucwords(countryName) ?? partnerCountry.iso3,
            value: partnerCountry.iso3,
          };
        }) ?? [];

      setPartnerCountries(options);
    } catch (err) {
      console.error(err);
    }
    setIsLoadingPartnerCountries(false);
  }

  useEffect(() => {
    if (isEmpty(partnerId)) {
      setPartnerCountries([]);
      setValue(name, '');
    } else {
      getPartnerCountries();
    }
  }, [partnerId]);

  useEffect(() => {
    if (countries.length <= 0) dispatch(getCountries());
  }, []);

  useEffect(() => {
    let optionSelected = partnerCountries?.filter((country) => country.value.toLowerCase() === value?.toLowerCase())?.[0];
    if (optionSelected) setSelectedOption(optionSelected);
    else setSelectedOption({ label: '---SELECT---', value: '' });
  }, [partnerCountries, value]);

  return (
    <Controller
      name={name}
      control={control}
      render={() => {
        return (
          <Autocomplete
            id={id}
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            value={selectedOption}
            onChange={handleChange}
            options={partnerCountries}
            loading={isLoadingPartnerCountries}
            autoHighlight
            isOptionEqualToValue={(option, value) => option.iso3 === value.iso3}
            getOptionLabel={(option) => (option?.label ? ucwords(option.label) : '')}
            renderOption={(props, option) => (
              <Box
                key={option.value + '_1'}
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                {option.label} ({option.value})
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  autoComplete: 'new-password',
                  endAdornment: (
                    <Fragment>
                      {isLoadingPartnerCountries ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
                name={name}
                error={!!errors[name]}
                helperText={errors[name]?.message || ''}
                label={label}
                variant={variant}
                fullWidth={fullWidth}
                required={required}
                size={size}
                disabled={disabled}
              />
            )}
          />
        );
      }}
    />
  );
}

FormSelectPartnerCountry.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small', 'large']),
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning']),
  onSelected: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
  partnerId: PropTypes.string,
};

FormSelectPartnerCountry.defaultProps = {
  id: 'select-partner-country',
  label: 'Select a country',
  error: false,
  helperText: '',
  required: false,
  disabled: false,
  fullWidth: true,
  size: 'small',
  variant: 'outlined',
  partnerId: null,
};
