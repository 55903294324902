import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';

export default function FormContainer({ children }) {
  return <Paper sx={{ p: 2 }}>{children}</Paper>;
}

FormContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
};
