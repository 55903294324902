import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import { connect } from 'react-redux';
import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import { compose } from '@reduxjs/toolkit';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { useState, useEffect, useMemo } from 'react';

import Spacer from 'core/spacer/Spacer';
import Button from 'core/buttons/Button';
import CircularProgress from 'core/progress/CircularProgress';

import {
  retryToGetAusTrackReports,
  setShowCreateProcessingModal,
} from 'app/features/ausTrackReports/ausTrackReportsSlice';
import ucfirst from 'helpers/ucfirst';

const style = {
  position: 'absolute',
  top: '64px',
  right: '0',
  width: 384,
  height: 'calc(100vh - 64px)',
  bgcolor: 'background.paper',
  border: 0,
  boxShadow: 24,
  p: 4,
};

function getStep1Label() {
  return 'Exporting Transaction';
}

function getStep2Label(isProcessed, isFailed) {
  return isProcessed
    ? 'Transaction Exported Successfully'
    : isFailed
    ? 'Transaction Export Failed'
    : '';
}

function AusTrackReportsProgressModal(props) {
  const dispatch = useDispatch();

  const { open, isProcessing, isProcessed, isFailed, errorMessage } = props;

  const steps = useMemo(() => {
    return [
      {
        label: getStep1Label(),
        showProgress: true,
        color: 'primary',
      },
      {
        label: getStep2Label(isProcessed, isFailed),
        showProgress: false,
        color: isProcessed ? 'primary' : 'error',
      },
    ];
  }, [isProcessed, isFailed]);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const hideProgressModal = () => {
    dispatch(setShowCreateProcessingModal(false));
  };

  useEffect(() => {
    if (isProcessing) handleNext();
  }, [isProcessing]);

  useEffect(() => {
    if (isProcessed) handleNext();
  }, [isProcessed]);

  return (
    <div>
      <Modal open={open} onClose={() => {}}>
        <Box sx={style}>
          <Box sx={{ maxWidth: 400 }}>
            {/* Step process */}

            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step) => (
                <Step key={step.label}>
                  <StepLabel color={step.color}>
                    <Box display="flex" gap={2} alignItems="center">
                      <Typography color={step.color}>{step.label}</Typography>
                      {isProcessing && step.showProgress && (
                        <CircularProgress size={30} thickness={6} />
                      )}
                    </Box>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Spacer />

            {/* If Form Process Failed */}

            {isFailed && (
              <Box>
                <Typography variant="body2" color="error">
                  {ucfirst(errorMessage)}
                </Typography>
                <Spacer />
                <Button
                  color="primary"
                  onClick={() => dispatch(retryToGetAusTrackReports())}
                  fullWidth
                >
                  Retry
                </Button>
                <Spacer />
                <Button
                  color="error"
                  onClick={() => hideProgressModal()}
                  variant="outlined"
                  fullWidth
                >
                  Cancel
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default compose(connect(mapStateToProps))(AusTrackReportsProgressModal);

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    open: state.ausTrackReports.create.showProcessingModal,
    isProcessing: state.ausTrackReports.create.isCreating,
    isProcessed: state.ausTrackReports.create.isCreated,
    isFailed: state.ausTrackReports.create.isFailed,
    errorMessage: state.ausTrackReports.create.errorMessage,
  };
}

AusTrackReportsProgressModal.propTypes = {
  open: PropTypes.bool,
  isProcessing: PropTypes.bool,
  isProcessed: PropTypes.bool,
  isFailed: PropTypes.bool,
};
