import React from 'react';
import { useNavigate } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Button from 'core/buttons/Button';
import PageContent from 'layouts/PageContent';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';
import PartnerFundingSourceForm from 'components/partner-funding-sources/PartnerFundingSourceForm';
import PartnerFundingSourceProgressIndicatorModal from 'components/partner-funding-sources/PartnerFundingSourceProgressIndicatorModal';
import FundingSourceInfoForm from 'components/Funding-source-info/FundingSourcesInfoForm';
import FundingSourceProgressIndicatorModal from 'components/funding-sources/FundingSourceProgressIndicatorModal';
import FundingSourceInfoProgressIndicatorModal from 'components/Funding-source-info/FundingSourceInfoProgressIndicatorModal';

function AddFundingSourceInfo() {
  const navigate = useNavigate();

  return (
    <PageContent
      title="Add Partner Funding Source Info"
      rightTopEndContent={
        <Button onClick={() => navigate(buildRoute('funding-sources-info.index'))}>
          List Partner Funding Source Info
        </Button>
      }
    >
      <FundingSourceInfoForm isAddMode={true} />
      <FundingSourceInfoProgressIndicatorModal isAddMode={true} />
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddFundingSourceInfo);
