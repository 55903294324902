import React from 'react';
import { useNavigate } from 'react-router-dom';

import withRole from 'hoc/withRole';
import Button from 'core/buttons/Button';
import PageContent from 'layouts/PageContent';
import AccountForm from 'components/accounts/AccountForm';
import AccountProgressIndicatorModal from 'components/accounts/AccountProgressIndicatorModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';

function AddAccount() {
  const navigate = useNavigate();

  return (
    <PageContent
      title="Add Account"
      rightTopEndContent={
        <Button onClick={() => navigate(buildRoute('accounts.index'))}>List Account</Button>
      }
    >
      <AccountForm isAddMode={true} />
      <AccountProgressIndicatorModal isAddMode={true} />
    </PageContent>
  );
}

export default withRole([acl.SUPERADMIN, acl.ADMIN])(AddAccount);
