import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import withRole from 'hoc/withRole';
import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import FinmoPayouts from 'components/finmo-payouts/FinmoPayouts';
// import FinmoPayoutFilterValues from 'components/finmo-payouts/FinmoPayoutFilterValues';
import { resetGetFinmoPayoutsFailure } from 'app/features/finmo-payouts/finmoPayoutSlice';
import FinmoPayoutBriefDetailsModal from 'components/finmo-payouts/FinmoPayoutBriefDetailsModal';

import acl from 'app/config/acl';
import { buildRoute } from 'routes';

function ListFinmoPayout({ isNetworkError }) {
  const finmoPayoutState = useSelector((state) => state.finmoPayouts.index);

  const { isError, errorCode } = finmoPayoutState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetFinmoPayoutsFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetFinmoPayoutsFailure())}
      />
    );

  return (
    <PageContent title="Finmo Payouts">
      {/* <FinmoPayoutFilterValues /> */}
      <FinmoPayouts />
      <FinmoPayoutBriefDetailsModal />
    </PageContent>
  );
}

export default compose(withAppState, withRole([acl.SUPERADMIN]))(ListFinmoPayout);
