import { createSlice } from '@reduxjs/toolkit';

import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import Response from 'app/config/Response';

const initialState = {
  index: {
    data: [],
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc',
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
  create: {
    showProcessingModal: false,
    isCreating: false,
    isCreated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },
  edit: {
    isLoading: false,
    isError: false,
    errorCode: null,
    data: null,
    errorMessage: null,
  },
  update: {
    showProcessingModal: false,
    isUpdating: false,
    isUpdated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },
  show: {
    showBriefDetailModal: false,
    partnerFundingSourceId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
};

export const partnerFundingSourceSlice = createSlice({
  name: 'partnerFundingSources',
  initialState,
  reducers: {
    getPartnerFundingSourcesInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getPartnerFundingSourcesSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getPartnerFundingSourcesFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetPartnerFundingSourcesFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    addPartnerFundingSourceInit: (state) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: true,
        isCreated: false,
        isFailed: false,
        errors: null,
        data: null,
      };
    },

    addPartnerFundingSourceSuccess: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },

    addPartnerFundingSourceFailure: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: false,
        isFailed: true,
        errors: null,
        data: null,
        errorMessage: payload?.response?.data?.message || '',
      };
    },

    retryToCreate: (state) => {
      state.create.retries += 1;
    },

    setShowCreateProcessingModal: (state, { payload }) => {
      state.create.showProcessingModal = payload;
    },

    editPartnerFundingSourceInit: (state) => {
      state.edit = {
        ...state.edit,
        isLoading: true,
        isError: false,
        errorCode: null,
        data: null,
        errorMessage: null,
      };

      state.update = {
        ...state.update,
        showProcessingModal: false,
      };
    },

    editPartnerFundingSourceSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.edit = {
          ...state.edit,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          data: null,
          errorMessage: 'PartnerFundingSource not found',
        };
      } else {
        state.edit = {
          ...state.edit,
          isLoading: false,
          isError: false,
          errorCode: null,
          data: payload.data,
        };
      }
    },

    editPartnerFundingSourceFailure: (state, { payload }) => {
      state.edit = {
        ...state.edit,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        data: payload,
      };
    },

    updatePartnerFundingSourceInit: (state) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: true,
        isUpdated: false,
        isFailed: false,
        errors: null,
        data: null,
      };
    },

    updatePartnerFundingSourceSuccess: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: false,
        isUpdated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },

    updatePartnerFundingSourceFailure: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: false,
        isUpdated: false,
        isFailed: true,
        errors: payload,
        data: null,
        errorMessage: payload?.response?.data?.message || '',
      };
    },

    retryToUpdate: (state) => {
      state.update.retries += 1;
    },

    setShowEditProcessingModal: (state, { payload }) => {
      state.update.showProcessingModal = payload;
    },

    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParam: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    getPartnerFundingSourceInit: (state) => {
      state.show = {
        ...state.show,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getPartnerFundingSourceSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          errorMessage: 'PartnerFundingSource not found',
        };
      } else {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: false,
          errorCode: null,
          errorMessage: null,
          data: payload.data,
        };
      }
    },

    getPartnerFundingSourceFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: null,
      };
    },

    resetGetPartnerFundingSourceFailure: (state) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getPartnerFundingSourceBriefDetailsInit: (state, { payload }) => {
      state.show = {
        ...state.show,
        showBriefDetailModal: payload.showBriefDetailModal,
        partnerFundingSourceId: payload.partnerFundingSourceId,
      };
    },

    hidePartnerFundingSourceBriefDetails: (state) => {
      state.show = {
        showBriefDetailModal: false,
        partnerFundingSourceId: null,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    deletePartnerFundingSourceInit: (state, { payload: partnerFundingSourceId }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == partnerFundingSourceId) {
          return {
            ...item,
            isDeleting: true,
          };
        }
        return item;
      });
    },

    deletePartnerFundingSourceSuccess: (state, { payload: partnerFundingSourceId }) => {
      const { page_number: currentPage, page_size: pageSize } = state.index.params;

      let startSN = (currentPage - 1) * pageSize;

      let data = state.index.data.filter((item) => item.id !== partnerFundingSourceId);

      state.index.data = data.map((item) => ({ ...item, sn: (startSN += 1) }));
    },

    deletePartnerFundingSourceFailure: (state, { payload: partnerFundingSourceId }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == partnerFundingSourceId) {
          return {
            ...item,
            isDeleting: false,
          };
        }
        return item;
      });
    },
  },
});

export const {
  getPartnerFundingSourcesInit,
  getPartnerFundingSourcesSuccess,
  getPartnerFundingSourcesFailure,
  resetGetPartnerFundingSourcesFailure,
  addPartnerFundingSourceInit,
  addPartnerFundingSourceSuccess,
  addPartnerFundingSourceFailure,
  retryToCreate,
  updatePartnerFundingSourceInit,
  updatePartnerFundingSourceSuccess,
  updatePartnerFundingSourceFailure,
  retryToUpdate,
  setParam,
  resetParam,
  deleteFilterParams,
  setShowCreateProcessingModal,
  setShowEditProcessingModal,
  getPartnerFundingSourceInit,
  getPartnerFundingSourceSuccess,
  getPartnerFundingSourceFailure,
  resetGetPartnerFundingSourceFailure,
  editPartnerFundingSourceInit,
  editPartnerFundingSourceSuccess,
  editPartnerFundingSourceFailure,
  getPartnerFundingSourceBriefDetailsInit,
  hidePartnerFundingSourceBriefDetails,
  deletePartnerFundingSourceInit,
  deletePartnerFundingSourceSuccess,
  deletePartnerFundingSourceFailure,
} = partnerFundingSourceSlice.actions;

export default partnerFundingSourceSlice.reducer;
