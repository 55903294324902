import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

function SettingItem({ labelPrimary, labelSecondary, content, isActive, actions }) {
  return (
    <Box
      sx={{
        w: '100%',
        px: 2,
        py: 1,
        display: 'inline-flex',
        border: '1px solid',
        borderColor: 'grey.400',
        borderRadius: '4px',
        my: 1
      }}
    >
      <Box display="inline-block" sx={{ width: '25%', height: 'inherit' }}>
        <div className="label__container">
          <Typography variant="subtitle1" fontWeight="400">
            {labelPrimary}
          </Typography>
          <Typography variant="caption">{labelSecondary}</Typography>
        </div>
      </Box>
      <Divider />
      <Box display="inline-block" sx={{ width: '65%', height: 'inherit' }}>
        {content}
      </Box>
      <Box display="inline-block" sx={{ width: '10%', height: 'inherit' }}>
        {isActive ? null : (
          <Box display="flex" justifyContent="flex-end">
            {actions}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default SettingItem;

SettingItem.propTypes = {
  labelPrimary: PropTypes.string,
  labelSecondary: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  actions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  isActive: PropTypes.bool
};
