import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';

import { getCountries } from 'app/features/constants/constantSaga';

import ucwords from 'helpers/ucwords';

export default function FormSelectCountry(props) {
  const {
    id,
    name,
    label,
    error,
    helperText,
    required,
    size,
    disabled,
    fullWidth,
    variant,
    value,
    onSelected,
  } = props;

  const dispatch = useDispatch();

  const [selectedOption, setSelectedOption] = useState(null);

  const { data: options, isLoading } = useSelector((state) => state.constants.countries);

  const [open, setOpen] = useState(false);

  const handleChange = (e, value) => {
    e.preventDefault();
    setSelectedOption(value);
    if (typeof onSelected === 'function') onSelected(value);
  };

  useEffect(() => {
    if (options.length <= 0) dispatch(getCountries());
  }, []);

  useEffect(() => {
    let optionSelected = options?.filter((country) => country.iso3 === value)?.[0];
    if (optionSelected) setSelectedOption(optionSelected);
  }, [options, value]);

  return (
    <Autocomplete
      id={id}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={selectedOption}
      onChange={handleChange}
      options={options}
      loading={isLoading}
      disabled={disabled}
      autoHighlight
      isOptionEqualToValue={(option, value) => option.iso3 === value.iso3}
      getOptionLabel={(option) => (option?.country ? ucwords(option.country) : '')}
      renderOption={(props, option) => (
        <Box
          key={option.country_id}
          component="li"
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.iso2.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.iso2.toLowerCase()}.png 2x`}
            alt=""
          />
          {ucwords(option.country)} ({option.iso3})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password',
            endAdornment: (
              <Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          name={name}
          error={error}
          helperText={helperText}
          label={label}
          variant={variant}
          fullWidth={fullWidth}
          required={required}
          size={size}
          disabled={disabled}
        />
      )}
    />
  );
}

FormSelectCountry.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small', 'large']),
  fullWidth: PropTypes.bool.isRequired,
  multiline: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
  focused: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning']),
  onSelected: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

FormSelectCountry.defaultProps = {
  label: 'Select a country',
  error: false,
  helperText: '',
  required: false,
  disabled: false,
  fullWidth: true,
  multiline: false,
  size: 'small',
  variant: 'outlined',
  focused: false,
  value: '',
};
