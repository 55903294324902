import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import Error from 'core/errors/Error';
import FormCheckbox from 'core/forms/FormCheckbox';
import TableContainer from 'core/table/TableContainer';
import TanstackReactTable from 'core/table/TanstackReactTable';

import DateUtils from 'utils/DateUtils';
import useAuthUser from 'hooks/useAuthUser';
import constants from 'app/config/constants';

import WebhookLogPagination from 'components/zai-transactions/WebhookLogPagination';
import { getZaiWebhookLogs } from 'app/features/transactions/zaiTransactionSaga';
import ZaiWebhookLogsAction from './ZaiWebhookLogsAction';

export default function ZaiWebhookLogs() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useAuthUser();

  const [columnVisibility, setColumnVisibility] = useState({});

  const transactionState = useSelector((state) => state.zaiTransactions.webhookLogs);

  const { data, params, isLoading, isError, errorCode } = transactionState;

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        header: 'Customer Name',
        accessorFn: (row) => row.customer_name,
      },
      {
        header: 'User ID',
        accessorFn: (row) => row.user_id,
      },

      {
        header: 'Reference Number',
        accessorFn: (row) => row.reference_no,
      },

      {
        header: 'Method Type',
        accessorFn: (row) => row.type_method,
      },
      {
        header: 'Status',
        accessorFn: (row) => row.status_description,
      },
      {
        header: 'Payment Amount',
        accessorFn: (row) => row.payment_amount,
        cell: ({ getValue }) => <Typography align="right">{getValue()}</Typography>,
      },

      {
        header: 'Transaction ID',
        accessorFn: (row) => row.transaction_id,
      },

      {
        header: 'Currency',
        accessorFn: (row) => row.transaction_currency,
      },
      {
        header: 'Created Date',
        accessorFn: (row) => DateUtils.isoToString(row.created_ts),
      },
      {
        header: 'Updated Date',
        accessorFn: (row) => (row.updated_ts ? DateUtils.isoToString(row.updated_ts) : '-'),
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  if (isError) return <Error status={errorCode} />;

  useEffect(() => {
    dispatch(
      getZaiWebhookLogs({
        ...params,
      }),
    );
  }, [JSON.stringify(params)]);

  return (
    <TableContainer>
      <ZaiWebhookLogsAction table={table} />
      <TanstackReactTable
        table={table}
        isLoading={isLoading}
        data={data}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <WebhookLogPagination />
    </TableContainer>
  );
}
