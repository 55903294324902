import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import constants from 'app/config/constants';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import Error from 'core/errors/Error';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import TableContainer from 'core/table/TableContainer';
import TanstackReactTable from 'core/table/TanstackReactTable';
import { useConfirm } from 'core/mui-confirm';

import { buildRoute } from 'routes';
import DateUtils from 'utils/DateUtils';
import Dimensions from 'app/config/Dimensions';
import LicensingPartnerActions from './LicensingPartnerActions';
import LicensingPartnerPagination from './LicensingPartnerPagination';
import {
  getLicensingPartners,
  deleteLicensingPartner,
} from 'app/features/licensing-partners/licensingPartnerSaga';
import { getLicensingPartnerBriefDetailsInit } from 'app/features/licensing-partners/licensingPartnerSlice';
import FallbackImageLoader from 'core/fallbackImageLoader/FallbackImageLoader';

const TextFlowWrapper = styled(Typography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '20rem',
}));

function LicensingPartners({ isActive }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const confirm = useConfirm();

  const licensingPartnersState = useSelector((state) => state.licensingPartners.index);

  const { data, params, isLoading, isError, errorCode } = licensingPartnersState;

  const handleDelete = useCallback(
    (LicensingPartnerId) => {
      confirm({
        icon: 'warning',
        confirmationText: 'Yes, Delete it.',
        description: 'This will permanently delete this licensing partner.',
      })
        .then(() => {
          return dispatch(deleteLicensingPartner(LicensingPartnerId));
        })
        .then(() => {
          dispatch(getLicensingPartners());
        })
        .catch(() => {});
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        id: 'name',
        header: 'Partner',
        accessorFn: (row) => row.name,
      },
      {
        id: 'country',
        header: 'Country',
        accessorFn: (row) => row.country ?? '-',
      },

      {
        id: 'logo',
        header: 'Logo',
        accessorFn: (row) => row.logo,
        cell: ({ row }) => <FallbackImageLoader imageUrl={row.original.logo} />,
      },

      {
        id: 'licensing-number',
        header: 'License Number',
        accessorFn: (row) => row.license_number,
      },
      {
        id: 'created_ts',
        header: 'Created At',
        accessorFn: (row) => (row.created_ts ? DateUtils.isoToString(row.created_ts) : '-'),
      },
      {
        id: 'updated_ts',
        header: 'Updated At',
        accessorFn: (row) => (row.updated_ts ? DateUtils.isoToString(row.updated_ts) : '-'),
      },
      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,
        cell: ({ row }) => (
          <Box display="flex" gap={1}>
            <IconButton
              size={Dimensions.table.iconButton.size}
              onClick={() => {
                dispatch(
                  getLicensingPartnerBriefDetailsInit({
                    showBriefDetailModal: true,
                    licensingPartnerId: row.original.id,
                  }),
                );
              }}
            >
              <AspectRatioIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
            <IconButton
              size={Dimensions.table.iconButton.size}
              onClick={() => navigate(buildRoute('licensing-partners.show', row.original.id))}
            >
              <FullscreenIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>

            <IconButton
              size={Dimensions.table.iconButton.size}
              color="error"
              onClick={() => handleDelete(row.original.id)}
              disabled={row.original?.isDeleting || false}
            >
              <DeleteForeverIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
          </Box>
        ),
      },
    ],
    [JSON.stringify(data)],
  );

  const [columnVisibility, setColumnVisibility] = useState(
    columns
      .filter((c) => c?.isVisible === false)
      .map((c) => c.id)
      .reduce((a, k) => ({ ...a, [k]: false }), {}),
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(getLicensingPartners({ is_active: isActive, ...params }));
  }, [JSON.stringify(params)]);

  if (isError) return <Error status={errorCode} />;

  return (
    <TableContainer>
      <LicensingPartnerActions table={table} />
      <TanstackReactTable
        table={table}
        isLoading={isLoading}
        data={data}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />

      <LicensingPartnerPagination />
    </TableContainer>
  );
}

LicensingPartners.propTypes = {
  isActive: PropTypes.bool,
};

export default LicensingPartners;
