import { useState } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import Logout from '@mui/icons-material/Logout';
import MuiMenuItem from '@mui/material/MenuItem';
import styled from '@mui/material/styles/styled';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Settings from '@mui/icons-material/Settings';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAdd from '@mui/icons-material/PersonAdd';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import Spacer from 'core/spacer/Spacer';

import { buildRoute } from 'routes';
import ucwords from 'helpers/ucwords';
import { setIsLogoutModalOpen } from 'app/features/auth/authSlice';

const UserBriefDetailWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(1, 2),
  columnGap: theme.spacing(2),
  '& .MuiAvatar-root': {
    margin: 0,
    height: '42px',
    width: '42px'
  }
}));

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  padding: theme.spacing(1.2, 2),
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main
  },
  '& .MuiListItemText-root': {
    color: theme.palette.primary.main
  },
  '&:hover': {
    backgroundColor: '#232D6C1A'
  }
}));

export default function AccountMenu() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="large"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            color="inherit"
          >
            <AccountCircleIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            px: 1.5,
            minWidth: '250px',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <UserBriefDetailWrapper>
          <Box>
            <Avatar
              src={`https://ui-avatars.com/api/?name=${user?.name}&background=232D6C&color=fff`}
              variant="rounded"
            />
          </Box>
          <Box>
            <Typography variant="body1">{user?.name}</Typography>
            <Typography variant="caption">{ucwords(user?.user_type)}</Typography>
          </Box>
        </UserBriefDetailWrapper>
        <Divider />
        <Spacer />
        <MenuItem onClick={() => navigate(buildRoute('profile'))}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => navigate(buildRoute('settings'))}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => dispatch(setIsLogoutModalOpen(true))}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
