import React from 'react';
import PropTypes from 'prop-types';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';

import Button from './Button';

export default function FilterButton({ isOpen, ...rest }) {
  return (
    <Button
      variant="outlined"
      endIcon={isOpen ? <FilterAltOffRoundedIcon /> : <FilterAltRoundedIcon />}
      uppercase
      {...rest}
    >
      Filter
    </Button>
  );
}

FilterButton.propTypes = {
  isOpen: PropTypes.bool,
};
