import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import PartnerForm from 'components/partners/PartnerForm';
import PartnerFormSkeleton from 'components/partners/PartnerFormSkeleton';
import PartnerProgressIndicatorModal from 'components/partners/PartnerProgressIndicatorModal';

import acl from 'app/config/acl';
import withRole from 'hoc/withRole';
import { editPartner } from 'app/features/partners/partnerSaga';

function EditPartner({ isNetworkError }) {
  const params = useParams();

  const dispatch = useDispatch();

  const {
    data: partner,
    isError,
    errorMessage,
    isLoading,
    errorCode
  } = useSelector((state) => state.partners.edit);

  useEffect(() => {
    dispatch(editPartner(params.partnerId));
  }, [params.partnerId, isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        message={errorMessage}
        onRetry={() => dispatch(editPartner(params.partnerId))}
      />
    );

  return (
    <PageContent title="Edit Partner">
      {isLoading ? <PartnerFormSkeleton /> : <PartnerForm isAddMode={false} partner={partner} />}
      <PartnerProgressIndicatorModal isAddMode={false} />
    </PageContent>
  );
}

export default compose(withAppState, withRole(acl.SUPERADMIN))(EditPartner);
