import { createSlice } from '@reduxjs/toolkit';

import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import Response from 'app/config/Response';

const initialState = {
  index: {
    data: [],
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc'
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null
  },
  create: {
    showProcessingModal: false,
    isCreating: false,
    isCreated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null
  },
  edit: {
    isLoading: false,
    isError: false,
    errorCode: null,
    data: null,
    errorMessage: null
  },
  update: {
    showProcessingModal: false,
    isUpdating: false,
    isUpdated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null
  },
  show: {
    showBriefDetailModal: false,
    apiConfigId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null
  }
};

export const apiConfigSlice = createSlice({
  name: 'apiConfigs',
  initialState,
  reducers: {
    getApiConfigsInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null
      };
    },

    getApiConfigsSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage
      };
    },

    getApiConfigsFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message
      };
    },

    resetGetApiConfigsFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null
      };
    },

    addApiConfigInit: (state) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: true,
        isCreated: false,
        isFailed: false,
        errors: null,
        data: null
      };
    },

    addApiConfigSuccess: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: true,
        isFailed: false,
        errors: null,
        data: payload.data
      };
    },

    addApiConfigFailure: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: false,
        isFailed: true,
        errors: null,
        data: null,
        errorMessage: payload?.response?.data?.message || ''
      };
    },

    retryToCreate: (state) => {
      state.create.retries += 1;
    },

    setShowCreateProcessingModal: (state, { payload }) => {
      state.create.showProcessingModal = payload;
    },

    editApiConfigInit: (state) => {
      state.edit = {
        ...state.edit,
        isLoading: true,
        isError: false,
        errorCode: null,
        data: null,
        errorMessage: null
      };

      state.update = {
        ...state.update,
        showProcessingModal: false
      };
    },

    editApiConfigSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.edit = {
          ...state.edit,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          data: null,
          errorMessage: 'ApiConfig not found'
        };
      } else {
        state.edit = {
          ...state.edit,
          isLoading: false,
          isError: false,
          errorCode: null,
          data: payload.data
        };
      }
    },

    editApiConfigFailure: (state, { payload }) => {
      state.edit = {
        ...state.edit,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        data: payload
      };
    },

    updateApiConfigInit: (state) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: true,
        isUpdated: false,
        isFailed: false,
        errors: null,
        data: null
      };
    },

    updateApiConfigSuccess: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: false,
        isUpdated: true,
        isFailed: false,
        errors: null,
        data: payload.data
      };
    },

    updateApiConfigFailure: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: false,
        isUpdated: false,
        isFailed: true,
        errors: payload,
        data: null,
        errorMessage: payload?.response?.data?.message || ''
      };
    },

    retryToUpdate: (state) => {
      state.update.retries += 1;
    },

    setShowEditProcessingModal: (state, { payload }) => {
      state.update.showProcessingModal = payload;
    },

    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParam: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    getApiConfigInit: (state) => {
      state.show = {
        ...state.show,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null
      };
    },

    getApiConfigSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          errorMessage: 'ApiConfig not found'
        };
      } else {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: false,
          errorCode: null,
          errorMessage: null,
          data: payload.data
        };
      }
    },

    getApiConfigFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: null
      };
    },

    resetGetApiConfigFailure: (state) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null
      };
    },

    getApiConfigBriefDetailsInit: (state, { payload }) => {
      state.show = {
        ...state.show,
        showBriefDetailModal: payload.showBriefDetailModal,
        apiConfigId: payload.apiConfigId
      };
    },

    hideApiConfigBriefDetails: (state) => {
      state.show = {
        showBriefDetailModal: false,
        apiConfigId: null,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null
      };
    }
  }
});

export const {
  getApiConfigsInit,
  getApiConfigsSuccess,
  getApiConfigsFailure,
  resetGetApiConfigsFailure,
  addApiConfigInit,
  addApiConfigSuccess,
  addApiConfigFailure,
  retryToCreate,
  updateApiConfigInit,
  updateApiConfigSuccess,
  updateApiConfigFailure,
  retryToUpdate,
  setParam,
  resetParam,
  deleteFilterParams,
  setShowCreateProcessingModal,
  setShowEditProcessingModal,
  getApiConfigInit,
  getApiConfigSuccess,
  getApiConfigFailure,
  resetGetApiConfigFailure,
  editApiConfigInit,
  editApiConfigSuccess,
  editApiConfigFailure,
  getApiConfigBriefDetailsInit,
  hideApiConfigBriefDetails
} = apiConfigSlice.actions;

export default apiConfigSlice.reducer;
