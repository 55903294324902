import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { compose } from '@reduxjs/toolkit';
import { useDispatch, connect } from 'react-redux';
import Pagination from 'core/pagination/Pagination';

import { setParam } from 'app/features/licensing-partners/licensingPartnerSlice';

function LicensingPartnerPagination({ totalCount, pageSize, pageNumber }) {
  const dispatch = useDispatch();

  return (
    <Box component="div" sx={{ py: 2 }}>
      <Pagination
        count={Math.ceil(totalCount / pageSize)}
        page={pageNumber}
        onChange={(currentPage) =>
          dispatch(
            setParam({
              page_number: currentPage,
            }),
          )
        }
        onRowPerPageChange={(rowPerPage) =>
          dispatch(
            setParam({
              page_size: rowPerPage,
              page_number: 1,
            }),
          )
        }
      />
    </Box>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    totalCount: state.licensingPartners.index.totalCount,
    pageSize: state.licensingPartners.index.params.page_size,
    pageNumber: state.licensingPartners.index.params.page_number,
  };
}

export default compose(connect(mapStateToProps))(LicensingPartnerPagination);

LicensingPartnerPagination.propTypes = {
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  pageNumber: PropTypes.number,
};
