import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { useSelector, useDispatch } from 'react-redux';

import Error from 'core/errors/Error';
import TableContainer from 'core/table/TableContainer';
import WebhookLogPagination from './WebhookLogPagination';
import TableBodySkeleton from 'core/table/TableBodySkeleton';
import MakePaymentTableRowForm from './MakePaymentTableRowForm';

import { ZaiWebhookLogStatus } from 'app/config/ZaiWebHookLogConfig';
import { getZaiWebhookLogs } from 'app/features/transactions/zaiTransactionSaga';

export default function MakePaymentWebhookLogs({ customerId, transactionId, status }) {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.zaiTransactions.webhookLogs);

  const { data, params, isLoading, isError, errorCode } = state;

  useEffect(() => {
    dispatch(
      getZaiWebhookLogs({
        ...params,
        UserId: customerId,
        status,
      }),
    );
  }, [JSON.stringify(params)]);

  const handleOnMakePaymentSuccess = () => {
    dispatch(
      getZaiWebhookLogs({
        ...params,
        UserId: customerId,
        status,
      }),
    );
  };

  if (isError) return <Error status={errorCode} />;

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>SN</TableCell>
            <TableCell
              sx={{
                minWidth: '15rem',
              }}
            >
              Debtor Information
            </TableCell>
            <TableCell>Webhook ID</TableCell>
            <TableCell>Transaction ID</TableCell>
            <TableCell align="right">Transaction Amount</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell>Payment Amount</TableCell>
            <TableCell>Remarks</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableBodySkeleton rowCount={10} columnCount={9} />
          ) : data?.length <= 0 ? (
            <TableRow>
              <TableCell colSpan={6} align="center">
                No data found
              </TableCell>
            </TableRow>
          ) : (
            <>
              {data?.map((row) => (
                <MakePaymentTableRowForm
                  key={row.webhook_id}
                  row={row}
                  transactionId={transactionId}
                  onMakePaymentSuccess={handleOnMakePaymentSuccess}
                />
              ))}
            </>
          )}
        </TableBody>
      </Table>
      <WebhookLogPagination />
    </TableContainer>
  );
}

MakePaymentWebhookLogs.propTypes = {
  customerId: PropTypes.string.isRequired,
  status: PropTypes.oneOf([ZaiWebhookLogStatus.PENDING, ZaiWebhookLogStatus.REFUNDABLE]).isRequired,
  transactionId: PropTypes.string.isRequired,
};
