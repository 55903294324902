import { useEffect } from 'react';
import { compose } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Error from 'core/errors/Error';
import withAppState from 'hoc/withAppState';
import PageContent from 'layouts/PageContent';
import Beneficiaries from 'components/beneficiaries/Beneficiaries';
import { resetGetBeneficiariesFailure } from 'app/features/beneficiaries/beneficiarySlice';
import BeneficiaryBriefDetailsModal from 'components/beneficiaries/BeneficiaryBriefDetailsModal';

function ListBeneficiary({ isNetworkError }) {
  const beneficiaryState = useSelector((state) => state.beneficiaries.index);

  const { isError, errorCode } = beneficiaryState;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (isError) dispatch(resetGetBeneficiariesFailure());
  }, [isNetworkError]);

  if (isError)
    return (
      <Error
        status={errorCode}
        withInDashboard={true}
        onRetry={() => dispatch(resetGetBeneficiariesFailure())}
      />
    );

  return (
    <PageContent title="Beneficiaries">
      {/* <BeneficiaryFilterValues /> */}
      <Beneficiaries />
      <BeneficiaryBriefDetailsModal />
    </PageContent>
  );
}

export default compose(withAppState)(ListBeneficiary);
