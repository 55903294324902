import * as Yup from 'yup';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';

import Button from 'core/buttons/Button';
import CancelButton from 'core/buttons/CancelButton';
import FormTextField from 'hook-form-controls/FormTextField';

import app from 'app/config/app';
import YupPassword from 'utils/YupPassword';
import { changePassword } from 'app/features/auth/authSaga';

YupPassword(Yup);

const resetPasswordSchema = Yup.object().shape({
  current_password: Yup.string(),
  new_password: Yup.string()
    .required('New Password is required')
    .min(8, 'Password must contain 8 or more characters')
    .minLowercase(1, 'Password must contain at least 1 lower case letter')
    .minUppercase(1, 'Password must contain at least 1 upper case letter')
    .minNumbers(1, 'Password must contain at least 1 number')
    .minSymbols(1, 'Password must contain at least 1 special character'),
  confirm_password: Yup.string()
    .required('Please retype your new password.')
    .oneOf([Yup.ref('new_password')], 'Your passwords do not match.')
});

function ChangePasswordForm({ setActiveSetting }) {
  const {
    isProcessing,
    errors: formErrors,
    isSuccess
  } = useSelector((state) => state.auth.changePasswordForm);

  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: {
      ...(app.isDevelopmentMode()
        ? {
            current_password: 'Kathmandu@123',
            new_password: 'Kathmandu@123',
            confirm_password: 'Kathmandu@123'
          }
        : {})
    },
    resolver: yupResolver(resetPasswordSchema),
    mode: 'onChange'
  });

  const { formState, handleSubmit, reset } = methods;

  const errors = { ...formState.errors, ...(typeof formErrors === 'object' ? formErrors : {}) };

  const onSubmit = async (data) => {
    dispatch(changePassword(data));
  };

  useEffect(() => {
    if (isSuccess) setActiveSetting(null), reset();
  }, [isSuccess]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" gap={2} sx={{ width: '350px' }}>
          <Box>
            <FormTextField
              name="current_password"
              label="Current Password"
              type="password"
              error={!!errors?.current_password}
              helperText={errors?.current_password?.message ?? ''}
            />
          </Box>
          <Box>
            <FormTextField
              name="new_password"
              label="New Password"
              type="password"
              error={!!errors?.new_password}
              helperText={errors?.new_password?.message ?? ''}
            />
          </Box>
          <Box>
            <FormTextField
              name="confirm_password"
              label="Renter Password"
              type="password"
              error={!!errors?.confirm_password}
              helperText={errors?.confirm_password?.message ?? ''}
            />
          </Box>
          <Box>
            <Stack direction="row" gap={2}>
              <Button
                type="submit"
                variant="contained"
                size="small"
                color="primary"
                disabled={isProcessing}
              >
                {!isProcessing ? 'Change Password' : 'Processing'}
              </Button>
              <CancelButton
                onClick={() => (setActiveSetting(null), reset())}
                disabled={isProcessing}
              >
                Cancel
              </CancelButton>
            </Stack>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
}

export default ChangePasswordForm;

ChangePasswordForm.propTypes = {
  setActiveSetting: PropTypes.func
};
