import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import Notify from 'utils/Notify';
import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getWebhookHeadersInit,
  getWebhookHeadersSuccess,
  getWebhookHeadersFailure,
  addWebhookHeaderInit,
  addWebhookHeaderSuccess,
  addWebhookHeaderFailure,
  updateWebhookHeaderInit,
  updateWebhookHeaderSuccess,
  updateWebhookHeaderFailure,
  getWebhookHeaderInit,
  getWebhookHeaderFailure,
  getWebhookHeaderSuccess,
  editWebhookHeaderInit,
  editWebhookHeaderSuccess,
  editWebhookHeaderFailure,
  deleteWebhookHeaderInit,
  deleteWebhookHeaderSuccess,
  deleteWebhookHeaderFailure,
} from './webhookHeaderSlice';

const getWebhookHeader = createAction('webhookHeaders/get');
const createWebhookHeader = createAction('webhookHeaders/create');
const updateWebhookHeader = createAction('webhookHeaders/update');
const getWebhookHeaders = createAction('webhookHeaders/list');
const editWebhookHeader = createAction('webhookHeaders/edit');
const deleteWebhookHeader = createAction('webhookHeaders/delete');

/**
 * create a webhookHeader
 *
 * @param {object} data
 */
function* createWebhookHeaderSaga({ payload: data }) {
  yield put(addWebhookHeaderInit());
  try {
    let response = yield callWrapperSaga(() => http.post(apiEndpoints.createWebhookHeader, data));
    yield put(addWebhookHeaderSuccess(response.data));
    Notify.success('New Webhook Header created successfully.');
  } catch (err) {
    yield put(addWebhookHeaderFailure(err));
    Notify.error('Failed to create new webhook header.');
  }
}

/**
 * update a webhookHeader
 *
 * @param {object} { id, ...data }
 */
function* updateWebhookHeaderSaga({ payload: { id, ...data } }) {
  try {
    yield put(updateWebhookHeaderInit());
    let endpoint = buildApiRoute(apiEndpoints.updateWebhookHeader, id);
    let response = yield callWrapperSaga(() => http.put(endpoint, data));
    yield put(updateWebhookHeaderSuccess(response.data));
  } catch (err) {
    yield put(updateWebhookHeaderFailure(err));
    Notify.error('Webhook Header update failed');
  }
}

/**
 * get a single webhookHeader
 */
function* getWebhookHeaderSaga({ payload: webhookHeaderId }) {
  try {
    yield put(getWebhookHeaderInit());
    let endpoint = buildApiRoute(apiEndpoints.getWebhookHeader, webhookHeaderId);
    let response = yield callWrapperSaga(() => http.get(endpoint, webhookHeaderId));
    yield put(getWebhookHeaderSuccess(response.data));
  } catch (err) {
    yield put(getWebhookHeaderFailure(err));
  }
}

/**
 * get a webhookHeaders list
 *
 */
function* getWebhookHeadersSaga({ payload }) {
  yield put(getWebhookHeadersInit());
  try {
    let endpoint = `${apiEndpoints.getWebhookHeaders}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getWebhookHeadersSuccess(response.data));
  } catch (err) {
    yield put(getWebhookHeadersFailure(err));
  }
}

/**
 * initiate edit webhookHeader
 * */
function* editWebhookHeaderSaga({ payload: webhookHeaderId }) {
  try {
    yield put(editWebhookHeaderInit());
    let endpoint = buildApiRoute(apiEndpoints.getWebhookHeader, webhookHeaderId);
    let response = yield callWrapperSaga(() => http.get(endpoint, webhookHeaderId));
    yield put(editWebhookHeaderSuccess(response.data));
  } catch (err) {
    yield put(editWebhookHeaderFailure(err));
  }
}

function* deleteWebhookHeaderSaga({ payload: webhookHeaderId }) {
  try {
    yield put(deleteWebhookHeaderInit(webhookHeaderId));
    let endpoint = buildApiRoute(apiEndpoints.deleteWebhookHeader, webhookHeaderId);
    yield callWrapperSaga(() => http.delete(endpoint));
    Notify.success('Webhook Header deleted successfully.');
    yield put(deleteWebhookHeaderSuccess(webhookHeaderId));
  } catch (err) {
    Notify.error('Failed to delete webhook header');
    yield put(deleteWebhookHeaderFailure(err));
  }
}

function* webhookHeaderSaga() {
  yield takeLatest(createWebhookHeader, createWebhookHeaderSaga);
  yield takeLatest(updateWebhookHeader, updateWebhookHeaderSaga);
  yield takeLatest(getWebhookHeader, getWebhookHeaderSaga);
  yield takeLatest(getWebhookHeaders, getWebhookHeadersSaga);
  yield takeLatest(editWebhookHeader, editWebhookHeaderSaga);
  yield takeLatest(deleteWebhookHeader, deleteWebhookHeaderSaga);
}

export default webhookHeaderSaga;
export {
  createWebhookHeader,
  updateWebhookHeader,
  getWebhookHeader,
  getWebhookHeaders,
  editWebhookHeader,
  deleteWebhookHeader,
};
