import React from 'react';

import Auth from 'utils/Auth';
import acl from 'app/config/acl';

export default function AuthUserIsSuperAdmin({ children }) {
  if (Auth.role() === acl.SUPERADMIN) {
    return <>{children}</>;
  }

  return <></>;
}
