import { createSlice } from '@reduxjs/toolkit';

import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import Response from 'app/config/Response';

const initialState = {
  index: {
    data: [],
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc',
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    success: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
  create: {
    showProcessingModal: false,
    isCreating: false,
    isCreated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },
  edit: {
    isLoading: false,
    isError: false,
    errorCode: null,
    data: null,
    errorMessage: null,
  },
  update: {
    showProcessingModal: false,
    isUpdating: false,
    isUpdated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },
  show: {
    showBriefDetailModal: false,
    partnerId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
};

export const partnerSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    getPartnersInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getPartnersSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getPartnersFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetPartnersFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getPartnersReportDownloadInit: (state) => {
      state.index.isLoading = false;
      state.index.isError = false;
      state.index.errorCode = null;
      state.index.errorMessage = null;
      state.index.success = false;
    },

    getPartnersReportDownloadSuccess: (state, { payload }) => {
      let { data } = payload;

      state.index = {
        ...state.index,
        isLoading: false,
        success: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
      };
    },

    getPartnersReportDownloadFailure: (state, { payload }) => {
      state.index.isLoading = false;
      state.index.isError = true;
      state.index.errorCode = payload?.response?.status ?? 0;
      state.index.errorMessage = payload?.response?.message;
    },

    resetPartnersReportDownload: (state) => {
      state.index.isLoading = false;
      state.index.isError = false;
      state.index.errorCode = null;
      state.index.errorMessage = null;
      state.index.success = false;
    },

    addPartnerInit: (state) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: true,
        isCreated: false,
        isFailed: false,
        errors: null,
        data: null,
      };
    },

    addPartnerSuccess: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },

    addPartnerFailure: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: false,
        isFailed: true,
        errors: null,
        data: null,
        errorMessage: payload?.response?.data?.message || '',
      };
    },

    retryToCreate: (state) => {
      state.create.retries += 1;
    },

    setShowCreateProcessingModal: (state, { payload }) => {
      state.create.showProcessingModal = payload;
    },

    editPartnerInit: (state) => {
      state.edit = {
        ...state.edit,
        isLoading: true,
        isError: false,
        errorCode: null,
        data: null,
        errorMessage: null,
      };

      state.update = {
        ...state.update,
        showProcessingModal: false,
      };
    },

    editPartnerSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.edit = {
          ...state.edit,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          data: null,
          errorMessage: 'Partner not found',
        };
      } else {
        state.edit = {
          ...state.edit,
          isLoading: false,
          isError: false,
          errorCode: null,
          data: payload.data,
        };
      }
    },

    editPartnerFailure: (state, { payload }) => {
      state.edit = {
        ...state.edit,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        data: payload,
      };
    },

    updatePartnerInit: (state) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: true,
        isUpdated: false,
        isFailed: false,
        errors: null,
        data: null,
      };
    },

    updatePartnerSuccess: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: false,
        isUpdated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },

    updatePartnerFailure: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: false,
        isUpdated: false,
        isFailed: true,
        errors: payload,
        data: null,
        errorMessage: payload?.response?.data?.message || '',
      };
    },

    retryToUpdate: (state) => {
      state.update.retries += 1;
    },

    setShowEditProcessingModal: (state, { payload }) => {
      state.update.showProcessingModal = payload;
    },

    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParam: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    getPartnerInit: (state) => {
      state.show = {
        ...state.show,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getPartnerSuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          errorMessage: 'Partner not found',
        };
      } else {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: false,
          errorCode: null,
          errorMessage: null,
          data: payload.data,
        };
      }
    },

    getPartnerFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: null,
      };
    },

    resetGetPartnerFailure: (state) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getPartnerBriefDetailsInit: (state, { payload }) => {
      state.show = {
        ...state.show,
        showBriefDetailModal: payload.showBriefDetailModal,
        partnerId: payload.partnerId,
      };
    },

    hidePartnerBriefDetails: (state) => {
      state.show = {
        showBriefDetailModal: false,
        partnerId: null,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },
  },
});

export const {
  getPartnersInit,
  getPartnersSuccess,
  getPartnersFailure,
  resetGetPartnersFailure,
  addPartnerInit,
  addPartnerSuccess,
  addPartnerFailure,
  retryToCreate,
  updatePartnerInit,
  updatePartnerSuccess,
  updatePartnerFailure,
  retryToUpdate,
  setParam,
  resetParam,
  deleteFilterParams,
  setShowCreateProcessingModal,
  setShowEditProcessingModal,
  getPartnerInit,
  getPartnerSuccess,
  getPartnerFailure,
  resetGetPartnerFailure,
  editPartnerInit,
  editPartnerSuccess,
  editPartnerFailure,
  getPartnerBriefDetailsInit,
  hidePartnerBriefDetails,
  getPartnersReportDownloadInit,
  getPartnersReportDownloadSuccess,
  getPartnersReportDownloadFailure,
  resetPartnersReportDownload,
} = partnerSlice.actions;

export default partnerSlice.reducer;
