import axios from 'axios';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import Notify from 'utils/Notify';
import { buildRoute } from 'routes';
import constants from 'app/config/constants';
import apiEndpoints from 'routes/apiEndpoints';

axios.defaults.headers['Accept'] = 'application/json';
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.timeoutErrorMessage = constants.REQUEST_TIMEOUT_ERROR;

const axiosInstance = axios.create({
  baseURL: app.apiBaseUrl,
  timeout: app.requestTimeout
});

// request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${Auth.getAccessToken()}`
    };
    return config;
  },
  (err) => Promise.reject(err)
);

// response interceptor
axiosInstance.interceptors.response.use(null, (err) => {
  if (err?.message === 'Network Error' || err?.code === 'ERR_NETWORK') {
    return Promise.reject(err);
  }

  if (err?.message === constants.REQUEST_TIMEOUT_ERROR) {
    Notify.error('Request Timeout');
    return Promise.reject(err);
  }

  let retryCount = 0;

  const { config: originalRequest, response } = err;

  /**
   * ----------------------
   * ***** README *******
   * ----------------------
   * below isCorsError check (app.isDevelopmentMode() && err.response.status === 0) is not required in production mode
   * This check is done because in development mode (localhost) api returns 401 but browser throws CORS Policy Error
   */

  let isCorsError = app.isDevelopmentMode() && response?.status === 0;

  const refreshToken = () => {
    return new Promise(function (resolve, reject) {
      axios
        .post(`${app.apiBaseUrl}${apiEndpoints.refreshToken}`, {
          access_token: Auth.getAccessToken(),
          refresh_token: Auth.getRefreshToken()
        })
        .then((response) => {
          let data = response.data.data;
          Auth.setAccessToken(data.token);
          Auth.setRefreshToken(data.refresh_token, data.valid_to);
          originalRequest.headers.Authorization = `Bearer ${data.token}`;
          resolve(axios(originalRequest));
        })
        .catch((error) => {
          if (err.response.status === 400 || retryCount > app.maxRefreshTokenTriesCount) {
            Notify.error('Session is expired');
            sessionStorage.setItem(constants.INTENDED_PATH, window.location.pathname);
            Auth.logout();
            window.location.href = buildRoute('login');
          }

          if (retryCount <= app.maxRefreshTokenTriesCount) {
            retryCount += 1;
            return refreshToken();
          }

          reject(error);
        });
    });
  };

  if (isCorsError || (response?.status === 401 && retryCount <= app.maxRefreshTokenTriesCount)) {
    retryCount += 1;
    return refreshToken();
  }

  return Promise.reject(err);
});

const http = {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  delete: axiosInstance.delete,
  patch: axiosInstance.patch
};

const axiosBaseQuery = () => {
  return async ({ url, method, data }) => {
    try {
      const result = await axiosInstance({ url: url, method, data });
      return { data: result.data };
    } catch (err) {
      return { error: { status: err.response?.status, data: err.response?.data } };
    }
  };
};

export default http;
export { axiosBaseQuery };
