export const KycLogsStatus = {
  True: true,
  False: false,
};

export const isKycExpiredOptions = [
  { value: KycLogsStatus.True, label: 'Yes' },
  { value: KycLogsStatus.False, label: 'No' },
];

export const isKycDeletedOptions = [
  { value: KycLogsStatus.True, label: 'Yes' },
  { value: KycLogsStatus.False, label: 'No' },
];

export const isKycFailedOptions = [
  { value: KycLogsStatus.True, label: 'Yes' },
  { value: KycLogsStatus.False, label: 'No' },
];
