import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import ReplayIcon from '@mui/icons-material/Replay';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { useLocation, useNavigate } from 'react-router-dom';

import _404 from './_404';
import _403 from './_403';
import _500 from './_500';
import _503 from './_503';
import Button from 'core/buttons/Button';
import Spacer from 'core/spacer/Spacer';
import ErrorContainer from './ErrorContainer';
import SomethingWentWrong from './SomethingWentWrong';

import Auth from 'utils/Auth';
import { buildRoute } from 'routes';

export default function Error({ status, message, withInDashboard, onRetry }) {
  const navigate = useNavigate();

  const location = useLocation();

  const hasPrevPath = !!location.state?.from?.pathname;

  const title =
    {
      503: '503: Service Unavailable',
      500: '500: Server Error',
      404: '404: Page Not Found',
      403: '403: Forbidden'
    }[status] ?? 'Something went wrong ?';

  const description =
    {
      503: 'Sorry, we are doing some maintenance. Please check back soon.',
      500: 'Whoops, something went wrong on our servers.',
      404: 'Sorry, the page you are looking for could not be found.',
      403: 'Sorry, you are forbidden from accessing this page.'
    }[status] ?? 'Contact system administrator';

  const component = {
    503: <_503 title={message || title} description={description} />,
    500: <_500 title={message || title} description={description} />,
    404: <_404 title={message || title} description={description} />,
    403: <_403 title={message || title} description={description} />
  }[status] ?? <SomethingWentWrong title={title} description={description} />;

  return (
    <ErrorContainer withInDashboard={withInDashboard}>
      {component}
      <Spacer />
      {!withInDashboard && (
        <Stack
          // sx={{ width: '30%' }}
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          gap={4}
        >
          {hasPrevPath && (
            <Button variant="outlined" startIcon={<ArrowBackIcon />} sx={{ textTransform: 'none' }}>
              Go Back
            </Button>
          )}
          {Auth.isLoggedIn() && (
            <Button
              onClick={() => navigate(buildRoute('home'))}
              sx={{ textTransform: 'none' }}
              endIcon={<ArrowForward />}
            >
              Take me to Home
            </Button>
          )}
          {!hasPrevPath && !Auth.isLoggedIn() && (
            <Button
              onClick={() => window.location.reload()}
              sx={{ textTransform: 'none' }}
              startIcon={<ReplayIcon />}
            >
              Try Reloading Page
            </Button>
          )}
        </Stack>
      )}

      {withInDashboard && Object.prototype.toString.call(onRetry) === '[object Function]' && (
        <>
          <Button sx={{ textTransform: 'none' }} endIcon={<ReplayIcon />} onClick={onRetry}>
            Retry
          </Button>
        </>
      )}
    </ErrorContainer>
  );
}

Error.propTypes = {
  status: PropTypes.number,
  withInDashboard: PropTypes.bool,
  onRetry: PropTypes.func,
  message: PropTypes.string
};

Error.defaultProps = {
  status: 0,
  withInDashboard: true
};
