import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';

import ActiveBadge from 'core/badges/ActiveBadge';
import VerifiedBadge from 'core/badges/VerifiedBadge';
import GroupedTableView from 'core/table/GroupedTableView';
import PartnerClickAwayDetails from 'components/partners/PartnerClickAwayDetails';

import Auth from 'utils/Auth';
import isEmpty from 'helpers/isEmpty';
import DateUtils from 'utils/DateUtils';
import { getFundingSource } from 'app/features/funding-sources/fundingSourceSaga';
import useCountries from 'hooks/useCountries';

export default function FundingSource({ fundingSourceId }) {
  const dispatch = useDispatch();

  const { getCountryNameByIso3Code } = useCountries();

  const viewFundingSourceData = useMemo(
    () => [
      {
        groupName: '',
        groupData: [
          {
            label: 'Payment Name',
            data: 'payment_name',
          },
          {
            label: 'Payment Value',
            data: 'payment_value',
          },
          {
            label: 'Country',
            get: ({ country }) => getCountryNameByIso3Code(country),
          },
          {
            label: 'Description',
            data: 'description',
          },
          {
            label: 'Is Active ?',
            data: 'is_active',
            get: ({ is_active }) => <ActiveBadge isActive={!!is_active} />,
          },
          {
            label: 'Is Verified ?',
            data: 'is_verified',
            get: ({ is_verified }) => <VerifiedBadge isVerified={!!is_verified} />,
          },
          {
            label: 'Created At',
            data: 'created_ts',
            get: ({ created_ts }) =>
              !isEmpty(created_ts) ? DateUtils.isoToString(created_ts) : '-',
          },
          {
            label: 'Created By',
            data: 'created_by',
          },
          {
            label: 'Updated At',
            data: 'updated_ts',
            get: ({ updated_ts }) =>
              !isEmpty(updated_ts) ? DateUtils.isoToString(updated_ts) : '-',
          },
          {
            label: 'Updated By',
            data: 'updated_by',
          },
        ],
      },
    ],
    [],
  );

  const {
    isError,
    errorCode,
    isLoading,
    data: fundingSource,
  } = useSelector((state) => state.fundingSources.show);

  useEffect(() => {
    dispatch(getFundingSource(fundingSourceId));
  }, [fundingSourceId]);

  return (
    <Grid container spacing={2}>
      {viewFundingSourceData.map((group, i) => (
        <GroupedTableView
          key={i}
          title={group.groupName}
          data={group.groupData}
          item={fundingSource}
          isLoading={isLoading}
        />
      ))}
    </Grid>
  );
}

FundingSource.propTypes = {
  fundingSourceId: PropTypes.string,
};
