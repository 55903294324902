import { createTheme } from '@mui/material/styles';

import Dimensions from 'app/config/Dimensions';
import ColorPalette from 'app/config/ColorPalette';

export const poppins = "'Poppins', sans-serif";

const defaultTheme = createTheme({
  palette: {
    primary: { ...ColorPalette.primary },
    success: { ...ColorPalette.success },
    error: { ...ColorPalette.error },
    info: { ...ColorPalette.info },
    warning: { ...ColorPalette.warning },
    grey: { ...ColorPalette.grey },
    menuActive: {
      main: ColorPalette.menuActive,
    },
    subMenuActive: {
      main: ColorPalette.subMenuActive,
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: poppins,
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          // '&::-webkit-scrollbar': {
          //   height: '20px'
          // },
          // '&::-webkit-scrollbar-track': {
          //   backgroundColor: '#F1F1F1'
          // },
          // '&::-webkit-scrollbar-thumb': {
          //   background: '#C1C1C1'
          //   // borderRadius: '6px'
          // },
          // '&::-webkit-scrollbar-thumb:hover': {
          //   background: '#C2C2C2',
          //   cursor: 'pointer'
          // }
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: ColorPalette.primary.main,
          '& .MuiTableCell-root': {
            color: '#FFFFFF',
          },
          th: {
            whiteSpace: 'nowrap',
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          'tr:hover': {
            cursor: 'pointer',
            td: {
              borderTop: `1px solid ${ColorPalette.grey[400]}`,
              borderBottom: `1px solid ${ColorPalette.grey[400]}`,
              zIndex: 999,
            },
            'td:first-of-type': {
              // borderLeft: `2px solid ${ColorPalette.success.main}`,
            },
            // boxShadow: `2px 2px 3px ${ColorPalette.grey[300]}`,
          },
          td: {
            whiteSpace: 'nowrap',
            paddingTop: Dimensions.padding.normal,
            paddingBottom: Dimensions.padding.normal,
            color: ColorPalette.grey[600],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {},
      },
    },
    MuiAlert: {
      
    }
  },
});

export default defaultTheme;
