import Grid from '@mui/material/Grid';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SpringModal from 'core/modal/SpringModal';
import Clipboard from 'core/clipboard/Clipboard';
import ZaiUserStatusBadge from './ZaiUserStatusBadge';
import GroupedTableView from 'core/table/GroupedTableView';

import { zaiCheckBalance } from 'app/features/transactions/zaiTransactionSaga';
import { closeCheckBalanceModal } from 'app/features/transactions/zaiTransactionSlice';

export default function CheckBalanceModal() {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.zaiTransactions.checkBalance);

  const { data, isLoading, isError, errorMessage } = state;

  useEffect(() => {
    if (state.customerId) {
      dispatch(zaiCheckBalance({ customerId: state.customerId }));
    }
  }, [state.customerId]);

  const definition = useMemo(
    () => [
      {
        label: 'Customer Name',
        data: 'customerName',
      },
      {
        label: 'Balance',
        data: 'balance',
      },
      {
        label: 'Currency',
        data: 'currency',
      },
      {
        label: 'Wallet ID',
        data: 'walletId',
        get: ({ walletId }) => <Clipboard content={walletId} label={<>{walletId}</>} />,
      },
      {
        label: 'Status',
        data: 'status',
        get: ({ status }) => <ZaiUserStatusBadge status={status} />,
      },
    ],
    [],
  );

  return (
    <SpringModal
      header="Balance Check"
      isOpen={state.isModalOpen}
      onClose={() => dispatch(closeCheckBalanceModal())}
      enableMaximizeButton={false}
      enableDefaultPadding
      sx={{
        minWidth: '600px',
      }}
    >
      {isError ? (
        errorMessage
      ) : (
        <Grid container spacing={2}>
          <GroupedTableView data={definition} item={data} isLoading={isLoading} disableTableHead />
        </Grid>
      )}
    </SpringModal>
  );
}
