/***
 *  This utils depends upon dayjs JS Library
 *  Read Docs : https://day.js.org/
 */

import dayjs from 'dayjs';

export class InvalidUnitException extends Error {}

class DateUtils {
  /**
   * get isoString to string
   *
   * @param {String} date
   * @param {Boolean} showTime
   */
  static isoToString(date, showTime = true) {
    if (showTime) return dayjs(date).format('MMM DD, YYYY hh:mm A');
    return dayjs(date).format('MMM DD, YYYY');
  }

  /**
   * check if date is recent date
   *
   * Reference : https://day.js.org/docs/en/display/difference
   *
   * @param {String} date
   * @param {string} unit  Available Units : https://day.js.org/docs/en/display/difference#list-of-all-available-units
   * @param {number} difference
   * @return {Boolean}
   * @throws {InvalidUnitException}
   */
  static isRecent(date, unit = 'day', difference = 1) {
    let supportedUnits = [
      'day',
      'week',
      'quarter',
      'month',
      'year',
      'hour',
      'minute',
      'hour',
      'second',
      'millisecond'
    ];

    if (!supportedUnits.includes(unit))
      throw new InvalidUnitException(
        `Invalid Unit. Supported Unit ${supportedUnits.join(
          ', '
        )} or Read docs https://day.js.org/docs/en/display/difference#list-of-all-available-units`
      );
    return dayjs().diff(date, unit, true) <= difference;
  }


  static getFromDate(date) {
    const date1 = dayjs(date).startOf('day');
    return date1.toISOString();
  }

  static getToDate(date) {
    const date1 = dayjs(date).endOf('day');
    return date1.toISOString();
  }
}

export default DateUtils;
