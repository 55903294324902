import { createAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import Notify from 'utils/Notify';
import { buildRoute } from 'routes';
import http from 'app/services/httpService';
import apiEndpoints from 'routes/apiEndpoints';
import { callWrapperSaga } from 'app/services/sagaService';
import guestHttp from 'app/services/guestHttpService';
import {
  setUser,
  setIsLoggingOut,
  loginFailure,
  loginInit,
  loginSuccess,
  changePasswordInit,
  changePasswordSuccess,
  changePasswordFailure,
} from './authSlice';

const login = createAction('auth/login');
const logout = createAction('auth/logout');
const getLoggedInUser = createAction('auth/user');
const changePassword = createAction('auth/changePassword');

/**
 * login a user
 */
function* loginSaga(action) {
  try {
    yield put(loginInit());
    let endpoint = app.apiBaseUrl + apiEndpoints.login;
    const payload = yield callWrapperSaga(() => guestHttp.post(endpoint, action.payload));
    const data = payload.data;
    if (data.code !== 200) throw new Error('Failed to Login');
    const { refresh_token, token, valid_to } = data.data;
    Auth.setAccessToken(token);
    Auth.setRefreshToken(refresh_token, valid_to);
    yield put(loginSuccess(true));
  } catch (err) {
    yield put(loginFailure(err));
  }
}

/**
 * get a current logged in user
 */
function* getLoggedInUserSaga() {
  try {
    const payload = yield callWrapperSaga(() => http.get(apiEndpoints.getLoggedInUser));
    Auth.setUser(payload.data.data);
    yield put(setUser(payload.data.data));
  } catch (e) {
    console.error(e);
  }
}

/**
 * logout a user
 */
function* logoutSaga() {
  yield put(setIsLoggingOut(true));
  try {
    yield callWrapperSaga(() => http.post(apiEndpoints.logout));
    Auth.logout();
    Notify.success('Logged out successfully !');
    window.location.href = buildRoute('login');
  } catch (e) {
    console.error(e);
    Notify.error('Failed to logout');
  }
  yield put(setIsLoggingOut(false));
}

function* changePasswordSaga({ payload }) {
  try {
    yield put(changePasswordInit());
    const response = yield callWrapperSaga(() => http.post(apiEndpoints.changePassword, payload));
    Notify.success('Password change successfully');
    yield put(changePasswordSuccess());
  } catch (e) {
    console.log(e);
    Notify.error('Failed to change password');
    yield put(changePasswordFailure(e));
  }
}

function* authSaga() {
  yield takeLatest(login, loginSaga);
  yield takeLatest(logout, logoutSaga);
  yield takeLatest(changePassword, changePasswordSaga);
  yield takeLatest(getLoggedInUser, getLoggedInUserSaga);
}

export default authSaga;
export { login, getLoggedInUser, logout, changePassword };
