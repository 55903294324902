import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import Button from 'core/buttons/Button';
import ReloadButton from 'core/buttons/ReloadButton';
import TableGridFilter from 'core/table/TableGridFilter';
import ColumnVisibility from 'core/table/ColumnVisibility';
import SelectPartner from 'components/partners/SelectPartner';
import SelectCustomer from 'components/customers/SelectCustomer';
import TableActionsContainer from 'core/table/TableActionsContainer';

import Auth from 'utils/Auth';
import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import { currentRouteName } from 'routes';
import FilterType from 'app/config/FilterType';
import useQueryParams from 'hooks/useQueryParams';
import { getCustomerKycLogs } from 'app/features/customer-kyc-logs/customerKycLogsSaga';
import { isKycDeletedOptions, isKycExpiredOptions, isKycFailedOptions } from 'app/config/KycLogs';
import { deleteFilterParams, setParam } from 'app/features/customer-kyc-logs/customerKycLogsSlice';

export default function CustomerKycLogsActions({
  table,
  enableFilterByPartner,
  defaultFilterQueries,
}) {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const customerKycLogsState = useSelector((state) => state.customerKycLogs.index);

  const [queryParams, updateQueryParams] = useQueryParams(app.ignoreFilterKeys);

  let availableFilters = [
    {
      label: 'Is KYC Expired ?',
      value: 'is_expired',
      type: FilterType.select,
      options: isKycExpiredOptions,
    },
    {
      label: 'Is KYC Failed ?',
      value: 'is_failed',
      type: FilterType.select,
      options: isKycFailedOptions,
    },
    {
      label: 'Is Kyc Deleted ?',
      value: 'is_deleted',
      type: FilterType.select,
      options: isKycDeletedOptions,
    },
  ];

  const { params: appliedFilters } = customerKycLogsState;

  const handleOnFilter = useCallback((data) => {
    dispatch(setParam({ ...data, page_number: 1 }));
  }, []);

  const handleOnReset = useCallback((e) => {
    dispatch(deleteFilterParams([...availableFilters.map((filter) => filter.value)]));
  });

  const partnerId = appliedFilters?.partner_id ?? Auth.getPartnerId();

  useEffect(() => {
    // update query parameters only for index page
    if (currentRouteName() === 'customer-kyc-logs.index') {
      updateQueryParams(appliedFilters);
    }
  }, [JSON.stringify(appliedFilters)]);

  useEffect(() => {
    dispatch(setParam(queryParams));
  }, [JSON.stringify(queryParams)]);

  return (
    <>
      <Box>
        <TableGridFilter
          showFilter={showFilter}
          appliedFilters={appliedFilters}
          availableFilters={availableFilters}
          onFilter={handleOnFilter}
          onReset={handleOnReset}
        />
      </Box>
      <TableActionsContainer>
        <Box display="flex" gap={2}>
          {Auth.isSuperAdmin() && enableFilterByPartner && (
            <SelectPartner
              id="select-partner"
              onSelected={(value) => {
                if (!isEmpty(value))
                  dispatch(setParam({ partner_id: value, user_id: '', page_number: 1 }));
                else dispatch(deleteFilterParams(['partner_id', 'user_id']));
              }}
              sx={{ width: '300px' }}
            />
          )}

          {!isEmpty(partnerId) && (
            <SelectCustomer
              id="select-customer"
              onSelected={(value) => {
                if (!isEmpty(value)) {
                  dispatch(setParam({ user_id: value, page_number: 1 }));
                } else {
                  dispatch(deleteFilterParams('user_id'));
                }
              }}
              sx={{ width: '300px' }}
              partnerId={partnerId}
            />
          )}
        </Box>
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            endIcon={<FilterAltIcon />}
            onClick={() => setShowFilter(!showFilter)}
            uppercase
          >
            Filter
          </Button>

          {table && <ColumnVisibility table={table} />}
          <ReloadButton
            onClick={() => {
              dispatch(
                getCustomerKycLogs({ ...appliedFilters, page_number: 1, ...defaultFilterQueries }),
              );
            }}
          />
        </Box>
      </TableActionsContainer>
    </>
  );
}

CustomerKycLogsActions.propTypes = {
  table: PropTypes.object,
  enableFilterByPartner: PropTypes.bool,
  defaultFilterQueries: PropTypes.object,
};

CustomerKycLogsActions.defaultProps = {
  enableFilterByPartner: true,
};
