import qs from 'qs';
import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import Notify from 'utils/Notify';
import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getFundingSourcesInit,
  getFundingSourcesSuccess,
  getFundingSourcesFailure,
  addFundingSourceInit,
  addFundingSourceSuccess,
  addFundingSourceFailure,
  updateFundingSourceInit,
  updateFundingSourceSuccess,
  updateFundingSourceFailure,
  getFundingSourceInit,
  getFundingSourceFailure,
  getFundingSourceSuccess,
  editFundingSourceInit,
  editFundingSourceSuccess,
  editFundingSourceFailure,
  deleteFundingSourceInit,
  deleteFundingSourceSuccess,
  deleteFundingSourceFailure,
  updateIsActiveStatusInit,
  updateIsActiveStatusSuccess,
  updateIsActiveStatusFailure,
} from './fundingSourceSlice';

const getFundingSource = createAction('fundingSources/get');
const createFundingSource = createAction('fundingSources/create');
const updateFundingSource = createAction('fundingSources/update');
const getFundingSources = createAction('fundingSources/list');
const deleteFundingSource = createAction('fundingSources/delete');
const editFundingSource = createAction('fundingSources/edit');
const updateIsActiveStatus = createAction('fundingSources/update-is-active-status');

/**
 * create a fundingSource
 *
 * @param {object} data
 */
function* createFundingSourceSaga({ payload: data }) {
  yield put(addFundingSourceInit());
  try {
    let response = yield callWrapperSaga(() => http.post(apiEndpoints.createFundingSource, data));
    yield put(addFundingSourceSuccess(response.data));
    Notify.success('New Funding Source created successfully.');
  } catch (err) {
    yield put(addFundingSourceFailure(err));
    Notify.error('Failed to create new funding source.');
  }
}

/**
 * update a fundingSource
 *
 * @param {object} { id, ...data }
 */
function* updateFundingSourceSaga({ payload: { id, ...data } }) {
  try {
    yield put(updateFundingSourceInit());
    let endpoint = buildApiRoute(apiEndpoints.updateFundingSource, id);
    let response = yield callWrapperSaga(() => http.put(endpoint, data));
    yield put(updateFundingSourceSuccess(response.data));
  } catch (err) {
    yield put(updateFundingSourceFailure(err));
    Notify.error('Funding Source update failed');
  }
}

/**
 * get a single fundingSource
 */
function* getFundingSourceSaga({ payload: fundingSourceId }) {
  try {
    yield put(getFundingSourceInit());
    let endpoint = buildApiRoute(apiEndpoints.getFundingSource, fundingSourceId);
    let response = yield callWrapperSaga(() => http.get(endpoint, fundingSourceId));
    yield put(getFundingSourceSuccess(response.data));
  } catch (err) {
    yield put(getFundingSourceFailure(err));
  }
}

/**
 * get a fundingSources list
 *
 */
function* getFundingSourcesSaga({ payload }) {
  yield put(getFundingSourcesInit());
  try {
    let endpoint = `${apiEndpoints.getFundingSources}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getFundingSourcesSuccess(response.data));
  } catch (err) {
    yield put(getFundingSourcesFailure(err));
  }
}

/**
 * initiate edit fundingSource
 * */
function* editFundingSourceSaga({ payload: fundingSourceId }) {
  try {
    yield put(editFundingSourceInit());
    let endpoint = buildApiRoute(apiEndpoints.getFundingSource, fundingSourceId);
    let response = yield callWrapperSaga(() => http.get(endpoint, fundingSourceId));
    yield put(editFundingSourceSuccess(response.data));
  } catch (err) {
    yield put(editFundingSourceFailure(err));
  }
}

function* deleteFundingSourceSaga({ payload: fundingSourceId }) {
  try {
    yield put(deleteFundingSourceInit(fundingSourceId));
    let endpoint = buildApiRoute(apiEndpoints.deleteFundingSource, fundingSourceId);
    yield callWrapperSaga(() => http.delete(endpoint));
    Notify.success('Funding Source deleted successfully.');
    yield put(deleteFundingSourceSuccess(fundingSourceId));
  } catch (err) {
    Notify.error('Failed to delete funding source');
    yield put(deleteFundingSourceFailure(err));
  }
}

function* updateIsActiveStatusSaga({ payload: { fundingSourceId, isActive } }) {
  try {
    yield put(updateIsActiveStatusInit({ fundingSourceId, isActive }));
    let endpoint = buildApiRoute(apiEndpoints.updateFundingSourceIsActiveStatus, fundingSourceId);
    yield callWrapperSaga(() => http.patch(`${endpoint}?${qs.stringify({ is_active: isActive })}`));
    Notify.success('Funding Source status changed successfully.');
    yield put(updateIsActiveStatusSuccess({ fundingSourceId, isActive }));
  } catch (err) {
    Notify.error('Failed to change funding source status');
    yield put(updateIsActiveStatusFailure({ fundingSourceId, isActive }));
  }
}

function* fundingSourceSaga() {
  yield takeLatest(createFundingSource, createFundingSourceSaga);
  yield takeLatest(updateFundingSource, updateFundingSourceSaga);
  yield takeLatest(getFundingSource, getFundingSourceSaga);
  yield takeLatest(getFundingSources, getFundingSourcesSaga);
  yield takeLatest(editFundingSource, editFundingSourceSaga);
  yield takeLatest(deleteFundingSource, deleteFundingSourceSaga);
  yield takeLatest(updateIsActiveStatus, updateIsActiveStatusSaga);
}

export default fundingSourceSaga;
export {
  createFundingSource,
  updateFundingSource,
  getFundingSource,
  getFundingSources,
  editFundingSource,
  deleteFundingSource,
  updateIsActiveStatus,
};
