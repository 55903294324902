/**
 * Transaction Status config
 *
 * Below config values must be same as the values in the database
 *
 */

export const transactionStatuses = {
  created: 'created',
  pending: 'pending',
  processing: 'processing',
  completed: 'completed',
  rejected: 'rejected',
  paid: 'paid',
};

/**
 *
 * Transaction Status options setup is for html select
 */

export const transactionStatusOptions = [
  { value: transactionStatuses.created, label: 'Created' },
  { value: transactionStatuses.pending, label: 'Pending' },
  { value: transactionStatuses.processing, label: 'Processing' },
  { value: transactionStatuses.completed, label: 'Completed' },
  { value: transactionStatuses.rejected, label: 'Rejected' },
  { value: transactionStatuses.paid, label: 'Paid' },
];
