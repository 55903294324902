import { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Tabs from 'core/tabs/Tabs';
import PartnerWebhooksTab from './tabs/PartnerWebhooksTab';
import PartnerCountriesTab from './tabs/PartnerCountriesTab';
import PartnerCredentialsTab from './tabs/PartnerCredentialsTab';
import PartnerWebhookHeadersTab from './tabs/PartnerWebhookHeadersTab';
import PartnerFundingSourcesTab from './tabs/PartnerFundingSourcesTab';
import PartnerDetailsTab from 'components/partners/tabs/PartnerDetailsTab';

import { getPartner } from 'app/features/partners/partnerSaga';
import PartnerCustomersTab from './tabs/PartnerCustomersTab';
import LicensePartnerMapperTab from './tabs/LicensePartnerMapperTab';

function Partner({ partnerId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPartner(partnerId));
  }, [partnerId]);

  return (
    <Tabs
      tabs={[
        {
          key: 'partner-details',
          tabName: 'Details',
          tabContent: <PartnerDetailsTab />,
        },
        {
          key: 'partner-credentials',
          tabName: 'Credentials',
          tabContent: <PartnerCredentialsTab partnerId={partnerId} />,
        },
        {
          key: 'partner-customers',
          tabName: 'Customers',
          tabContent: <PartnerCustomersTab partnerId={partnerId} />,
        },
        {
          key: 'partner-webhooks',
          tabName: 'Webhooks',
          tabContent: <PartnerWebhooksTab partnerId={partnerId} />,
        },
        {
          key: 'partner-webhook-headers',
          tabName: 'Webhook Headers',
          tabContent: <PartnerWebhookHeadersTab partnerId={partnerId} />,
        },
        {
          key: 'partner-funding-sources',
          tabName: 'Funding Sources',
          tabContent: <PartnerFundingSourcesTab partnerId={partnerId} />,
        },
        {
          key: 'partner-countries',
          tabName: 'Countries',
          tabContent: <PartnerCountriesTab partnerId={partnerId} />,
        },
        {
          key: 'licensing-partners',
          tabName: 'Licensing Partners',
          tabContent: <LicensePartnerMapperTab partnerId={partnerId} />,
        },
      ]}
    />
  );
}

export default memo(Partner);

Partner.propTypes = {
  partnerId: PropTypes.string.isRequired,
};
