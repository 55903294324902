import { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Controller, useFormContext } from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function FormDatePicker({
  name,
  label,
  error,
  helperText,
  required,
  size,
  fullWidth,
  rules,
  disabled,
  variant,
  focused,
  color,
  value,
  defaultValue,
  dateFormat,
  dateMask,
  minDate,
  maxDate,
  placeholder,
  disableFuture,
}) {
  const { control, clearErrors, setValue } = useFormContext();

  useEffect(() => {
    if (!value && !defaultValue) setValue(name, undefined);
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={label}
            onChange={(date) => {
              let newDate = dateFormat
                .replace(/yyyy/g, date.getFullYear())
                .replace(/MM/g, date.getMonth() + 1)
                .replace(/dd/g, date.getDate());

              setValue(name, newDate);
            }}
            value={field.value ?? ''}
            inputFormat={dateFormat}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                error={error}
                helperText={helperText}
                color={color}
                variant={variant}
                size={size}
                fullWidth={fullWidth}
                required={required}
                disabled={disabled}
                focused={focused}
                onFocus={() => clearErrors(name)}
                autoComplete="off"
              />
            )}
            mask={dateMask}
            minDate={minDate}
            maxDate={maxDate}
            toolbarPlaceholder={placeholder}
            disableFuture={disableFuture}
          />
        </LocalizationProvider>
      )}
    />
  );
}

export default FormDatePicker;

FormDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small', 'large']),
  fullWidth: PropTypes.bool.isRequired,
  rules: PropTypes.object,
  multiline: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
  focused: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning']),
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  dateFormat: PropTypes.oneOf(['yyyy-MM-dd', 'MM-dd-yyyy', 'yyyy/MM/dd', 'MM/dd/yyyy']).isRequired,
  dateMask: PropTypes.oneOf(['____-__-__', '__-__-____', '____/__/__', '__/__/____']).isRequired,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  placeholder: PropTypes.string,
};

FormDatePicker.defaultProps = {
  type: 'text',
  label: '',
  error: false,
  helperText: '',
  required: false,
  disabled: false,
  fullWidth: true,
  rules: {},
  multiline: false,
  size: 'small',
  variant: 'outlined',
  focused: false,
  value: '',
  color: 'primary',
  minDate: undefined,
  maxDate: undefined,
  dateFormat: 'yyyy/MM/dd',
  dateMask: '____/__/__',
  placeholder: 'YYYY/MM/DD',
};
