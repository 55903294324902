import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import Notify from 'utils/Notify';
import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getPartnerFundingSourcesInit,
  getPartnerFundingSourcesSuccess,
  getPartnerFundingSourcesFailure,
  addPartnerFundingSourceInit,
  addPartnerFundingSourceSuccess,
  addPartnerFundingSourceFailure,
  updatePartnerFundingSourceInit,
  updatePartnerFundingSourceSuccess,
  updatePartnerFundingSourceFailure,
  getPartnerFundingSourceInit,
  getPartnerFundingSourceFailure,
  getPartnerFundingSourceSuccess,
  editPartnerFundingSourceInit,
  editPartnerFundingSourceSuccess,
  editPartnerFundingSourceFailure,
  deletePartnerFundingSourceInit,
  deletePartnerFundingSourceSuccess,
  deletePartnerFundingSourceFailure,
} from './partnerFundingSourceSlice';

const getPartnerFundingSource = createAction('partnerFundingSources/get');
const createPartnerFundingSource = createAction('partnerFundingSources/create');
const updatePartnerFundingSource = createAction('partnerFundingSources/update');
const getPartnerFundingSources = createAction('partnerFundingSources/list');
const editPartnerFundingSource = createAction('partnerFundingSources/edit');
const deletePartnerFundingSource = createAction('partnerFundingSources/delete');

/**
 * create a partnerFundingSource
 *
 * @param {object} data
 */
function* createPartnerFundingSourceSaga({ payload: data }) {
  yield put(addPartnerFundingSourceInit());
  try {
    let response = yield callWrapperSaga(() =>
      http.post(apiEndpoints.createPartnerFundingSource, data),
    );
    yield put(addPartnerFundingSourceSuccess(response.data));
    Notify.success('New Partner Funding Source created successfully.');
  } catch (err) {
    yield put(addPartnerFundingSourceFailure(err));
    Notify.error('Failed to create new funding source.');
  }
}

/**
 * update a partnerFundingSource
 *
 * @param {object} { id, ...data }
 */
function* updatePartnerFundingSourceSaga({ payload: { id, ...data } }) {
  try {
    yield put(updatePartnerFundingSourceInit());
    let endpoint = buildApiRoute(apiEndpoints.updatePartnerFundingSource, id);
    let response = yield callWrapperSaga(() => http.put(endpoint, data));
    yield put(updatePartnerFundingSourceSuccess(response.data));
  } catch (err) {
    yield put(updatePartnerFundingSourceFailure(err));
    Notify.error('Partner Funding Source update failed');
  }
}

/**
 * get a single partnerFundingSource
 */
function* getPartnerFundingSourceSaga({ payload: partnerFundingSourceId }) {
  try {
    yield put(getPartnerFundingSourceInit());
    let endpoint = buildApiRoute(apiEndpoints.getPartnerFundingSource, partnerFundingSourceId);
    let response = yield callWrapperSaga(() => http.get(endpoint, partnerFundingSourceId));
    yield put(getPartnerFundingSourceSuccess(response.data));
  } catch (err) {
    yield put(getPartnerFundingSourceFailure(err));
  }
}

/**
 * get a partnerFundingSources list
 *
 */
function* getPartnerFundingSourcesSaga({ payload }) {
  yield put(getPartnerFundingSourcesInit());
  try {
    let endpoint = `${apiEndpoints.getPartnerFundingSources}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getPartnerFundingSourcesSuccess(response.data));
  } catch (err) {
    yield put(getPartnerFundingSourcesFailure(err));
  }
}

/**
 * initiate edit partnerFundingSource
 * */
function* editPartnerFundingSourceSaga({ payload: partnerFundingSourceId }) {
  try {
    yield put(editPartnerFundingSourceInit());
    let endpoint = buildApiRoute(apiEndpoints.getPartnerFundingSource, partnerFundingSourceId);
    let response = yield callWrapperSaga(() => http.get(endpoint, partnerFundingSourceId));
    yield put(editPartnerFundingSourceSuccess(response.data));
  } catch (err) {
    yield put(editPartnerFundingSourceFailure(err));
  }
}

function* deletePartnerFundingSourceSaga({ payload: partnerFundingSourceId }) {
  try {
    yield put(deletePartnerFundingSourceInit(partnerFundingSourceId));
    let endpoint = buildApiRoute(apiEndpoints.deletePartnerFundingSource, partnerFundingSourceId);
    yield callWrapperSaga(() => http.delete(endpoint));
    Notify.success('Partner Funding Source deleted successfully.');
    yield put(deletePartnerFundingSourceSuccess(partnerFundingSourceId));
  } catch (err) {
    Notify.error('Failed to delete funding source');
    yield put(deletePartnerFundingSourceFailure(err));
  }
}

function* partnerFundingSourceSaga() {
  yield takeLatest(createPartnerFundingSource, createPartnerFundingSourceSaga);
  yield takeLatest(updatePartnerFundingSource, updatePartnerFundingSourceSaga);
  yield takeLatest(getPartnerFundingSource, getPartnerFundingSourceSaga);
  yield takeLatest(getPartnerFundingSources, getPartnerFundingSourcesSaga);
  yield takeLatest(editPartnerFundingSource, editPartnerFundingSourceSaga);
  yield takeLatest(deletePartnerFundingSource, deletePartnerFundingSourceSaga);
}

export default partnerFundingSourceSaga;
export {
  createPartnerFundingSource,
  updatePartnerFundingSource,
  getPartnerFundingSource,
  getPartnerFundingSources,
  editPartnerFundingSource,
  deletePartnerFundingSource,
};
