import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { compose } from '@reduxjs/toolkit';
import { useDispatch, connect, useSelector } from 'react-redux';

import FinmoPagination from 'core/pagination/FinmoPagination';
import { setParam } from 'app/features/finmo-payins/finmoPayinSlice';

function FinmoPayinPagination({ totalCount, pageSize, pageNumber }) {
  const dispatch = useDispatch();

  const data = useSelector((state) => state.finmoPayins.index.data);

  return (
    <Box component="div" sx={{ py: 2 }}>
      <FinmoPagination
        count={Math.ceil(totalCount / pageSize)}
        page={pageNumber}
        onChange={(currentPage) => dispatch(setParam({ page: currentPage }))}
        onRowPerPageChange={(rowPerPage) => dispatch(setParam({ limit: rowPerPage, page: 1 }))}
        perPageValue={pageSize}
        disableNextPage={data?.length <= 0}
      />
    </Box>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    totalCount: state.finmoPayins.index.totalCount,
    pageSize: state.finmoPayins.index.params.limit,
    pageNumber: state.finmoPayins.index.params.page,
  };
}

export default compose(connect(mapStateToProps))(FinmoPayinPagination);

FinmoPayinPagination.propTypes = {
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  pageNumber: PropTypes.number,
};
