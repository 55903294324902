import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DataObjectIcon from '@mui/icons-material/DataObject';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';

const ExportDocuments = ({ onPdfDownload, onExcelDownload, isLoading }) => {
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button disabled={isLoading} variant="outlined" {...bindTrigger(popupState)}>
            <SimCardDownloadOutlinedIcon
              sx={{
                marginRight: 0.4,
              }}
            />
            Export
          </Button>
          <Menu {...bindMenu(popupState)}>
            <MenuItem
              onClick={() => {
                onExcelDownload();
                popupState.close();
              }}
            >
              <ListAltIcon
                sx={{
                  marginRight: 1,
                }}
              />
              Excel
            </MenuItem>
            <MenuItem
              onClick={() => {
                onPdfDownload();
                popupState.close();
              }}
            >
              <PictureAsPdfIcon
                sx={{
                  marginRight: 1,
                }}
              />
              Pdf
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export default ExportDocuments;
