import React from 'react';
import Box from '@mui/material/Box';

export default function Center({ children, ...rest }) {
  return (
    <Box {...rest} display="flex" alignItems="center" justifyContent="center">
      {children}
    </Box>
  );
}
