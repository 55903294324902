import { createSlice } from '@reduxjs/toolkit';

import isEmpty from 'helpers/isEmpty';

const initialState = {
  show: {
    data: [],
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
};

const transactionDocumentSlice = createSlice({
  name: 'transactionDocument',
  initialState,
  reducers: {
    getTransactionDocumentInit: (state) => {
      state.show = { ...state.show, isLoading: true, isError: false };
    },
    getTransactionDocumentSuccess: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        data: isEmpty(payload?.data) ? [] : payload?.data,
      };
    },
    getTransactionDocumentFailure: (state, { payload }) => {
      console.log(payload);
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status || 0,
        errorMessage: payload?.response?.message || 'Failed to load document',
        data: [],
      };
    },
  },
});

export const {
  getTransactionDocumentInit,
  getTransactionDocumentSuccess,
  getTransactionDocumentFailure,
} = transactionDocumentSlice.actions;

export default transactionDocumentSlice.reducer;
