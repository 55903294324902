import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material';
import Button from 'core/buttons/Button';
import { useNavigate } from 'react-router-dom';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';

import useInterval from 'hooks/useInterval';
import { buildRoute } from 'routes';
import TextButton from 'core/buttons/TextButton';

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  height: '100vh',
  width: '100vw',
  backgroundColor: alpha(theme.palette.grey[800], 0.8),
  top: 0,
  right: 0,
  zIndex: 1200,
  display: 'grid',
  placeItems: 'center'
}));

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(0.5),
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  textAlign: 'center'
}));

export default function PasswordResetLinkExpired({ onCancel }) {
  const [timer, setTimer] = useState(10);

  const navigate = useNavigate();

  useInterval(() => {
    if (timer !== 0) setTimer(timer - 1);
    if (timer <= 0) redirectToForgotPassword();
  }, [1000]);

  const redirectToForgotPassword = () => (window.location.href = buildRoute('forgot_password'));

  return (
    <Wrapper>
      <Container>
        <Box>
          <Typography variant="h6" color="error.main">
            Seems like password reset link is expired.
          </Typography>
          <Typography variant="subtitle1">
            Redirecting to &nbsp;
            <a
              style={{
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              onClick={redirectToForgotPassword}
            >
              <strong>Forgot Password</strong>
            </a>
            &nbsp; in <strong>{timer}</strong>s
          </Typography>
        </Box>
        <Box sx={{ width: '100%' }} display="flex" justifyContent="space-between">
          <Button
            onClick={redirectToForgotPassword}
            size="large"
            variant="outlined"
            sx={{ textTransform: 'none' }}
            endIcon={<ArrowForwardTwoToneIcon />}
          >
            Continue
          </Button>
          <Button size="large" sx={{ textTransform: 'none' }} color="error" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </Container>
    </Wrapper>
  );
}

PasswordResetLinkExpired.propTypes = {
  onCancel: PropTypes.func
};
