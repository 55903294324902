import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MuiCircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

// Inspired by the former Facebook spinners.
export default function CircularProgress({ size, thickness, ...props }) {
  return (
    <Box sx={{ position: 'relative' }}>
      <MuiCircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 400 : 800]
        }}
        size={size}
        thickness={thickness}
        {...props}
        value={100}
      />
      <MuiCircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={size}
        thickness={thickness}
        {...props}
      />
    </Box>
  );
}

CircularProgress.propTypes = {
  size: PropTypes.number,
  thickness: PropTypes.number
};

CircularProgress.defaultProps = {
  size: 40,
  thickness: 4
};
