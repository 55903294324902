import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import qs from 'qs';
import Notify from 'utils/Notify';
import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getAccountsInit,
  getAccountsSuccess,
  getAccountsFailure,
  addAccountInit,
  addAccountSuccess,
  addAccountFailure,
  updateAccountInit,
  updateAccountSuccess,
  updateAccountFailure,
  getAccountInit,
  getAccountFailure,
  getAccountSuccess,
  editAccountInit,
  editAccountSuccess,
  editAccountFailure,
  deleteAccountInit,
  deleteAccountSuccess,
  deleteAccountFailure,
  updateIsActiveStatusInit,
  updateIsActiveStatusSuccess,
  updateIsActiveStatusFailure
} from './accountSlice';

const getAccount = createAction('account/get');
const editAccount = createAction('account/edit');
const getAccounts = createAction('account/list');
const createAccount = createAction('account/create');
const updateAccount = createAction('account/update');
const deleteAccount = createAction('account/delete');
const updateIsActiveStatus = createAction('account/update-is-active-status');

/**
 * create a account
 *
 * @param {object} data
 */
function* createAccountSaga({ payload: data }) {
  yield put(addAccountInit());
  try {
    let response = yield callWrapperSaga(() => http.post(apiEndpoints.createAccount, data));
    yield put(addAccountSuccess(response.data));
    Notify.success('New account created successfully.');
  } catch (err) {
    yield put(addAccountFailure(err));
    Notify.error('Failed to create new account.');
  }
}

/**
 * update a account
 *
 * @param {object} { id, ...data }
 */
function* updateAccountSaga({ payload: { id, ...data } }) {
  try {
    yield put(updateAccountInit());
    let endpoint = buildApiRoute(apiEndpoints.updateAccount, id);
    let response = yield callWrapperSaga(() => http.put(endpoint, data));
    yield put(updateAccountSuccess(response.data));
    Notify.success('Account updated successfully.');
  } catch (err) {
    yield put(updateAccountFailure(err));
    Notify.error('Account update failed');
  }
}

/**
 * get a single account
 */
function* getAccountSaga({ payload: accountId }) {
  try {
    yield put(getAccountInit());
    let endpoint = buildApiRoute(apiEndpoints.getAccount, accountId);
    let response = yield callWrapperSaga(() => http.get(endpoint, accountId));
    yield put(getAccountSuccess(response.data));
  } catch (err) {
    yield put(getAccountFailure(err));
  }
}

/**
 * get a accounts list
 *
 */
function* getAccountsSaga({ payload }) {
  yield put(getAccountsInit());
  try {
    let endpoint = `${apiEndpoints.getAccounts}?${new URLSearchParams(payload)}`;
    let response = yield callWrapperSaga(() => http.get(endpoint));
    yield put(getAccountsSuccess(response.data));
  } catch (err) {
    yield put(getAccountsFailure(err));
  }
}

/**
 * initiate edit account
 * */
function* editAccountSaga({ payload: accountId }) {
  try {
    yield put(editAccountInit());
    let endpoint = buildApiRoute(apiEndpoints.getAccount, accountId);
    let response = yield callWrapperSaga(() => http.get(endpoint, accountId));
    yield put(editAccountSuccess(response.data));
  } catch (err) {
    yield put(editAccountFailure(err));
  }
}

function* deleteAccountSaga({ payload: accountId }) {
  try {
    yield put(deleteAccountInit(accountId));
    let endpoint = buildApiRoute(apiEndpoints.deleteAccount, accountId);
    yield callWrapperSaga(() => http.delete(endpoint));
    Notify.success('Account deleted successfully.');
    yield put(deleteAccountSuccess(accountId));
  } catch (err) {
    Notify.error('Failed to delete account');
    yield put(deleteAccountFailure(err));
  }
}

function* updateIsActiveStatusSaga({ payload: { accountId, isActive } }) {
  try {
    yield put(updateIsActiveStatusInit({ accountId, isActive }));
    let endpoint = buildApiRoute(apiEndpoints.updateIsActiveStatus, accountId);
    yield callWrapperSaga(() => http.patch(`${endpoint}?${qs.stringify({ is_active: isActive })}`));
    Notify.success('Account status changed successfully.');
    yield put(updateIsActiveStatusSuccess({ accountId, isActive }));
  } catch (err) {
    Notify.error('Failed to change account status');
    yield put(updateIsActiveStatusFailure({ accountId, isActive }));
  }
}

function* accountSaga() {
  yield takeLatest(getAccount, getAccountSaga);
  yield takeLatest(getAccounts, getAccountsSaga);
  yield takeLatest(editAccount, editAccountSaga);
  yield takeLatest(createAccount, createAccountSaga);
  yield takeLatest(updateAccount, updateAccountSaga);
  yield takeLatest(deleteAccount, deleteAccountSaga);
  yield takeLatest(updateIsActiveStatus, updateIsActiveStatusSaga);
}

export default accountSaga;
export {
  createAccount,
  updateAccount,
  getAccount,
  getAccounts,
  editAccount,
  deleteAccount,
  updateIsActiveStatus
};
