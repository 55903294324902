import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { compose } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';

import Button from 'core/buttons/Button';
import Avatar from '@mui/material/Avatar';
import Skeleton from 'core/skeleton/Skeleton';

import { buildRoute } from 'routes';
import isEmpty from 'helpers/isEmpty';
import useCountries from 'hooks/useCountries';
import AuthUserIsSuperAdmin from 'components/auth/AuthUserIsSuperAdmin';

const PartnerCardContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  gap: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[200]}`,
}));

const PartnerLogo = styled(Avatar)(({ theme }) => ({
  height: '80px',
  width: '80px',
  background: theme.palette.primary.main,
}));

const PartnerDetails = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const PartnerActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginRight: 'auto',
  flexGrow: 1,
  alignSelf: 'baseline',
  justifyContent: 'end',
}));

function PartnerCard({ isLoading, partner }) {
  const { getCountryNameByIso3Code } = useCountries();

  const navigate = useNavigate();

  const params = useParams();

  if (isLoading)
    return (
      <PartnerCardContainer elevation={0}>
        <Skeleton sx={{ width: '80px', height: '80px', borderRadius: '4px' }} />

        <PartnerDetails sx={{ gap: 1 }}>
          <Skeleton sx={{ width: '250px', height: '32px', borderRadius: '4px' }} />

          <Stack
            flexDirection="row"
            alignItems="center"
            gap={1}
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            <Skeleton sx={{ width: '200px', height: '16px', borderRadius: '4px' }} />
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={1}
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            <Skeleton sx={{ width: '180px', height: '12px', borderRadius: '10px' }} />
          </Stack>
        </PartnerDetails>
        <PartnerActions>
          <Skeleton sx={{ width: '130px', height: '37px', borderRadius: '4px' }} />
        </PartnerActions>
      </PartnerCardContainer>
    );

  return (
    <PartnerCardContainer elevation={0}>
      <PartnerLogo
        src={`https://ui-avatars.com/api/?background=232D6C&color=fff&name=${partner.name}`}
        variant="rounded"
      />
      <PartnerDetails sx={{ gap: 0.4 }}>
        <Typography variant="h5" fontWeight={800} letterSpacing={1.5} color="primary.main">
          {partner.name}
        </Typography>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={1}
          sx={{ color: (theme) => theme.palette.primary.main }}
        >
          <PlaceIcon sx={{ fontSize: 'inherit' }} />
          <Typography>
            {[partner.city, partner.state, getCountryNameByIso3Code(partner.country)]
              .filter((v) => !!v)
              .join(', ')}
          </Typography>
        </Stack>
        {partner?.post_code && (
          <Stack
            flexDirection="row"
            alignItems="center"
            gap={1}
            sx={{ color: (theme) => theme.palette.primary.main }}
          >
            <LocalPostOfficeIcon sx={{ fontSize: 'inherit' }} />
            <Typography variant="subtitle2">Post Code - {partner.post_code}</Typography>
          </Stack>
        )}
      </PartnerDetails>
      <PartnerActions>
        <AuthUserIsSuperAdmin>
          {isLoading ? (
            <Skeleton sx={{ width: '130px', height: '37px', borderRadius: '4px' }} />
          ) : (
            <Button onClick={() => navigate(buildRoute('partners.edit', params.partnerId))}>
              Edit Partner
            </Button>
          )}
        </AuthUserIsSuperAdmin>
      </PartnerActions>
    </PartnerCardContainer>
  );
}

export default compose(connect(mapStateToProps))(PartnerCard);

PartnerCard.propTypes = {
  partner: PropTypes.object,
  isLoading: PropTypes.bool,
};

PartnerCard.defaultProps = {
  partner: {},
  isLoading: false,
};

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    ...(!Object.prototype.hasOwnProperty.call(ownProps, 'partner')
      ? {
          partner: state.partners.show.data || {},
        }
      : {}),
    ...(!Object.prototype.hasOwnProperty.call(ownProps, 'isLoading')
      ? {
          isLoading: state.partners.show.isLoading || isEmpty(state.partners.show.data),
        }
      : {}),
  };
}
