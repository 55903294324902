import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

import Auth from 'utils/Auth';
import { buildRoute } from 'routes';
import Button from 'core/buttons/Button';
import Webhooks from 'components/webhooks/Webhooks';
import WebhookBriefDetailsModal from 'components/webhooks/WebhookBriefDetailsModal';

export default function PartnerWebhooksTab({ partnerId }) {
  const navigate = useNavigate();

  return (
    <>
      <Box display="flex" justifyContent="flex-end" sx={{ mb: 2 }}>
        <Button
          onClick={() => {
            navigate(
              buildRoute('webhooks.create', Auth.isSuperAdmin() ? { partner_id: partnerId } : {}),
            );
          }}
        >
          Add New Webhook
        </Button>
      </Box>
      <Webhooks preFilterByPartner={true} partnerId={partnerId} />
      <WebhookBriefDetailsModal />
    </>
  );
}

PartnerWebhooksTab.propTypes = {
  partnerId: PropTypes.string.isRequired,
};
