import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import debounce from 'helpers/debounce';
import http from 'app/services/httpService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';
import { useLocation } from 'react-router-dom';

export default function SelectPartner(props) {
  const {
    id,
    name,
    label,
    error,
    helperText,
    required,
    size,
    disabled,
    fullWidth,
    variant,
    value,
    onSelected,
    ...rest
  } = props;

  const [selectedValue, setSelectedValue] = useState({ label: '---SELECT---', value: '' });
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    page_size: 20,
    page_number: 1,
    sort_by: 'name',
    order_by: 'asc',
  });

  const location = useLocation();

  const handleSearchChange = (e) => {
    debounce(() => {
      setParams({
        ...params,
        search: e.target.value,
      });
    }, 500);
  };

  /**
   * fetch partners
   */
  const fetchPartners = async () => {
    setIsLoading(true);
    try {
      let endpoint = `${app.apiBaseUrl + apiEndpoints.getPartners}?${new URLSearchParams(params)}`;
      let response = await http.get(endpoint);
      let { data, pagination } = response.data;
      if (data) {
        data = data.map((p) => ({ label: p.name, value: p.id }));
        let index = options.findIndex((o) => o.id === selectedValue?.value);
        if (index === -1) data.unshift(selectedValue);
        setOptions(data);
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  /**
   * handle on autocomplete change
   */
  const handleChange = (e, value) => {
    e.preventDefault();
    setSelectedValue(value);
    if (typeof onSelected === 'function') onSelected(value?.value || undefined);
  };

  /**
   * fetch partner
   */
  const fetchPartner = async (partnerId) => {
    try {
      let endpoint = buildApiRoute(apiEndpoints.getPartner, partnerId);
      let response = await http.get(endpoint);
      let partner = response?.data?.data;
      if (partner) {
        let index = options.findIndex((o) => o?.value === partner.id);
        if (index === -1) setOptions([...options, { label: partner.name, value: partner.id }]);
        setSelectedValue({ label: partner.name, value: partner.id });
      }
    } catch (err) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (params?.search?.length > 1) fetchPartners();
  }, [JSON.stringify(params)]);

  useEffect(() => {
    if (isEmpty(value)) fetchPartners();
  }, []);

  useEffect(() => {
    if (!value || !params.search) fetchPartners();
  }, [value, params]);

  // useEffect(() => {
  //   if (!open) setOptions([]);
  // }, [open]);

  useEffect(() => {
    if (value) fetchPartner(value);
  }, []);

  useEffect(() => {
    if (isEmpty(value)) {
      setSelectedValue({ label: '---SELECT---', value: '' });
    }
  }, [location.pathname]);

  return (
    <Autocomplete
      id={id}
      value={selectedValue}
      // {...(value ? { value: selectedValue } : {})}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={handleChange}
      options={options}
      loading={isLoading}
      autoHighlight
      getOptionLabel={(option) => option?.label ?? ''}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ '& > img': { px: 2, py: 4, flexShrink: 0, border: '1px solid red' } }}
          {...props}
          key={option?.value ?? 't' + Math.random()}
        >
          {option?.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          onChange={handleSearchChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          variant={variant}
          fullWidth={fullWidth}
          error={error}
          helperText={helperText}
          required={required}
        />
      )}
      size={size}
      {...rest}
    />
  );
}

SelectPartner.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small', 'large']),
  fullWidth: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['outlined', 'standard', 'filled']),
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'info', 'success', 'warning']),
  onSelected: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

SelectPartner.defaultProps = {
  id: 'select-partner-' + Math.random(),
  label: 'Select Partner',
  error: false,
  helperText: '',
  required: false,
  disabled: false,
  fullWidth: true,
  size: 'small',
  variant: 'outlined',
};
