import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import MuiPagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

export default function Pagination(props) {
  const { page, count, onChange, onRowPerPageChange, enableRowPerPage, perPageValue, sx } = props;

  const perPageOptions = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        p: 1,
        justifyContent: enableRowPerPage ? 'space-between' : 'flex-end',
        ...sx
      }}
    >
      {enableRowPerPage && (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography sx={{ color: 'grey.700' }}>Rows per page:</Typography>
          <div>
            <FormControl variant="standard">
              <Select
                value={perPageValue ?? 10}
                defaultValue={perPageValue}
                onChange={(e) => onRowPerPageChange(e.target.value)}
              >
                {perPageOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    selected={parseInt(option.value) === parseInt(perPageValue)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Box>
      )}

      <MuiPagination
        color="primary"
        variant="outlined"
        shape="rounded"
        page={page}
        count={count}
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(_, value) => onChange(value)}
      />
    </Box>
  );
}

Pagination.propTypes = {
  page: PropTypes.number,
  count: PropTypes.number,
  onChange: PropTypes.func,
  onRowPerPageChange: PropTypes.func,
  enableRowPerPage: PropTypes.bool,
  perPageValue: PropTypes.number,
  sx: PropTypes.object
};

Pagination.defaultProps = {
  enableRowPerPage: true,
  sx: {}
};
