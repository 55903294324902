import React from 'react';
import Grid from '@mui/material/Grid';
import PageContent from 'layouts/PageContent';
import MyProfile from 'components/auth/MyProfile';

export default function Profile() {
  return (
    <PageContent title="My Profile">
      <MyProfile />
    </PageContent>
  );
}
