import { createAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';

import Notify from 'utils/Notify';
import http from 'app/services/httpService';
import { callWrapperSaga } from 'app/services/sagaService';
import apiEndpoints, { buildApiRoute } from 'routes/apiEndpoints';

import {
  getLicensingPartnerInit,
  getLicensingPartnerSuccess,
  getLicensingPartnerFailure,
  getLicensingPartnersInit,
  getLicensingPartnersSucess,
  getLicensingPartnersFailure,
  addLicensingPartnerInit,
  addLicensingPartnerSuccess,
  addLicensingPartnerFailure,
  updateLicensingPartnerInit,
  updateLicensingPartnerSuccess,
  updateLicensingPartnerFailure,
  deleteLicensingPartnerInit,
  deleteLicensingPartnerSuccess,
  deleteLicensingPartnerFailure,
} from './licensingPartnerSlice';

const getLicensingPartners = createAction('licensingPartners/list');
const getLicensingPartner = createAction('licensingPartners/get');
const createLicensingPartner = createAction('licensingPartners/create');
const updateLicensingPartner = createAction('licensingPartners/update');
const deleteLicensingPartner = createAction('licensingPartners/delete');

function* createLicensingPartnerSaga({ payload: data }) {
  yield put(addLicensingPartnerInit());

  try {
    let response = yield callWrapperSaga(() =>
      http.post(apiEndpoints.createLicensingPartner, data),
    );

    yield put(addLicensingPartnerSuccess(response.data));

    Notify.success('New Licensing Partner created successfully');
  } catch (err) {
    yield put(addLicensingPartnerFailure(err));

    Notify.error('Failed to create new Licensing Partner');
  }
}

function* updateLicensingPartnerSaga({ payload: { licensingPartnerId, data } }) {
  try {
    yield put(updateLicensingPartnerInit());
    let endpoint = buildApiRoute(apiEndpoints.updateLicensingPartner, licensingPartnerId);
    let response = yield callWrapperSaga(() => http.put(endpoint, data));
    yield put(updateLicensingPartnerSuccess(response.data));
  } catch (err) {
    yield put(updateLicensingPartnerFailure(err));
    Notify.error('Failed to update Licensing Partner');
  }
}

function* getLicensingPartnersSaga({ payload }) {
  yield put(getLicensingPartnersInit());

  try {
    let endpoint = `${apiEndpoints.getLicensingPartners}?${new URLSearchParams(payload)}`;

    let response = yield callWrapperSaga(() => http.get(endpoint));

    yield put(getLicensingPartnersSucess(response.data));
  } catch (error) {
    yield put(getLicensingPartnersFailure(error));
  }
}

function* getLicensingPartnerSaga({ payload: partnerId }) {
  try {
    yield put(getLicensingPartnerInit());
    let endpoint = buildApiRoute(apiEndpoints.getLicensingPartner, partnerId);
    let response = yield callWrapperSaga(() => http.get(endpoint, partnerId));

    yield put(getLicensingPartnerSuccess(response.data));
  } catch (error) {
    yield put(getLicensingPartnerFailure(error));
  }
}

function* deleteLicensingPartnerSaga({ payload: licensingPartnerId }) {
  try {
    yield put(deleteLicensingPartnerInit());
    let endpoint = buildApiRoute(apiEndpoints.deleteLicensingPartner, licensingPartnerId);
    yield callWrapperSaga(() => http.delete(endpoint));
    Notify.success('Licensing Partner Deleted Successfully');
  } catch (error) {
    Notify.error('Failed to delete Licensing Partner');
    yield put(deleteLicensingPartnerFailure(error));
  }
}

function* licensingPartnerSaga() {
  yield takeLatest(getLicensingPartners, getLicensingPartnersSaga);
  yield takeLatest(getLicensingPartner, getLicensingPartnerSaga);
  yield takeLatest(createLicensingPartner, createLicensingPartnerSaga);
  yield takeLatest(updateLicensingPartner, updateLicensingPartnerSaga);
  yield takeLatest(deleteLicensingPartner, deleteLicensingPartnerSaga);
}

export default licensingPartnerSaga;

export {
  getLicensingPartners,
  getLicensingPartner,
  createLicensingPartner,
  updateLicensingPartner,
  deleteLicensingPartner,
};
