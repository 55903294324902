import React from 'react';
import PropTypes from 'prop-types';
import PartnerCountries from 'components/partner-countries/PartnerCountries';
import PartnerCountryBriefDetailsModal from 'components/partner-countries/PartnerCountryBriefDetailsModal';

export default function PartnerCountriesTab({ partnerId }) {
  return (
    <>
      <PartnerCountries preFilterByPartner={true} partnerId={partnerId} />
      <PartnerCountryBriefDetailsModal />
    </>
  );
}

PartnerCountriesTab.propTypes = {
  partnerId: PropTypes.string.isRequired,
};
