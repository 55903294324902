import PropTypes from 'prop-types';
import ucwords from 'helpers/ucwords';
import Chip from '@mui/material/Chip';
import CachedIcon from '@mui/icons-material/Cached';
import PendingIcon from '@mui/icons-material/Pending';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

import { kycStatuses } from 'app/config/KYC';

export default function KycStatus({ status, size }) {
  let icon;

  let color;

  switch (status) {
    case kycStatuses.created:
      icon = <EventAvailableIcon size={size} />;
      color = 'info';
      break;

    case kycStatuses.pending:
      icon = <PendingIcon size={size} />;
      color = 'warning';
      break;

    case kycStatuses.processing:
      icon = <CachedIcon size={size} />;
      color = 'secondary';
      break;

    case kycStatuses.completed:
      icon = <CheckCircleIcon size={size} />;
      color = 'success';
      break;

    case kycStatuses.rejected:
      icon = <HighlightOffIcon size={size} />;
      color = 'error';
      break;

    case kycStatuses.expired:
      icon = <EventBusyIcon size={size} />;
      color = 'error';
      break;
  }

  return <Chip size={size} icon={icon} color={color} label={ucwords(status)} />;
}

KycStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(kycStatuses)),
  size: PropTypes.oneOf(['inherit', 'large', 'medium', 'small']),
};

KycStatus.defaultProps = {
  size: 'small',
};
