import { createAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import Notify from 'utils/Notify';
import guestHttp from 'app/services/guestHttpService';

import { setIsCheckingInternetConnection, setIsNetworkError } from './appSlice';

const checkInternetConnection = createAction('app/checkInternetConnection');

function* checkInternetConnectionSaga() {
  try {
    yield put(setIsCheckingInternetConnection(true));
    yield call(() => guestHttp.get('/'));
  } catch (err) {
    if (err?.message === 'Network Error' || err?.code === 'ERR_NETWORK') {
      yield put(setIsNetworkError(true));
    } else {
      yield put(setIsNetworkError(false));
      Notify.success("You're back online.");
    }
    yield put(setIsCheckingInternetConnection(false));
  }
}

function* authSaga() {
  yield takeLatest(checkInternetConnection, checkInternetConnectionSaga);
}

export default authSaga;
export { checkInternetConnection };
