import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ucwords from 'helpers/ucwords';
import { getCountries } from 'app/features/constants/constantSaga';

const useCountries = () => {
  const dispatch = useDispatch();

  const { data: countries, isLoading } = useSelector((state) => state.constants.countries);

  useEffect(() => {
    if (countries.length <= 0 && !isLoading) dispatch(getCountries());
  }, []);

  const getCountryNameByIso3Code = useCallback(
    (iso3) => {
      if (countries.length <= 0) {
        return iso3;
      }

      let countryName =
        countries?.filter((c) => c.iso3?.toLowerCase() === iso3?.toLowerCase())?.[0]?.country ??
        undefined;

      return countryName ? ucwords(countryName) : iso3;
    },
    [countries],
  );

  return { countries, getCountryNameByIso3Code };
};

export default useCountries;
