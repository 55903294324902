import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';

import Error from 'core/errors/Error';
import Button from 'core/buttons/Button';
import FormCheckbox from 'core/forms/FormCheckbox';
import TableContainer from 'core/table/TableContainer';
import TanstackReactTable from 'core/table/TanstackReactTable';

import isEmpty from 'helpers/isEmpty';
import Modal from 'app/components/Modal';
import constants from 'app/config/constants';
import { getCustomerKycDocument } from 'app/features/customer-kyc-documents/customerKycDocumentsSaga';
import { getCustomerKycDocumentFailure } from 'app/features/customer-kyc-documents/customerKycDocumentSlice';

import PdfViewerIcon from '../../assets/images/pdfViewer.png';
import CustomerKycDocumentPagination from './CustomerKycDocumentPagination';

export default function CustomerKycDocument({ customerId }) {
  const dispatch = useDispatch();
  const [isModalOen, setIsModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);

  const handleOpenModal = () => {
    setPdfUrl(pdfUrl);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setPdfUrl('');
  };

  const handleOpenImageModal = () => {
    setImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setIsModalOpen(false);
    setImageUrl('');
  };

  const [columnVisibility, setColumnVisibility] = useState({});

  const {
    data: customerKycDocument,
    isLoading: documentLoading,
    isError: documentError,
    errorCode,
    errorMessage,
  } = useSelector((state) => state.customerKycDocument.show);

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row, rowIndex) => rowIndex + 1,
        cell: (info) => info.row.index + 1,
      },

      {
        header: 'Document',
        accessorFn: (row) => row.document,
        cell: ({ row }) => (
          <>
            {isEmpty(row.original.document) ? (
              'No Documents'
            ) : (
              <Box size="small">
                {row.original.format === 'pdf' ? (
                  <Box
                    onClick={() => {
                      handleOpenModal();
                      setPdfUrl(row.original.document);
                    }}
                  >
                    <img src={PdfViewerIcon} alt="pdf-viewer" height={100} width={100} />
                  </Box>
                ) : (
                  <img
                    onClick={() => {
                      handleOpenImageModal();
                      setImageUrl(row.original.document);
                    }}
                    src={row.original.document}
                    height={100}
                    width={100}
                    alt="Document"
                    sx={{
                      objectFit: 'contain',
                    }}
                  />
                )}
              </Box>
            )}
          </>
        ),
      },
      {
        header: 'Format',
        accessorFn: (row) => row.format,
      },
      {
        header: 'Customer ID',
        accessorFn: (row) => row.customer_Id,
      },
      {
        header: 'Side',
        accessorFn: (row) => row.side,
      },
      {
        header: 'Status',
        accessorFn: (row) => row.status,
        cell: ({ row }) => <>{row.status ?? 'N/A'}</>,
      },
    ],
    [],
  );

  const table = useReactTable({
    data: customerKycDocument,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });
  Modal;

  useEffect(() => {
    if (customerId) {
      dispatch(getCustomerKycDocument(customerId));
    }
  }, [customerId, dispatch]);

  return (
    <TableContainer>
      {documentLoading ? (
        <Typography alignItems="center">Loading...</Typography>
      ) : documentError ? (
        <Error
          status={errorCode}
          message={errorMessage}
          onRetry={() => dispatch(getCustomerKycDocumentFailure())}
        />
      ) : !customerKycDocument || customerKycDocument.length === 0 ? (
        <Typography alignItems="center">No documents found.</Typography>
      ) : (
        <>
          <TanstackReactTable
            table={table}
            isLoading={documentLoading}
            data={customerKycDocument}
            columnsCount={columns.length}
          />
          <CustomerKycDocumentPagination />
        </>
      )}
      <Modal
        sx={{
          height: '700px',
          width: '900px',
        }}
        open={isModalOen}
        onClose={handleCloseModal}
      >
        {pdfUrl ? (
          <Box>
            <a href={pdfUrl} target="_blank" rel="noreferrer">
              <Button size="small">Open in New Window</Button>
            </a>

            <iframe src={pdfUrl} height={680} width={870} />
          </Box>
        ) : (
          <img
            src={imageUrl}
            height={650}
            width={850}
            style={{
              objectFit: 'contain',
            }}
          />
        )}
      </Modal>
    </TableContainer>
  );
}

CustomerKycDocument.propTypes = {
  customerId: PropTypes.string.isRequired,
};
