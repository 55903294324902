import { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';

import Error from 'core/errors/Error';
import MenuButton from 'core/buttons/MenuButton';
import FormCheckbox from 'core/forms/FormCheckbox';
import TableContainer from 'core/table/TableContainer';
import TransactionsActions from './ZaiTransactionsActions';
import TanstackReactTable from 'core/table/TanstackReactTable';

import { buildRoute } from 'routes';
import DateUtils from 'utils/DateUtils';
import useAuthUser from 'hooks/useAuthUser';
import constants from 'app/config/constants';
import Dimensions from 'app/config/Dimensions';
import ZaiTransactionPagination from './ZaiTransactionPagination';
import { getZaiTransactions } from 'app/features/transactions/zaiTransactionSaga';
import {
  openCheckBalanceModal,
  openMakePaymentModal,
  openRefundPaymentModal,
} from 'app/features/transactions/zaiTransactionSlice';
import { getTransactionBriefDetailsInit } from 'app/features/transactions/zaiTransactionSlice';

export default function ZaiTransactions({ filterByCustomer, customerId }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const user = useAuthUser();

  const [columnVisibility, setColumnVisibility] = useState({});

  const transactionState = useSelector((state) => state.zaiTransactions.index);

  const { data, params, isLoading, isError, errorCode } = transactionState;

  const columns = useMemo(
    () => [
      {
        id: constants.TABLE_CHECKBOX_SELECT_CHECKBOX_ID,
        header: ({ table }) => (
          <FormCheckbox
            forTableHead={true}
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <FormCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: constants.TABLE_SERIAL_NUMBER_COLUMN_ID,
        header: 'SN',
        accessorFn: (row) => row.sn,
      },
      {
        header: 'Customer',
        accessorFn: (row) => row.user_name,
      },
      ...(user?.isSuperAdmin()
        ? [
            {
              header: 'Partner Name',
              accessorFn: (row) => row.partner_name,
            },
          ]
        : []),
      {
        header: 'Funding Type',
        accessorFn: (row) => row.funding_type,
      },

      {
        header: 'Purpose',
        accessorFn: (row) => row.txn_purpose,
      },
      {
        header: 'Amount',
        accessorFn: (row) => row.amount,
        cell: ({ getValue }) => <Typography align="right">{getValue()}</Typography>,
      },
      {
        header: 'Currency',
        accessorFn: (row) => row.currency,
      },
      {
        header: 'Created Date',
        accessorFn: (row) => DateUtils.isoToString(row.created_ts),
      },
      {
        header: 'Updated Date',
        accessorFn: (row) => (row.updated_ts ? DateUtils.isoToString(row.updated_ts) : '-'),
      },
      {
        id: constants.TABLE_ACTION_COLUMN_ID,
        header: 'Action',
        accessorFn: (row) => row.id,
        cell: ({ getValue, row }) => (
          <Box display="flex" gap={1}>
            <IconButton
              size={Dimensions.table.iconButton.size}
              onClick={() => {
                dispatch(
                  getTransactionBriefDetailsInit({
                    showBriefDetailModal: true,
                    transactionId: getValue(),
                  }),
                );
              }}
            >
              <AspectRatioIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
            <IconButton
              size={Dimensions.table.iconButton.size}
              onClick={() => navigate(buildRoute('zai-transactions.show', getValue()))}
            >
              <FullscreenIcon fontSize={Dimensions.table.iconButton.iconSize} />
            </IconButton>
            <MenuButton
              ButtonProps={{
                children: 'Actions',
              }}
            >
              <MenuItem
                onClick={() =>
                  dispatch(openCheckBalanceModal({ customerId: row.original.user_id }))
                }
              >
                <AccountBalanceWalletRoundedIcon /> Check Balance
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() =>
                  dispatch(
                    openMakePaymentModal({
                      transactionId: row.original.id,
                      customerName: row.original.user_name,
                      customerId: row.original.user_id,
                    }),
                  )
                }
              >
                <PaymentRoundedIcon />
                Make Payment
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() =>
                  dispatch(
                    openRefundPaymentModal({
                      customerName: row.original.user_name,
                      customerId: row.original.user_id,
                    }),
                  )
                }
              >
                <RefreshRoundedIcon /> Refund Payment
              </MenuItem>
            </MenuButton>
          </Box>
        ),
      },
    ],
    [],
  );

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    dispatch(
      getZaiTransactions({
        ...params,
        ...(filterByCustomer
          ? {
              user_id: customerId,
            }
          : {}),
      }),
    );
  }, [JSON.stringify(params)]);

  if (isError) return <Error status={errorCode} />;

  return (
    <TableContainer>
      <TransactionsActions
        table={table}
        enableFilterByPartner={!filterByCustomer}
        defaultFilterQueries={{
          ...(filterByCustomer
            ? {
                user_id: customerId,
              }
            : {}),
        }}
      />
      <TanstackReactTable
        table={table}
        isLoading={isLoading}
        data={data}
        columnsCount={
          columns.length - Object.entries(columnVisibility ?? {}).filter(([k, v]) => !v).length
        }
      />
      <ZaiTransactionPagination />
    </TableContainer>
  );
}
