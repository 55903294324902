import { createAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';

import http from 'app/services/httpService';
import apiEndpoints from 'routes/apiEndpoints';
import { callWrapperSaga } from 'app/services/sagaService';
import { setCountriesData, setCountriesIsLoading } from './constantSlice';

const getCountries = createAction('constants/get_countries');

/**
 * get a countries list
 *
 */
function* getCountriesSaga() {
  yield put(setCountriesIsLoading(true));
  try {
    const payload = yield callWrapperSaga(() => http.get(apiEndpoints.getCountries));
    yield put(setCountriesData(payload?.data?.data ?? []));
  } catch (err) {
    console.error(err);
  }
  yield put(setCountriesIsLoading(false));
}

function* constantSaga() {
  yield takeLatest(getCountries, getCountriesSaga);
}

export default constantSaga;
export { getCountries };
