import { createSlice } from '@reduxjs/toolkit';

import app from 'app/config/app';
import isEmpty from 'helpers/isEmpty';
import Response from 'app/config/Response';

const initialState = {
  index: {
    data: [],
    params: {
      page_size: app.perPage || 10,
      page_number: 1,
      sort_by: 'created_ts',
      order_by: 'desc',
    },
    isLoading: false,
    totalCount: 0,
    totalPage: 0,
    isError: false,
    errorCode: null,
    errorMessage: null,
  },
  create: {
    showProcessingModal: false,
    isCreating: false,
    isCreated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },
  edit: {
    isLoading: false,
    isError: false,
    errorCode: null,
    data: null,
    errorMessage: null,
  },
  update: {
    showProcessingModal: false,
    isUpdating: false,
    isUpdated: false,
    isFailed: false,
    errors: null,
    data: null,
    retries: 0,
    errorMessage: null,
  },
  show: {
    showBriefDetailModal: false,
    partnerCountryId: null,
    isLoading: false,
    isError: false,
    errorCode: null,
    errorMessage: null,
    data: null,
  },
};

export const partnerCountrySlice = createSlice({
  name: 'partnerCountries',
  initialState,
  reducers: {
    getPartnerCountriesInit: (state) => {
      state.index = {
        ...state.index,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    getPartnerCountriesSuccess: (state, { payload }) => {
      let { data, pagination } = payload;

      const { totalCount, totalPage, currentPage, pageSize } = pagination;

      let startSN = (currentPage - 1) * pageSize;

      data = data?.map((item) => ({ sn: (startSN += 1), ...item }));

      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data,
        totalCount,
        totalPage,
      };
    },

    getPartnerCountriesFailure: (state, { payload }) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
      };
    },

    resetGetPartnerCountriesFailure: (state) => {
      state.index = {
        ...state.index,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
      };
    },

    addPartnerCountryInit: (state) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: true,
        isCreated: false,
        isFailed: false,
        errors: null,
        data: null,
      };
    },

    addPartnerCountrySuccess: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },

    addPartnerCountryFailure: (state, { payload }) => {
      state.create = {
        ...state.create,
        showProcessingModal: true,
        isCreating: false,
        isCreated: false,
        isFailed: true,
        errors: null,
        data: null,
        errorMessage: payload?.response?.data?.message || '',
      };
    },

    retryToCreate: (state) => {
      state.create.retries += 1;
    },

    setShowCreateProcessingModal: (state, { payload }) => {
      state.create.showProcessingModal = payload;
    },

    editPartnerCountryInit: (state) => {
      state.edit = {
        ...state.edit,
        isLoading: true,
        isError: false,
        errorCode: null,
        data: null,
        errorMessage: null,
      };

      state.update = {
        ...state.update,
        showProcessingModal: false,
      };
    },

    editPartnerCountrySuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.edit = {
          ...state.edit,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          data: null,
          errorMessage: 'PartnerCountry not found',
        };
      } else {
        state.edit = {
          ...state.edit,
          isLoading: false,
          isError: false,
          errorCode: null,
          data: payload.data,
        };
      }
    },

    editPartnerCountryFailure: (state, { payload }) => {
      state.edit = {
        ...state.edit,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        data: payload,
      };
    },

    updatePartnerCountryInit: (state) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: true,
        isUpdated: false,
        isFailed: false,
        errors: null,
        data: null,
      };
    },

    updatePartnerCountrySuccess: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: false,
        isUpdated: true,
        isFailed: false,
        errors: null,
        data: payload.data,
      };
    },

    updatePartnerCountryFailure: (state, { payload }) => {
      state.update = {
        ...state.update,
        showProcessingModal: true,
        isUpdating: false,
        isUpdated: false,
        isFailed: true,
        errors: payload,
        data: null,
        errorMessage: payload?.response?.data?.message || '',
      };
    },

    retryToUpdate: (state) => {
      state.update.retries += 1;
    },

    setShowEditProcessingModal: (state, { payload }) => {
      state.update.showProcessingModal = payload;
    },

    setParam: (state, { payload }) => {
      state.index.params = { ...state.index.params, ...payload };
    },

    resetParam: (state) => {
      state.index.params = {
        page_size: state.index.params.page_size,
        page_number: 1,
      };
    },

    deleteFilterParams: (state, { payload }) => {
      if (Array.isArray(payload)) payload.forEach((key) => delete state.index.params[key]);
      else delete state.index.params[payload];
    },

    getPartnerCountryInit: (state) => {
      state.show = {
        ...state.show,
        isLoading: true,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getPartnerCountrySuccess: (state, { payload }) => {
      if (isEmpty(payload?.data)) {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: true,
          errorCode: Response.HTTP_NOT_FOUND,
          errorMessage: 'PartnerCountry not found',
        };
      } else {
        state.show = {
          ...state.show,
          isLoading: false,
          isError: false,
          errorCode: null,
          errorMessage: null,
          data: payload.data,
        };
      }
    },

    getPartnerCountryFailure: (state, { payload }) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: true,
        errorCode: payload?.response?.status ?? 0,
        errorMessage: payload?.response?.message,
        data: null,
      };
    },

    resetGetPartnerCountryFailure: (state) => {
      state.show = {
        ...state.show,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    getPartnerCountryBriefDetailsInit: (state, { payload }) => {
      state.show = {
        ...state.show,
        showBriefDetailModal: payload.showBriefDetailModal,
        partnerCountryId: payload.partnerCountryId,
      };
    },

    hidePartnerCountryBriefDetails: (state) => {
      state.show = {
        showBriefDetailModal: false,
        partnerCountryId: null,
        isLoading: false,
        isError: false,
        errorCode: null,
        errorMessage: null,
        data: null,
      };
    },

    deletePartnerCountryInit: (state, { payload: partnerCountryId }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == partnerCountryId) {
          return {
            ...item,
            isDeleting: true,
          };
        }
        return item;
      });
    },

    deletePartnerCountrySuccess: (state, { payload: partnerCountryId }) => {
      const { page_number: currentPage, page_size: pageSize } = state.index.params;

      let startSN = (currentPage - 1) * pageSize;

      let data = state.index.data.filter((item) => item.id !== partnerCountryId);

      state.index.data = data.map((item) => ({ ...item, sn: (startSN += 1) }));
    },

    deletePartnerCountryFailure: (state, { payload: partnerCountryId }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == partnerCountryId) {
          return {
            ...item,
            isDeleting: false,
          };
        }
        return item;
      });
    },

    updateIsActiveStatusInit: (state, { payload: { partnerCountryId, isActive } }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == partnerCountryId) {
          return {
            ...item,
            is_active: isActive,
            isTogglingIsActiveStatus: true,
          };
        }
        return item;
      });
    },

    updateIsActiveStatusSuccess: (state, { payload: { partnerCountryId, isActive } }) => {},

    updateIsActiveStatusFailure: (state, { payload: { partnerCountryId, isActive } }) => {
      state.index.data = state.index.data.map((item) => {
        if (item.id == partnerCountryId) {
          return {
            ...item,
            is_active: !isActive,
            isTogglingIsActiveStatus: false,
          };
        }
        return item;
      });
    },
  },
});

export const {
  getPartnerCountriesInit,
  getPartnerCountriesSuccess,
  getPartnerCountriesFailure,
  resetGetPartnerCountriesFailure,
  addPartnerCountryInit,
  addPartnerCountrySuccess,
  addPartnerCountryFailure,
  retryToCreate,
  updatePartnerCountryInit,
  updatePartnerCountrySuccess,
  updatePartnerCountryFailure,
  retryToUpdate,
  setParam,
  resetParam,
  deleteFilterParams,
  setShowCreateProcessingModal,
  setShowEditProcessingModal,
  getPartnerCountryInit,
  getPartnerCountrySuccess,
  getPartnerCountryFailure,
  resetGetPartnerCountryFailure,
  editPartnerCountryInit,
  editPartnerCountrySuccess,
  editPartnerCountryFailure,
  getPartnerCountryBriefDetailsInit,
  hidePartnerCountryBriefDetails,
  deletePartnerCountryInit,
  deletePartnerCountrySuccess,
  deletePartnerCountryFailure,
  updateIsActiveStatusInit,
  updateIsActiveStatusFailure,
  updateIsActiveStatusSuccess,
} = partnerCountrySlice.actions;

export default partnerCountrySlice.reducer;
