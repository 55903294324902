import { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Tabs from 'core/tabs/Tabs';
import AccountDetailsTab from './tabs/AccountDetailsTab';

import { getAccount } from 'app/features/accounts/accountSaga';

function Account({ accountId }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccount(accountId));
  }, [accountId]);

  return (
    <Tabs
      tabs={[
        {
          key: 'partner-details',
          tabName: 'General Details',
          tabContent: <AccountDetailsTab />
        }
      ]}
    />
  );
}

export default memo(Account);

Account.propTypes = {
  accountId: PropTypes.string.isRequired
};
