import { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

import Button from 'core/buttons/Button';
import MoreActions from 'core/lab/MoreActions';
import ReloadButton from 'core/buttons/ReloadButton';
import TableGridFilter from 'core/table/TableGridFilter';
import ColumnVisibility from 'core/table/ColumnVisibility';
import TableActionsContainer from 'core/table/TableActionsContainer';

import Sheet from 'utils/Sheet';
import app from 'app/config/app';
import ucwords from 'helpers/ucwords';
import FilterType from 'app/config/FilterType';
import useQueryParams from 'hooks/useQueryParams';
import FinmoPayinStatus from 'app/config/FinmoPayinStatus';
import { getFinmoPayins } from 'app/features/finmo-payins/finmoPayinSaga';
import { setParam, deleteFilterParams } from 'app/features/finmo-payins/finmoPayinSlice';

export default function FinmoPayinsActions({ table }) {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const finmoPayinState = useSelector((state) => state.finmoPayins.index);

  const [queryParams, updateQueryParams] = useQueryParams(app.ignoreFilterKeys);

  let availableFilters = [
    {
      label: 'Status',
      value: 'status',
      type: FilterType.select,
      options: Object.entries(FinmoPayinStatus).map(([key, value]) => ({
        label: ucwords(value),
        value: value,
      })),
    },
    {
      label: 'Customer ID',
      value: 'customer_id',
      type: FilterType.input,
    },
    {
      label: 'Credit Wallet ID',
      value: 'credit_wallet_id',
      type: FilterType.input,
    },
    {
      label: 'Payin Method Name',
      value: 'payin_method_name',
      type: FilterType.input,
    },
    {
      label: 'Created At',
      value: 'created_at',
      type: FilterType.datepicker,
    },
    {
      label: 'Start Time',
      value: 'start_time',
      type: FilterType.epochTimestampPicker,
    },
    {
      label: 'End Time',
      value: 'end_time',
      type: FilterType.epochTimestampPicker,
    },
  ];

  const { params: appliedFilters } = finmoPayinState;

  const handleOnFilter = useCallback((data) => {
    dispatch(setParam({ ...data, page: 1 }));
  }, []);

  const handleOnReset = useCallback((e) => {
    dispatch(deleteFilterParams([...availableFilters.map((filter) => filter.value)]));
  });

  useEffect(() => {
    updateQueryParams(appliedFilters);
  }, [JSON.stringify(appliedFilters)]);

  useEffect(() => {
    dispatch(setParam(queryParams));
  }, [JSON.stringify(queryParams)]);

  return (
    <>
      <Box>
        <TableGridFilter
          showFilter={showFilter}
          appliedFilters={appliedFilters}
          availableFilters={availableFilters}
          onFilter={handleOnFilter}
          onReset={handleOnReset}
        />
      </Box>
      <TableActionsContainer>
        <Box></Box>
        <Box display="flex" gap={1}>
          <Button
            variant="outlined"
            endIcon={<FilterAltIcon />}
            onClick={() => setShowFilter(!showFilter)}
            uppercase
          >
            Filter
          </Button>

          {table && <ColumnVisibility table={table} />}
          <ReloadButton
            onClick={() => {
              dispatch(
                getFinmoPayins({
                  ...appliedFilters,
                  page: 1,
                }),
              );
            }}
          />
          <MoreActions
            onExportToCsv={() => Sheet.exportReactTableSelectedRowsToCsv(table, 'finmo_payins_')}
            onExportToExcel={() =>
              Sheet.exportReactTableSelectedRowsToExcel(table, 'finmo_payins_')
            }
          />
        </Box>
      </TableActionsContainer>
    </>
  );
}

FinmoPayinsActions.propTypes = {
  table: PropTypes.object,
};
